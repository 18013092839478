import { ITrackingSectionTemplate } from '../../global.models'
import { TrackingTemplate } from './tracking-template.model'
import { AllocationRule } from './allocation-rule.model'
import { BowlSetup } from '../bowls/bowl-setup/bowl-setup.model'
import { MenuSectionTemplate } from '../menu-templates/menu-section-template.model'
import { FormControl } from '@angular/forms'

export class TrackingSectionTemplate {
  public id: string
  public menuSectionTemplateId: string

  public bowlSetups: BowlSetup[]
  public allocationRuleIds: FormControl
  public allocationRules: AllocationRule[]

  private _savedTemplate: any

  constructor(public trackingSectionTemplate?: ITrackingSectionTemplate, public trackingTemplate?: TrackingTemplate) {
    this.id = trackingSectionTemplate?.id
    this.menuSectionTemplateId = trackingSectionTemplate?.menu_section_template_id

    this.bowlSetups = (trackingSectionTemplate?.bowl_setups || []).map((bowlSetup) => new BowlSetup(bowlSetup))
    this.allocationRuleIds = new FormControl(trackingSectionTemplate?.scale_groups?.map((scaleGroup) => scaleGroup.scale_group_id) || [])
    this._generateAllocationRules()

    this._savedTemplate = JSON.stringify(this.asDict)
  }

  public get menuSectionTemplate(): MenuSectionTemplate {
    return this.trackingTemplate.menuTemplate.menuSectionTemplateWithId(this.menuSectionTemplateId)
  }
  private _generateAllocationRules(): void {
    this.allocationRules = this.allocationRuleIds.value
      .filter((scaleGroupId) => this.trackingTemplate.location.scale_group_with_id(scaleGroupId)?.scales?.length)
      .map((allocationRuleId, index) => new AllocationRule({ scale_group_id: allocationRuleId, index: index }, this))
      .sort((a, b) => (this.trackingTemplate?.location?.scale_group_with_id(a.scaleGroupId.value)?.index?.value < this.trackingTemplate?.location?.scale_group_with_id(b.scaleGroupId.value)?.index?.value ? -1 : 1))
  }

  public get changed(): boolean {
    return this._savedTemplate != JSON.stringify(this.asDict)
  }

  public get isBowlSelectionValid(): boolean {
    return !(this.bowlSetups.find((bowlSetup) => bowlSetup.default.value) == undefined)
  }

  get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined && this.isBowlSelectionValid
  }

  public get asDict(): ITrackingSectionTemplate {
    return {
      id: this.id,
      menu_section_template_id: this.menuSectionTemplateId,
      bowl_setups: this.bowlSetups.map((bowlSetup) => bowlSetup.as_dict),
      scale_groups: this.allocationRuleIds.value
        .filter((allocationRuleId) => allocationRuleId != 'all')
        .map((allocationRuleId, index) => {
          return { scale_group_id: allocationRuleId, index: index }
        })
    }
  }

  public patchValues(trackingSectionTemplate?: ITrackingSectionTemplate): void {
    if (trackingSectionTemplate?.id) this.id = trackingSectionTemplate?.id
    if (trackingSectionTemplate?.menu_section_template_id) this.menuSectionTemplateId = trackingSectionTemplate?.menu_section_template_id
    this.bowlSetups = (trackingSectionTemplate?.bowl_setups || []).map((bowlSetup) => new BowlSetup(bowlSetup))
    this.allocationRuleIds = new FormControl(trackingSectionTemplate?.scale_groups?.map((scaleGroup) => scaleGroup.scale_group_id) || [])
    this._generateAllocationRules()

    this._savedTemplate = JSON.stringify(this.asDict)
  }

  public toggleBowlSetupSelection(bowlSetup: BowlSetup): void {
    const selectedBowlSetupIndex: number = this.bowlSetups.findIndex((selectedBowlSetup) => selectedBowlSetup.id == bowlSetup.id)
    if (selectedBowlSetupIndex >= 0 && !bowlSetup.isDefaultSelectionForTrackingSectionTemplate(this)) {
      this.bowlSetups.forEach((selectedBowlSetup) => selectedBowlSetup.default.setValue(false))
      this.bowlSetups[selectedBowlSetupIndex].default.setValue(true)
    } else if (selectedBowlSetupIndex >= 0) {
      this.bowlSetups.splice(selectedBowlSetupIndex, 1)
      if (!this.isBowlSelectionValid && this.bowlSetups.length > 0) this.bowlSetups[0].default.setValue(true)
    } else {
      const newBowlSetup = new BowlSetup(bowlSetup.as_dict)
      this.bowlSetups.push(newBowlSetup)
      if (!this.isBowlSelectionValid) newBowlSetup.default.setValue(true)
    }
  }
}
