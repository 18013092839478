import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Observable, Subscription } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { Gateway } from './gateway.model'
import { RestApiService } from '../../services/rest-api.service'
import { IGateway } from '../../global.models'
import { WebsocketsService } from '../../services/websockets.service'
import { ENotificationType, IPushNotification } from '../../global.types'
import moment from 'moment'
import { LocationsService } from '../location/locations.service'

@Injectable({
  providedIn: 'root'
})
export class GatewaysService {
  gateways: Gateway[] = []

  notificationSubscription: Subscription

  constructor(public fb: FormBuilder, private restApiService: RestApiService, private locationService: LocationsService, private websocketsService: WebsocketsService, @Inject(LOCALE_ID) public language: string) {}

  getGateways(fields?: string[], gatewayId?: string, locationIds?: string[], offset?: number): Observable<Gateway[]> {
    let params = {}
    if (fields) params['fields'] = fields.join(',')
    if (gatewayId) params['search_string'] = gatewayId
    if (locationIds?.length) params['location_ids'] = locationIds.join(',')
    if (offset != undefined) {
      params['limit'] = 10
      params['offset'] = offset
    }
    return this.restApiService.loadGateways(params).pipe(
      tap((response) => {
        response['locations']?.forEach((location) => this.locationService.addToLocations(location))
        response['gateways']?.forEach((gateway) => this.addToGateways(gateway))
      }),
      map((response) => response['gateways']?.map((gateway) => this.gateway_with_id(gateway.id)))
    )
  }

  addToGateways(gateway: IGateway): void {
    let existing_gateway: Gateway = this.gateway_with_id(gateway.id)
    if (!existing_gateway) this.gateways.push(new Gateway(gateway))
    else existing_gateway.patchValues(gateway)
  }

  gateway_with_id(gateway_id: string): Gateway {
    return this.gateways.find((gateway) => gateway.id == gateway_id)
  }

  saveGateway(gateway: Gateway): Observable<IGateway> {
    return this.restApiService.saveGateway(gateway.as_dict)
  }

  pingGateways() {
    this.gateways.forEach((gateway) => {
      this.websocketsService.pingGateway(gateway)
    })
  }

  listenForGatewayPings() {
    this.notificationSubscription = this.websocketsService.notification.subscribe((notification: IPushNotification) => {
      console.log('WebSocket: Recieved notification of type ' + notification.type + ' from gateway: ' + notification.source)
      switch (notification.type) {
        case ENotificationType.Ping: {
          let gateway = this.gateway_with_id(notification.source)
          if (gateway) {
            gateway.last_interaction = moment()
          }
          break
        }
      }
    })
  }

  get gateways_sorted(): Gateway[] {
    return this.gateways //.sort((a, b) => (a.serial > b.serial ? 1 : -1))
  }

  requestMenderInformation(gateway: Gateway): Observable<IGateway> {
    return this.restApiService.requestMenderInformation(gateway.id)
  }
}
