<div class="l-clear l-bottommargin-large l-display-flex">
    <div class="l-width-25p l-display-flex l-flex-column">
        <label class="l-bottommargin-large">Vælg vægt der skal udskiftes</label>
        <scale-picker (scaleSelected)="onScaleOutSelected($event)"></scale-picker>
    </div>
    <div class="l-width-25p l-display-flex l-flex-column l-leftmargin-small">
        <label class="l-bottommargin-large">Vælg vægt der skal indsættes</label>
        <scale-picker (scaleSelected)="onScaleInSelected($event)"></scale-picker>
    </div>
</div>

<div class="l-clear">
    <button [disabled]="!scale_out || !scale_in || swapping" (click)="swapScales()" class="l-float-right l-margin-vertical-large l-leftmargin-small l-padding-horizontal-semi-large l-padding-vertical-large l-font-medium l-button-dark">
        <span class="l-rightmargin-tiny l-display-inline-flex">
            <mat-spinner *ngIf="swapping" [diameter]="19" class="o-inline-block"></mat-spinner>
        </span>        
        <label>Udskift vægt</label>
    </button>
    <button (click)="closeView()" class="l-float-right l-margin-vertical-large l-leftmargin-small l-padding-horizontal-semi-large l-padding-vertical-large l-font-medium l-button-dark">
        <label>Annuller</label>
    </button>
</div>