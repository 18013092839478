import { AbstractControl, FormBuilder, FormControl } from '@angular/forms'
import { Names } from '../names/names.model'
import { FoodopLibModule } from '../../foodop-lib.module'
import { INutritionFact } from '../../global.models'

export class NutritionFact {
  nutrition_type_code_value: string
  names: Names
  base_unit: FormControl
  base_value: FormControl
  precision: FormControl
  preparation: FormControl
  unit: FormControl
  value: FormControl
  avg_daily_intake_pct: FormControl
  share: number

  fb: FormBuilder
  constructor(public nutrition_fact?: INutritionFact, required_nutrition_fact?: any) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.nutrition_type_code_value = required_nutrition_fact?.nutrition_type_code_value || nutrition_fact?.nutrition_type_code_value
    this.names = new Names(required_nutrition_fact?.names || nutrition_fact?.names)
    this.base_unit = this.fb.control(nutrition_fact?.base_unit || 'GRM')
    this.base_value = this.fb.control(nutrition_fact?.base_value || 100)
    this.precision = this.fb.control(nutrition_fact?.precision || 'APPROXIMATELY')
    this.preparation = this.fb.control(nutrition_fact?.preparation || 'UNPREPARED')
    this.unit = this.fb.control(this.nutrition_type_code_value == 'ENER-' ? (nutrition_fact?.unit == 'KJO' ? 'KJO' : 'E14') : 'GRM')
    this.value = this.fb.control(nutrition_fact?.value)
    this.avg_daily_intake_pct = this.fb.control(nutrition_fact?.avg_daily_intake_pct)
  }

  get display_unit(): string {
    switch (this.unit.value) {
      case 'GRM':
        return 'g'
      case 'MLT':
        return 'ml'
      case 'E14':
        return 'kcal'
    }
  }

  get formatted_nutrition_value(): string {
    if (this.nutrition_type_code_value == 'ENER-') return this.formatted_energy_nutrition
    else return Math.round(this.value.value * 10) / 10 + ' ' + this.display_unit
  }

  get formatted_energy_nutrition(): string {
    return Number(Math.round(parseFloat(this.value.value * 4.184 + 'e' + 0)) + 'e-' + 0) + ' kJ / ' + Number(Math.round(parseFloat(this.value.value + 'e' + 0)) + 'e-' + 0) + ' kcal'
  }

  get is_sub_category(): boolean {
    return this.nutrition_type_code_value == 'FASAT' || this.nutrition_type_code_value == 'SUGAR-'
  }

  get as_dict(): INutritionFact {
    return {
      nutrition_type_code_value: this.nutrition_type_code_value,
      names: this.names.as_dict,
      base_unit: this.base_unit.value,
      base_value: this.base_value.value,
      precision: this.precision.value,
      preparation: this.preparation.value,
      unit: this.unit.value,
      value: this.value.value,
      avg_daily_intake_pct: this.avg_daily_intake_pct.value
    }
  }
}
