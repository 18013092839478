export function caseString(string: string, capitalization: 'none' | 'upperCase' | 'titleCase' | 'lowerCase'): string {
  if (!string) return ''
  switch (capitalization) {
    case 'upperCase':
      return string.toUpperCase()
    case 'titleCase':
      return capitalizeFirstLetter(string)
    case 'lowerCase':
      return string.toLowerCase()
    default:
      return string
  }
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
