import { Injectable } from '@angular/core'
import { RestApiService } from '../../services/rest-api.service'
import { catchError, map, scan, tap } from 'rxjs/operators'
import { merge, Observable, Subject } from 'rxjs'
import { DisplayTemplate } from './display-template.model'
import { IDisplayTemplate } from '../../global.models'
import { defaultSelectedDishDisplayElements, defaultSelectedScaleDisplayElements } from '../../global.types'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'

@Injectable({
  providedIn: 'root'
})
export class DisplayTemplatesService {
  display_templates: DisplayTemplate[] = []
  private _newDisplayTemplates$: Subject<any> = new Subject<any>()

  constructor(private restApiService: RestApiService, private _subsidiaryService: SubsidiaryService) {}

  loadDisplayTemplates(): Observable<DisplayTemplate[]> {
    const loadPipe$ = this.restApiService.loadDisplayTemplates().pipe(catchError(() => []))

    return merge(loadPipe$, this._newDisplayTemplates$).pipe(
      scan((displayTemplates: any[], newDisplayTemplates: any[]) => {
        if (!newDisplayTemplates) return this.display_templates
        else {
          const map = new Map()
          displayTemplates.forEach((displayTemplate) => map.set(displayTemplate.id, displayTemplate))
          newDisplayTemplates.forEach((displayTemplate) => map.set(displayTemplate.id, { ...map.get(displayTemplate.id), ...displayTemplate }))
          return Array.from(map.values())
        }
      }),
      tap((displayTemplates) => displayTemplates?.sort((a, b) => (a.name > b.name ? 1 : -1))?.forEach((displayTemplate) => this.addToDisplayTemplates(displayTemplate))),
      map((display_templates) => display_templates.map((display_template) => this.display_template_with_id(display_template.id)))
    )
  }

  loadDisplayTemplateWithID(display_template_id?: string): Observable<IDisplayTemplate> {
    const params = {
      fields: 'all'
    }
    if (display_template_id) params['display_template_id'] = display_template_id

    return this.restApiService.loadDisplayTemplate(params).pipe(
      tap((display_template) => {
        if (display_template) {
          this.addToDisplayTemplates(display_template)
        }
      })
    )
  }

  saveDisplayTemplate(displayTemplate: DisplayTemplate): Observable<IDisplayTemplate> {
    if (displayTemplate.is_new) {
      return this.restApiService.createDisplayTemplate(displayTemplate.as_dict, { subsidiary_id: this._subsidiaryService.subsidiary.id }).pipe(tap((response) => this._newDisplayTemplates$.next([response])))
    } else return this.restApiService.updateDisplayTemplate(displayTemplate.as_dict, { subsidiary_id: this._subsidiaryService.subsidiary.id })
  }

  deleteTemplate(displayTemplate: DisplayTemplate): Observable<any> {
    return this.restApiService.deleteDisplayTemplate({ display_templatee_id: displayTemplate.id }).pipe(
      tap(() => {
        this.display_templates = this.display_templates.filter((template) => template.id != displayTemplate.id)
        this._newDisplayTemplates$.next(null)
      })
    )
  }

  addToDisplayTemplates(display_template: IDisplayTemplate): void {
    if (!this.display_templates.find((loaded_display_template) => loaded_display_template.id == display_template.id)) this.display_templates.push(new DisplayTemplate(display_template))
  }

  display_template_with_id(display_template_id: string): DisplayTemplate {
    return this.display_templates.find((display_template) => display_template.id == display_template_id)
  }
}
