import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { RestApiService } from '../../services/rest-api.service'
import { IAccessRole } from '../../global.models'
import { AccessRole } from './access-role.model'

@Injectable({
  providedIn: 'root'
})
export class AccessRolesService {
  access_roles: AccessRole[] = []

  constructor(public fb: FormBuilder, private restApiService: RestApiService, @Inject(LOCALE_ID) public language: string) {}

  loadAccessRoles(): Observable<AccessRole[]> {
    const params = {
      fields: 'all',
      active: true
    }
    return this.restApiService.getAllAccessRoles(params).pipe(
      tap((access_roles) => {
        access_roles.forEach((access_role) => this.addToAccessRoles(access_role))
      }),
      map((access_roles) => access_roles.map((access_role) => this.access_role_with_id(access_role.id)))
    )
  }

  addToAccessRoles(access_role: IAccessRole): void {
    let existing_access_role: AccessRole = this.access_role_with_id(access_role.id)
    if (!existing_access_role) this.access_roles.push(new AccessRole(access_role))
  }

  access_role_with_id(access_role_id: string): AccessRole {
    return this.access_roles.find((access_role) => access_role.id == access_role_id)
  }
}
