import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, Output, SimpleChanges } from '@angular/core'
import * as d3 from 'd3'
import { Observable, Subscription, fromEvent } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import moment from 'moment'
import { GlobalFunctionsService } from 'foodop-lib'

@Component({
  selector: 'scatter-line-chart',
  template: ``,
  styleUrls: ['./chart-style.css']
})
export class ScatterLineChart implements OnChanges {
  @Input() bowl_setups
  @Input() trash_bowls
  @Input() raw_data
  @Input() presentation_data
  @Input() bowls
  @Input() signalReloadUpdateChart

  @Input() height = 400
  @Input() width = 1200
  @Input() container: string
  @Input() startTime: string
  @Input() endTime: string

  @Output() bowlClicked = new EventEmitter<any>()

  svg

  xScale
  yScale

  xAxis
  yAxis

  transform = ''
  axisBottomTransform = ''
  axisLeftTransform = ''
  labelTransform = ''
  chartareaTransform = ''

  area = []
  bowl_areas
  waste_areas
  eaten_areas
  refilled_areas
  bowl_pickers
  bowl_tooltips = {}
  processed_data_line
  raw_data_dots
  messageBox
  trash_legend
  dish_legend

  chartWidth: number
  chartHeight: number

  svgMargin = { top: 0, right: 0, bottom: 0, left: 0 }
  graphMargin = { top: 0.1, right: 0.05, bottom: 60, left: 0.05 }

  resizeSub: Subscription

  /*********************************/
  /*    INITIALIZATION & UPDATE    */
  /*********************************/
  constructor(@Inject(LOCALE_ID) public language: string, public func: GlobalFunctionsService) {}

  ngOnInit() {
    this.updateChart()

    this.resizeSub = fromEvent(window, 'resize')
      .pipe(debounceTime(400))
      .subscribe((e) => {
        this.updateChart()
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.presentation_data.length && changes['presentation_data'] && !changes['presentation_data']?.firstChange) {
      this.updateChart()
    }
    if (this.raw_data && changes['raw_data']) {
      this.updateChart()
    }
    if (this.presentation_data.length && this.bowls && changes['bowls'] && !changes['bowls']?.firstChange) {
      this.updateChart()
    }
    if (changes['signalReloadUpdateChart'] && !changes['signalReloadUpdateChart']?.firstChange) {
      this.updateChart()
    }
  }

  /*********************************/
  /*        LOGIC FUNCTIONS        */
  /*********************************/

  updateChart() {
    let container = document.getElementById(this.container)

    this.chartWidth = container.clientWidth - container.clientWidth * (this.graphMargin.left + this.graphMargin.right)
    this.chartHeight = container.clientHeight - container.clientHeight * this.graphMargin.top - this.graphMargin.bottom

    // SVG
    if (!this.svg) this.drawSVG(container)

    // X axis
    this.drawXAxis()

    // Y axis
    this.drawYAxis()

    // Draw line
    this.drawLine()

    // Draw dots
    this.drawDots()

    // Draw bowls
    this.drawBowls()

    // Draw waste areas
    this.drawWasteAreas()

    // Draw eaten areas
    this.drawEatenAreas()

    // Draw refilled areas
    this.drawRefilledAreas()

    // Draw legend
    this.drawLegend()

    // Draw bowl pickers
    this.drawBowlPickers()
  }

  drawSVG(container) {
    // append the svg object to the body of the page
    this.svg = d3
      .select('#' + this.container)
      .append('svg')
      .attr('width', container.clientWidth)
      .attr('height', container.clientHeight)
      .attr('class', 'l-position-relative')
      .append('g')
      .attr('transform', 'translate(' + container.clientWidth * this.graphMargin.left + ',' + container.clientHeight * this.graphMargin.top + ')')
  }
  drawXAxis() {
    this.xScale = d3
      .scaleTime()
      .range([0, this.chartWidth])
      .domain([new Date(this.startTime), new Date(this.endTime)])

    if (!this.xAxis) {
      this.xAxis = this.svg
        .append('g')
        .attr('transform', 'translate(0,' + this.chartHeight + ')')
        .call(d3.axisBottom(this.xScale).tickFormat(d3.timeFormat('%H:%M')))
    } else {
      this.xAxis
        .transition()
        .duration(200)
        .call(d3.axisBottom(this.xScale).tickFormat(d3.timeFormat('%H:%M')))
    }

    this.xAxis.selectAll('text').attr('transform', 'translate(-10,5)rotate(-45)').style('text-anchor', 'end')
  }

  drawYAxis() {
    this.yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(this.presentation_data, (d: any) => {
          return d.weight / 1000
        })
      ])
      .range([this.chartHeight, 0])

    if (!this.yAxis) {
      this.yAxis = this.svg.append('g').call(d3.axisLeft(this.yScale))
    } else {
      this.yAxis.transition().duration(200).call(d3.axisLeft(this.yScale))
    }
  }

  drawLine() {
    if (!this.processed_data_line) {
      this.processed_data_line = this.svg
        .append('path')
        .datum(this.presentation_data)
        .attr('fill', 'none')
        .attr('stroke', '#69b3a2')
        .attr('stroke-width', 1.5)
        .attr('class', 'processed_data_line')
        .attr(
          'd',
          d3
            .line()
            .x((d: any) => {
              return this.xScale(new Date(d.timestamp))
            })
            .y((d: any) => {
              return this.yScale(d['weight'] / 1000)
            })
            .curve(d3.curveStepAfter)
        )
    } else {
      this.processed_data_line
        .datum(this.presentation_data)
        .transition()
        .duration(200)
        .attr(
          'd',
          d3
            .line()
            .x((d: any) => {
              return this.xScale(new Date(d.timestamp))
            })
            .y((d: any) => {
              return this.yScale(d['weight'] / 1000)
            })
            .curve(d3.curveStepAfter)
        )
        .attr('stroke', '#69b3a2')
        .attr('fill', 'none')
    }
  }

  drawDots() {
    // Set element data
    this.raw_data_dots = this.svg.selectAll('.raw_dots').data(this.raw_data)

    // Remove exited elements
    this.raw_data_dots.exit().remove()

    // Update remaining elements
    this.raw_data_dots
      .transition()
      .duration(200)
      .attr('cx', (d: any) => {
        return this.xScale(new Date(d.timestamp))
      })
      .attr('cy', (d: any) => {
        return this.yScale(d['weight'] / 1000)
      })

    // Add new elements
    this.raw_data_dots
      .enter()
      .append('circle')
      .attr('cx', (d: any) => {
        return this.xScale(new Date(d.timestamp))
      })
      .attr('cy', (d: any) => {
        return this.yScale(d['weight'] / 1000)
      })
      .attr('r', 2)
      .style('opacity', 0.5)
      .style('fill', '#69b3a2')
      .attr('class', 'raw_dots')
  }

  drawBowls() {
    // Set element data
    this.bowl_areas = this.svg.selectAll('.bowl').data(this.bowls)

    // Remove exited elements
    this.bowl_areas.exit().remove()

    // Update remaining elements
    this.bowl_areas.each((datum, index, n) => {
      d3.select(n[index])
        .datum(datum['bowl_area'])
        .transition()
        .duration(200)
        .attr(
          'd',
          d3
            .area()
            .x((d: any) => this.xScale(new Date(d['timestamp']))) // Position of both line breaks on the X axis
            .y1((d: any) => this.yScale(d['bowl_weight'] / 1000)) // Y position of top line breaks
            .y0(this.yScale(0)) // Y position of bottom line breaks
        )
    })

    // Add new elements
    this.bowl_areas
      .enter()
      .append('path')
      .datum((d: any) => d['bowl_area'])
      .attr(
        'd',
        d3
          .area()
          .x((d: any) => this.xScale(new Date(d['timestamp']))) // Position of both line breaks on the X axis
          .y1((d: any) => this.yScale(d['bowl_weight'] / 1000)) // Y position of top line breaks
          .y0(this.yScale(0)) // Y position of bottom line breaks
      )
      .attr('fill', 'lightgrey')
      .attr('opacity', 0.5)
      .attr('class', (d: any) => 'bowl_' + d['bowl_index'])
      .attr('class', (d: any) => 'bowl')
  }

  drawWasteAreas() {
    // Set element data
    this.waste_areas = this.svg.selectAll('.waste_area').data(this.bowls)

    // Remove exited elements
    this.waste_areas.exit().remove()

    // Update remaining elements
    this.waste_areas.each((datum, index, n) => {
      d3.select(n[index])
        .datum(datum['waste_area'])
        .transition()
        .duration(200)
        .attr(
          'd',
          d3
            .area()
            .x((d: any) => this.xScale(new Date(d['timestamp']))) // Position of both line breaks on the X axis
            .y1((d: any) => this.yScale(d['final_weight'] / 1000)) // Y position of top line breaks
            .y0((d: any) => this.yScale(d['bowl_weight'] / 1000)) // Y position of bottom line breaks
            .curve(d3.curveStepAfter)
        )
    })

    // Add new elements
    this.waste_areas
      .enter()
      .append('path')
      .datum((d: any) => d['waste_area'])
      .attr(
        'd',
        d3
          .area()
          .x((d: any) => this.xScale(new Date(d['timestamp']))) // Position of both line breaks on the X axis
          .y1((d: any) => this.yScale(d['final_weight'] / 1000)) // Y position of top line breaks
          .y0((d: any) => this.yScale(d['bowl_weight'] / 1000)) // Y position of bottom line breaks
          .curve(d3.curveStepAfter)
      )
      .attr('fill', 'red')
      .attr('opacity', 0.25)
      .attr('class', (d: any) => 'waste_area_' + d['bowl_index'])
      .attr('class', (d: any) => 'waste_area')
  }

  drawEatenAreas() {
    // Set element data
    this.eaten_areas = this.svg.selectAll('.eaten_area').data(this.bowls)

    // Remove exited elements
    this.eaten_areas.exit().remove()

    // Update remaining elements
    this.eaten_areas.each((datum, index, n) => {
      d3.select(n[index])
        .datum(datum['eaten_area'])
        .transition()
        .duration(200)
        .attr(
          'd',
          d3
            .area()
            .x((d: any) => {
              return this.xScale(new Date(d['timestamp']))
            }) // Position of both line breaks on the X axis
            .y1((d: any) => this.yScale(d['weight'] / 1000)) // Y position of top line breaks
            .y0((d: any) => this.yScale(d['eaten_lower_weight'] / 1000)) // Y position of bottom line breaks
            .curve(d3.curveStepAfter)
        )
    })

    // Add new elements
    this.eaten_areas
      .enter()
      .append('path')
      .datum((d: any) => d['eaten_area'])
      .attr(
        'd',
        d3
          .area()
          .x((d: any) => {
            return this.xScale(new Date(d['timestamp']))
          }) // Position of both line breaks on the X axis
          .y1((d: any) => this.yScale(d['weight'] / 1000)) // Y position of top line breaks
          .y0((d: any) => this.yScale(d['eaten_lower_weight'] / 1000)) // Y position of bottom line breaks
          .curve(d3.curveStepAfter)
      )
      .attr('fill', '#69b3a2')
      .attr('opacity', 0.25)
      .attr('class', (d: any) => 'eaten_area_' + d['bowl_index'])
      .attr('class', (d: any) => 'eaten_area')
  }

  drawRefilledAreas() {
    // Set element data
    this.refilled_areas = this.svg.selectAll('.refilled_area').data(this.bowls)

    // Remove exited elements
    this.refilled_areas.exit().remove()

    // Update remaining elements
    this.refilled_areas.each((datum, index, n) => {
      d3.select(n[index])
        .datum(datum['refilled_area'])
        .transition()
        .duration(200)
        .attr(
          'd',
          d3
            .area()
            .x((d: any) => {
              return this.xScale(new Date(d['timestamp']))
            }) // Position of both line breaks on the X axis
            .y1((d: any) => this.yScale(d['eaten_lower_weight'] / 1000)) // Y position of top line breaks
            .y0((d: any) => this.yScale(d['bowl_weight'] / 1000)) // Y position of bottom line breaks
            .curve(d3.curveStepAfter)
        )
    })

    // Add new elements
    this.refilled_areas
      .enter()
      .append('path')
      .datum((d: any) => d['refilled_area'])
      .attr(
        'd',
        d3
          .area()
          .x((d: any) => {
            return this.xScale(new Date(d['timestamp']))
          }) // Position of both line breaks on the X axis
          .y1((d: any) => this.yScale(d['eaten_lower_weight'] / 1000)) // Y position of top line breaks
          .y0((d: any) => this.yScale(d['bowl_weight'] / 1000)) // Y position of bottom line breaks
          .curve(d3.curveStepAfter)
      )
      .attr('fill', '#eedd54')
      .attr('opacity', 0.25)
      .attr('class', (d: any) => 'refilled_area_' + d['bowl_index'])
      .attr('class', (d: any) => 'refilled_area')
  }

  drawLegend() {
    if (!this.trash_legend) {
      this.trash_legend = this.svg
        .append('g')
        .attr('class', 'trash_legend')
        .attr('transform', 'translate(' + 20 + ',' + -20 + ')')

      this.trash_legend.append('circle').attr('class', 'legend').attr('cx', 0).attr('cy', 0).attr('r', 7).attr('fill', 'red').attr('opacity', 0.25)

      // Add legend labels
      this.trash_legend
        .append('text')
        .attr('class', 'legend')
        .attr('x', 15)
        .attr('y', 0) // 100 is where the first dot appears. 25 is the distance between dots
        .text($localize`Affald`)
        .attr('text-anchor', 'left')
        .style('alignment-baseline', 'middle')
        .style('font-size', '0.7rem')

      this.trash_legend.append('circle').attr('class', 'legend').attr('cx', 75).attr('cy', 0).attr('r', 7).attr('fill', 'lightgrey').attr('opacity', 0.5)

      this.trash_legend
        .append('text')
        .attr('class', 'legend')
        .attr('x', 90)
        .attr('y', 0) // 100 is where the first dot appears. 25 is the distance between dots
        .text($localize`Affaldsspand`)
        .attr('text-anchor', 'left')
        .style('alignment-baseline', 'middle')
        .style('font-size', '0.7rem')
    } else {
      this.trash_legend
        .transition()
        .duration(200)
        .attr('transform', 'translate(' + 20 + ',' + -20 + ')')
    }

    if (!this.dish_legend) {
      this.dish_legend = this.svg
        .append('g')
        .attr('class', 'dish_legend')
        .attr('transform', 'translate(' + 20 + ',' + -20 + ')')

      // Add legend dots
      this.dish_legend.append('circle').attr('class', 'legend').attr('cx', 0).attr('cy', 0).attr('r', 7).attr('fill', '#69b3a2').attr('opacity', 0.25)

      this.dish_legend.append('circle').attr('class', 'legend').attr('cx', 75).attr('cy', 0).attr('r', 7).attr('fill', 'red').attr('opacity', 0.25)

      this.dish_legend.append('circle').attr('class', 'legend_refill').attr('cx', 150).attr('cy', 0).attr('r', 7).attr('fill', '#eedd54').attr('opacity', 0.5)

      this.dish_legend.append('circle').attr('class', 'legend').attr('cx', 310).attr('cy', 0).attr('r', 7).attr('fill', 'lightgrey').attr('opacity', 0.5)

      // Add legend labels
      this.dish_legend
        .append('text')
        .attr('class', 'legend')
        .attr('x', 15)
        .attr('y', 0) // 100 is where the first dot appears. 25 is the distance between dots
        .text($localize`Spist`)
        .attr('text-anchor', 'left')
        .style('alignment-baseline', 'middle')
        .style('font-size', '0.7rem')
      // Add legend labels
      this.dish_legend
        .append('text')
        .attr('class', 'legend')
        .attr('x', 90)
        .attr('y', 0) // 100 is where the first dot appears. 25 is the distance between dots
        .text($localize`Spildt`)
        .attr('text-anchor', 'left')
        .style('alignment-baseline', 'middle')
        .style('font-size', '0.7rem')
      // Add legend labels
      this.dish_legend
        .append('text')
        .attr('class', 'legend_refill')
        .attr('x', 165)
        .attr('y', 0) // 100 is where the first dot appears. 25 is the distance between dots
        .text($localize`Overført til næste fad`)
        .attr('text-anchor', 'left')
        .style('alignment-baseline', 'middle')
        .style('font-size', '0.7rem')

      // Add legend labels
      this.dish_legend
        .append('text')
        .attr('class', 'legend')
        .attr('x', 325)
        .attr('y', 0) // 100 is where the first dot appears. 25 is the distance between dots
        .text($localize`Fad`)
        .attr('text-anchor', 'left')
        .style('alignment-baseline', 'middle')
        .style('font-size', '0.7rem')
    } else {
      this.dish_legend
        .transition()
        .duration(200)
        .attr('transform', 'translate(' + 20 + ',' + -20 + ')')
    }

    // Toggle legend
    if (this.bowls.find((bowl) => bowl.bowl_setup_id)) {
      this.trash_legend.style('display', 'none')
      this.dish_legend.style('display', 'block')
    } else {
      this.trash_legend.style('display', 'block')
      this.dish_legend.style('display', 'none')
    }
  }

  drawBowlPickers() {
    let container = document.getElementById(this.container)

    this.bowl_pickers = d3
      .select('.' + this.container)
      .selectAll('.bowl_picker')
      .data(this.bowls.filter((bowl) => bowl.bowl_setup_id || bowl.trash_bowl_id))

    this.bowl_pickers.exit().remove()

    this.bowl_pickers
      .enter()
      .append('div')
      .attr('height', '20px')
      .attr('width', '20px')
      .style('left', (d: any) => {
        return container.clientWidth * this.graphMargin.left + this.xScale(new Date(new Date(d['bowl_start']).getTime() + (new Date(d['bowl_end']).getTime() - new Date(d['bowl_start']).getTime()) / 2)) - 10 + 'px'
      })
      .style('bottom', (d: any) => {
        return this.graphMargin.bottom + (this.chartHeight - this.yScale(d['bowl_weight'] / 1000 / 2)) - 10 + 'px'
      })
      .attr('id', (d: any) => 'bowl_pointer_' + d['bowl_index'])
      .attr('class', (d: any) => 'bowl_picker')
      .attr('opacity', 0.5)
      .style('position', 'absolute')
      .style('background-color', '#69b3a2')
      .style('border', 'solid')
      .style('border-color', 'rgb(172 204 196)')
      .style('border-width', '3px')
      .style('border-radius', '20px')
      .style('padding', '10px')
      .style('cursor', 'pointer')
      .on('mouseover', handleMouseOver)
      .on('mouseout', handleMouseOut)
      .on('click', (event, d: any) => {
        event.stopPropagation()
        this.toggleBowlPicker(event, d)
      })
      .html(
        (d: any) =>
          "<div id='bowl_" +
          d['bowl_index'] +
          "' class='l-position-absolute l-font-micro l-text-center is-hidden l-text-nowrap l-position-top-0 l-position-left-28'>" +
          (this.bowl_setups.find((bowl_setup) => bowl_setup.id == d['bowl_setup_id'])
            ? this.bowl_setups.find((bowl_setup) => bowl_setup.id == d['bowl_setup_id'])['name']
            : this.bowl_setups['trash_bowls'].find((bowl) => bowl.id == d['trash_bowl_id'])
            ? this.bowl_setups['trash_bowls'].find((bowl) => bowl.id == d['trash_bowl_id'])['bowl_name']
            : '' + '</div>')
      )

    this.bowl_pickers.each((datum, index, n) => {
      d3.select(n[index])
        .attr('id', (d: any) => 'bowl_pointer_' + d['bowl_index'])
        .on('click', (event, d: any) => {
          event.stopPropagation()
          this.toggleBowlPicker(event, d)
        })
        .html(
          (d: any) =>
            "<div id='bowl_" +
            d['bowl_index'] +
            "' class='l-position-absolute l-font-micro l-text-center is-hidden l-text-nowrap l-position-top-0 l-position-left-28'>" +
            (this.bowl_setups.find((bowl_setup) => bowl_setup.id == d['bowl_setup_id'])
              ? this.bowl_setups.find((bowl_setup) => bowl_setup.id == d['bowl_setup_id'])['name']
              : this.bowl_setups['trash_bowls'].find((bowl) => bowl.id == d['trash_bowl_id'])
              ? this.bowl_setups['trash_bowls'].find((bowl) => bowl.id == d['trash_bowl_id'])['bowl_name']
              : '' + '</div>')
        )
        .transition()
        .duration(200)
        .style('left', (d: any) => {
          return container.clientWidth * this.graphMargin.left + this.xScale(new Date(new Date(d['bowl_start']).getTime() + (new Date(d['bowl_end']).getTime() - new Date(d['bowl_start']).getTime()) / 2)) - 10 + 'px'
        })
        .style('bottom', (d: any) => {
          return this.graphMargin.bottom + (this.chartHeight - this.yScale(d['bowl_weight'] / 1000 / 2)) - 10 + 'px'
        })
    })

    function handleMouseOver(d, i) {
      // Add interactivity
      // Use D3 to select element, change color and size
      d3.select(this).style('border-color', '#69b3a2')
      this.firstChild.classList.remove('is-hidden')
    }

    function handleMouseOut(d, i) {
      // Add interactivity
      // Use D3 to select element, change color and size
      d3.select(this).style('border-color', 'rgb(172 204 196)')
      this.firstChild.classList.add('is-hidden')
    }

    // Handle click events related to bowl picker:
    this.svg.on('click', () => {
      this.closeBowlPicker()
    })
  }

  toggleBowlPicker(event, bowl) {
    //Use D3 to select element, change color and size
    let classes: string[] = d3
      .select('#bowl_' + bowl['bowl_index'])
      .attr('class')
      .split(' ')
    if (classes.find((c) => c == 'is-hidden')) {
      d3.select('#bowl_pointer_' + bowl['bowl_index']).style('border-color', 'rgb(172 204 196)')
      d3.select('#bowl_' + bowl['bowl_index']).classed('is-hidden', false)
      this.bowlClicked.emit({ data: bowl, posX: event.target.offsetLeft, posY: event.target.offsetTop, hidden: true })
    } else {
      d3.select('#bowl_pointer_' + bowl['bowl_index']).style('border-color', '#69b3a2')
      d3.select('#bowl_' + bowl['bowl_index']).classed('is-hidden', true)
      this.bowlClicked.emit({ data: bowl, posX: event.target.offsetLeft, posY: event.target.offsetTop, hidden: false })
    }
  }

  closeBowlPicker() {
    this.bowls.forEach((bowl) => {
      d3.select('#bowl_' + bowl['bowl_index']).classed('is-hidden', false)
    })
    this.bowlClicked.emit({ data: null, posX: 0, posY: 0, hidden: true })
  }
}
