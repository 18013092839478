import { Pipe, PipeTransform } from '@angular/core'
import { Location } from '../models/location/location.model'
@Pipe({
  name: 'locationFilterPipe'
})
export class LocationFilterPipe implements PipeTransform {
  public transform(locations: Location[], filter: string): any[] {
    const name_filter = filter?.toLowerCase()

    if (!locations || !locations.length) return []
    if (!name_filter) return locations

    let filtered_locations = locations.filter((location: Location) => {
      if (location.name.value?.toLowerCase()?.indexOf(name_filter) >= 0) return true
      if (location.subsidiary.name.value?.toLowerCase()?.indexOf(name_filter) >= 0) return true
      if (location.subsidiary.nick_name.value?.toLowerCase()?.indexOf(name_filter) >= 0) return true
      if (location.subsidiary.organization.name.value?.toLowerCase()?.indexOf(name_filter) >= 0) return true
    })

    return filtered_locations
  }
}
