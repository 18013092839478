import { FormBuilder, FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { FoodopLibModule } from '../../foodop-lib.module'
import { Names } from '../names/names.model'
import { DishCatalogueService } from './dish-catalogue.service'
import { map, tap } from 'rxjs/operators'
import { IDishCatalogue } from '../../global.models'
import { UserService } from '../user/user.service'

export class DishCatalogue {
  id: string
  names: Names
  read_access: FormControl
  write_access: FormControl
  visibility: FormControl
  user_id: string
  subsidiary_id: string
  organization_id: string
  standard_catalogue: boolean

  saved_dish_catalogue: any
  uploading = false

  dishCatalogueService: DishCatalogueService
  userService: UserService
  fb: FormBuilder

  constructor(public dish_catalogue?: IDishCatalogue) {
    this.dishCatalogueService = FoodopLibModule.injector.get(DishCatalogueService)
    this.userService = FoodopLibModule.injector.get(UserService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.id = dish_catalogue?.id
    this.names = new Names(dish_catalogue?.names)
    this.read_access = this.fb.control(dish_catalogue?.read_access != null ? dish_catalogue?.read_access : 3)
    this.write_access = this.fb.control(dish_catalogue?.write_access != null ? dish_catalogue?.write_access : 0)
    this.visibility = this.fb.control(dish_catalogue?.visibility != null ? dish_catalogue?.visibility : true)
    this.standard_catalogue = dish_catalogue?.standard_catalogue != undefined ? dish_catalogue?.standard_catalogue : false
    this.user_id = dish_catalogue?.user_id || this.userService.user.id.value
    this.subsidiary_id = dish_catalogue?.subsidiary_id || this.userService.user.subsidiary_id.value
    this.organization_id = dish_catalogue?.organization_id || this.userService.user.subsidiary.organization_id.value

    this.saved_dish_catalogue = JSON.stringify(this.as_dict)
  }

  isNameTranslated(language: string) {
    return this.names[language].value ? true : false
  }

  get changed(): boolean {
    return this.saved_dish_catalogue != JSON.stringify(this.as_dict)
  }

  get is_valid(): boolean {
    return this.names.valid
  }

  get user_has_write_access(): boolean {
    if (this.standard_catalogue) return false
    if (this.user_id == this.userService.user.id.value) return true
    else if (this.subsidiary_id == this.userService.user.subsidiary_id.value && this.write_access.value == 1) return true
    else if (this.organization_id == this.userService.user.subsidiary.organization_id.value && this.write_access.value == 2) return true
    else return false
  }

  restore() {
    this.patchValues(JSON.parse(this.saved_dish_catalogue))
  }

  patchValues(dish_catalogue?: IDishCatalogue): void {
    if (dish_catalogue?.id) this.id = dish_catalogue.id
    if (dish_catalogue?.names) this.names = new Names(dish_catalogue.names)
    if (dish_catalogue?.read_access) this.read_access.setValue(dish_catalogue.read_access)
    if (dish_catalogue?.write_access) this.read_access.setValue(dish_catalogue.write_access)
    if (dish_catalogue?.visibility) this.visibility.setValue(dish_catalogue.visibility)
    if (dish_catalogue?.standard_catalogue) this.standard_catalogue = dish_catalogue.standard_catalogue
    if (dish_catalogue?.user_id) this.user_id = dish_catalogue.user_id
    if (dish_catalogue?.subsidiary_id) this.subsidiary_id = dish_catalogue.subsidiary_id
    if (dish_catalogue?.organization_id) this.organization_id = dish_catalogue.organization_id
  }

  save(): Observable<DishCatalogue> {
    if (this.id) return this.dishCatalogueService.updateDishCatalogue(this.as_dict)
    else return this.dishCatalogueService.createDishCatalogue(this.as_dict)
  }

  updateVisibility(): Observable<any> {
    if (this.standard_catalogue) return this.dishCatalogueService.updateStandardDishCatalogueVisibility(this.id, this.visibility.value)
    else return this.dishCatalogueService.updateCustomDishCatalogueVisibility(this.id, this.visibility.value)
  }

  saveNames(): Observable<DishCatalogue> {
    this.uploading = true
    return this.dishCatalogueService.saveDishCatalogueNames(this).pipe(
      tap((raw_ingredient) => {
        this.uploading = false
      }),
      map(() => {
        return this
      })
    )
  }

  get as_dict(): IDishCatalogue {
    return {
      id: this.id,
      names: this.names.as_dict,
      read_access: this.read_access.value,
      write_access: this.write_access.value,
      visibility: this.visibility.value,
      user_id: this.user_id,
      subsidiary_id: this.subsidiary_id,
      organization_id: this.organization_id
    }
  }
  get names_dict(): IDishCatalogue {
    return {
      id: this.id,
      names: this.names.as_dict
    }
  }
}
