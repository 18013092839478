<div class="l-display-flex l-space-between l-position-center-vertically l-topmargin-medium">
    
    <input autocomplete="off"  matInput placeholder="Search for mac" [formControl]="scaleSearchControl" class="l-width-300p">

    <div class="add-button-container">
        <button type="button" mat-button class="primary-button" (click)="openSwapScalesView()">
            Udskift vægt(e)
        </button>
    </div>
</div>

<div *ngIf="scales$ | async as scales">
    <ng-container *ngIf="scales.length; else noScales">
        <ng-container *ngIf="organizations$ | async as organizations">
            <mat-card *ngFor="let scale of scales; let scale_index=index" class="l-margin-medium">
                <div class="l-display-flex l-space-between l-position-center-vertically">
                    <label class="l-font-bold l-font-small"><mat-icon class="l-font-bold l-font-small o-size-mini l-margin-horizontal-3">dvr</mat-icon>{{scale.macc}} | {{scale.index.value}}</label>
            
                        <mat-form-field class="l-font-small l-width-30p">
                            <mat-label>Lokation</mat-label>
                            <mat-select #locationSelector [formControl]="scale.location_id" (optionsScroll)="onScroll()">
                                <mat-select-trigger>{{scaleLocationName(scale)}}</mat-select-trigger>
                                <mat-option class="default-selection-option" [value]="scale.location_id.value"></mat-option>
                                <mat-option (click)="$event.stopPropagation();" class="option-level-0 mat-select-search-field">
                                    <input (click)="$event.stopPropagation();" (keydown)="$event.stopPropagation()" autocomplete="off" #customerSearchInput lang="en" [formControl]="customerSearchControl" type="text" matInput placeholder="Search for locations">
                                </mat-option>
                
                                <mat-option [value]="null" (click)="selectLocationForScale(scale)">-- Ingen --</mat-option>
                
                                <ng-container *ngFor="let organization of organizations | organizationFilterPipe: customerSearchControl.value; let organizationIndex = index">
                                    <mat-option [value]="organization.id" class="option-level-1" [ngStyle]="{'z-index': subsidiariesBeforeIndex(organizationIndex)+organizationIndex}" disabled>
                                        <div class="option-container">
                                            <label class="check-box-label non-selectable-label" [innerHTML]="organization.name.value | boldSearchQuery: customerSearchInput.value"></label>
                                        </div>
                                    </mat-option>
                                    <ng-container *ngFor="let subsidiary of organization.subsidiaries | subsidiaryFilterPipe: customerSearchControl.value; let subidiaryIndex = index">
                                        <mat-option [value]="subsidiary.id" class="option-level-2" [ngStyle]="{'z-index': subsidiariesBeforeIndex(organizationIndex)+subidiaryIndex}" disabled>
                                            <div class="sub-option-container">
                                                <label class="check-box-label non-selectable-label" [innerHTML]="subsidiary.name.value | boldSearchQuery: customerSearchInput.value"></label>
                                            </div>
                                        </mat-option>
                                        <mat-option *ngFor="let location of subsidiary.locations | locationFilterPipe: customerSearchControl.value" [ngClass]="{'l-font-italic': location.gateways.length == 0}"  [value]="location.id" [disabled]="location.gateways.length == 0">
                                            <div class="sub-sub-option-container" (click)="selectLocationForScale(scale,location)">
                                                <label class="check-box-label" (click)="selectLocationForScale(scale,location)" [innerHTML]="(location.name.value | boldSearchQuery: customerSearchInput.value) + (location.gateways.length == 0 ? ' (no gateways allocated)' : '')"></label>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                </ng-container>
                                <mat-option *ngIf="loadingOrganizations" class="o-cursor-default">
                                    <div class="l-display-flex l-position-center-vertically">
                                        <mat-spinner class="o-inline-block l-float-left" color="primary" diameter="19"></mat-spinner> 
                                        <label class="l-font-small l-line-height-19 l-padding-micro" i18n>Søger efter kunder ...</label>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
            
                    <mat-form-field *ngIf="scale.location_id.value" class="l-font-small l-width-30p">
                        <mat-label>Gateway</mat-label>
                        <mat-select [formControl]="scale.gateway_id" (selectionChange)="scale.save().subscribe()" (keydown)="$event.stopImmediatePropagation()" [value]="scale.gateway_id.value">
                            <mat-select-trigger>{{scaleGatewayID(scale)}}</mat-select-trigger>
                            <mat-option class="default-selection-option" [value]="scale.location_id.value"></mat-option>
                            <mat-option [value]="null">-- Ingen --</mat-option>
                            <mat-option *ngFor="let gateway of scale.location?.gateways" [value]="gateway.id" [disabled]="gateway.scales.length >= 10">
                                {{gateway.id}} ({{gateway.scales.length}} vægte allokeret)
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <mat-form-field *ngIf="scale.location_id.value" class="l-font-small l-width-30p">
                        <mat-label>Scale Group</mat-label>
                        <mat-select [formControl]="scale.scale_group_id" (selectionChange)="scale.save().subscribe()" (keydown)="$event.stopPropagation()" [value]="scale.scale_group_id.value">
                            <mat-option [value]="0">-- Ingen --</mat-option>
                            <mat-option *ngFor="let scale_group of scale.location?.scale_groups" [value]="scale_group.id">
                                {{scale_group.name.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-card>
        </ng-container>
    </ng-container>
    <ng-template #noScales>
        <div *ngIf="(scaleSearchControl.value || selectedLocationIds.length) && !loadingScales" class="loading-container">
            <label>No scales found!</label>
        </div>
    </ng-template>
</div>
<div *ngIf="loadingScales" class="loading-container">
    <mat-spinner [diameter]="25" color="primary"></mat-spinner>
    <label>Loading scales ...</label>
</div>

<div *ngIf="moreScalesAvailable" class="load-more-button-container">
    <button (click)="loadMoreScales()" type="button" mat-button class="secondary-button">
        Load more scales
    </button>
</div>

