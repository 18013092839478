import { NgModule } from '@angular/core'

import { PortalModule } from '@angular/cdk/portal'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { ColorSketchModule } from 'ngx-color/sketch';


@NgModule({
  imports: [],
  declarations: [],
  exports: [FormsModule, ReactiveFormsModule, HttpClientModule, NgbModule, PortalModule, BrowserModule, BrowserAnimationsModule, ColorSketchModule]
})
export class SharedModule {}
