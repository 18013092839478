import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { GlobalFunctionsService, Organization, OrganizationsService, RestApiService } from 'foodop-lib'
import moment from 'moment'
import { Observable, Subject, catchError, debounceTime, exhaustMap, filter, scan, startWith, switchMap, takeWhile, tap } from 'rxjs'

@Component({
  selector: 'app-analyses',
  templateUrl: './analyses.component.html',
  styleUrls: ['./analyses.component.css']
})
export class AnalysesComponent implements OnInit {
  public customerSearchControl: FormControl = new FormControl('')
  public loadingOrganizations: boolean = false
  public isLoadingData: boolean = false
  public selectedSubsidiary: FormControl
  public selectedGrouping: FormControl
  public dateRange: FormGroup = this.fb.group({
    start: [{ value: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 15) }, Validators.required],
    end: [{ value: new Date() }, Validators.required]
  })

  private _organizationsOffset: number = 0
  private _loadNextOrganizations$ = new Subject()
  private _organizations: Organization[] = []

  public organizations$: Observable<Organization[]> = this._loadSearchedOrganizations()

  constructor(public organizationsService: OrganizationsService, public func: GlobalFunctionsService, private fb: FormBuilder, private restApiService: RestApiService) {
    moment.locale('da')
  }

  get options_valid(): boolean {
    return this.dateRange.valid && this.selectedSubsidiary.valid && this.selectedGrouping.valid
  }

  ngOnInit(): void {
    this.selectedSubsidiary = this.fb.control({ value: null, disabled: false }, [Validators.required])
    this.selectedGrouping = this.fb.control({ value: 'menu_section', disabled: false }, [Validators.required])
  }

  public subsidiariesBeforeIndex(organizationIndex: number): number {
    return this._organizations
      .slice(0, organizationIndex + 1)
      .map((organization) => organization.subsidiaries.length)
      .reduce((a, b) => a + b, 0)
  }

  public onScroll(): void {
    this._loadNextOrganizations$.next(true)
  }

  public getData(): void {
    this.isLoadingData = true
    console.log('Downloading data')
    let params = {}
    params['date_start'] = moment(this.dateRange.get('start').value).format('YYYY-MM-DD')
    params['date_end'] = moment(this.dateRange.get('end').value).format('YYYY-MM-DD')
    params['ids'] = this.selectedSubsidiary.value?.id
    params['group_level'] = this.selectedGrouping.value

    this.restApiService.getSubsidiaryData(params).subscribe((insights) => {
      if (insights) {
        insights.forEach((insight) => {
          if (params['group_level'] == 'menu_dish') {
            insight['recipe_name_da'] = insight['recipe_names']?.da
            insight['recipe_name_en'] = insight['recipe_names']?.en
          }

          insight['section_name_da'] = insight['section_names']['da']
          insight['section_name_en'] = insight['section_names']['en']
        })

        let headerList = ['section_name_da', 'section_name_en', 'date', 'location_name', 'food_waste', 'consumption', 'bowls', 'refills', 'portions', 'group_rows', 'subsidiary_id', 'location_id', 'menu_section_id', 'valid']
        let unitList = [null, null, null, null, null, null, null, null, null, null, null, null, null, null]
        if (params['group_level'] == 'menu_dish') {
          headerList = ['recipe_name_da', 'recipe_name_en'].concat(headerList)
          unitList = [null, null].concat(unitList)
        }

        let filename = 'FoodOp Data, ' + params['date_start'] + ' - ' + params['date_end']
        let csvData = this.func.ConvertToCSV(insights, headerList, unitList)
        console.log(csvData)
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' })
        let dwldLink = document.createElement('a')
        let url = URL.createObjectURL(blob)
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1
        if (isSafariBrowser) {
          dwldLink.setAttribute('target', '_blank')
        }
        dwldLink.setAttribute('href', url)
        dwldLink.setAttribute('download', filename + '.csv')
        dwldLink.style.visibility = 'hidden'
        document.body.appendChild(dwldLink)
        dwldLink.click()
        document.body.removeChild(dwldLink)

        this.isLoadingData = false
      } else {
        this.isLoadingData = false
      }
    })
  }

  private _loadSearchedOrganizations(): Observable<Organization[]> {
    const filterChanges$ = this.customerSearchControl.valueChanges.pipe(
      startWith(''),
      tap((value) => {
        if (typeof value === 'string') {
          this._organizations = []
          this._organizationsOffset = 0
        }
      }),
      filter((value) => typeof value === 'string' && (value.length >= 2 || value == '')),
      tap(() => {
        this.loadingOrganizations = true
      }),
      debounceTime(200)
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextOrganizations$.pipe(
          startWith(0),
          exhaustMap(() => this.organizationsService.loadOrganizations('subsidiaries', this.customerSearchControl.value.toLowerCase(), this._organizationsOffset)),
          takeWhile((organizations) => organizations.length == 10, true),
          scan((allOrganizations: Organization[], newOrganizations: Organization[]) => allOrganizations.concat(newOrganizations)),
          tap((organizations) => {
            this._organizations = organizations
            this._organizationsOffset += 10
            this.loadingOrganizations = false
          }),
          catchError(() => [])
        )
      })
    )
  }
}
