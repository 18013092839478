import { AbstractControl, FormBuilder, FormControl } from '@angular/forms'
import { IDisplayFormat } from '../../global.models'

export const enum ElementSide {
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left'
}

export class DisplayFormat extends FormBuilder {
  width: number
  height: number

  padding_top: FormControl
  padding_right: FormControl
  padding_bottom: FormControl
  padding_left: FormControl

  column_gap: FormControl
  row_gap: FormControl

  max_vertical_padding: number
  max_horizontal_padding: number
  min_vertical_padding = 0
  min_horizontal_padding = 0

  constructor(display_format?: IDisplayFormat) {
    super()
    this.width = display_format?.width || 416
    this.height = display_format?.height || 240
    this.padding_top = super.control(display_format?.padding_top || 25)
    this.padding_right = super.control(display_format?.padding_right || 20)
    this.padding_bottom = super.control(display_format?.padding_bottom || 25)
    this.padding_left = super.control(display_format?.padding_left || 20)

    this.column_gap = super.control(display_format?.column_gap || 10)
    this.row_gap = super.control(display_format?.row_gap || 10)

    this.max_vertical_padding = this.height / 2
    this.max_horizontal_padding = this.width / 2
  }

  increasePadding(side: ElementSide): void {
    switch (side) {
      case ElementSide.top:
        this.padding_top.setValue(Math.max(Math.min(this.padding_top.value + 1, this.max_vertical_padding), this.min_vertical_padding))
        break
      case ElementSide.right:
        this.padding_right.setValue(Math.max(Math.min(this.padding_right.value + 1, this.max_horizontal_padding), this.min_horizontal_padding))
        break
      case ElementSide.bottom:
        this.padding_bottom.setValue(Math.max(Math.min(this.padding_bottom.value + 1, this.max_vertical_padding), this.min_vertical_padding))
        break
      case ElementSide.left:
        this.padding_left.setValue(Math.max(Math.min(this.padding_left.value + 1, this.max_horizontal_padding), this.min_horizontal_padding))
        break
      default:
        break
    }
  }

  decreasePadding(side: ElementSide): void {
    switch (side) {
      case ElementSide.top:
        this.padding_top.setValue(Math.max(Math.min(this.padding_top.value - 1, this.max_vertical_padding), this.min_vertical_padding))
        break
      case ElementSide.right:
        this.padding_right.setValue(Math.max(Math.min(this.padding_right.value - 1, this.max_horizontal_padding), this.min_horizontal_padding))
        break
      case ElementSide.bottom:
        this.padding_bottom.setValue(Math.max(Math.min(this.padding_bottom.value - 1, this.max_vertical_padding), this.min_vertical_padding))
        break
      case ElementSide.left:
        this.padding_left.setValue(Math.max(Math.min(this.padding_left.value - 1, this.max_horizontal_padding), this.min_horizontal_padding))
        break
      default:
        break
    }
  }

  get paddingTopIsMax(): boolean {
    return this.padding_top.value == this.max_vertical_padding
  }
  get paddingRightIsMax(): boolean {
    return this.padding_right.value == this.max_horizontal_padding
  }
  get paddingBottomIsMax(): boolean {
    return this.padding_bottom.value == this.max_vertical_padding
  }
  get paddingLeftIsMax(): boolean {
    return this.padding_left.value == this.max_horizontal_padding
  }
  get paddingTopIsMin(): boolean {
    return this.padding_top.value == this.min_vertical_padding
  }
  get paddingRightIsMin(): boolean {
    return this.padding_right.value == this.min_horizontal_padding
  }
  get paddingBottomIsMin(): boolean {
    return this.padding_bottom.value == this.min_vertical_padding
  }
  get paddingLeftIsMin(): boolean {
    return this.padding_left.value == this.min_horizontal_padding
  }
  get available_height(): number {
    return this.height - this.padding_top.value - this.padding_bottom.value
  }
  get available_width(): number {
    return this.width - this.padding_right.value - this.padding_left.value
  }

  get as_dict(): IDisplayFormat {
    return {
      width: this.width,
      height: this.height,
      padding_top: this.padding_top.value,
      padding_right: this.padding_right.value,
      padding_bottom: this.padding_bottom.value,
      padding_left: this.padding_left.value,
      column_gap: this.column_gap.value,
      row_gap: this.row_gap.value
    }
  }

  as_css(display_size_scale) {
    return {
      'padding-top': this.padding_top.value * display_size_scale + 'px',
      'padding-right': this.padding_right.value * display_size_scale + 'px',
      'padding-bottom': this.padding_bottom.value * display_size_scale + 'px',
      'padding-left': this.padding_left.value * display_size_scale + 'px'
    }
  }
}
