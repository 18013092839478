<div class="top-bar-container">
    <label *ngIf="scale_dish" class="card_title" i18n>Målinger for {{scale_dish.names[language]}}</label>
    <button type="button" mat-button (click)="onCloseView()" class="secondary-button" i18n>Luk</button>
</div>

<mat-slide-toggle color="primary" *ngIf="scale_dish_data && !scaleDishDataIsLoading" [formControl]="show_raw_data" class="l-font-small" i18n>Vis rådata</mat-slide-toggle>

<div #scatterlinechartcontainer *ngIf="scale_dish_data && scale_dish_data['presentation_data']" class="l-margin-vertical-large widget l-position-relative">
    <scatter-line-chart id="presentation_chart" container="presentation_chart" [startTime]="scale_dish_data['serving_time']" [endTime]="scale_dish_data['download_time']" class="svg-container widget presentation_chart" [bowl_setups]="dashboardService.bowl_setups" [trash_bowls]="dashboardService.trash_bowls" [bowls]="scale_dish_data.bowls" [signalReloadUpdateChart]="signalReloadUpdateChart" [presentation_data]="scale_dish_data['presentation_data']" [raw_data]="show_raw_data.value ? scale_dish_data['raw_data'] : []" [height]="scatterlinechartcontainer.offsetHeight" [width]="scatterlinechartcontainer.offsetWidth" (bowlClicked)="onBowlClicked($event)"></scatter-line-chart>
    <!-- <div class="l-theme-white l-position-absolute l-overflow l-width-300 l-padding-horizontal-medium l-padding-top-micro l-border-radius l-box-shadow"  [hidden]="bowlPickerHidden" [style.left]="bowlPickerX + 'px'" [style.top]="bowlPickerY + 'px'">
        <bowl-picker-v2 *ngIf="!scaleDishDataIsLoading && scale_dish_data" [bowlPicked]="bowlPicked" (bowlChanged)="onBowlChanged()" [scale_dish_data]="scale_dish_data"></bowl-picker-v2>
    </div> -->
</div>

<div *ngIf="scaleDishDataIsLoading" class="chart-overlay l-display-flex l-position-center-vertically">
    <div class="l-width-full l-topmargin-large">
        <label class="l-font-mini-dynamic l-width-full l-text-center" i18n>Loader ...</label>
        <mat-spinner [diameter]="25" color="primary" class="o-inline-block l-center-top"></mat-spinner>
    </div>
</div>


<div *ngIf="!scale_dish_data && !scaleDishDataIsLoading">
    <label class="l-font-small-dynamic l-font-italic l-width-full l-text-center l-topmargin-large" i18n>Ingen data fundet</label>
</div>
