import { FormControl } from '@angular/forms'
import { MenuDish } from '../menu-dish/menu-dish.model'
import { MenuSection } from '../menu-section/menu-section.model'
import { ScaleGroup } from '../scale-group/scale-group.model'
import { Tracking } from '../tracking/tracking.model'
import { IAllocationRule } from '../../global.models'
import { Scale } from '../scale/scale.model'
import { ScaleDish } from '../scale-dish/scale-dish.model'
import { TrackingSectionTemplate } from './tracking-section-template.model'

export class AllocationRule {
  public index: FormControl
  public scaleGroupId: FormControl
  public trackingSectionTemplateId: string

  constructor(private _allocationRule: IAllocationRule, public trackingSectionTemplate: TrackingSectionTemplate) {
    this.index = new FormControl(_allocationRule?.index)
    this.scaleGroupId = new FormControl(_allocationRule?.scale_group_id)
  }

  public get scaleGroup(): ScaleGroup {
    return this.trackingSectionTemplate.trackingTemplate.location.scale_group_with_id(this.scaleGroupId.value)
  }

  public get scales(): Scale[] {
    return this.scaleGroup?.scales
  }

  public get asDict(): IAllocationRule {
    return {
      index: this.index.value,
      scale_group_id: this.scaleGroupId.value
    }
  }

  public unallocatedScalesForTracking(tracking: Tracking): Scale[] {
    return this.scaleGroup?.scales?.filter((scale) => !scale.scale_dish_for_serving(tracking))
  }

  public allocatedScalesForTracking(tracking: Tracking): Scale[] {
    return this.scaleGroup?.scales?.filter((scale) => scale.scale_dish_for_serving(tracking))
  }

  public scaleDishesForMenuDish(menuDish: MenuDish, tracking: Tracking): ScaleDish[] {
    return tracking.scaleDishesForMenuDishInScaleGroup(menuDish, this.scaleGroup)
  }
  public allocateDishesInMenuSection(menuSection: MenuSection, tracking: Tracking): void {
    menuSection.menu_dishes.forEach((dish: MenuDish) => {
      if (this.scaleGroup?.available_scales_for_serving(tracking)?.length > 0) {
        tracking.addScaleDish(null, this.scaleGroup.available_scales_for_serving(tracking)[0], dish, null, tracking.trackingTemplate.trackingSectionTemplateForMenuSectionTemplate(menuSection.section_template).bowlSetups, this.scaleGroup.type.value)
      }
    })
  }
  public removeDishesFromMenuSection(menuSection: MenuSection, tracking: Tracking): void {
    menuSection.menu_dishes.forEach((menuDish: MenuDish) => {
      const scale_dishes_for_menu_dish_in_scale_group = tracking.scaleDishesForMenuDishInScaleGroup(menuDish, this.scaleGroup)
      if (scale_dishes_for_menu_dish_in_scale_group.length > 0) tracking.removeScaleDish(scale_dishes_for_menu_dish_in_scale_group[scale_dishes_for_menu_dish_in_scale_group.length - 1])
    })
  }

  public allocateMenuDish(menuDish: MenuDish, tracking: Tracking): void {
    if (this.unallocatedScalesForTracking(tracking)?.length) {
      tracking.addScaleDish(null, this.unallocatedScalesForTracking(tracking)[0], menuDish, null, tracking.trackingTemplate.trackingSectionTemplateForMenuSectionTemplate(menuDish.menu_section.section_template).bowlSetups, this.scaleGroup.type.value)
    }
  }
  public unallocateMenuDish(menuDish: MenuDish, tracking: Tracking): void {
    const scale_dishes_for_menu_dish_in_scale_group = this.scaleDishesForMenuDish(menuDish, tracking)
    if (scale_dishes_for_menu_dish_in_scale_group.length > 0) tracking.removeScaleDish(scale_dishes_for_menu_dish_in_scale_group[scale_dishes_for_menu_dish_in_scale_group.length - 1])
  }
}
