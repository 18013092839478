import { IAccessRole } from '../../global.models'
import { Domain } from '../domain/domain.model'

export class AccessRole {
  id: string
  name: string
  read: string
  write: string
  domains: Domain[]

  constructor(public access_role?: IAccessRole) {
    this.id = access_role?.id
    this.name = access_role.name
    this.read = access_role.read
    this.write = access_role.write
    this.domains = (access_role.domains || []).map((domain) => new Domain(domain))
  }

  get as_dict(): IAccessRole {
    return {
      id: this.id,
      name: this.name,
      read: this.read,
      write: this.write,
      domains: this.domains.map((domain) => domain.as_dict)
    }
  }
}
