import { AbstractControl, FormBuilder, FormControl } from '@angular/forms'
import { LanguageSettings } from '../language-settings/language-settings.model'
import { IPlatformSettings } from '../../global.models'

export class PlatformSettings extends FormBuilder {
  language: LanguageSettings
  dish_visibility: FormControl
  ingredient_visibility: FormControl

  saved_platform_settings: any

  constructor(public platform_settings?: IPlatformSettings) {
    super()
    this.language = new LanguageSettings(platform_settings?.language)
    this.dish_visibility = super.control(platform_settings?.dish_visibility)
    this.ingredient_visibility = super.control(platform_settings?.ingredient_visibility)

    this.saved_platform_settings = JSON.stringify(this.as_dict)
  }

  get as_dict(): IPlatformSettings {
    return {
      language: this.language.as_dict,
      dish_visibility: this.dish_visibility.value,
      ingredient_visibility: this.ingredient_visibility.value
    }
  }
}
