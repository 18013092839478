import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { Organization } from './organization.model'
import { IOrganization } from '../../global.models'
import { RestApiService } from '../../services/rest-api.service'
import { LocationsService } from '../location/locations.service'
import { GatewaysService } from '../gateways/gateways.service'

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  organization: Organization
  organizations: Organization[] = []

  constructor(public fb: FormBuilder, private subsidiaryService: SubsidiaryService, private locationsService: LocationsService, private gatewaysService: GatewaysService, private restApiService: RestApiService, @Inject(LOCALE_ID) public language: string) {}

  loadOrganizations(fields?: string, search_string?: string, offset?: number): Observable<Organization[]> {
    const params = {
      fields: fields
    }
    if (search_string) params['search_string'] = encodeURIComponent(search_string)
    if (offset != undefined) {
      params['limit'] = 10
      params['offset'] = offset
    }
    return this.restApiService.getAllOrganizations(params).pipe(
      tap((organizations) => {
        organizations.forEach((organization) => {
          organization.subsidiaries?.forEach((subsidiary) => {
            subsidiary.locations?.forEach((location) => {
              this.locationsService.addToLocations(Object.assign(location, { subsidiary_id: subsidiary.id }))
              location.gateways?.forEach((gateway) => this.gatewaysService.addToGateways(Object.assign(gateway, { location_id: location.id })))
            })
            this.subsidiaryService.addToSubsidiaries(Object.assign(subsidiary, { organization_id: organization.id }))
          })
          this.addToOrganizations(organization)
        })
        this.organizations = this.organizations.sort((a, b) => (a.active.value == b.active.value ? (a.name.value > b.name.value ? 1 : -1) : a.active.value > b.active.value ? -1 : 1))
      }),
      map((organizations) => organizations.map((organization) => this.organization_with_id(organization.id)))
    )
  }

  addToOrganizations(organization: IOrganization): void {
    let existing_organization: Organization = this.organization_with_id(organization.id)
    if (!existing_organization) this.organizations.push(new Organization(organization))
    else existing_organization.patchValues(organization)
  }

  organization_with_id(organization_id: string): Organization {
    return this.organizations.find((organization) => organization.id == organization_id)
  }

  loadOrganization(organization?: Organization): Observable<IOrganization> {
    let params = {
      fields: 'subsidiaries,locations,gateways,scale_groups,scales'
    }
    if (organization) params['organization_id'] = organization.id
    return this.restApiService.loadOrganization(params).pipe(
      tap((organization) => {
        organization.subsidiaries?.forEach((subsidiary) => {
          subsidiary.locations?.forEach((location) => {
            this.locationsService.addToLocations(Object.assign(location, { subsidiary_id: subsidiary.id }))
          })
          this.subsidiaryService.addToSubsidiaries(Object.assign(subsidiary, { organization_id: organization.id }))
        })
        this.addToOrganizations(organization)
      })
    )
  }

  saveOrganization(organization: Organization): Observable<IOrganization> {
    if (organization.is_new) return this.restApiService.createOrganization(organization.as_dict).pipe(tap(() => this.organizations.push(organization)))
    else return this.restApiService.updateOrganization(organization.as_dict)
  }
}
