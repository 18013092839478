import { FormBuilder, FormControl } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { Display } from '../display/display.model'
import { GatewaysService } from '../gateways/gateways.service'
import { Location } from '../location/location.model'
import { LocationsService } from '../location/locations.service'
import { ScaleDish } from '../scale-dish/scale-dish.model'
import { ScaleGroup } from '../scale-group/scale-group.model'
import { Tracking } from '../tracking/tracking.model'
import { ScalesService } from './scales.service'
import { IScale } from '../../global.models'
import { Observable, map, of, tap } from 'rxjs'
import moment from 'moment'
import { Gateway } from '../gateways/gateway.model'
import { DisplayTemplate } from '../display-template/display-template.model'
import { defaultSelectedScaleDisplayElements } from '../../global.types'

export class Scale {
  macc: string
  serial: string
  model: string
  battery: number
  sampling: boolean
  sleeping: boolean
  connected: boolean
  updated: Date

  location_id: FormControl
  scale_group_id: FormControl
  gateway_id: FormControl
  index: FormControl

  trash_template_id: FormControl

  default_display: Display
  name: FormControl
  scale_group_name: string

  saved_scale: any
  saving = false

  scalesService: ScalesService
  locationsService: LocationsService
  gatewaysService: GatewaysService
  fb: FormBuilder
  constructor(scale: IScale, scale_group?: ScaleGroup) {
    this.scalesService = FoodopLibModule.injector.get(ScalesService)
    this.locationsService = FoodopLibModule.injector.get(LocationsService)
    this.gatewaysService = FoodopLibModule.injector.get(GatewaysService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.macc = scale?.macc
    this.serial = scale?.serial
    this.model = scale?.model
    this.battery = scale?.battery
    this.sampling = scale?.sampling
    this.sleeping = scale?.sleeping
    this.connected = scale?.connected
    this.updated = scale?.updated

    this.scale_group_id = this.fb.control(scale?.scale_group_id || scale_group?.id)
    this.gateway_id = this.fb.control(scale?.gateway_id)
    this.location_id = this.fb.control(scale?.location_id || scale_group?.location_id)

    this.trash_template_id = this.fb.control(scale?.trash_template_id)

    this.index = this.fb.control(scale?.index)
    this.name = this.fb.control($localize`Vægt ` + this.index.value)
    this.scale_group_name = scale_group?.name?.value

    this.saved_scale = JSON.stringify(this.as_dict)
  }

  patchValues(scale: IScale): void {
    if (scale?.macc) this.macc = scale.macc
    if (scale?.serial) this.serial = scale.serial
    if (scale?.model) this.model = scale.model
    if (scale?.battery) this.battery = scale.battery
    if (scale?.sampling) this.sampling = scale.sampling
    if (scale?.sleeping) this.sleeping = scale.sleeping
    if (scale?.connected) this.connected = scale.connected
    if (scale?.updated) this.updated = scale.updated

    if (scale?.scale_group_id) this.scale_group_id.setValue(scale.scale_group_id)
    if (scale?.gateway_id) this.gateway_id.setValue(scale.gateway_id)
    if (scale?.location_id) this.location_id.setValue(scale?.location_id)
    if (scale?.index) this.index.setValue(scale.index)
    if (scale?.name) this.name.setValue(scale.name)

    this.saved_scale = JSON.stringify(this.as_dict)
  }

  display(tracking: Tracking): Display {
    if (this.scale_dish_for_serving(tracking)) return this.scale_dish_for_serving(tracking).display
    else {
      if (!this.default_display) {
        this.default_display = new Display(null, tracking.language, new DisplayTemplate({ id: 'default_scale_display', display_element_templates: defaultSelectedScaleDisplayElements }), tracking.language.value)
        this.default_display.reDraw(null, null, this)
      }
      return this.default_display
    }
  }

  scale_dish_for_serving(tracking: Tracking): ScaleDish {
    return tracking?.scaleDishes?.find((scaleDish) => scaleDish.scale_macc.value == this.macc)
  }

  get location(): Location {
    return this.locationsService.location_with_id(this.location_id.value)
  }
  get changed(): boolean {
    return this.saved_scale != JSON.stringify(this.as_dict)
  }

  get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined
  }

  get allocated(): boolean {
    return this.gateway_id.value && this.scale_group_id.value
  }
  get unallocated(): boolean {
    return !this.gateway_id.value && !this.scale_group_id.value
  }

  get gateway(): Gateway {
    return this.gatewaysService.gateway_with_id(this.gateway_id.value)
  }

  get scaleGroup(): ScaleGroup {
    return this.location.scale_group_with_id(this.scale_group_id.value)
  }

  get minutes_since_last_interaction(): number {
    return moment().diff(this.updated, 'minutes')
  }

  get time_since_last_interaction(): string {
    if (this.minutes_since_last_interaction < 60) return this.minutes_since_last_interaction + ' minutes ago'
    else if (this.minutes_since_last_interaction < 1440) return moment().diff(this.updated, 'hours') + ' hours ago'
    else return moment().diff(this.updated, 'days') + ' days ago'
  }

  save(): Observable<Scale> {
    if (this.allocated || (this.unallocated && !this.location_id.value)) {
      this.saving = true
      return this.scalesService.saveScale(this.as_dict).pipe(
        tap((scale) => {
          this.patchValues(scale)
          this.saved_scale = JSON.stringify(this.as_dict)
          this.saving = false
        }),
        map(() => {
          return this
        })
      )
    } else return of()
  }

  get as_dict(): IScale {
    return {
      macc: this.macc,
      serial: this.serial,
      model: this.model,
      battery: this.battery,
      sampling: this.sampling,
      sleeping: this.sleeping,
      updated: this.updated,
      scale_group_id: this.scale_group_id.value,
      gateway_id: this.gateway_id.value,
      trash_template_id: this.trash_template_id.value
    }
  }
}
