import { FormControl } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { DisplayElementTemplate } from '../display-element-template/display-element-template.model'
import { DisplayTemplate } from '../display-template/display-template.model'
import { DisplayTemplatesService } from '../display-template/display-templates.service'
import { Recipe } from '../recipe/recipe.model'
import { Scale } from '../scale/scale.model'
import { TrashTemplate } from '../trash-template/trash-template.model'
import { EDisplayElementContentType, EDisplayElementInformationType, EFunctionStatusCode } from '../../global.types'
import { IDisplay } from '../../global.models'
import { DisplayElement } from '../display-element/display-element.model'

export class Display {
  display_components: DisplayElement[] = []
  display_size_scale: number

  displayTemplatesService: DisplayTemplatesService
  constructor(private _trackingDisplayTemplateId?: FormControl, private _trackingLanguage?: FormControl, private _defaultDisplayTemplate?: DisplayTemplate, private _defaultLanguage?: string) {
    this.displayTemplatesService = FoodopLibModule.injector.get(DisplayTemplatesService)
  }

  get display_template(): DisplayTemplate {
    return this.displayTemplatesService.display_template_with_id(this._trackingDisplayTemplateId?.value) || this._defaultDisplayTemplate
  }
  get language(): string {
    return this._trackingLanguage?.value || this._defaultLanguage
  }

  get valid(): boolean {
    return this.display_components.length > 0
  }

  addElementsForRecipe(recipe: Recipe): void {
    this.display_template?.selected_display_element_templates_sorted.forEach((display_element_template_shown: DisplayElementTemplate) => {
      switch (display_element_template_shown.code) {
        case EDisplayElementInformationType.title:
          this.addElement(recipe.names[this.language].value, EDisplayElementContentType.string, display_element_template_shown)
          break
        case EDisplayElementInformationType.description:
          this.addElement(recipe.descriptions[this.language].value, EDisplayElementContentType.string, display_element_template_shown)
          break
        case EDisplayElementInformationType.ingredients:
          this.addElement(recipe.ingredientsStringWithLabel(this.language), EDisplayElementContentType.string, display_element_template_shown)
          break
        case EDisplayElementInformationType.allergens:
          this.addElement(recipe.allergensStringWithLabel(this.language, display_element_template_shown.show_may_contained_allergens.value, display_element_template_shown.allergen_format.value), EDisplayElementContentType.string, display_element_template_shown)
          break
        case EDisplayElementInformationType.co2:
          this.addElement(recipe.co2StringWithLabel(this.language), EDisplayElementContentType.string, display_element_template_shown)
          break
        case EDisplayElementInformationType.tags:
          this.addElement(
            recipe.tagsLabel(
              this.language,
              ', ',
              $localize` og `,
              display_element_template_shown.tag_categories.value.map((tag) => tag.code)
            ),
            EDisplayElementContentType.string,
            display_element_template_shown
          )
          break
        case EDisplayElementInformationType.nutrition:
          this.addElement(
            recipe.nutritionDisplayLabel(
              this.language,
              display_element_template_shown.nutrition_types.value.map((nutrition_type) => nutrition_type.code)
            ),
            EDisplayElementContentType.string,
            display_element_template_shown
          )
          break
        default:
          break
      }
    })
  }

  addElementsForTrashTemplate(trash_template: TrashTemplate): void {
    this.display_template?.selected_display_element_templates_sorted.forEach((display_element_template_shown) => {
      switch (display_element_template_shown.code) {
        case EDisplayElementInformationType.title:
          this.addElement(trash_template.names[this.language].value, EDisplayElementContentType.string, display_element_template_shown)
          break
        case EDisplayElementInformationType.description:
          this.addElement(trash_template.descriptions[this.language].value, EDisplayElementContentType.string, display_element_template_shown)
          break
        default:
          break
      }
    })
  }

  addElementsForDefaultDisplay(scale: Scale): void {
    this.display_template?.selected_display_element_templates_sorted.forEach((display_element_template_shown) => {
      switch (display_element_template_shown.code) {
        case EDisplayElementInformationType.scale_name:
          this.addElement(scale.name.value, EDisplayElementContentType.string, display_element_template_shown)
          break
        case EDisplayElementInformationType.scale_group:
          this.addElement(scale.scale_group_name, EDisplayElementContentType.string, display_element_template_shown)
          break
        default:
          break
      }
    })
  }

  addElement(content: any, content_type: EDisplayElementContentType, display_element_template: DisplayElementTemplate) {
    if (!this.display_components.find((display_element) => display_element.display_element_template.code == display_element_template.code)) {
      let new_element: DisplayElement = new DisplayElement(content, content_type, display_element_template)
      let adjustment: EFunctionStatusCode = new_element.adjustFontSizeToBoundaries()

      let trim = 3
      while (adjustment == EFunctionStatusCode.reachedMinFontSize) {
        new_element = new DisplayElement(content.slice(0, -trim) + '...', content_type, display_element_template)
        adjustment = new_element.adjustFontSizeToBoundaries()
        new_element.invalid = true
        trim += 1
      }

      this.display_components.push(new_element)
    }
  }

  removeElement(): void {
    if (this.displayhasElements) this.display_components.splice(this.display_components.length - 1, 1)
  }
  get displayhasElements(): boolean {
    return this.display_components.length > 0
  }

  get string_display_components(): DisplayElement[] {
    return this.display_components.filter((display_element) => display_element.content_type == EDisplayElementContentType.string)
  }

  reDraw(recipe?: Recipe, trash_template?: TrashTemplate, scale?: Scale): void {
    this.display_template?.setDisplayElementBoundaries()
    this.display_components = []
    this._drawElements(recipe, trash_template, scale)
  }
  private _drawElements(recipe?: Recipe, trash_template?: TrashTemplate, scale?: Scale): void {
    if (recipe) this.addElementsForRecipe(recipe)
    if (trash_template) this.addElementsForTrashTemplate(trash_template)
    if (scale) this.addElementsForDefaultDisplay(scale)
  }

  set_display_size_scale(display_identifier: string): void {
    // Check if scale display is rendered:
    const checkIfScaleDisplayIsRendered = setInterval(() => {
      const display_HTMLElement = document.getElementById('display_' + display_identifier)

      if (display_HTMLElement) {
        clearInterval(checkIfScaleDisplayIsRendered)
        const display_HTMLElement_width = parseFloat(getComputedStyle(display_HTMLElement).width)
        this.display_size_scale = display_HTMLElement_width / this.display_template?.display_format.width
      }
    }, 50)
  }

  get as_dict(): IDisplay {
    return {
      display_components: this.display_components.map((display_element) => display_element.as_dict),
      display_format: this.display_template?.display_format.as_dict,
      language: this.language
    }
  }
}
