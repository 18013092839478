


<mat-tab-group mat-align-tabs="center" animationDuration="800ms">

    <!-- Scales Tab -->
    <mat-tab label="Info" class="padding-large">

        <div class="gateway-wrapper">
            <div class="gateway-info-container">
                <label>ID: {{gateway.id}}</label>
                <label class="gateway-online-label">
                    Online: {{ gateway.online }}
                    <span *ngIf="gateway.online" class="material-icons icon-true">check_circle</span>
                    <span *ngIf="!gateway.online" class="material-icons icon-false">warning</span>
                </label>
                <label>Last seen by Mender: {{ gateway.last_seen_by_mender ? gateway.last_seen_by_mender.format('YYYY-MM-DD HH:mm:ss') : 'never'}}</label>
                <label>Last interaction: {{ gateway.last_interaction ? gateway.last_interaction.format('YYYY-MM-DD HH:mm:ss') : 'never'}}</label>
                <label>Connection status:&nbsp; {{status}}</label>
                <label>Version: {{gateway.software_version}}</label>
                <label>Mender status: {{gateway.mender_status}}</label>
                <label>Allocation: {{gateway.location_id?.value ? gateway.location?.name?.value : 'Unallocated'}}</label>
                <label>Scales: {{gateway.scales?.length}}</label>
            </div>

            <div class="gateway-actions-container">
                <button (click)="restartGateway();" class="gateway-action-button l-display-flex l-flex-horizontally-center l-position-center-vertically l-theme-green l-box-shadow l-border-radius l-font-mini l-leftmargin-small l-padding-large-dynamic">
                    Restart gateway
                    <mat-icon *ngIf="notificationStatus['restartGateway'] == 'failed'" class="l-leftmargin-small">check_box_outline_blank</mat-icon>
                    <mat-spinner *ngIf="notificationStatus['restartGateway'] == 'sending'" [diameter]="14" class="o-inline-block l-leftmargin-small"></mat-spinner>
                    <mat-icon *ngIf="notificationStatus['restartGateway'] == 'sent'" class="l-leftmargin-small">check_box</mat-icon>
                </button>
                <button (click)="getScaleStatus();" class="gateway-action-button l-theme-green l-box-shadow l-border-radius l-font-mini l-leftmargin-small l-padding-large-dynamic">
                    Læs status fra vægte
                    <mat-icon *ngIf="notificationStatus['getScaleStatus'] == 'failed'" class="l-leftmargin-small">check_box_outline_blank</mat-icon>
                    <mat-spinner *ngIf="notificationStatus['getScaleStatus'] == 'sending'" [diameter]="14" class="o-inline-block l-leftmargin-small"></mat-spinner>
                    <mat-icon *ngIf="notificationStatus['getScaleStatus'] == 'sent'" class="l-leftmargin-small">check_box</mat-icon>
                </button>
                <button (click)="pickScaleAndDate();" class="gateway-action-button l-theme-green l-box-shadow l-border-radius l-font-mini l-leftmargin-small l-padding-large-dynamic">Hent data fra vægt</button>
                <button (click)="clearGatewaySchedule();" class="gateway-action-button l-display-flex l-flex-horizontally-center l-position-center-vertically l-theme-green l-box-shadow l-border-radius l-font-mini l-leftmargin-small l-padding-large-dynamic">
                    Delete gateway schedule
                    <mat-icon *ngIf="notificationStatus['clearGatewaySchedule'] == 'failed'" class="l-leftmargin-small">check_box_outline_blank</mat-icon>
                    <mat-spinner *ngIf="notificationStatus['clearGatewaySchedule'] == 'sending'" [diameter]="14" class="o-inline-block l-leftmargin-small"></mat-spinner>
                    <mat-icon *ngIf="notificationStatus['clearGatewaySchedule'] == 'sent'" class="l-leftmargin-small">check_box</mat-icon>
                </button>
                <button (click)="updateGatewaySchedule();" class="gateway-action-button l-display-flex l-flex-horizontally-center l-position-center-vertically l-theme-green l-box-shadow l-border-radius l-font-mini l-leftmargin-small l-padding-large-dynamic">
                    Update gateway schedule
                    <mat-icon *ngIf="notificationStatus['updateGatewaySchedule'] == 'failed'" class="l-leftmargin-small">check_box_outline_blank</mat-icon>
                    <mat-spinner *ngIf="notificationStatus['updateGatewaySchedule'] == 'sending'" [diameter]="14" class="o-inline-block l-leftmargin-small"></mat-spinner>
                    <mat-icon *ngIf="notificationStatus['updateGatewaySchedule'] == 'sent'" class="l-leftmargin-small">check_box</mat-icon>
                </button>
                <button (click)="recreateGatewayDB();" class="gateway-action-button l-display-flex l-flex-horizontally-center l-position-center-vertically l-theme-green l-box-shadow l-border-radius l-font-mini l-leftmargin-small l-padding-large-dynamic">
                    Recreate gateway database
                    <mat-icon *ngIf="notificationStatus['recreateGatewayDB'] == 'failed'" class="l-leftmargin-small">check_box_outline_blank</mat-icon>
                    <mat-spinner *ngIf="notificationStatus['recreateGatewayDB'] == 'sending'" [diameter]="14" class="o-inline-block l-leftmargin-small"></mat-spinner>
                    <mat-icon *ngIf="notificationStatus['recreateGatewayDB'] == 'sent'" class="l-leftmargin-small">check_box</mat-icon>
                </button>
            </div>
        </div>

    </mat-tab>

    <mat-tab label="Log" class="padding-large">

        <app-gateway-logs *ngIf="gateway" class="l-width-full l-clear l-block" [gateway]="gateway"></app-gateway-logs>

    </mat-tab>

</mat-tab-group>