import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { RestApiService } from '../../services/rest-api.service'
import { Scale } from './scale.model'
import { IScale } from '../../global.models'
import { LocationsService } from '../location/locations.service'

@Injectable({
  providedIn: 'root'
})
export class ScalesService {
  scales: Scale[] = []

  constructor(public fb: FormBuilder, private restApiService: RestApiService, @Inject(LOCALE_ID) public language: string, private locationService: LocationsService) {}

  getScale(macc: string): Observable<IScale> {
    const params = {
      fields: 'all',
      active: true,
      macc: macc
    }
    return this.restApiService.loadScale(params).pipe(
      tap((scale) => {
        this.addToScales(scale)
      })
    )
  }

  getScales(fields?: string[], gateway_id?: string, scale_group_id?: boolean, macc?: string, locationIds?: string[], offset?: number): Observable<Scale[]> {
    let params = {}
    if (fields) params['fields'] = fields.join(',')
    if (gateway_id) params['gateway_id'] = gateway_id
    if (scale_group_id) params['scale_group_id'] = scale_group_id
    if (macc) params['search_string'] = macc
    if (locationIds?.length) params['location_ids'] = locationIds.join(',')
    if (offset != undefined) {
      params['limit'] = 10
      params['offset'] = offset
    }
    return this.restApiService.loadScales(params).pipe(
      tap((response) => {
        response['locations']?.forEach((location) => this.locationService.addToLocations(location))
        response['scales']?.forEach((scale) => this.addToScales(scale))
      }),
      map((response) => response['scales']?.map((scale) => this.scale_with_macc(scale.macc)))
    )
  }

  addToScales(scale: IScale): void {
    const existing_scale: Scale = this.scale_with_macc(scale.macc)
    if (!existing_scale) this.scales.push(new Scale(scale))
    else existing_scale.patchValues(scale)
  }

  scale_with_macc(scale_macc: string): Scale {
    return this.scales.find((scale) => scale.macc == scale_macc)
  }

  saveScale(scale: IScale): Observable<IScale> {
    return this.restApiService.saveScale(scale)
  }

  swapScales(scale_in: string, scale_out: string): Observable<any> {
    let params = {}
    if (scale_in) params['scale_in'] = scale_in
    if (scale_out) params['scale_out'] = scale_out

    return this.restApiService.swapScales(params).pipe(
      tap((response) => {
        if (response) {
          console.log('Swapped scales successfully!')
        } else {
          console.log('Failed to swap scales!')
        }
      })
    )
  }

  get scales_sorted(): Scale[] {
    return this.scales.sort((a, b) => (a.gateway_id.value == b.gateway_id.value ? (a.index.value > b.index.value ? 1 : -1) : a.gateway_id.value > b.gateway_id.value ? 1 : -1))
  }
}
