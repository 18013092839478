import moment from 'moment'

export function createDaterange(startDate: moment.Moment, endDate: moment.Moment, granularity: moment.unitOfTime.DurationConstructor, includeWeekends: boolean): moment.Moment[] {
  let daterange: moment.Moment[] = []

  if (startDate && endDate) {
    let date: moment.Moment = moment(moment(startDate).format('YYYY-MM-DD'))
    while (date.isSameOrBefore(endDate, granularity)) {
      daterange.push(date)
      if (!includeWeekends && date.weekday() == 5) date = date.clone().add(3, granularity)
      else date = date.clone().add(1, granularity)
    }
  } else if (startDate) {
    daterange.push(moment(startDate))
  } else if (endDate) {
    daterange.push(moment(endDate))
  }
  return daterange
}
