import { FormControl, Validators } from '@angular/forms'
import { BowlSetup } from '../bowls/bowl-setup/bowl-setup.model'
import { Bowl } from '../bowls/bowl/bowl.model'
import { Display } from '../display/display.model'
import { MenuDish } from '../menu-dish/menu-dish.model'
import { Scale } from '../scale/scale.model'
import { TrashTemplate } from '../trash-template/trash-template.model'
import { IScaleDish } from '../../global.models'
import { Tracking } from '../tracking/tracking.model'
import moment from 'moment'
import { ScalesService } from '../scale/scales.service'
import { FoodopLibModule } from '../../foodop-lib.module'

export class ScaleDish {
  public id: string
  public scale_macc: FormControl
  public scale_index: number

  public display: Display

  public menu_dish_id: string
  public type: string

  public planned: moment.Moment
  public starting: moment.Moment
  public started: moment.Moment
  public failed_start: moment.Moment
  public stopping: moment.Moment
  public stopped: moment.Moment
  public failed_stop: moment.Moment
  public updated: moment.Moment
  public failed_start_out_of_reach: moment.Moment
  public failed_stop_out_of_reach: moment.Moment
  public data_uploaded: boolean
  public insights_up_to_date: boolean
  public valid_insight: boolean
  public flag_drift: boolean
  public flag_level_shift: boolean
  public flag_no_insights: boolean
  public flag_offset: boolean

  public bowlSetups: BowlSetup[]

  private _scalesService: ScalesService

  constructor(tracking: Tracking, public scaleDish?: IScaleDish, type?: string, scale?: Scale, menu_dish?: MenuDish, trash_template?: TrashTemplate, bowlSetups?: BowlSetup[], trash_bowls?: Bowl[]) {
    this._scalesService = FoodopLibModule.injector.get(ScalesService)

    this.id = scaleDish?.id
    this.scale_macc = new FormControl({ value: scaleDish?.scale_macc || scale?.macc, disabled: false }, [Validators.required])
    this.scale_index = scaleDish?.scale_index || scale?.index.value

    this.menu_dish_id = menu_dish?.id
    this.type = scaleDish?.type || type || 'dish'

    this.bowlSetups = scaleDish ? scaleDish.bowl_setups.map((bowlSetup) => new BowlSetup(bowlSetup)) : bowlSetups ? bowlSetups.map((bowlSetup) => new BowlSetup(bowlSetup.as_dict)) : []

    this.display = new Display(tracking.displayTemplateId, tracking.language)
    this.data_uploaded = scaleDish?.data_uploaded
    this.insights_up_to_date = scaleDish?.insights_up_to_date
    this.valid_insight = scaleDish?.valid_insight
    this.flag_drift = scaleDish?.flag_drift
    this.flag_level_shift = scaleDish?.flag_level_shift
    this.flag_no_insights = scaleDish?.flag_no_insights
    this.flag_offset = scaleDish?.flag_offset

    this._setStatus(scaleDish)
  }

  public get scale(): Scale {
    return this._scalesService.scales.find((scale) => scale.macc == this.scale_macc.value)
  }

  public get is_bowl_selection_valid(): boolean {
    return this.bowlSetups?.find((bowlSetup) => bowlSetup.default.value) != undefined
  }

  public get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined && this.is_bowl_selection_valid && this.display.valid
  }

  public toggleBowlSetupSelection(bowlSetup: BowlSetup): void {
    const selected_bowlSetup_index: number = this.bowlSetups.findIndex((selected_bowlSetup) => selected_bowlSetup.id == bowlSetup.id)
    if (selected_bowlSetup_index >= 0 && !bowlSetup.isDefaultSelectionForScaleDish(this)) {
      this.bowlSetups.forEach((selected_bowlSetup) => selected_bowlSetup.default.setValue(false))
      this.bowlSetups[selected_bowlSetup_index].default.setValue(true)
    } else if (selected_bowlSetup_index >= 0) {
      this.bowlSetups.splice(selected_bowlSetup_index, 1)
      if (!this.is_bowl_selection_valid && this.bowlSetups.length > 0) this.bowlSetups[0].default.setValue(true)
    } else {
      const new_bowlSetup = new BowlSetup(bowlSetup.as_dict)
      this.bowlSetups.push(new_bowlSetup)
      if (!this.is_bowl_selection_valid) new_bowlSetup.default.setValue(true)
    }
  }

  public get asDict(): IScaleDish {
    const display_dict = this.display.as_dict
    display_dict['scale_macc'] = this.scale_macc.value
    return {
      id: this.id,
      scale_macc: this.scale_macc.value,
      menu_dish_id: this.menu_dish_id,
      scale_index: this.scale_index,
      display: display_dict,
      type: this.type,
      bowl_setups: this.bowlSetups.map((bowlSetup) => bowlSetup.as_dict)
    }
  }

  private _setStatus(scaleDish: IScaleDish): void {
    ;['planned', 'starting', 'started', 'failed_start', 'stopping', 'stopped', 'failed_stop', 'updated', 'failed_start_out_of_reach', 'failed_stop_out_of_reach'].forEach((key) => {
      if (scaleDish && key in scaleDish && scaleDish[key]) this[key] = moment(moment.utc(scaleDish[key]).format())
    })
  }
}
