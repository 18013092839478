import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { FoodopLibModule } from '../../foodop-lib.module'
import { PlatformSettings } from '../platform-settings/platform-settings.model'
import { PortionTemplate } from '../portion-template/portion_template.model'
import { SubsidiaryService } from './subsidiary.service'
import { IPortionTemplate, ISubsidiary } from '../../global.models'
import { LocationsService } from '../location/locations.service'
import { Location } from '../location/location.model'
import { OrganizationsService } from '../organization/organizations.service'
import { Organization } from '../organization/organization.model'

export class Subsidiary {
  id: string
  name: FormControl
  nick_name: FormControl
  platform_settings: PlatformSettings
  customer_type: FormControl
  logo_url: FormControl
  canteen_guest_count: FormControl
  organization_id: FormControl
  active: FormControl
  show_weekends: FormControl
  procurement_module_enabled: FormControl
  procurement_grouping: FormControl
  share_recipes: FormControl

  website: FormControl
  phone: FormControl
  cvr: FormControl
  address: FormControl
  postal: FormControl
  city: FormControl
  country: FormControl

  portion_templates: PortionTemplate[]

  saved_subsidiary: any
  saving = false
  has_loaded_details = false

  organizationsService: OrganizationsService
  subsidiaryService: SubsidiaryService
  locationsService: LocationsService
  fb: FormBuilder

  constructor(public subsidiary?: ISubsidiary) {
    this.organizationsService = FoodopLibModule.injector.get(OrganizationsService)
    this.subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)
    this.locationsService = FoodopLibModule.injector.get(LocationsService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.id = subsidiary?.id
    this.name = this.fb.control({ value: subsidiary?.name, disabled: false }, [Validators.required])
    this.nick_name = this.fb.control({ value: subsidiary?.nick_name, disabled: false })
    this.platform_settings = new PlatformSettings(subsidiary?.platform_settings)
    this.customer_type = this.fb.control({ value: subsidiary?.customer_type, disabled: false }, [Validators.required])
    this.canteen_guest_count = this.fb.control(subsidiary?.canteen_guest_count)
    this.logo_url = this.fb.control(subsidiary?.logo_url)
    this.organization_id = this.fb.control(subsidiary?.organization_id, [Validators.required])
    this.active = this.fb.control(subsidiary?.active)
    this.show_weekends = this.fb.control(subsidiary?.show_weekends)
    this.procurement_module_enabled = this.fb.control(subsidiary?.procurement_module_enabled)
    this.procurement_grouping = this.fb.control(subsidiary?.procurement_grouping)
    this.share_recipes = this.fb.control(subsidiary?.share_recipes != undefined ? subsidiary?.share_recipes : true)

    this.website = this.fb.control(subsidiary?.website)
    this.phone = this.fb.control(subsidiary?.phone)
    this.cvr = this.fb.control(subsidiary?.cvr)
    this.address = this.fb.control(subsidiary?.address)
    this.postal = this.fb.control(subsidiary?.postal)
    this.city = this.fb.control(subsidiary?.city)
    this.country = this.fb.control(subsidiary?.country)

    this._reindex_portion_templates(subsidiary?.portion_templates || [])
    this.portion_templates = (subsidiary?.portion_templates || []).map((portion_template) => new PortionTemplate(portion_template))
    if (!this.default_portion_template && this.portion_templates.length) this.portion_templates[0].default.setValue(true)

    this.saved_subsidiary = JSON.stringify(this.as_dict)
  }

  patchValues(subsidiary: ISubsidiary): void {
    if (subsidiary?.id) this.id = subsidiary.id
    if (subsidiary?.name) this.name.setValue(subsidiary.name)
    if (subsidiary?.nick_name) this.nick_name.setValue(subsidiary.nick_name)
    if (subsidiary?.platform_settings) this.platform_settings = new PlatformSettings(subsidiary.platform_settings)
    if (subsidiary?.customer_type) this.customer_type.setValue(subsidiary.customer_type)
    if (subsidiary?.canteen_guest_count) this.canteen_guest_count.setValue(subsidiary.canteen_guest_count)
    if (subsidiary?.organization_id) this.organization_id.setValue(subsidiary?.organization_id)
    if (subsidiary?.logo_url) this.logo_url.setValue(subsidiary.logo_url)
    if (subsidiary?.active != undefined) this.active.setValue(subsidiary.active)
    if (subsidiary?.show_weekends) this.show_weekends.setValue(subsidiary.show_weekends)
    if (subsidiary?.procurement_module_enabled) this.procurement_module_enabled.setValue(subsidiary.procurement_module_enabled)
    if (subsidiary?.procurement_grouping != undefined) this.procurement_grouping.setValue(subsidiary.procurement_grouping)
    if (subsidiary?.share_recipes != undefined) this.share_recipes.setValue(subsidiary.share_recipes)

    if (subsidiary?.website) this.website.setValue(subsidiary.website)
    if (subsidiary?.phone) this.phone.setValue(subsidiary.phone)
    if (subsidiary?.cvr) this.cvr.setValue(subsidiary.cvr)
    if (subsidiary?.address) this.address.setValue(subsidiary.address)
    if (subsidiary?.postal) this.postal.setValue(subsidiary.postal)
    if (subsidiary?.city) this.city.setValue(subsidiary.city)
    if (subsidiary?.country) this.country.setValue(subsidiary.country)

    if (subsidiary?.portion_templates) {
      this._reindex_portion_templates(subsidiary?.portion_templates || [])
      this.portion_templates = (subsidiary?.portion_templates || []).map((portion_template) => new PortionTemplate(portion_template))
    }

    this.saved_subsidiary = JSON.stringify(this.as_dict)
  }

  get active_portion_templates_sorted(): PortionTemplate[] {
    return this.portion_templates.filter((portion_template) => portion_template.active.value).sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }
  get inactive_portion_templates_sorted(): PortionTemplate[] {
    return this.portion_templates.filter((portion_template) => !portion_template.active.value).sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }
  get portion_templates_sorted(): PortionTemplate[] {
    return [...this.active_portion_templates_sorted, ...this.inactive_portion_templates_sorted]
  }
  get default_portion_template(): PortionTemplate {
    return this.portion_templates.find((portion_template) => portion_template.default.value)
  }

  get locations(): Location[] {
    return this.locationsService.locations.filter((location) => this.id == location.subsidiary_id.value)
  }

  get organization(): Organization {
    return this.organizationsService.organization_with_id(this.organization_id.value)
  }

  get locations_sorted(): Location[] {
    return this.locationsService.locations_sorted.filter((location) => this.id == location.subsidiary_id.value)
  }

  get active_dish_or_dish_waste_locations(): Location[] {
    return this.locationsService.active_dish_or_dish_waste_locations.filter((location) => this.id == location.subsidiary_id.value)
  }

  get active_dish_locations_sorted(): Location[] {
    return this.locationsService.active_dish_locations_sorted.filter((location) => this.id == location.subsidiary_id.value)
  }

  get active_dish_and_display_locations_sorted(): Location[] {
    return this.locationsService.active_dish_and_display_locations_sorted.filter((location) => this.id == location.subsidiary_id.value)
  }

  get active_waste_locations_sorted(): Location[] {
    return this.locationsService.active_waste_locations_sorted.filter((location) => this.id == location.subsidiary_id.value)
  }

  get active_dish_waste_locations_sorted(): Location[] {
    return this.locationsService.active_dish_waste_locations_sorted.filter((location) => this.id == location.subsidiary_id.value)
  }

  get active_locations_sorted(): Location[] {
    return this.locationsService.active_locations_sorted.filter((location) => this.id == location.subsidiary_id.value)
  }

  portion_template_with_id(portion_template_id: string): PortionTemplate {
    return this.portion_templates.find((portion_template) => portion_template.id == portion_template_id)
  }

  togglePortionTemplateDefault(portion_template: PortionTemplate): void {
    if (portion_template.default.value) {
      const new_default_portion_template = this.portion_templates.find((portion_template) => portion_template.id != portion_template.id)
      new_default_portion_template.default.setValue(true)
      portion_template.default.setValue(false)
    } else {
      const old_default_portion_template = this.portion_templates.find((portion_template) => portion_template.default.value)
      old_default_portion_template.default.setValue(false)
      portion_template.default.setValue(true)
    }
  }
  _reindex_portion_templates(portions: IPortionTemplate[]): void {
    portions
      .sort((a, b) => (a.index > b.index ? 1 : -1))
      .forEach((portion, index) => {
        portion.index = index
      })
  }

  get portion_templates_valid(): boolean {
    return this.portion_templates.find((portion_template) => !portion_template.valid) == undefined
  }

  get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined && this.portion_templates_valid
  }

  get changed(): boolean {
    return this.saved_subsidiary != JSON.stringify(this.as_dict)
  }

  get is_new(): Boolean {
    return !this.id
  }

  save(): Observable<Subsidiary> {
    this.saving = true
    return this.subsidiaryService.saveSubsidiary(this).pipe(
      tap((subsidiary) => {
        this.patchValues(subsidiary)
        this.saved_subsidiary = JSON.stringify(this.as_dict)
        this.saving = false
        this.organization?.addSubsidiary(this)
      }),
      map(() => {
        return this
      })
    )
  }

  get as_dict(): ISubsidiary {
    return {
      id: this.id,
      name: this.name.value,
      nick_name: this.nick_name.value,
      customer_type: this.customer_type.value,
      website: this.website.value,
      phone: this.phone.value,
      cvr: this.cvr.value,
      address: this.address.value,
      postal: this.postal.value,
      city: this.city.value,
      country: this.country.value,
      active: this.active.value,
      organization_id: this.organization_id.value,
      show_weekends: this.show_weekends.value,
      procurement_module_enabled: this.procurement_module_enabled.value,
      procurement_grouping: this.procurement_grouping.value,
      share_recipes: this.share_recipes.value,
      portion_templates: this.portion_templates.map((portion_template) => portion_template.as_dict)
    }
  }
}
