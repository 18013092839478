import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Component, ComponentRef, ViewContainerRef } from '@angular/core'
import { OrganizationComponent } from './organization/organization.component'
import { GlobalFunctionsService, Organization, OrganizationsService } from 'foodop-lib'
import { Observable, Subject, catchError, debounceTime, exhaustMap, filter, scan, startWith, switchMap, takeWhile, tap } from 'rxjs'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css']
})
export class OrganizationsComponent {
  public customerSearchControl: FormControl = new FormControl('')
  public customerSelection: FormControl = new FormControl([])
  public loadingOrganizations: boolean = false
  public moreOrganizationsAvailable: boolean = false

  private _organizationsOffset: number = 0
  private _loadNextOrganizations$ = new Subject()
  private overlayRef: OverlayRef

  public organizations$: Observable<Organization[]> = this._loadOrganizations()

  constructor(public organizationsService: OrganizationsService, public func: GlobalFunctionsService, public overlay: Overlay, public viewContainerRef: ViewContainerRef) {}

  public openOrganization(organization?: Organization) {
    let config = new OverlayConfig()
    config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
    config.hasBackdrop = true

    this.overlayRef = this.overlay.create(config)

    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose()
    })

    this.overlayRef.addPanelClass('l-width-70p')
    this.overlayRef.addPanelClass('l-max-height-80p')
    this.overlayRef.addPanelClass('l-min-height-365')
    this.overlayRef.addPanelClass('l-overflow')

    const compRef: ComponentRef<OrganizationComponent> = this.overlayRef.attach(
      new ComponentPortal(
        OrganizationComponent,
        this.viewContainerRef,
        this.func.createInjector({
          organization_id: organization?.id
        })
      )
    )
  }

  public loadMore(): void {
    this._loadNextOrganizations$.next(true)
  }

  private _loadOrganizations(): Observable<Organization[]> {
    const filterChanges$ = this.customerSearchControl.valueChanges.pipe(
      startWith(''),
      tap((value) => {
        if (typeof value === 'string') {
          this._organizationsOffset = 0
        }
      }),
      filter((value) => typeof value === 'string' && (value.length >= 2 || value == '')),
      debounceTime(200)
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextOrganizations$.pipe(
          startWith(0),
          tap(() => (this.loadingOrganizations = true)),
          exhaustMap(() => this.organizationsService.loadOrganizations('all', this.customerSearchControl.value.toLowerCase(), this._organizationsOffset)),
          takeWhile((organizations) => {
            this.moreOrganizationsAvailable = organizations.length == 10
            return organizations.length == 10, true
          }),
          scan((allOrganizations: Organization[], newOrganizations: Organization[]) => allOrganizations.concat(newOrganizations)),
          tap((organizations) => {
            this._organizationsOffset += 10
            this.loadingOrganizations = false
          }),
          catchError(() => [])
        )
      })
    )
  }
}
