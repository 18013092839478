import { FormBuilder, FormControl } from '@angular/forms'
import { Observable, Subscription, tap } from 'rxjs'
import { FoodopLibModule } from '../../foodop-lib.module'
import { GlobalFunctionsService } from '../../services/global-functions.service'
import { RawIngredient } from './raw-ingredient.model'
import { IRecipePortionIngredient } from '../../global.models'

export class RecipePortionIngredient {
  calculated_share: FormControl
  amount_rounded: FormControl = new FormControl()
  amount: number
  index: FormControl

  shrinkage_subscription: Subscription
  preparation_subscription: Subscription
  netto_subscription: Subscription

  public amountChange$: Observable<number> = this._listenForAmountChanges()

  fb: FormBuilder
  func: GlobalFunctionsService

  constructor(public raw_ingredient: RawIngredient, public portion_ingredient?: IRecipePortionIngredient, private ingredient_index?: number) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)
    this.func = FoodopLibModule.injector.get(GlobalFunctionsService)

    this.calculated_share = this.fb.control(portion_ingredient?.calculated_share)
    this.amount_rounded.setValue(portion_ingredient?.amount?.toString(), { emitEvent: false })
    this.amount = portion_ingredient?.amount || this.amount_rounded.value
    this.index = this.fb.control(portion_ingredient?.index || ingredient_index)
  }

  get netto_share(): number {
    return this.calculated_share.value * this.raw_ingredient.netto_factor.value
  }
  get object(): RawIngredient {
    return this.raw_ingredient
  }

  calculated_netto_share(portion_netto_amount: number): number {
    return this.amount != undefined && portion_netto_amount > 0 ? ((this.amount_in_kg * this.raw_ingredient.netto_factor.value) / portion_netto_amount) * 100 : null
  }

  setBruttoAmountBasedOnCalculatedShares(amount_in_g: number, total_calculated_shares: number): void {
    if (this.calculated_share.value != undefined && total_calculated_shares > 0) {
      this.amount = (this.object.unit.value == 'g' ? amount_in_g : amount_in_g / 1000) * (this.calculated_share.value / total_calculated_shares)
      this.amount_rounded.setValue(this.func.round(this.amount, this.object.unit.value == 'g' ? 0 : 2)?.toString(), { emitEvent: false })
    }
  }

  updateBruttoAmountBasedOnNettoDiff(diff_netto_amount_in_kg: number): void {
    this.amount = this.amount + (this.object.unit.value == 'g' ? diff_netto_amount_in_kg * 1000 : diff_netto_amount_in_kg) / this.raw_ingredient.netto_factor.value
    this.amount_rounded.setValue(this.func.round(this.amount, this.object.unit.value == 'g' ? 0 : 2)?.toString(), { emitEvent: false })
  }

  updateIngredientCalculatedShares(portion_amount: number): void {
    this.calculated_share.setValue(this.amount != undefined && portion_amount > 0 ? (this.amount_in_kg / portion_amount) * 100 : null)
  }

  get amount_in_kg(): number {
    return this.object.unit.value == 'g' ? this.amount / 1000 : this.amount
  }

  get as_dict(): IRecipePortionIngredient {
    return {
      calculated_share: this.calculated_share.value,
      index: this.index.value,
      ingredient_id: this.object.id
    }
  }

  private _listenForAmountChanges(): Observable<number> {
    return this.amount_rounded.valueChanges.pipe(
      tap(() => {
        this.amount = this.amount_rounded.value ? parseFloat(this.amount_rounded.value) : null
      })
    )
  }
}
