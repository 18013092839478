<mat-form-field class="l-width-full">
    <mat-label>Navn</mat-label>
    <input autocomplete="off" matInput [formControl]="subsidiary.name">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Kaldenavn</mat-label>
    <input autocomplete="off" matInput [formControl]="subsidiary.nick_name">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Kundetype</mat-label>
    <mat-select [formControl]="subsidiary.customer_type" [value]="subsidiary.customer_type.value">
        <mat-option value="canteen">Kantine</mat-option>
        <mat-option value="catering">Cateringvirksomhed</mat-option>
        <mat-option value="catering_customer">Frokostordningskunde</mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Hjemmeside</mat-label>
    <input autocomplete="off" matInput [formControl]="subsidiary.website">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Telefon</mat-label>
    <input autocomplete="off" matInput [formControl]="subsidiary.phone">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>CVR</mat-label>
    <input autocomplete="off" matInput [formControl]="subsidiary.cvr">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Adresse</mat-label>
    <input autocomplete="off" matInput [formControl]="subsidiary.address">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Postnummer</mat-label>
    <input autocomplete="off" matInput [formControl]="subsidiary.postal">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>By</mat-label>
    <input autocomplete="off" matInput [formControl]="subsidiary.city">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Land</mat-label>
    <input autocomplete="off" matInput [formControl]="subsidiary.country">
</mat-form-field>

<div class="l-width-full">
    <mat-label>Er denne subsidiary aktiv?</mat-label>
    <mat-slide-toggle color="primary" [formControl]="subsidiary.active"></mat-slide-toggle>
</div>

<div class="l-width-full l-overflow">
    <button type="button" mat-button [disabled]="!subsidiary.valid || !subsidiary.changed" (click)="saveSubsidiary()" class="l-float-right l-margin-horizontal-medium l-bottommargin-large primary-button" ><span ><mat-spinner *ngIf="subsidiary.saving" [diameter]="19" class="o-inline-block"></mat-spinner></span>Gem rettelser</button>
</div>