import { FormControl } from '@angular/forms'
import { FoodopLibModule } from '../../../foodop-lib.module'
import { IMenuPrintElementStringFormatTemplate } from '../../../global.models'
import { print_fonts } from '../../../global.types'
import { FilesService } from '../../../services/files.service'

export class MenuPrintTemplateStringElementFormat {
  public hidden: FormControl

  public marginTop: FormControl
  public marginRight: FormControl
  public marginBottom: FormControl
  public marginLeft: FormControl

  public fontColor: FormControl
  public fontFamily: FormControl
  public fontSize: FormControl
  public fontStyle: FormControl
  public textAlignment: FormControl
  public capitalization: FormControl

  private _filesService: FilesService

  constructor(public elementFormat?: IMenuPrintElementStringFormatTemplate, private defaultHidden?: boolean) {
    this._filesService = FoodopLibModule.injector.get(FilesService)

    this.hidden = new FormControl(elementFormat?.hidden != undefined ? elementFormat?.hidden : defaultHidden || false)

    this.marginTop = new FormControl(elementFormat?.marginTop || 0)
    this.marginRight = new FormControl(elementFormat?.marginRight || 0)
    this.marginBottom = new FormControl(elementFormat?.marginBottom || 0)
    this.marginLeft = new FormControl(elementFormat?.marginLeft || 0)

    this.fontColor = new FormControl(elementFormat?.fontColor || '#000000')
    this.fontFamily = new FormControl(elementFormat?.fontFamily || 'Arial')
    this.fontSize = new FormControl(elementFormat?.fontSize || 20)
    this.fontStyle = new FormControl(elementFormat?.fontStyle || ['normal'])
    this.textAlignment = new FormControl(elementFormat?.textAlignment || 'left')
    this.capitalization = new FormControl(elementFormat?.capitalization || 'titleCase')

    this._loadCustomFont()
  }

  public get asDict(): IMenuPrintElementStringFormatTemplate {
    return {
      hidden: this.hidden.value,

      marginTop: this.marginTop.value,
      marginRight: this.marginRight.value,
      marginBottom: this.marginBottom.value,
      marginLeft: this.marginLeft.value,

      fontColor: this.fontColor.value,
      fontFamily: this.fontFamily.value,
      fontSize: this.fontSize.value,
      fontStyle: this.fontStyle.value,
      textAlignment: this.textAlignment.value,
      capitalization: this.capitalization.value
    }
  }

  public patchValues(elementFormat): void {
    this.hidden.setValue(elementFormat?.hidden != undefined ? elementFormat?.hidden : false, { emitEvent: false })

    this.marginTop.setValue(elementFormat?.marginTop || 0, { emitEvent: false })
    this.marginRight.setValue(elementFormat?.marginRight || 0, { emitEvent: false })
    this.marginBottom.setValue(elementFormat?.marginBottom || 0, { emitEvent: false })
    this.marginLeft.setValue(elementFormat?.marginLeft || 0, { emitEvent: false })

    this.fontColor.setValue(elementFormat?.fontColor || '#000000', { emitEvent: false })
    this.fontFamily.setValue(elementFormat?.fontFamily || 'Arial', { emitEvent: false })
    this.fontSize.setValue(elementFormat?.fontSize || 20, { emitEvent: false })
    this.fontStyle.setValue(elementFormat?.fontStyle || ['normal'], { emitEvent: false })
    this.textAlignment.setValue(elementFormat?.textAlignment || 'left', { emitEvent: false })
    this.capitalization.setValue(elementFormat?.capitalization || 'titleCase', { emitEvent: false })
  }

  private _loadCustomFont() {
    if (!print_fonts.includes(this.fontFamily.value)) {
      this._filesService.getFileUrl([this.fontFamily.value], 'fonts').subscribe()
    }
  }
}
