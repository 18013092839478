import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Observable } from 'rxjs'
import { RestApiService } from '../../services/rest-api.service'
import { IScaleDish } from '../../global.models'

@Injectable({
  providedIn: 'root'
})
export class ScaleDishService {
  constructor(public fb: FormBuilder, private restApiService: RestApiService, @Inject(LOCALE_ID) public language: string) {}

  getScaleDishes(params): Observable<IScaleDish[]> {
    return this.restApiService.getScaleDishes(params)
  }
}
