<div class="l-overflow l-margin-horizontal-small">
    <!-- Title -->
    <label class="l-font-large l-bottommargin-large">Velkommen {{userService.user.first_name.value}}!</label>    
</div>

<div class="l-display-flex">
    <mat-card (click)="customers()" class="optionTile l-display-flex l-margin-medium l-flex-horizontally-center l-position-center-vertically l-cursor-pointer">
        <label class="l-block l-text-center">Administrer Kunder</label>
    </mat-card>

    <mat-card (click)="equipment()" class="optionTile l-display-flex l-margin-medium l-flex-horizontally-center l-position-center-vertically l-cursor-pointer">
        <label class="l-block l-text-center">Administrer Udstyr</label>
    </mat-card>
</div>
