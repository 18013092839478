import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { UserService } from 'foodop-lib'

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.css'],
  host: {
    class: 'l-margin-horizontal-3rem l-position-relative l-block l-topmargin-large'
  }
})
export class FrontpageComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, public userService: UserService) {}

  ngOnInit(): void {}

  customers() {
    this.router.navigate(['/customers'], {
      relativeTo: this.route
    })
  }

  equipment() {
    this.router.navigate(['/equipment'], {
      relativeTo: this.route
    })
  }

  operationDashboard() {
    this.router.navigate(['/operations-dashboard'], {
      relativeTo: this.route
    })
  }
}
