import { FormBuilder, FormControl } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { Names } from '../names/names.model'
import { RawIngredient } from '../raw-ingredient/raw-ingredient.model'
import { RecipePortionIngredient } from '../raw-ingredient/recipe-portion-ingredient.model'
import * as uuid from 'uuid'
import { merge, Observable, Subscription, tap } from 'rxjs'
import { GlobalFunctionsService } from '../../services/global-functions.service'
import { RecipePortionSubRecipe } from './recipe-portion-sub-recipe.model'
import { Recipe } from './recipe.model'
import { PortionTemplate } from '../portion-template/portion_template.model'
import { IRecipePortion } from '../../global.models'
import { roundString } from '../../utils/number-operators'

export class RecipePortion {
  id: string
  recipe_id: string
  portion_size: FormControl = new FormControl()
  portion_size_long: number
  index: FormControl
  default: FormControl
  names: Names
  portion_template_id: string

  servings: FormControl = new FormControl()
  servings_long: number

  portion_ingredients: RecipePortionIngredient[]
  portion_sub_recipes: RecipePortionSubRecipe[]

  ingredient_amount_subscription: Subscription

  public servingsChange$: Observable<number> = this._listenForServingsChanges()
  public portionSizeChange$: Observable<number> = this._listenForPortionSizeChanges()
  public ingredientAmountChange$: Observable<any>
  public subRecipeAmountChange$: Observable<any>

  fb: FormBuilder
  func: GlobalFunctionsService

  constructor(public raw_ingredients: RawIngredient[], public portion?: IRecipePortion, public sub_recipes?: Recipe[], menu_guests?: number, portion_template?: PortionTemplate, is_sub_recipe?: boolean) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)
    this.func = FoodopLibModule.injector.get(GlobalFunctionsService)

    this.names = new Names(portion_template?.names.as_dict || portion?.names)

    this.id = portion_template ? uuid.v1() : portion?.id || uuid.v1()
    this.portion_size.setValue(this.func.round(portion?.portion_size || 100, 0).toString(), { emitEvent: false })
    this.portion_size_long = portion?.portion_size || 100
    this.index = this.fb.control(portion_template?.index.value != undefined ? portion_template?.index.value : portion?.index != undefined ? portion?.index : 0)
    this.default = this.fb.control(portion_template?.default.value != undefined ? portion_template?.default.value : portion?.default != undefined ? portion?.default : true)
    this.portion_template_id = portion_template?.id

    let servings = 100
    if (menu_guests != undefined) servings = menu_guests
    else if (portion?.servings != undefined) servings = portion?.servings
    if (portion_template?.servings.value != undefined) servings = portion_template?.servings.value
    this.servings.setValue(this.func.round(servings, 0).toString(), { emitEvent: false })
    this.servings_long = servings

    this.portion_ingredients = (portion?.portion_ingredients || [])
      .filter((portion_ingredient: any) => raw_ingredients.find((raw_ingredient) => raw_ingredient.id == portion_ingredient.ingredient_id))
      .map((portion_ingredient: any) => {
        const ingredient = raw_ingredients.find((raw_ingredient) => raw_ingredient.id == portion_ingredient.ingredient_id)
        return new RecipePortionIngredient(ingredient, portion_ingredient)
      })

    this.portion_sub_recipes = (portion?.portion_sub_recipes || []).map(
      (portion_sub_recipe: any) =>
        new RecipePortionSubRecipe(
          portion_sub_recipe,
          sub_recipes.find((sub_recipe) => sub_recipe.id == portion_sub_recipe.recipe_id)
        )
    )

    this.ingredientAmountChange$ = this._listenForIngredientAmountChanges()
    this.subRecipeAmountChange$ = this._listenForSubRecipeAmountChanges()
    this._setIngredientBruttoAmountBasedOnCalculatedShares(is_sub_recipe)
    this._setSubRecipeBruttoAmountBasedOnCalculatedShares(is_sub_recipe)
  }

  public get portion_brutto_amount(): number {
    return this.portion_ingredients_brutto_amount + this.portion_sub_recipes_brutto_amount
  }

  public get portion_ingredients_brutto_amount(): number {
    return this.portion_ingredients
      .filter((ingredient) => ingredient.amount)
      .map((ingredient) => ingredient.amount_in_kg)
      .reduce((a, b) => a + b, 0)
  }

  public get portion_sub_recipes_brutto_amount(): number {
    return this.portion_sub_recipes
      .filter((sub_recipe) => sub_recipe.amount)
      .map((sub_recipe) => sub_recipe.amount_in_kg)
      .reduce((a, b) => a + b, 0)
  }

  public get unlocked_portion_ingredients(): RecipePortionIngredient[] {
    return this.portion_ingredients.filter((portion_ingredient) => !portion_ingredient.object.lock)
  }

  public get portion_ingredients_sorted(): RecipePortionIngredient[] {
    return this.portion_ingredients.sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }

  public get subRecipesSorted(): RecipePortionSubRecipe[] {
    return this.portion_sub_recipes.sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }

  public get unlocked_portion_sub_recipes(): RecipePortionSubRecipe[] {
    return this.portion_sub_recipes.filter((portion_sub_recipe) => !portion_sub_recipe.object.lock)
  }

  public get portion_ingredients_and_sub_recipes(): Array<RecipePortionIngredient | RecipePortionSubRecipe> {
    return [...this.portion_ingredients, ...this.portion_sub_recipes]
  }

  public get unlocked_portion_ingredients_and_sub_recipes(): Array<RecipePortionIngredient | RecipePortionSubRecipe> {
    return [...this.unlocked_portion_ingredients, ...this.unlocked_portion_sub_recipes]
  }
  public get invalid_ingredients_and_sub_recipes(): Array<RecipePortionIngredient | RecipePortionSubRecipe> {
    return this.portion_ingredients_and_sub_recipes.filter((sub_ingredient) => sub_ingredient.amount < 0)
  }

  public get portion_netto_amount_based_on_serving_sizes(): number {
    return this.portion_size_long * this.servings_long || 0
  }

  public get sum_of_unlocked_ingredient_shares(): number {
    return this.unlocked_portion_ingredients.map((portion_ingredient) => portion_ingredient.calculated_share.value).reduce((a, b) => a + b, 0)
  }

  public get sum_of_unlocked_sub_recipe_shares(): number {
    return this.unlocked_portion_sub_recipes.map((portion_sub_recipe) => portion_sub_recipe.calculated_share.value).reduce((a, b) => a + b, 0)
  }

  public get sum_of_unlocked_sub_ingredient_shares(): number {
    return this.sum_of_unlocked_ingredient_shares + this.sum_of_unlocked_sub_recipe_shares
  }

  public get sum_of_sub_ingredient_netto_amounts(): number {
    return this._sum_of_ingredient_netto_amounts + this._sum_of_sub_recipe_netto_amounts
  }

  public get sum_of_ingredient_shares(): number {
    return this.portion_ingredients.map((portion_ingredient) => portion_ingredient.calculated_share.value).reduce((a, b) => a + b, 0)
  }

  public get is_total_amount_valid(): boolean {
    return this._locked_portion_ingredients_and_sub_recipes.length > 0 ? this.portion_netto_amount_based_on_serving_sizes / 1000 >= this._sum_of_locked_sub_ingredient_brutto_amounts : true
  }

  public get as_dict(): IRecipePortion {
    return {
      names: this.names.as_dict,
      id: this.id,
      portion_size: this.portion_size_long,
      index: this.index.value,
      default: this.default.value,
      servings: this.servings_long,
      portion_template_id: this.portion_template_id,
      portion_ingredients: this.portion_ingredients.map((portion_ingredient) => portion_ingredient.as_dict),
      portion_sub_recipes: this.portion_sub_recipes.map((portion_sub_recipe) => portion_sub_recipe.as_dict)
    }
  }

  public sub_recipe_with_id(sub_recipe_id: string): Recipe {
    return this.sub_recipes.find((sub_recipe) => sub_recipe.id == sub_recipe_id)
  }

  public portion_ingredient_with_id(raw_ingredient_id: string): RecipePortionIngredient {
    return this.portion_ingredients.find((portion_ingredient) => portion_ingredient.object.id == raw_ingredient_id)
  }

  public portion_sub_recipe_with_id(recipe_id: string): RecipePortionSubRecipe {
    return this.portion_sub_recipes.find((portion_sub_recipe) => portion_sub_recipe.object.id == recipe_id)
  }

  public portion_ingredient_or_sub_recipe_with_id(id: string): RecipePortionIngredient | RecipePortionSubRecipe {
    return this.portion_ingredient_with_id(id) || this.portion_sub_recipe_with_id(id)
  }

  public portion_share(sum_of_portion_netto_amounts_based_on_serving_sizes: number): number {
    if (sum_of_portion_netto_amounts_based_on_serving_sizes) return this.portion_brutto_amount / sum_of_portion_netto_amounts_based_on_serving_sizes
    else if (!sum_of_portion_netto_amounts_based_on_serving_sizes) return 1
  }

  public isNameTranslated(language: string): boolean {
    return this.names[language].value ? true : false
  }

  public get sum_of_portion_netto_amounts_in_kg(): number {
    return this._sum_of_portion_ingredients_netto_amounts_in_kg + this._sum_of_portion_sub_recipes_netto_amounts_in_kg
  }

  public get portion_brutto_amount_based_on_portion_netto_amount_in_g(): number {
    return this.portion_netto_amount_based_on_serving_sizes / (this._weighted_sum_of_ingredient_shrinkages + this._weighted_sum_of_sub_recipe_shrinkages)
  }

  public addIngredient(ingredient: RawIngredient): void {
    this.portion_ingredients.push(new RecipePortionIngredient(ingredient, null, this.portion_ingredients.length))
    this.ingredientAmountChange$ = this._listenForIngredientAmountChanges()
  }

  public removeIngredient(ingredientToRemove: RawIngredient): void {
    const indexOfIngredient: number = this.portion_ingredients.findIndex((ingredient) => ingredient.object.id == ingredientToRemove.id)
    if (indexOfIngredient >= 0) this.portion_ingredients.splice(indexOfIngredient, 1)
    this.ingredientAmountChange$ = this._listenForIngredientAmountChanges()
  }

  public addSubRecipe(subRecipe: Recipe): void {
    this.portion_sub_recipes.push(new RecipePortionSubRecipe(null, subRecipe, this.portion_sub_recipes.length))
    this.subRecipeAmountChange$ = this._listenForSubRecipeAmountChanges()
  }

  public removeSubRecipe(subRecipeToRemove: Recipe): void {
    const indexOfSubRecipe: number = this.portion_sub_recipes.findIndex((subRecipe) => subRecipe.object.id == subRecipeToRemove.id)
    if (indexOfSubRecipe >= 0) this.portion_sub_recipes.splice(indexOfSubRecipe, 1)
    this.subRecipeAmountChange$ = this._listenForSubRecipeAmountChanges()
  }

  private get _sum_of_portion_ingredients_netto_amounts_in_kg(): number {
    return this.portion_ingredients
      .filter((ingredient) => ingredient.amount)
      .map((ingredient) => ingredient.amount_in_kg * this._raw_ingredient_with_id(ingredient.object.id).netto_factor.value)
      .reduce((a, b) => a + b, 0)
  }

  private get _sum_of_portion_sub_recipes_netto_amounts_in_kg(): number {
    return this.portion_sub_recipes
      .filter((sub_recipe) => sub_recipe.amount)
      .map((sub_recipe) => sub_recipe.amount_in_kg * this.sub_recipe_with_id(sub_recipe.object.id).netto_factor.value)
      .reduce((a, b) => a + b, 0)
  }

  private get _locked_portion_ingredients(): RecipePortionIngredient[] {
    return this.portion_ingredients.filter((portion_ingredient) => portion_ingredient.object.lock)
  }

  private get _sum_of_locked_ingredients_brutto_amounts(): number {
    return this._locked_portion_ingredients.map((portion_ingredient) => portion_ingredient.amount_in_kg).reduce((a, b) => a + b, 0)
  }

  private get _sum_of_locked_sub_recipes_brutto_amounts(): number {
    return this._locked_portion_sub_recipes.map((portion_sub_recipe) => portion_sub_recipe.amount_in_kg).reduce((a, b) => a + b, 0)
  }

  private get _sum_of_locked_sub_ingredient_brutto_amounts(): number {
    return this._sum_of_locked_ingredients_brutto_amounts + this._sum_of_locked_sub_recipes_brutto_amounts
  }

  private get _locked_portion_sub_recipes(): RecipePortionSubRecipe[] {
    return this.portion_sub_recipes.filter((portion_sub_recipe) => portion_sub_recipe.object.lock)
  }

  private get _sum_of_sub_recipe_netto_amounts(): number {
    return this.portion_sub_recipes.map((portion_sub_recipe) => portion_sub_recipe.amount_in_kg * this.sub_recipe_with_id(portion_sub_recipe.object.id).netto_factor.value || 0).reduce((a, b) => a + b, 0)
  }

  private get _locked_portion_ingredients_and_sub_recipes(): Array<RecipePortionIngredient | RecipePortionSubRecipe> {
    return [...this._locked_portion_ingredients, ...this._locked_portion_sub_recipes]
  }

  private get _sum_of_calculated_shares(): number {
    return this.sum_of_ingredient_shares + this._sum_of_sub_recipe_shares
  }
  private get _weighted_sum_of_ingredient_shrinkages(): number {
    if (!this._sum_of_calculated_shares) return 1
    else return this.portion_ingredients.map((portion_ingredient) => (this._raw_ingredient_with_id(portion_ingredient.object.id).netto_factor.value * portion_ingredient.calculated_share.value) / 100).reduce((a, b) => a + b, 0)
  }

  private get _weighted_sum_of_sub_recipe_shrinkages(): number {
    if (!this._sum_of_calculated_shares) return 1
    return this.portion_sub_recipes.map((sub_recipe) => (this.sub_recipe_with_id(sub_recipe.object.id).netto_factor.value * sub_recipe.calculated_share.value) / 100).reduce((a, b) => a + b, 0)
  }

  private get _sum_of_ingredient_netto_amounts(): number {
    return this.portion_ingredients.map((portion_ingredient) => portion_ingredient.amount_in_kg * this._raw_ingredient_with_id(portion_ingredient.object.id).netto_factor.value || 0).reduce((a, b) => a + b, 0)
  }

  private get _sum_of_sub_recipe_shares(): number {
    return this.portion_sub_recipes.map((portion_sub_recipe) => portion_sub_recipe.calculated_share.value).reduce((a, b) => a + b, 0)
  }

  private _setIngredientBruttoAmountBasedOnCalculatedShares(is_sub_recipe): void {
    if (is_sub_recipe && this.unlocked_portion_ingredients) {
      console.debug('Setting ingredient amount for ' + this.unlocked_portion_ingredients.length + ' sub recipe ingredients')
    }
    this.unlocked_portion_ingredients.forEach((ingredient) => ingredient.setBruttoAmountBasedOnCalculatedShares(this.portion_brutto_amount_based_on_portion_netto_amount_in_g * (this.sum_of_ingredient_shares / 100), this.sum_of_ingredient_shares))
  }

  private _setSubRecipeBruttoAmountBasedOnCalculatedShares(is_sub_recipe): void {
    if (this.unlocked_portion_sub_recipes.length) {
      console.debug('Setting sub recipe amount for ' + this.unlocked_portion_sub_recipes.length + ' sub recipes')
    }
    this.unlocked_portion_sub_recipes.forEach((portion_sub_recipe) => portion_sub_recipe.setBruttoAmountBasedOnCalculatedShares(this.portion_brutto_amount_based_on_portion_netto_amount_in_g * (this._sum_of_sub_recipe_shares / 100), this._sum_of_sub_recipe_shares))
  }

  private _raw_ingredient_with_id(raw_ingredient_id: string): RawIngredient {
    return this.raw_ingredients.find((raw_ingredient) => raw_ingredient.id == raw_ingredient_id)
  }

  private _listenForServingsChanges(): Observable<number> {
    return this.servings.valueChanges.pipe(
      tap(() => {
        this.servings_long = roundString(this.servings.value)
      })
    )
  }

  private _listenForPortionSizeChanges(): Observable<number> {
    return this.portion_size.valueChanges.pipe(
      tap(() => {
        this.portion_size_long = roundString(this.portion_size.value)
      })
    )
  }

  private _listenForIngredientAmountChanges(): Observable<any> {
    return merge(...this.portion_ingredients.map((ingredient) => ingredient.amountChange$)).pipe(
      tap(() => {
        this._updateIngredientCalculatedShares()
      })
    )
  }

  private _listenForSubRecipeAmountChanges(): Observable<any> {
    return merge(...this.portion_sub_recipes.map((sub_recipe) => sub_recipe.amountChange$)).pipe(
      tap(() => {
        this._updateIngredientCalculatedShares()
      })
    )
  }

  private _updateIngredientCalculatedShares(): void {
    this.portion_ingredients.forEach((ingredient) => {
      ingredient.updateIngredientCalculatedShares(this.portion_brutto_amount)
    })
  }
}
