import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { Subsidiary } from './../models/subsidiary/subsidiary.model'
import { MenuTemplate } from '../models/menu-templates/menu-template.model'

export function isValidSubsidiaryId(subsidiaries: Subsidiary[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null
    return subsidiaries.find((subsidiary) => subsidiary.id == control.value) ? null : { invalid: true }
  }
}

export function isValidMenuTemplateId(menuTemplates: MenuTemplate[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null
    return menuTemplates.find((menuTemplate) => menuTemplate.id == control.value) ? null : { invalid: true }
  }
}

export class CustomValidators {
  static isNumbers(control: FormControl) {
    if (control.value == undefined) {
      return null
    }

    return String(control.value).match(/^-?[0-9.]+$/) && !isNaN(control.value) ? null : { isNumbers: true }
  }

  static isBoolean(control: FormControl) {
    if (!control.value) {
      return null
    }

    return typeof control.value == 'boolean' ? null : { isBoolean: true }
  }

  static isValidColor(control: FormControl) {
    if (!control.value) return null
    return CSS.supports('color', control.value) ? null : { isValidColor: true }
  }
}
