import { Component, ElementRef, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { Location, Subsidiary, Organization, OrganizationsService } from 'foodop-lib'
import { Observable, Subject, catchError, debounceTime, exhaustMap, filter, scan, startWith, switchMap, takeWhile, tap } from 'rxjs'

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css'],
  host: {
    class: 'l-margin-horizontal-3rem l-position-relative l-block l-topmargin-large'
  }
})
export class EquipmentComponent {
  @ViewChild('customerInput') customerInput: ElementRef
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger

  public customerSearchControl: FormControl = new FormControl('')
  public customerSelection: FormControl = new FormControl([])
  public loadingOrganizations: boolean = false

  private _organizationsOffset: number = 0
  private _loadNextOrganizations$ = new Subject()
  private _organizations: Organization[] = []

  public organizations$: Observable<Organization[]> = this._loadOrganizations()

  constructor(public organizationsService: OrganizationsService) {}

  public toggleSelection(option: Location | Subsidiary | Organization): void {
    if (option.constructor.name == 'Organization') {
      if (this.optionSelected(option)) {
        this.customerSelection.setValue(
          this.customerSelection.value.filter(
            (selected_option: Location | Subsidiary | Organization) =>
              (option as Organization).id != selected_option.id &&
              !(option as Organization).subsidiaries.map((subsidiary) => subsidiary.id).includes(selected_option.id) &&
              !(option as Organization).subsidiaries.map((subsidiary) =>
                subsidiary.locations
                  .map((location) => location.id)
                  .flat()
                  .includes(selected_option.id)
              )
          )
        )
      } else {
        this.customerSelection.setValue(
          this.customerSelection.value
            .concat([option])
            .concat((option as Organization).subsidiaries)
            .concat((option as Organization).subsidiaries.map((subsidiary) => subsidiary.locations).flat())
        )
      }
    }
    if (option.constructor.name == 'Subsidiary') {
      if (this.optionSelected(option)) {
        this.customerSelection.setValue(
          this.customerSelection.value.filter((selected_option: Location | Subsidiary | Organization) => (option as Subsidiary).id != selected_option.id && !(option as Subsidiary).locations.map((location) => location.id).includes(selected_option.id))
        )
      } else {
        this.customerSelection.setValue(this.customerSelection.value.concat([option]).concat((option as Subsidiary).locations))
      }
    } else if (option.constructor.name == 'Location')
      if (this.optionSelected(option)) this.customerSelection.setValue(this.customerSelection.value.filter((selected_option: Location) => selected_option.id != option.id))
      else this.customerSelection.setValue(this.customerSelection.value.concat([option]))
  }

  public optionSelected(option: Location | Subsidiary | Organization): boolean {
    return this.customerSelection.value.find((selectionOption) => selectionOption.id == option.id) != undefined
  }

  public subsidiariesBeforeIndex(organizationIndex: number): number {
    return this._organizations
      .slice(0, organizationIndex + 1)
      .map((organization) => organization.subsidiaries.length)
      .reduce((a, b) => a + b, 0)
  }

  public onScroll(): void {
    this._loadNextOrganizations$.next(true)
  }

  public get selections(): string {
    const selections = this.customerSelection.value.filter((selectionOption) => {
      if (selectionOption.constructor.name == 'Organization') return true
      if (selectionOption.constructor.name == 'Subsidiary') {
        return !this.customerSelection.value
          .filter((selectionOption) => selectionOption.constructor.name == 'Organization')
          .map((organization) => organization.subsidiaries.map((subsidiary) => subsidiary.id))
          .flat()
          .includes(selectionOption.id)
      }
      if (selectionOption.constructor.name == 'Location') {
        return !this.customerSelection.value
          .filter((selectionOption) => selectionOption.constructor.name == 'Subsidiary')
          .map((subsidiary) => subsidiary.locations.map((location) => location.id))
          .flat()
          .includes(selectionOption.id)
      }
    })
    selections.sort((a, b) => {
      if (a.constructor.name == 'Organization' && b.constructor.name != 'Organization') return -1
      if (a.constructor.name != 'Organization' && b.constructor.name == 'Organization') return 1
      if (a.constructor.name == 'Subsidiary' && b.constructor.name == 'Location') return -1
      if (a.constructor.name == 'Location' && b.constructor.name == 'Subsidiary') return 1
      return 0
    })
    return (
      selections
        .map((selection) => selection.name.value)
        .slice(0, 3)
        .join(', ') + (selections.length > 3 ? ' + ' + selections.length + ' more' : '')
    )
  }

  private _loadOrganizations(): Observable<Organization[]> {
    const filterChanges$ = this.customerSearchControl.valueChanges.pipe(
      startWith(''),
      tap((value) => {
        if (typeof value === 'string') {
          this._organizations = []
          this._organizationsOffset = 0
        }
      }),
      filter((value) => typeof value === 'string' && (value.length > 2 || value == '')),
      tap(() => {
        this.loadingOrganizations = true
      }),
      debounceTime(200)
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextOrganizations$.pipe(
          startWith(0),
          exhaustMap(() => this.organizationsService.loadOrganizations('all', this.customerSearchControl.value.toLowerCase(), this._organizationsOffset)),
          takeWhile((organizations) => organizations.length == 10, true),
          scan((allOrganizations: Organization[], newOrganizations: Organization[]) => allOrganizations.concat(newOrganizations)),
          tap((organizations) => {
            this._organizations = organizations
            this._organizationsOffset += 10
            this.loadingOrganizations = false
          }),
          catchError(() => [])
        )
      })
    )
  }
}
