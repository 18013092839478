import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core'
import { FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms'
import moment from 'moment'
import { PortalInjector } from '@angular/cdk/portal'
import { INJECTION_DATA } from './injection.service'

@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionsService {
  constructor(private injector: Injector, @Inject(LOCALE_ID) public language: string) {}

  average(array: number[]): number {
    if (array.length == 0) return 0
    return array.reduce((a, b) => a + b, 0) / array.length
  }

  round(num, decimalPlaces = 0) {
    if (num >= 0) {
      const p = Math.pow(10, decimalPlaces)
      const n = num * p * (1 + Number.EPSILON)
      return Math.round(n) / p
    } else {
      return null
    }
  }
  roundString(str: string, decimalPlaces?: number): number {
    if (str.length == 0) return 0
    if (decimalPlaces) return this.round(parseFloat(str?.replace(',', '.')), decimalPlaces)
    else return parseFloat(str?.replace(',', '.'))
  }

  arrayToString(array: string[], separator: string, last_separator: string, firstItemCapitalization?: 'none' | 'upperCase' | 'titleCase' | 'lowerCase', allItemCapitalization?: 'none' | 'upperCase' | 'titleCase' | 'lowerCase', max?: number): string {
    let string = ''
    array.forEach((element, index) => {
      const casedElement = this.caseString(element, index == 0 ? firstItemCapitalization : allItemCapitalization)
      if ((max && index < max) || max == undefined) string += casedElement
      if (index < (max != null ? Math.min(array.length - 2, max - 1) : array.length - 2)) string += separator
      if (index == (max != null ? Math.min(array.length - 2, max - 1) : array.length - 2)) string += last_separator
      if (max && index == max) string += array.length - max + $localize` flere`
    })
    return string
  }

  public caseString(string: string, capitalization: 'none' | 'upperCase' | 'titleCase' | 'lowerCase'): string {
    if (!string) return ''
    switch (capitalization) {
      case 'upperCase':
        return string.toUpperCase()
      case 'titleCase':
        return this.capitalizeFirstLetter(string)
      case 'lowerCase':
        return string.toLowerCase()
      default:
        return string
    }
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  compareID(optionOne, optionTwo): boolean {
    return (optionOne == 0 && optionTwo == 0) || (optionOne && optionTwo && optionOne.id && optionOne.id && optionOne.id == optionTwo.id)
  }

  compareCode(optionOne, optionTwo): boolean {
    return optionOne && optionTwo && optionOne.code && optionOne.code && optionOne.code == optionTwo.code
  }
  createInjector(dataToPass): PortalInjector {
    const injectionTokens = new WeakMap()
    injectionTokens.set(INJECTION_DATA, dataToPass)
    return new PortalInjector(this.injector, injectionTokens)
  }

  weekNumber(startDate: Date, endDate: Date): string {
    const start = startDate.getDay() == 0 ? new Date(startDate.getTime() - 1000 * 60 * 60 * 24) : startDate
    const end = endDate.getDay() == 0 ? new Date(endDate.getTime() - 1000 * 60 * 60 * 24) : endDate

    if (moment(start).week() == moment(end).week()) {
      return (moment(start).week() == 0 ? 52 : moment(start).week() - 1).toString()
    } else {
      return (moment(start).week() == 0 ? 52 : moment(start).week() - 1) + ' - ' + (moment(end).week() == 0 ? 52 : moment(end).week() - 1)
    }
  }

  public stringSimilarity(str1: string, str2: string, gramSize: number = 2) {
    function getNGrams(s: string, len: number) {
      s = ' '.repeat(len - 1) + s.toLowerCase() + ' '.repeat(len - 1)
      let v = new Array(s.length - len + 1)
      for (let i = 0; i < v.length; i++) {
        v[i] = s.slice(i, i + len)
      }
      return v
    }

    if (!str1?.length || !str2?.length) {
      return 0.0
    }

    //Order the strings by length so the order they're passed in doesn't matter
    //and so the smaller string's ngrams are always the ones in the set
    let s1 = str1.length < str2.length ? str1 : str2
    let s2 = str1.length < str2.length ? str2 : str1

    let pairs1 = getNGrams(s1, gramSize)
    let pairs2 = getNGrams(s2, gramSize)
    let set = new Set<string>(pairs1)

    let total = pairs2.length
    let hits = 0
    for (let item of pairs2) {
      if (set.delete(item)) {
        hits++
      }
    }
    return hits / total
  }

  _FormArrayIndexOfObjectWithKey(formarray: FormArray, object: object, key: string): number {
    for (let index = 0; index < formarray.length; index++) {
      if (formarray.at(index).value[key] == object[key]) {
        return index
      }
    }
    return -1
  }

  _sortBySectionIndex(a, b) {
    const sectionA = a.section_index
    const sectionB = b.section_index

    let comparison = 0
    if (sectionA > sectionB) {
      comparison = 1
    } else if (sectionA < sectionB) {
      comparison = -1
    }
    return comparison
  }

  _sortByDishIndex(a, b) {
    const dishA = a.dish_index
    const dishB = b.dish_index

    let comparison = 0
    if (dishA > dishB) {
      comparison = 1
    } else if (dishA < dishB) {
      comparison = -1
    }
    return comparison
  }
  _sortByIndex(a, b) {
    const objectA = a.index
    const objectB = b.index

    let comparison = 0
    if (objectA > objectB) {
      comparison = 1
    } else if (objectA < objectB) {
      comparison = -1
    }
    return comparison
  }
  _localTimeFormatter(time: Date): string {
    const hours = time.getHours()
    let minutes
    if (time.getMinutes() < 10) {
      minutes = '0' + time.getMinutes()
    } else {
      minutes = time.getMinutes().toString()
    }
    return hours + ':' + minutes
  }

  _localDateFormatter(date: any): string {
    if (date instanceof Date) {
      date = moment(date)
    } else if (typeof date == 'string') {
      date = moment(new Date(date))
    }
    return date.format('YYYY-MM-DD')
  }

  getDateRange(startDate, endDate, type) {
    const fromDate = moment(startDate)
    const toDate = moment(endDate)
    const diff = toDate.diff(fromDate, type)
    const range = []
    for (let i = 0; i <= diff; i++) {
      range.push(moment(startDate).add(i, type))
    }
    return range
  }

  createDaterange(dates: any, include_weekends: boolean): moment.Moment[] {
    let daterange: moment.Moment[] = []
    if (dates.start && dates.end) {
      let start: moment.Moment = moment(moment(dates.start).format('YYYY-MM-DD'))
      let end: moment.Moment = moment(moment(dates.end).format('YYYY-MM-DD'))
      while (start.isSameOrBefore(end)) {
        daterange.push(start)
        if (!include_weekends && start.weekday() == 5) start = start.clone().add(3, 'days')
        else start = start.clone().add(1, 'days')
      }
    } else if (dates.start) {
      daterange.push(moment(dates.start))
    } else if (dates.end) {
      daterange.push(moment(dates.end))
    }
    return daterange
  }

  ConvertToCSV(objArray, headerList, unitList) {
    // Rows
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
    let str = ''
    let row = ''

    // Header row:
    headerList.forEach((header, idx) => {
      row += (idx > 0 ? ';' : '') + header + (unitList[idx] ? ' [' + unitList[idx] + ']' : '')
    })

    row = row.slice(0, -1)
    str += row + '\r\n'

    // Data rows:
    for (let i = 0; i < array.length; i++) {
      let line = ''
      headerList.forEach((header, idx) => {
        line += (idx > 0 ? ';' : '') + array[i][header]?.toString().replace('.', ',')
      })

      // Line break
      str += line + '\r\n'
    }
    return str
  }

  public unique(array: any[], key?: string): any[] {
    return array.filter((value, index, self) => self.indexOf(value) === index)
  }
}

export function cloneAbstractControl<T extends AbstractControl>(control: T): T {
  let newControl: T

  if (control instanceof FormGroup) {
    const formGroup = new FormGroup({}, control.validator, control.asyncValidator)
    const controls = control.controls

    Object.keys(controls).forEach((key) => {
      formGroup.addControl(key, cloneAbstractControl(controls[key]))
    })

    newControl = formGroup as any
  } else if (control instanceof FormArray) {
    const formArray = new FormArray([], control.validator, control.asyncValidator)

    control.controls.forEach((formControl) => formArray.push(cloneAbstractControl(formControl)))

    newControl = formArray as any
  } else if (control instanceof FormControl) {
    newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any
  } else {
    throw new Error('Error: unexpected control value')
  }

  if (control.disabled) newControl.disable({ emitEvent: false })

  return newControl
}
