import { MenuDish } from '../menu-dish/menu-dish.model'
import { MenuPrintTemplate } from '../menu-print-template/menu-print-template.model'
import { Menu } from '../menu/menu.model'
import { MenuSection } from '../menu-section/menu-section.model'
import { PrintPage } from './print-page.model'
import { PrintPageStringElement } from './print-page-elements/string-element.model'
import { MenuPrintTemplateMenuElement } from '../menu-print-template/elements/menu-element.model'
import { MenuPrintTemplateMenuDateElement } from '../menu-print-template/elements/menu-date-element.model'
import { MenuPrintTemplateMenuTitleElement } from '../menu-print-template/elements/menu-title-element.model'
import { MenuPrintTemplateCustomTextElement } from '../menu-print-template/elements/custom-text-element.model'
import { MenuSectionTemplate } from '../menu-templates/menu-section-template.model'
import { MenuPrintTemplateWeekLabelElement } from '../menu-print-template/elements/week-label-element.model'
import { MenuTemplate } from '../menu-templates/menu-template.model'
import moment from 'moment'

export class PrintDocument {
  public pages: PrintPage[] = []
  public onePageScaling: number = 1

  constructor(public documentWrapper: HTMLElement, public menuPrintTemplate: MenuPrintTemplate, public menus: Menu[], public menuTemplate: MenuTemplate, public selectedMenuSectionTemplates?: MenuSectionTemplate[], public selectedMenuDishes?: MenuDish[]) {
    this.renderDocument()
  }

  public get firstPageElement(): HTMLElement {
    return this.documentWrapper?.firstChild.nodeName == 'DIV' ? (this.documentWrapper?.firstChild?.firstChild as HTMLElement) : undefined
  }
  public get DOMWidth(): number {
    return this.firstPageElement?.clientWidth || 0
  }
  public get DOMHeight(): number {
    return this.firstPageElement?.clientHeight || this.DOMWidth * this.aspectRatio
  }
  public get aspectRatio(): number {
    return this.menuPrintTemplate?.pageHeight / this.menuPrintTemplate?.pageWidth
  }
  public get printWidth(): number {
    return this.menuPrintTemplate?.template?.pageFormat?.orientation?.value == 'portrait' ? 792 : 1120
  }
  public get DOMToPrintSizeFactor(): number {
    return this.DOMWidth / this.printWidth
  }
  public get currentPage(): PrintPage {
    if (this.pages.length) return this.pages[this.pages.length - 1]
    else return
  }

  public scaledDistance(distance: number): number {
    return (this.percentageDistance(distance) / 100) * this.DOMWidth
  }

  public scaledPrintDistance(distance: number): number {
    return (this.percentageDistance(distance) / 100) * this.printWidth
  }

  public percentageDistance(distance: number): number {
    return ((distance * this.onePageScaling) / this.menuPrintTemplate.pageWidth) * 100
  }

  public renderDocument() {
    this.onePageScaling = 1
    if (this.pages.length) {
      this.pages = this.pages.slice(0, 1)
      this.currentPage.render()
      this._addBodyElements(this.generatePrintPageElementsFromTemplate(this.menuPrintTemplate.template.body.elements))
    } else {
      this._addPage()
      const isFirstPageAdded = setInterval(() => {
        if (this.firstPageElement) {
          clearInterval(isFirstPageAdded)
          this._addBodyElements(this.generatePrintPageElementsFromTemplate(this.menuPrintTemplate.template.body.elements))
        }
      }, 50)
    }
  }

  public generatePrintPageElementsFromTemplate(
    elements: (MenuPrintTemplateMenuElement | MenuPrintTemplateMenuTitleElement | MenuPrintTemplateWeekLabelElement | MenuPrintTemplateMenuDateElement | MenuPrintTemplateCustomTextElement)[]
  ): (PrintPageStringElement | PrintPageStringElement[][][][])[] {
    return elements
      .map((elementTemplate) => {
        if (elementTemplate.type == 'menu')
          return this.menuPrintTemplate.type == 'weekly' ? this.generatePrintPageElementsFromMenus(elementTemplate as MenuPrintTemplateMenuElement) : this.generatePrintPageElementsFromMenu(elementTemplate as MenuPrintTemplateMenuElement, this.menus[0])
        else if (elementTemplate.type == 'menuTitle') return new PrintPageStringElement(elementTemplate, this, undefined, this.menuTemplate)
        else if (elementTemplate.type == 'menuDate') return new PrintPageStringElement(elementTemplate, this, undefined, this.menus[0].date)
        else if (elementTemplate.type == 'weekLabel') return new PrintPageStringElement(elementTemplate, this, undefined, [this.menus[0].date, this.menus[this.menus.length - 1].date])
        else if (elementTemplate.type == 'customString') return new PrintPageStringElement(elementTemplate, this, undefined, (elementTemplate as MenuPrintTemplateCustomTextElement).customContent)
        else return new PrintPageStringElement(elementTemplate, this)
      })
      .flat()
  }

  public generatePrintPageElementsFromMenus(elementTemplate: MenuPrintTemplateMenuElement): PrintPageStringElement[][][][][] {
    if (this.menuPrintTemplate.template.pageFormat.menusOrientation.value == 'horizontal') {
      return this.selectedMenuSectionTemplates
        .map((sectionTemplate, index) => {
          return this.menuTemplate.selectedWeekdays.map((weekday: number) => {
            let menu = this.menus.find((menu) => moment(menu.date.value).weekday() == weekday)
            let menuSection = menu?.menu_sections_sorted?.find((menuSection: MenuSection) => menuSection.section_template.id == sectionTemplate.id)
            if (!menu || !menuSection) return []
            else return this._generatePrintPageElementsFromMenuSection(elementTemplate, menuSection, index)
          })
        })
        .filter((printPageElementGroup) => !Array.isArray(printPageElementGroup) || printPageElementGroup.flat().flat().length)
    } else
      return this.menus.flatMap((menu: Menu) => this.generatePrintPageElementsFromMenu(elementTemplate as MenuPrintTemplateMenuElement, menu, true)).filter((printPageElementGroup) => !Array.isArray(printPageElementGroup) || printPageElementGroup.flat().flat().length)
  }

  public generatePrintPageElementsFromMenu(elementTemplate: MenuPrintTemplateMenuElement, menu: Menu, showMenuDate?: boolean): PrintPageStringElement[][][][][] {
    return menu.menu_sections_sorted
      .filter((menuSection: MenuSection) => this.selectedMenuSectionTemplates.find((sectionTemplate) => sectionTemplate.id == menuSection.section_template.id))
      .map((menuSection: MenuSection, index) => {
        return [this._generatePrintPageElementsFromMenuSection(elementTemplate, menuSection, showMenuDate ? index : null)].filter((section) => section.length)
      })
      .filter((printPageElementGroup) => !Array.isArray(printPageElementGroup) || printPageElementGroup.flat().flat().length)
  }

  private _generatePrintPageElementsFromMenuSection(elementTemplate: MenuPrintTemplateMenuElement, menuSection: MenuSection, index: number): PrintPageStringElement[][][] {
    let menuSectionPrintElements: PrintPageStringElement[][] = []
    const selectedMenuDishes = menuSection.menu_dishes_sorted.filter((menuDish: MenuDish) => this.selectedMenuDishes.find((selectedMenuDish) => selectedMenuDish.id == menuDish.id))
    const dateElement: PrintPageStringElement[] = [new PrintPageStringElement(elementTemplate, this, 'menuDate', menuSection.menu.date)]
    const sectionElement: PrintPageStringElement[] = [new PrintPageStringElement(elementTemplate, this, 'sectionName', menuSection.section_template)]
    const dishElements: PrintPageStringElement[][] = selectedMenuDishes.map((menuDish: MenuDish) => this._generatePrintPageElementsFromMenuDish(elementTemplate, menuDish)).filter((dish) => dish.length)
    if (index == 0 && (selectedMenuDishes.length || this.menuPrintTemplate.template.pageFormat.emptySectionDisplay.value != 'hide')) menuSectionPrintElements.push(dateElement)
    if (!selectedMenuDishes.length) {
      if (this.menuPrintTemplate.template.pageFormat.emptySectionDisplay.value != 'hide') menuSectionPrintElements.push(sectionElement)
      if (!selectedMenuDishes.length && this.menuPrintTemplate.template.pageFormat.emptySectionDisplay.value == 'showWithLabel' && elementTemplate.menuElements.emptySectionLabel.customContent[this.menuPrintTemplate.language.value].value) {
        const emptySectionElement: PrintPageStringElement[] = [new PrintPageStringElement(elementTemplate, this, 'emptySectionLabel', elementTemplate.menuElements.emptySectionLabel.customContent)]
        menuSectionPrintElements.push(emptySectionElement)
      }
    } else if (dishElements.length) {
      menuSectionPrintElements.push(sectionElement)
      menuSectionPrintElements.push(...dishElements)
    }
    return [menuSectionPrintElements]
  }

  private _generatePrintPageElementsFromMenuDish(elementTemplate: MenuPrintTemplateMenuElement, menuDish: MenuDish): PrintPageStringElement[] {
    return [
      new PrintPageStringElement(elementTemplate, this, 'dishName', menuDish.recipe),
      new PrintPageStringElement(elementTemplate, this, 'dishDescription', menuDish.recipe),
      new PrintPageStringElement(elementTemplate, this, 'dishIngredients', menuDish.recipe),
      new PrintPageStringElement(elementTemplate, this, 'dishAllergens', menuDish.recipe),
      new PrintPageStringElement(elementTemplate, this, 'dishTags', menuDish.recipe),
      new PrintPageStringElement(elementTemplate, this, 'dishCO2', menuDish.recipe)
    ].filter((element) => !element.elementTemplate.format.hidden.value && element.elementString)
  }

  private _addPage() {
    this.pages.push(new PrintPage(this))
  }

  private _addBodyElements(printPageElementSections: (PrintPageStringElement | PrintPageStringElement[][][][])[]): void {
    if (this.menuPrintTemplate.template.pageFormat.pageSplit.value == 'onePage') this._addBodyToOnePage(printPageElementSections)
    else this._addBodyToMultiplePages(printPageElementSections)
  }

  private _addBodyToOnePage(printPageElementGroups: (PrintPageStringElement | PrintPageStringElement[][][][])[]): void {
    this._scalePage(printPageElementGroups)

    if (this._totalContentHeight(printPageElementGroups) <= this.currentPage.bodyContentDOMHeight) {
      printPageElementGroups.forEach((printPageElementGroup) => {
        this._addPrintPageElementGroupToBodyElements(printPageElementGroup)
      })
    } else {
      // Error handle if content is scaled 95%+ and still can't fit
    }
  }

  private _addBodyToMultiplePages(printPageElementGroups: (PrintPageStringElement | PrintPageStringElement[][][][])[]): void {
    this._scalePage(printPageElementGroups)

    printPageElementGroups.forEach((printPageElementGroup: PrintPageStringElement | PrintPageStringElement[][][][], sectionIndex: number) => {
      const _groupIsMenuSectionsRow = Array.isArray(printPageElementGroup) && this.menuPrintTemplate.type == 'weekly'
      const _isGroupMenuSectionGroup = Array.isArray(printPageElementGroup) && this.menuPrintTemplate.type == 'daily'
      const _printPageElementSectionDOMSize = this._printPageElementGroupDOMSize(printPageElementGroup)
      const _groupExceedsCurrentPage = _printPageElementSectionDOMSize + this.currentPage.contentHeight > this.currentPage.bodyContentDOMHeight
      const _splitPagePerSection = this.menuPrintTemplate.template.pageFormat.pageSplit.value == 'sectionOnePage'
      const _isFirstSection = sectionIndex == 0
      if (_groupExceedsCurrentPage || ((_groupIsMenuSectionsRow || _isGroupMenuSectionGroup) && _splitPagePerSection && !_isFirstSection)) this._addPage()
      this._addPrintPageElementGroupToBodyElements(printPageElementGroup)
    })
  }

  private _addPrintPageElementGroupToBodyElements(printPageElementGroup: PrintPageStringElement | PrintPageStringElement[][][][]): void {
    const _groupIsMenuSectionsRow = Array.isArray(printPageElementGroup) && this.menuPrintTemplate.type == 'weekly'
    const _isGroupMenuSectionGroup = Array.isArray(printPageElementGroup) && this.menuPrintTemplate.type == 'daily'
    if (_groupIsMenuSectionsRow) {
      this._addMenuSectionRowBodyElements(printPageElementGroup)
    } else if (_isGroupMenuSectionGroup) {
      this._addMenuSectionBodyElements(printPageElementGroup)
    } else {
      this._addStringElementToBodyElements(printPageElementGroup as PrintPageStringElement)
    }
  }

  private _addMenuSectionRowBodyElements(menuSectionRow: PrintPageStringElement[][][][]): void {
    this.currentPage.bodyElements.push(menuSectionRow.map((menuSection) => menuSection.flat().flat()))
    this.currentPage.contentHeight += this._menuSectionRowDOMSize(menuSectionRow)
  }

  private _addMenuSectionBodyElements(menuSection: PrintPageStringElement[][][][]): void {
    menuSection
      .flat()
      .flat()
      .forEach((printPageElementGroup: PrintPageStringElement[]) => {
        printPageElementGroup.forEach((printPageElement: PrintPageStringElement) => {
          this._addStringElementToBodyElements(printPageElement)
        })
      })
  }

  private _addStringElementToBodyElements(printPageElement: PrintPageStringElement): void {
    this.currentPage.bodyElements.push(printPageElement)
    this.currentPage.contentHeight += printPageElement.componentDOMSize
  }

  private _scalePage(printPageElementGroups: (PrintPageStringElement | PrintPageStringElement[][][][])[]): void {
    if (this.menuPrintTemplate.template.pageFormat.pageSplit.value == 'onePage') {
      while (this._totalContentHeight(printPageElementGroups) > this.currentPage.bodyContentDOMHeight) {
        this.onePageScaling -= 0.01
        if (this.onePageScaling < 0.05) break
      }
    } else {
      printPageElementGroups.forEach((printPageElementGroup: PrintPageStringElement | PrintPageStringElement[][][][]) => {
        if (Array.isArray(printPageElementGroup)) this._scaleToFitSection(printPageElementGroup)
      })
    }
  }

  private _scaleToFitSection(menuSectionRow: PrintPageStringElement[][][][]): void {
    while (this._menuSectionRowDOMSize(menuSectionRow) > this.currentPage.bodyContentDOMHeight) {
      this.onePageScaling -= 0.01
      if (this.onePageScaling < 0.05) break
    }
  }

  private _totalContentHeight(printPageElementGroups: (PrintPageStringElement | PrintPageStringElement[][][][])[]): number {
    return printPageElementGroups.reduce((acc, printPageElementGroup) => acc + this._printPageElementGroupDOMSize(printPageElementGroup), 0)
  }

  private _printPageElementGroupDOMSize(printPageElementGroup: PrintPageStringElement | PrintPageStringElement[][][][]): number {
    const _groupIsMenuSectionsRow = Array.isArray(printPageElementGroup) && this.menuPrintTemplate.type == 'weekly'
    const _isGroupMenuSectionGroup = Array.isArray(printPageElementGroup) && this.menuPrintTemplate.type == 'daily'
    return _groupIsMenuSectionsRow ? this._menuSectionRowDOMSize(printPageElementGroup) : _isGroupMenuSectionGroup ? this._menuSectionDOMSize(printPageElementGroup.flat()) : (printPageElementGroup as PrintPageStringElement).componentDOMSize
  }

  private _menuSectionRowDOMSize(menuSectionRow: PrintPageStringElement[][][][]): number {
    return Math.max(...menuSectionRow.map((menuSection) => this._menuSectionDOMSize(menuSection)))
  }
  private _menuSectionDOMSize(menuSection: PrintPageStringElement[][][]): number {
    return menuSection
      .flat()
      .flat()
      .reduce((acc, printPageElement) => acc + printPageElement.componentDOMSize, 0)
  }
}
