import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { localesList } from 'foodop-lib'

@Component({
  selector: 'language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.css']
})
export class LanguagePickerComponent {
  @Input() selected_language: FormControl
  @Input() label: string

  @Output() languageChanged = new EventEmitter()

  localesList = localesList

  constructor() {}

  compareLanguageCode(optionOne, optionTwo): boolean {
    return optionOne && optionTwo && (optionOne == optionTwo || (optionOne.code && optionOne.code == optionTwo) || (optionTwo.code && optionOne == optionTwo.code) || (optionOne.code && optionTwo.code && optionOne.code == optionTwo.code))
  }
}
