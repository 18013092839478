import { NgModule, Injectable } from '@angular/core'
import { Routes, RouterModule, CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { MsalGuard } from '@azure/msal-angular'
import { BrowserUtils } from '@azure/msal-browser'
import { FrontpageComponent } from '../components/views/frontpage/frontpage.component'
import { CustomersComponent } from '../components/views/customers/customers.component'
import { EquipmentComponent } from '../components/views/equipment/equipment.component'
import { AnalysesComponent } from '../components/views/analyses/analyses.component'
import { OperationOverviewComponent } from '../components/views/operation-overview/operation-overview.component'

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'welcome', component: FrontpageComponent, canActivate: [MsalGuard] },
  { path: 'customers', component: CustomersComponent, canActivate: [MsalGuard] },
  { path: 'equipment', component: EquipmentComponent, canActivate: [MsalGuard] },
  { path: 'operations', component: OperationOverviewComponent, canActivate: [MsalGuard] },
  { path: 'analyses', component: AnalysesComponent, canActivate: [MsalGuard] }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      //initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
