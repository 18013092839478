<div *ngIf="!organization.loading">
    <div class="l-width-full l-overflow">
        <button type="button" mat-button [disabled]="!organization.valid || !organization.changed" (click)="saveOrganization()" class="l-float-right l-margin-horizontal-medium l-bottommargin-large primary-button" ><span ><mat-spinner *ngIf="organization.saving" [diameter]="19" class="o-inline-block"></mat-spinner></span>Gem kundeændringer</button>
    </div>
    
    <div class="l-width-full">
        <mat-label>Er denne kunde aktiv?</mat-label>
        <mat-slide-toggle color="primary" [formControl]="organization.active"></mat-slide-toggle>
    </div>
    
    <mat-form-field class="l-width-full">
        <mat-label>Navn</mat-label>
        <input autocomplete="off" matInput [formControl]="organization.name">
    </mat-form-field>
    
    <mat-form-field class="l-width-full">
        <mat-label>Hjemmeside</mat-label>
        <input autocomplete="off" matInput [formControl]="organization.website">
    </mat-form-field>
    
    <mat-form-field class="l-width-full">
        <mat-label>Telefon</mat-label>
        <input autocomplete="off" matInput [formControl]="organization.phone">
    </mat-form-field>
    
    <mat-form-field class="l-width-full">
        <mat-label>CVR</mat-label>
        <input autocomplete="off" matInput [formControl]="organization.cvr">
    </mat-form-field>
    
    <mat-form-field class="l-width-full">
        <mat-label>Adresse</mat-label>
        <input autocomplete="off" matInput [formControl]="organization.address">
    </mat-form-field>
    
    <mat-form-field class="l-width-full">
        <mat-label>Postnummer</mat-label>
        <input autocomplete="off" matInput [formControl]="organization.postal">
    </mat-form-field>
    
    <mat-form-field class="l-width-full">
        <mat-label>By</mat-label>
        <input autocomplete="off" matInput [formControl]="organization.city">
    </mat-form-field>
    
    <mat-form-field class="l-width-full">
        <mat-label>Land</mat-label>
        <input autocomplete="off" matInput [formControl]="organization.country">
    </mat-form-field>
    
    
    <label class="l-bottommargin-small l-block">Subsidiaries</label>
    <subsidiaries [organization]="organization"></subsidiaries>
</div>

<div *ngIf="organization.loading">
    <div class="loading-overlay">
        <label class="l-font-mini-dynamic l-width-full l-text-center">Loader ...</label>
        <mat-spinner [diameter]="25" color="primary" class="o-inline-block l-center-top"></mat-spinner>
    </div>
</div>