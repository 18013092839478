import { Component, Inject, OnInit } from '@angular/core'
import { INJECTION_DATA, Organization, OrganizationsService } from 'foodop-lib'

@Component({
  selector: 'organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css'],
  host: {
    class: 'l-position-relative l-width-full l-height-full l-theme-white l-overflow l-border-radius l-padding-large'
  }
})
export class OrganizationComponent implements OnInit {
  organization: Organization

  constructor(@Inject(INJECTION_DATA) public initData: any, public organizationsService: OrganizationsService) {
    this.organization = this.organizationsService.organization_with_id(initData.organization_id) || new Organization()
    if (!this.organization.details_loaded && !this.organization.is_new) {
      this.organization.load_details().subscribe()
    }
  }

  ngOnInit(): void {}

  saveOrganization(): void {
    this.organization.save().subscribe()
  }
}
