import { FormBuilder, FormControl } from '@angular/forms'
import { MenuSection } from '../menu-section/menu-section.model'
import moment from 'moment'
import { MenuDish } from '../menu-dish/menu-dish.model'
import { FoodopLibModule } from '../../foodop-lib.module'
import { TrackingsService } from '../tracking/trackings.service'
import { Tracking } from '../tracking/tracking.model'
import { MenusService } from './menus.service'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { Observable, forkJoin, map, tap } from 'rxjs'
import { RawIngredient } from '../raw-ingredient/raw-ingredient.model'
import { Recipe } from '../recipe/recipe.model'
import { IMenu, IMenuSection } from '../../global.models'
import { GlobalFunctionsService } from '../../services/global-functions.service'
import { CacheMapService } from '../../services/caching/cache-map.service'
import { NutritionFact } from '../nutrition/nutrition-fact.model'
import { NutritionService } from '../nutrition/nutrition.service'
import { MenuTemplate } from '../menu-templates/menu-template.model'
import { MenuTemplatesService } from '../menu-templates/menu-templates.service'
import { sampleMenuTemplate1 } from '../../global.types'
import { TrackingTemplatesService } from '../tracking-templates/tracking-templates.service'
import { TrackingGroupTemplate } from '../tracking-templates/tracking-group-template.model'

export class Menu {
  id: string
  menuTemplateId: string
  date: FormControl
  guests: FormControl

  menu_sections: MenuSection[]

  isUpdatingTrackings = false
  details_are_loaded = false
  saved_menu: any
  loading = false

  private _defaultMenuTemplate = new MenuTemplate(sampleMenuTemplate1)

  menusService: MenusService
  nutritionService: NutritionService
  trackingsService: TrackingsService
  subsidiaryService: SubsidiaryService
  menuTemplatesService: MenuTemplatesService
  trackingTemplatesService: TrackingTemplatesService
  cacheMapService: CacheMapService
  func: GlobalFunctionsService
  fb: FormBuilder

  constructor(public menu?: IMenu) {
    this.trackingsService = FoodopLibModule.injector.get(TrackingsService)
    this.subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)
    this.menusService = FoodopLibModule.injector.get(MenusService)
    this.nutritionService = FoodopLibModule.injector.get(NutritionService)
    this.menuTemplatesService = FoodopLibModule.injector.get(MenuTemplatesService)
    this.trackingTemplatesService = FoodopLibModule.injector.get(TrackingTemplatesService)
    this.cacheMapService = FoodopLibModule.injector.get(CacheMapService)
    this.func = FoodopLibModule.injector.get(GlobalFunctionsService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.id = menu?.id
    this.menuTemplateId = menu?.menu_template_id
    this.date = this.fb.control(moment(menu.date).format('YYYY-MM-DD'))
    this.guests = this.fb.control(menu.guests || this._defaultGuests)

    this.menu_sections = this.menuTemplate?.menuSectionTemplates
      ?.sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
      .map((section_template) => {
        return new MenuSection(
          this,
          Object.assign(
            {},
            menu?.menu_sections?.find((menu_section) => menu_section.menu_section_template_id == section_template.id || (!menu_section.menu_section_template_id && menu_section.section_index == section_template.index.value)),
            { menu_section_template_id: section_template.id },
            { section_index: section_template.index.value }
          )
        )
      })

    this.saved_menu = JSON.stringify(this.as_dict)
  }

  get menuTemplate(): MenuTemplate {
    return this.menuTemplatesService.menuTemplateWithId(this.menuTemplateId) || this._defaultMenuTemplate
  }
  public get activeTrackingGroupTemplates(): TrackingGroupTemplate[] {
    return this.func
      .unique(
        this.trackingTemplatesService.trackingTemplatesForMenuTemplateId(this.menuTemplateId, true).map((trackingTemplate) => trackingTemplate.trackingGroupTemplate),
        'id'
      )
      .filter((trackingGroupTemplate) => trackingGroupTemplate.active.value)
  }
  private get _defaultGuests(): number {
    return this.activeTrackingGroupTemplates.reduce((acc, trackingGroupTemplate) => acc + Number(trackingGroupTemplate.defaultGuests[moment(this.date.value).weekday()].value), 0)
  }

  public setDefaultGuests(): void {
    this.guests.setValue(this._defaultGuests)
  }

  patchValue(menu: IMenu): void {
    if (menu?.id) this.id = menu.id
    if (menu?.menu_template_id) this.menuTemplateId = menu.menu_template_id
    if (menu?.date) this.date.setValue(menu.date)
    this.patchSections(menu?.menu_sections)
    this.saved_menu = JSON.stringify(this.as_dict)
  }

  patchSections(menu_sections: IMenuSection[]): void {
    if (menu_sections) {
      menu_sections.forEach((menu_section) => {
        if (this.section_with_id(menu_section.id)) this.section_with_id(menu_section.id).patchValues(menu_section)
        else if (this.section_for_section_template(menu_section.menu_section_template_id)) this.section_for_section_template(menu_section.menu_section_template_id).patchValues(menu_section)
        else if (this.menu_sections.length > menu_section.section_index) this.menu_sections[menu_section.section_index].patchValues(menu_section)
        else {
          this.menu_sections.push(new MenuSection(this, menu_section))
        }
      })
    }
  }

  get dishes(): MenuDish[] {
    let dishes = []
    this.menu_sections_sorted
      ?.filter((menu_section) => menu_section.menuSectionTemplateId)
      .forEach((menu_section) => {
        dishes = dishes.concat(menu_section.menu_dishes_sorted)
      })
    return dishes
  }

  get recipes(): Recipe[] {
    return this.dishes.map((dish) => dish.recipe)
  }

  get selectedRecipesWithPortionSize(): MenuDish[] {
    return this.dishes.filter((dish) => dish.selectedPortion && dish.selectedPortion?.portion_size?.value)
  }

  get selectedRecipesWithoutIngredients(): MenuDish[] {
    return this.dishes.filter((dish) => dish.selectedPortion && !dish.recipe.ingredients_and_sub_recipes.length)
  }

  get selectedRecipesWithoutAllNutrition(): MenuDish[] {
    return this.dishes.filter((dish) => dish.selectedPortion && dish.recipe.ingredients_and_sub_recipes.length && dish.recipe.ingredients_and_sub_recipes.length == dish.recipe.sub_ingredients_without_nutrition.length)
  }

  get selectedRecipesWithoutSomeNutrition(): MenuDish[] {
    return this.dishes.filter((dish) => dish.selectedPortion && dish.recipe.ingredients_and_sub_recipes.length && dish.recipe.ingredients_and_sub_recipes.length < dish.recipe.sub_ingredients_without_nutrition.length)
  }

  get selectedRecipesWithIngredientsAndNutrition(): MenuDish[] {
    return this.dishes.filter((dish) => dish.selectedPortion && dish.recipe.ingredients_and_sub_recipes.length && !dish.recipe.sub_ingredients_without_nutrition.length)
  }

  get recipesWithIngredientsAndNutrition(): MenuDish[] {
    return this.dishes.filter((dish) => dish.recipe.ingredients_and_sub_recipes.length && !dish.recipe.sub_ingredients_without_nutrition.length)
  }

  get selectedRecipePortionSizes(): number[] {
    return this.dishes.filter((dish) => dish.selectedPortion).map((dish) => this.func.roundString(dish.selectedPortion?.portion_size?.value || '0'))
  }

  get totalSelectedRecipePortionSizes(): number {
    return this.selectedRecipePortionSizes.reduce((acc, val) => acc + val, 0)
  }

  get selectedRecipePortionShares(): number[] {
    return this.selectedRecipePortionSizes.map((recipe_portion_size) => recipe_portion_size / this.totalSelectedRecipePortionSizes)
  }

  get selectedRecipePortionNutritionFacts(): NutritionFact[][] {
    return this.dishes.filter((dish) => dish.selectedPortion).map((dish) => dish.recipe.calculateNutrition([dish.selectedPortion]))
  }

  get portionWeightedNutritionFacts(): NutritionFact[] {
    return this.nutritionService.aggregateNutritionFacts(this.selectedRecipePortionNutritionFacts, this.selectedRecipePortionShares)
  }

  get scaledPortionWeightedNutritionFacts(): NutritionFact[] {
    return this.nutritionService.scaleNutritionFacts(this.portionWeightedNutritionFacts, this.totalSelectedRecipePortionSizes / 100)
  }

  section_with_id(menu_section_id: string): MenuSection {
    return this.menu_sections.find((menu_section) => menu_section.id == menu_section_id)
  }

  section_for_section_template(menuSectionTemplateId: string): MenuSection {
    return this.menu_sections.find((menu_section) => menu_section.menuSectionTemplateId == menuSectionTemplateId)
  }

  dish_with_id(menu_dish_id: string): MenuDish {
    return this.dishes.find((menu_dish) => menu_dish.id == menu_dish_id)
  }
  dish_with_recipe_id(recipe_id: string): MenuDish {
    return this.dishes.find((menu_dish) => menu_dish.recipe.id == recipe_id)
  }

  scale_dish_started_for_dishes(menu_dishes: MenuDish[]): boolean {
    return this.trackings.find((tracking) => moment(tracking.servingTime.value) < moment() && menu_dishes.find((menu_dish) => tracking.scaleDishesForMenuDish(menu_dish))) != undefined
  }

  get trash_types(): any[] {
    const trash_types = []
    this.menu_sections
      .filter((menu_section) => !menu_section.menuSectionTemplateId)
      .forEach((menu_section) => {
        trash_types.push(menu_section.menu_dishes)
      })
    return trash_types
  }

  get menu_sections_sorted(): MenuSection[] {
    return this.menu_sections.sort((a, b) => (a.section_template?.index.value > b.section_template?.index.value ? 1 : -1))
  }

  get is_in_the_past(): boolean {
    return moment(this.date.value, 'YYYY-MM-DD').startOf('day').isBefore(moment().startOf('day'))
  }

  get is_valid(): boolean {
    return this.dishes.length > 0 ? true : false
  }

  get is_new(): boolean {
    return this.id ? false : true
  }

  get date_value(): moment.Moment {
    return moment(this.date.value, 'YYYY-MM-DD')
  }

  get trackings(): Tracking[] {
    return this.trackingsService.trackings ? this.trackingsService.trackings.filter((tracking) => tracking.menuId == this.id) : []
  }

  get has_trackings(): boolean {
    return this.trackings.length > 0
  }

  get tracking_begun(): boolean {
    return this.trackings.find((tracking) => tracking.servingTime.value.isBefore(moment())) != undefined
  }

  get raw_ingredients(): any[] {
    return [
      ...new Map(
        [].concat
          .apply(
            this.dishes.map((dish) => dish.recipe.ingredients),
            [].concat.apply(this.dishes.map((dish) => dish.recipe.sub_recipes.map((sub_recipe) => sub_recipe.ingredients)).flat())
          )
          .flat()
          .map((item: RawIngredient) => [item['id'], item])
      ).values()
    ]
  }
  save(): Observable<any> {
    return this.menusService.upsertMenu(this)
  }

  reload(): Observable<any> {
    return this.menusService.getMenuByID(this.id).pipe(
      tap((menu) => {
        this.patchValue(menu)
        this.details_are_loaded = true
        this.cacheMapService.deleteCacheEntryForURL('menu')
      })
    )
  }

  moveMenu(date: moment.Moment): Observable<Menu> {
    let to_menu: Menu = this._create_destination_menu(date, this.menuTemplateId)
    let to_menu_sections: MenuSection[] = this.menu_sections.map((menu_section) => this._create_destination_menu_section(to_menu, menu_section.menuSectionTemplateId))
    to_menu_sections.forEach((to_menu_section) => {
      this.section_for_section_template(to_menu_section.menuSectionTemplateId)
        .menu_dishes.map((menu_dish, index) => {
          let menu_dish_copy: MenuDish = menu_dish.copy(false, to_menu_section)
          menu_dish_copy.index.setValue(null)
          menu_dish_copy.changed.setValue(true)
          return menu_dish_copy
        })
        .forEach((menu_dish) => {
          menu_dish.recipe.addingRecipeToMenu = true
          to_menu_section.menu_dishes.push(menu_dish)
        })
    })

    this._clearServingsForMenu()
    this._clearMenu()
    return this.menusService
      .upsertMenu(
        to_menu,
        null,
        this.id,
        this.menu_sections.map((menu_section) => menu_section.id)
      )
      .pipe(map(() => to_menu))
  }

  _clearServingsForMenu(): void {
    this.trackings.forEach((tracking) => {
      this.trackingsService.trackings.splice(
        this.trackingsService.trackings.findIndex((s) => s.id == tracking.id),
        1
      )
    })
  }
  _clearMenu(): void {
    this.menusService.menus.splice(this.menusService.menus.indexOf(this), 1, new Menu({ menu_template_id: this.menuTemplate.id, date: this.date.value }))
  }

  copyMenu(dates: any): Observable<Menu[]> {
    const daterange: moment.Moment[] = this.func.createDaterange(dates, this.subsidiaryService.subsidiary?.show_weekends?.value)
    let completed_copies = []
    daterange.forEach((date) => {
      let to_menu: Menu = this._create_destination_menu(date, this.menuTemplateId)
      let to_menu_sections: MenuSection[] = this.menu_sections.map((menu_section) => this._create_destination_menu_section(to_menu, menu_section.menuSectionTemplateId))
      to_menu_sections.forEach((to_menu_section) => {
        this.section_for_section_template(to_menu_section.menuSectionTemplateId)
          .menu_dishes.map((menu_dish, index) => {
            let menu_dish_copy: MenuDish = menu_dish.copy(true, to_menu_section)
            menu_dish_copy.index.setValue(null)
            menu_dish_copy.changed.setValue(true)
            return menu_dish_copy
          })
          .forEach((menu_dish) => {
            menu_dish.recipe.addingRecipeToMenu = true
            to_menu_section.menu_dishes.push(menu_dish)
          })
      })
      completed_copies.push(this.menusService.upsertMenu(to_menu).pipe(map(() => to_menu)))
    })
    return forkJoin(completed_copies)
  }

  _create_destination_menu(date: moment.Moment, menuTemplateId: string): Menu {
    return this.menusService.menuForDateAndMenuTemplateId(date, menuTemplateId) || new Menu({ menu_template_id: menuTemplateId, date: date })
  }
  _create_destination_menu_section(to_menu: Menu, menuSectionTemplateId: string): MenuSection {
    return to_menu.section_for_section_template(menuSectionTemplateId)
  }

  clearMenu(): Observable<any> {
    return this.menusService.deleteMenu(this.id).pipe(
      tap(() => {
        this._clearServingsForMenu()
        this._clearMenu()
      })
    )
  }

  removeDuplicatedDishes(): MenuDish[] {
    let duplicated_dishes: MenuDish[] = []
    this.menu_sections.forEach((menu_section) => {
      duplicated_dishes.push(...menu_section.removeDuplicatedDishes())
    })
    return duplicated_dishes
  }

  get as_dict(): IMenu {
    return {
      id: this.id,
      menu_template_id: this.menuTemplateId,
      date: this.date?.value,
      guests: this.guests?.value
    }
  }
  get upload_dict(): IMenu {
    return {
      id: this.id,
      date: this.date?.value,
      guests: this.guests?.value,
      menu_template_id: this.menuTemplateId,
      menu_sections: (this.menu_sections || []).filter((menu_section) => menu_section.menu_dishes.length && menu_section.changed).map((menu_section) => menu_section.upload_dict)
    }
  }
}
