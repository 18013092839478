import { FoodopLibModule } from '../../foodop-lib.module'
import { Names } from '../names/names.model'
import { ITag } from '../../global.models'
import { Observable, catchError, map, of } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { ETagCategory } from './tag.service'

export class Tag {
  public readonly id: string
  public readonly category: ETagCategory
  public readonly names: Names
  public readonly icon$: Observable<string>

  private _httpClient: HttpClient

  constructor(private _tag?: ITag) {
    this._httpClient = FoodopLibModule.injector.get(HttpClient)

    this.id = _tag?.id
    this.category = _tag?.category as ETagCategory
    this.names = new Names(_tag?.names)
    this.icon$ = this._fetchIcon()
  }
  public get asDict(): ITag {
    return {
      id: this.id,
      category: this.category,
      names: this.names.as_dict
    }
  }

  public get iconPath(): string {
    return 'assets/ingredient_icons/' + this.id + '.png'
  }
  private _fetchIcon(): Observable<string> {
    if (this.category != ETagCategory.ingredient_tag) return of()
    return this._httpClient.get(this.iconPath, { responseType: 'text' }).pipe(
      map(() => {
        return this.iconPath
      }),
      catchError(() => {
        return of()
      })
    )
  }
}
