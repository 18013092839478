import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { NutritionFact } from './nutrition-fact.model'

@Injectable({
  providedIn: 'root'
})
export class NutritionService {
  constructor(@Inject(LOCALE_ID) public language: string) {}

  aggregateNutritionFacts(nutrition_facts: NutritionFact[][], shares: number[]): any {
    const calculated_nutritions: NutritionFact[] = []
    nutrition_facts.forEach((object_nutrition_facts, index) => {
      const object_share = shares[index]
      object_nutrition_facts.forEach((nutrition_fact) => {
        if (nutrition_fact.unit.value == 'MGM') {
          nutrition_fact.value.setValue(nutrition_fact.value.value / 1000)
          nutrition_fact.unit.setValue('GRM')
        }
        if (nutrition_fact.unit.value == 'MC') {
          nutrition_fact.value.setValue(nutrition_fact.value.value / 1000000)
          nutrition_fact.unit.setValue('GRM')
        }
        if (nutrition_fact.unit.value == 'MLT') {
          nutrition_fact.unit.setValue('GRM')
        }
        if (!(nutrition_fact.value.value >= 0)) nutrition_fact.value.setValue(0)

        const matched_nutrition_fact = calculated_nutritions.find(
          (nutrition) =>
            nutrition.preparation.value == nutrition_fact.preparation.value &&
            nutrition.base_unit.value == nutrition_fact.base_unit.value &&
            nutrition.base_value.value == nutrition_fact.base_value.value &&
            nutrition.nutrition_type_code_value == nutrition_fact.nutrition_type_code_value
        )

        if (matched_nutrition_fact) {
          matched_nutrition_fact.value.setValue(matched_nutrition_fact.value.value + nutrition_fact.value.value * object_share)
        } else {
          const new_nutrition_fact = new NutritionFact(JSON.parse(JSON.stringify(nutrition_fact.as_dict)))
          new_nutrition_fact.value.setValue(nutrition_fact.value.value * object_share)
          calculated_nutritions.push(new_nutrition_fact)
        }
      })
    })

    return calculated_nutritions
  }

  scaleNutritionFacts(nutrition_facts: NutritionFact[], scale: number): NutritionFact[] {
    return nutrition_facts.map((nutrition_fact) => {
      const new_nutrition_fact = new NutritionFact(JSON.parse(JSON.stringify(nutrition_fact.as_dict)))
      new_nutrition_fact.value.setValue(nutrition_fact.value.value * scale)
      return new_nutrition_fact
    })
  }
}
