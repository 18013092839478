import { Injectable } from '@angular/core'
import { map, tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { BowlSetup } from './bowl-setup/bowl-setup.model'
import { Bowl } from './bowl/bowl.model'
import { RestApiService } from '../../services/rest-api.service'
import { EBowlType } from '../../global.types'

@Injectable({
  providedIn: 'root'
})
export class BowlsService {
  dishBowlSetups: BowlSetup[] = []
  portionBowlSetups: BowlSetup[] = []
  dishBowls: Bowl[] = []
  portionBowls: Bowl[] = []
  trashBowls: Bowl[] = []
  constructor(private restApiService: RestApiService) {}

  public getBowlSetupsForSubsidiary(type: EBowlType, fields?: any) {
    const params = {
      type,
      fields
    }
    return this.restApiService.getBowlSetupsForSubsidiary(params).pipe(
      tap((bowlSetups) => {
        bowlSetups.forEach((bowlSetup) => this.addToBowlSetups(new BowlSetup(bowlSetup)))
      })
    )
  }

  public createBowlSetup(bowlSetup: BowlSetup, params: any): Observable<BowlSetup> {
    return this.restApiService.createBowlSetup(bowlSetup.as_dict, params).pipe(
      tap((response) => {
        bowlSetup.id = response['bowl_setup_id']
        bowlSetup.saving.setValue(false)
        bowlSetup.savedBowlSetup = JSON.stringify(bowlSetup.as_dict)
        this.addToBowlSetups(bowlSetup)
      }),
      map(() => bowlSetup)
    )
  }

  public updateBowlSetup(bowlSetup: BowlSetup, params: any): Observable<BowlSetup> {
    return this.restApiService.updateBowlSetup(bowlSetup.as_dict, params).pipe(
      tap(() => {
        bowlSetup.saving.setValue(false)
        bowlSetup.savedBowlSetup = JSON.stringify(bowlSetup.as_dict)
        this.addToBowlSetups(bowlSetup)
      }),
      map(() => bowlSetup)
    )
  }

  public addToBowlSetups(bowlSetup: BowlSetup): void {
    let existing_bowlSetup: BowlSetup
    switch (bowlSetup.type) {
      case EBowlType.dish:
        existing_bowlSetup = this._dishBowlSetupWithId(bowlSetup.id)
        if (!existing_bowlSetup) this.dishBowlSetups.push(bowlSetup)
        else existing_bowlSetup.patchValue(bowlSetup.as_dict)
        break
      case EBowlType.portion:
        existing_bowlSetup = this._portionBowlSetupWithId(bowlSetup.id)
        if (!existing_bowlSetup) this.portionBowlSetups.push(bowlSetup)
        else existing_bowlSetup.patchValue(bowlSetup.as_dict)
        break
    }
  }
  public get bowlSetups(): BowlSetup[] {
    return [...this.dishBowlSetups, ...this.portionBowlSetups]
  }
  public bowlSetupWithId(bowlSetupId: string): BowlSetup {
    return this.bowlSetups.find((bowlSetup) => bowlSetup.id == bowlSetupId)
  }

  public getBowlsForSubsidiary(type: EBowlType) {
    const params = {
      type
    }
    return this.restApiService.getBowlsForSubsidiary(params).pipe(
      tap((bowls) => {
        bowls.forEach((bowl) => {
          const new_bowl = new Bowl(bowl)
          if (type == EBowlType.portion) new_bowl.portion_bowl.setValue(true)
          if (type == EBowlType.trash) new_bowl.trash_bowl.setValue(true)
          this.addToBowls(new_bowl)
        })
      })
    )
  }

  public createBowl(bowl: Bowl, params: any): Observable<Bowl> {
    return this.restApiService.createBowl(bowl.as_dict, params).pipe(
      tap((response) => {
        if (Array.isArray(response)) {
          bowl.id = response[0]['id']
        } else {
          bowl.id = response['bowls'][0]['id']
          this.addToBowlSetups(new BowlSetup(response['bowl_setups'][0]))
        }
        bowl.saving.setValue(false)
        bowl.saved_bowl = JSON.stringify(bowl.as_dict)
        this.addToBowls(bowl)
      }),
      map(() => bowl)
    )
  }

  public updateBowl(bowl: Bowl, params: any): Observable<Bowl> {
    return this.restApiService.updateBowl(bowl.as_dict, params).pipe(
      tap((response) => {
        if (Array.isArray(response)) {
          bowl.id = response[0]['id']
        } else {
          bowl.id = response['bowls'][0]['id']
          this.addToBowlSetups(new BowlSetup(response['bowl_setups'][0]))
        }
        bowl.saving.setValue(false)
        bowl.saved_bowl = JSON.stringify(bowl.as_dict)
        this.addToBowls(bowl)
      }),
      map(() => bowl)
    )
  }

  public addToBowls(bowl: Bowl): void {
    let existing_bowl: Bowl
    switch (bowl.type) {
      case EBowlType.dish:
        existing_bowl = this.dishBowlWithId(bowl.id)
        if (!existing_bowl) this.dishBowls.push(bowl)
        else existing_bowl.patchValue(bowl.as_dict)
        break
      case EBowlType.portion:
        existing_bowl = this.portionBowlWithId(bowl.id)
        if (!existing_bowl) this.portionBowls.push(bowl)
        else existing_bowl.patchValue(bowl.as_dict)
        break
      case EBowlType.trash:
        existing_bowl = this.trashBowlWithId(bowl.id)
        if (!existing_bowl) this.trashBowls.push(bowl)
        else existing_bowl.patchValue(bowl.as_dict)
        break
    }
  }

  public dishBowlWithId(bowlId: string): Bowl {
    return this.dishBowls.find((bowl) => bowl.id == bowlId)
  }
  public portionBowlWithId(bowlId: string): Bowl {
    return this.portionBowls.find((bowl) => bowl.id == bowlId)
  }
  public trashBowlWithId(bowlId: string): Bowl {
    return this.trashBowls.find((bowl) => bowl.id == bowlId)
  }

  private _dishBowlSetupWithId(bowlSetupId: string): BowlSetup {
    return this.dishBowlSetups.find((bowlSetup) => bowlSetup.id == bowlSetupId)
  }
  private _portionBowlSetupWithId(bowlSetupId: string): BowlSetup {
    return this.portionBowlSetups.find((bowlSetup) => bowlSetup.id == bowlSetupId)
  }
}
