import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { Subsidiary } from '../subsidiary/subsidiary.model'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { IOrganization } from '../../global.models'
import { OrganizationsService } from './organizations.service'
import { Observable, map, tap } from 'rxjs'

export class Organization {
  id: string
  name: FormControl
  nick_name: FormControl
  website: FormControl
  phone: FormControl
  cvr: FormControl
  address: FormControl
  postal: FormControl
  city: FormControl
  country: FormControl
  active: FormControl

  subsidiary_ids: string[]
  subsidiaries_sorted: Subsidiary[]

  saved_organization: any
  saving: boolean = false
  loading: boolean = false
  details_loaded: boolean = false

  fb: FormBuilder
  subsidiaryService: SubsidiaryService
  organizationsService: OrganizationsService

  constructor(public organization?: IOrganization) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)
    this.subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)
    this.organizationsService = FoodopLibModule.injector.get(OrganizationsService)

    this.id = organization?.id
    this.name = this.fb.control(organization?.name, [Validators.required])
    this.nick_name = this.fb.control(organization?.nick_name)
    this.website = this.fb.control(organization?.website)
    this.phone = this.fb.control(organization?.phone)
    this.cvr = this.fb.control(organization?.cvr)
    this.address = this.fb.control(organization?.address)
    this.postal = this.fb.control(organization?.postal)
    this.city = this.fb.control(organization?.city)
    this.country = this.fb.control(organization?.country)
    this.active = this.fb.control(organization?.active != undefined ? organization?.active : true)

    this.subsidiary_ids = (organization?.subsidiaries || []).map((subsidiary) => subsidiary.id)

    this.saved_organization = JSON.stringify(this.as_dict)
  }

  patchValues(organization: IOrganization): void {
    if (organization?.id) this.id = organization.id
    if (organization?.name) this.name.setValue(organization.name)
    if (organization?.nick_name) this.nick_name.setValue(organization.nick_name)
    if (organization?.website) this.website.setValue(organization.website)
    if (organization?.phone) this.phone.setValue(organization.phone)
    if (organization?.cvr) this.cvr.setValue(organization.cvr)
    if (organization?.address) this.address.setValue(organization.address)
    if (organization?.postal) this.postal.setValue(organization.postal)
    if (organization?.city) this.city.setValue(organization.city)
    if (organization?.country) this.country.setValue(organization.country)
    if (organization?.active != undefined) this.active.setValue(organization.active)

    if (organization?.subsidiaries) this.subsidiary_ids = (organization?.subsidiaries || []).map((subsidiary) => subsidiary.id)

    this.saved_organization = JSON.stringify(this.as_dict)
  }

  get valid(): Boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined
  }

  get changed(): Boolean {
    return this.saved_organization != JSON.stringify(this.as_dict)
  }

  get is_new(): Boolean {
    return !this.id
  }

  get subsidiaries(): Subsidiary[] {
    return this.subsidiary_ids.map((id) => this.subsidiaryService.subsidiary_with_id(id))
  }

  get activeSubsidiaries(): Subsidiary[] {
    return this.subsidiary_ids.map((id) => this.subsidiaryService.subsidiary_with_id(id)).filter((subsidiary) => subsidiary.active.value)
  }

  addSubsidiary(subsidiary: Subsidiary): void {
    if (!this.subsidiary_ids.find((subsidiary_id) => subsidiary_id == subsidiary.id)) this.subsidiary_ids.push(subsidiary.id)
  }

  save(): Observable<Organization> {
    this.saving = true
    return this.organizationsService.saveOrganization(this).pipe(
      tap((organization) => {
        this.patchValues(organization)
        this.saved_organization = JSON.stringify(this.as_dict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  load_details(): Observable<Organization> {
    this.loading = true
    return this.organizationsService.loadOrganization(this).pipe(
      tap((organization) => {
        this.patchValues(organization)
        this.loading = false
        this.details_loaded = true
      }),
      map(() => {
        return this
      })
    )
  }

  get as_dict(): IOrganization {
    return {
      id: this.id,
      name: this.name.value,
      nick_name: this.nick_name.value,
      website: this.website.value,
      phone: this.phone.value,
      cvr: this.cvr.value,
      address: this.address.value,
      postal: this.postal.value,
      city: this.city.value,
      country: this.country.value,
      active: this.active.value
      //subsidiaries: (this.subsidiaries || []).map((subsidiary) => subsidiary.as_dict)
    }
  }
}
