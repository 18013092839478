<div class="options-container">
    <label>Hent kundedata</label>
        
    <mat-form-field class="picker" (click)="$event.stopPropagation()">
        <mat-label>Subsidiary</mat-label>
        <mat-select #subsidiarySelector [formControl]="selectedSubsidiary" (optionsScroll)="onScroll()">
            <mat-select-trigger>{{selectedSubsidiary.value?.name?.value}}</mat-select-trigger>
            <mat-option class="default-selection-option" [value]="selectedSubsidiary.value"></mat-option>
            
            <mat-option (click)="$event.stopPropagation();" class="option-level-0 mat-select-search-field">
                <input (click)="$event.stopPropagation();" autocomplete="off" #customerSearchInput lang="en" [formControl]="customerSearchControl" type="text" matInput placeholder="Search for subsidiaries">
            </mat-option>

            <mat-option [value]="null">-- Ingen --</mat-option>

            <ng-container *ngFor="let organization of organizations$ | async | organizationFilterPipe: customerSearchControl.value; let organizationIndex = index">
                <mat-option [value]="organization.id" class="option-level-1" [ngStyle]="{'z-index': subsidiariesBeforeIndex(organizationIndex)+organizationIndex}" disabled>
                    <div class="option-container">
                        <label class="check-box-label non-selectable-label" [innerHTML]="organization.name.value | boldSearchQuery: customerSearchInput.value"></label>
                    </div>
                </mat-option>
                <ng-container *ngFor="let subsidiary of organization.subsidiaries | subsidiaryFilterPipe: customerSearchControl.value; let subidiaryIndex = index">
                    <mat-option [value]="subsidiary">
                        <div class="sub-option-container">
                            <label class="check-box-label" [innerHTML]="subsidiary.name.value | boldSearchQuery: customerSearchInput.value"></label>
                        </div>
                    </mat-option>
                </ng-container>
            </ng-container>
            <mat-option *ngIf="loadingOrganizations" class="o-cursor-default">
                <div class="l-display-flex l-position-center-vertically">
                    <mat-spinner class="o-inline-block l-float-left" color="primary" diameter="19"></mat-spinner> 
                    <label class="l-font-small l-line-height-19 l-padding-micro" i18n>Søger efter kunder ...</label>
                </div>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <date-picker [date_range]="dateRange"></date-picker>

    <mat-form-field appearance="fill" class="picker">
        <mat-label>Gruppering</mat-label>
        <mat-select [formControl]="selectedGrouping">
            <mat-option value="menu_section">
                Sektioner
            </mat-option>
            <mat-option value="menu_dish">
                Retter
            </mat-option>
        </mat-select>
    </mat-form-field>  

    <button [disabled]="isLoadingData || !options_valid" type="button" mat-button class="primary-button" (click)="getData()">
        <mat-spinner *ngIf="isLoadingData" [diameter]="19" class="o-inline-block"></mat-spinner>
        <label *ngIf="!isLoadingData">Hent data</label>
        <label *ngIf="isLoadingData">Henter data</label>
    </button>
</div>

