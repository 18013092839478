import { MenuPrintTemplateImageElement } from '../../menu-print-template/elements/image-element.model'
import { MenuPrintTemplateImageElementFormat } from '../../menu-print-template/formats/image-format.model'
import { PrintDocument } from '../print-document.model'

export class PrintPageImageElement {
  constructor(public template: MenuPrintTemplateImageElement, public printDocument?: PrintDocument, public menuItemIdentifier?: string, public model?: any, public translation_path?: string[]) {}

  public get elementTemplate(): MenuPrintTemplateImageElement {
    return this.template
  }

  public get format(): MenuPrintTemplateImageElementFormat {
    return this.elementTemplate.format
  }

  public get imageObjectFit(): object {
    return {
      'object-fit': this.format.objectFit.value
    }
  }

  public get imageStyle(): any {
    return Object.assign({
      position: 'absolute',
      width: this.format.position.value == 'full-page' ? '100%' : this.printDocument.percentageDistance(this.format.width.value) + '%',
      height: this.format.position.value == 'full-page' ? '100%' : (this.printDocument.scaledDistance(this.format.height.value) / this.printDocument.DOMHeight) * 100 + '%',
      opacity: this.format.opacity.value / 100
    })
  }

  public get imagePrintStyle(): any {
    return Object.assign(this.imageStyle, this.imagePrintPosition)
  }

  public get imageDragPosition(): object {
    switch (this.format.position.value) {
      case 'center':
        return {
          x: this.printDocument.DOMWidth / 2 - this.printDocument.scaledDistance(this.format.width.value) / 2,
          y: this.printDocument.DOMHeight / 2 - this.printDocument.scaledDistance(this.format.height.value) / 2
        }
      case 'full-page':
        return {
          x: 0,
          y: 0
        }
      case 'top-left':
        return {
          x: this.printDocument.scaledDistance(this.format.left.value),
          y: this.printDocument.scaledDistance(this.format.top.value)
        }
      case 'top-right':
        return {
          x: this.printDocument.DOMWidth - this.printDocument.scaledDistance(this.format.width.value) - this.printDocument.scaledDistance(this.format.right.value),
          y: this.printDocument.scaledDistance(this.format.top.value)
        }
      case 'bottom-left':
        return {
          x: this.printDocument.scaledDistance(this.format.left.value),
          y: this.printDocument.DOMHeight - this.printDocument.scaledDistance(this.format.bottom.value) - this.printDocument.scaledDistance(this.format.height.value)
        }
      case 'bottom-right':
        return {
          x: this.printDocument.DOMWidth - this.printDocument.scaledDistance(this.format.width.value) - this.printDocument.scaledDistance(this.format.right.value),
          y: this.printDocument.DOMHeight - this.printDocument.scaledDistance(this.format.bottom.value) - this.printDocument.scaledDistance(this.format.height.value)
        }
    }
  }

  public get imagePrintPosition(): object {
    switch (this.format.position.value) {
      case 'center':
        return {
          left: this.printDocument.printWidth / 2 - this.printDocument.scaledPrintDistance(this.format.width.value) / 2,
          top: this.printDocument.printWidth / 2 - this.printDocument.scaledPrintDistance(this.format.height.value) / 2
        }
      case 'full-page':
        return {
          left: 0,
          top: 0
        }
      case 'top-left':
        return {
          left: this.printDocument.scaledPrintDistance(this.format.left.value) + 'px',
          top: this.printDocument.scaledPrintDistance(this.format.top.value) + 'px'
        }
      case 'top-right':
        return {
          right: this.printDocument.scaledPrintDistance(this.format.right.value) + 'px',
          top: this.printDocument.scaledPrintDistance(this.format.top.value) + 'px'
        }
      case 'bottom-left':
        return {
          left: this.printDocument.scaledPrintDistance(this.format.left.value) + 'px',
          bottom: this.printDocument.scaledPrintDistance(this.format.bottom.value) + 'px'
        }
      case 'bottom-right':
        return {
          right: this.printDocument.scaledPrintDistance(this.format.right.value) + 'px',
          bottom: this.printDocument.scaledPrintDistance(this.format.bottom.value) + 'px'
        }
    }
  }
}
