
<mat-accordion multi="true" class="l-overflow" *ngIf="organization">
    <ng-container>
        <mat-expansion-panel *ngFor="let subsidiary of organization.subsidiaries; let subsidiary_index=index"
                [expanded]="panelOpenState[subsidiary_index]" 
                (opened)="panelOpenState[subsidiary_index] = true;" 
                (closed)="panelOpenState[subsidiary_index] = false;">
            
            <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*" class="o-padding-vertical-large o-padding-right-extra-large o-padding-left-large l-position-relative">
                <mat-panel-title>
                    {{subsidiary.name.value}} {{subsidiary.active.value ? '' : ' (inaktiv)'}}
                </mat-panel-title>
            </mat-expansion-panel-header>
        
            <subsidiary [subsidiary]="subsidiary" class="l-display-flex l-flex-column l-padding-medium"></subsidiary>

            <label class="l-margin-vertical-medium l-block">Lokationer</label>
            <locations [subsidiary]="subsidiary"></locations>
           
        </mat-expansion-panel>
    </ng-container>

</mat-accordion>


<div *ngIf="organization.subsidiaries.length == 0">
    Ingen subsidiaries tilføjet
</div>

<button [disabled]="!organization.valid || organization.is_new" type="button" mat-button class="primary-button" (click)="addSubsidiary()">
    Tilføj subsidiary
</button>