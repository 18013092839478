import { FormControl } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { ITrackingGroupTemplate, ITrackingTemplate } from '../../global.models'
import { TrackingTemplatesService } from './tracking-templates.service'
import { TrackingTemplate } from './tracking-template.model'

export class TrackingGroupTemplate {
  public id: string
  public name: FormControl
  public active: FormControl
  public index: FormControl

  public defaultLanguage: FormControl
  public defaultWeekdays: FormControl
  public defaultGuests: FormControl[]
  public allDaysGuests: FormControl
  public defaultKgPrice: FormControl
  public defaultPortionSize: FormControl
  public defaultDisplayTemplateId: FormControl

  public allDaysSameGuests: FormControl = new FormControl(false)
  public saving = false

  private _weekdays = [0, 1, 2, 3, 4, 5, 6]
  private _savedTemplate: any
  private _trackingTemplatesService: TrackingTemplatesService

  constructor(public trackingGroupTemplate?: ITrackingGroupTemplate) {
    this._trackingTemplatesService = FoodopLibModule.injector.get(TrackingTemplatesService)

    this.id = trackingGroupTemplate?.id
    this.name = new FormControl(trackingGroupTemplate?.name)
    this.active = new FormControl(trackingGroupTemplate?.active != undefined ? trackingGroupTemplate?.active : true)
    this.index = new FormControl(trackingGroupTemplate?.index || this._trackingTemplatesService.allTrackingGroupTemplates.filter((template) => template.active.value).length)

    this.defaultLanguage = new FormControl(trackingGroupTemplate?.default_language || 'da')
    this.defaultWeekdays = new FormControl({ value: this._parseWeekdays(trackingGroupTemplate?.default_weekdays), disabled: false })
    this.defaultGuests = this._weekdays.map((weekday) => new FormControl({ value: this._parseGuestNumbers(trackingGroupTemplate?.default_guests, weekday), disabled: false }))
    this.allDaysGuests = new FormControl({ value: this.defaultGuests.find((guest_number) => guest_number.value).value, disabled: false })
    if (this.defaultGuests.every((guest_number) => guest_number.value == this.defaultGuests[0].value)) this.allDaysSameGuests.setValue(true, { emitEvent: false })
    this.defaultKgPrice = new FormControl({ value: trackingGroupTemplate?.default_kilo_price || 20, disabled: false })
    this.defaultPortionSize = new FormControl({ value: trackingGroupTemplate?.default_portion_size || 400, disabled: false })
    this.defaultDisplayTemplateId = new FormControl({ value: trackingGroupTemplate?.default_display_template_id, disabled: false })

    this._listenForAllDaysGuestsChanges()
    this._listenForallDaysSameGuestsChanges()
    this._savedTemplate = JSON.stringify(this.asDict)
  }

  public get isNew(): boolean {
    return this.id == undefined
  }

  public get changed(): boolean {
    return this._savedTemplate != JSON.stringify(this.asDict)
  }

  public get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined
  }

  public get selectedWeekdays(): number[] {
    return this.defaultWeekdays.value.filter((weekday) => weekday != 'all')
  }
  public get activeTrackingTemplates(): TrackingTemplate[] {
    return this._trackingTemplatesService.filteredTrackingTemplates.filter((trackingTemplate) => trackingTemplate.active.value && trackingTemplate.trackingGroupTemplateId.value == this.id)
  }
  public get filteredActiveMenuTrackingTemplates(): TrackingTemplate[] {
    return this.activeTrackingTemplates.filter((trackingTemplate) => trackingTemplate.type.value == 'menu')
  }

  public get allActiveTrackingTemplates(): TrackingTemplate[] {
    return this._trackingTemplatesService.allTrackingTemplates.filter((trackingTemplate) => trackingTemplate.active.value && trackingTemplate.trackingGroupTemplateId.value == this.id)
  }
  public get allActiveMenuTrackingTemplates(): TrackingTemplate[] {
    return this.allActiveTrackingTemplates.filter((trackingTemplate) => trackingTemplate.type.value == 'menu')
  }

  public get allActiveWasteTrackingTemplates(): TrackingTemplate[] {
    return this.allActiveTrackingTemplates.filter((trackingTemplate) => trackingTemplate.type.value == 'waste')
  }

  public get asDict(): ITrackingGroupTemplate {
    return {
      id: this.id,
      name: this.name.value,
      active: this.active.value,
      index: this.index.value,
      default_language: this.defaultLanguage.value,
      default_weekdays: this.selectedWeekdays,
      default_guests: this.defaultGuests.map((weekday) => weekday.value),
      default_kilo_price: this.defaultKgPrice.value,
      default_portion_size: this.defaultPortionSize.value,
      default_display_template_id: this.defaultDisplayTemplateId.value
    }
  }

  public get asTrackingTemplateDict(): ITrackingTemplate {
    return {
      name: this.name.value,
      index: this.index.value,
      language: this.defaultLanguage.value,
      weekdays: this.selectedWeekdays,
      tracking_group_template_id: this.id,
      display_template_id: this.defaultDisplayTemplateId.value
    }
  }

  public restore(): void {
    this.patchValues(JSON.parse(this._savedTemplate))
  }

  public save(trackingTemplate?: TrackingTemplate): Observable<TrackingGroupTemplate> {
    this.saving = true
    return this._trackingTemplatesService.saveTrackingGroupTemplate(this, trackingTemplate).pipe(
      tap((trackingGroupTemplate) => {
        this.patchValues(trackingGroupTemplate)
        this._savedTemplate = JSON.stringify(this.asDict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  public patchValues(trackingGroupTemplate?: ITrackingGroupTemplate): void {
    if (trackingGroupTemplate?.id) this.id = trackingGroupTemplate?.id
    if (trackingGroupTemplate?.name) this.name.setValue(trackingGroupTemplate.name)
    if (trackingGroupTemplate?.active != undefined) this.active.setValue(trackingGroupTemplate.active)
    if (trackingGroupTemplate?.default_language) this.defaultLanguage.setValue(trackingGroupTemplate.default_language)
    if (trackingGroupTemplate?.default_weekdays) this.defaultWeekdays.setValue(this._parseWeekdays(trackingGroupTemplate.default_weekdays))
    if (trackingGroupTemplate?.default_guests) {
      this.defaultGuests.forEach((weekday, index) => weekday.setValue(this._parseGuestNumbers(trackingGroupTemplate?.default_guests, index)))
      this.allDaysGuests.setValue(this.defaultGuests.find((guest_number) => guest_number.value).value)
      if (this.defaultGuests.every((guest_number) => guest_number.value == this.defaultGuests[0].value)) this.allDaysSameGuests.setValue(true, { emitEvent: false })
    }
    if (trackingGroupTemplate?.default_kilo_price) this.defaultKgPrice.setValue(trackingGroupTemplate.default_kilo_price)
    if (trackingGroupTemplate?.default_portion_size) this.defaultPortionSize.setValue(trackingGroupTemplate.default_portion_size)
    if (trackingGroupTemplate?.default_display_template_id) this.defaultDisplayTemplateId.setValue(trackingGroupTemplate.default_display_template_id)

    this._savedTemplate = JSON.stringify(this.asDict)
  }

  private _parseWeekdays(weekdays?: number[]): any[] {
    if (!weekdays) return [0, 1, 2, 3, 4]
    if (weekdays.length == 7) return ['all'].concat(weekdays as any[])
    return weekdays
  }
  private _parseGuestNumbers(guestNumbers: number[], weekday: number): number {
    if (!guestNumbers) return 200
    else if (guestNumbers.length > weekday) return guestNumbers[weekday]
    return guestNumbers[0]
  }

  private _listenForAllDaysGuestsChanges(): void {
    this.allDaysGuests.valueChanges.subscribe((guests) => {
      if (this.allDaysSameGuests.value) this.defaultGuests.forEach((weekday) => weekday.setValue(guests))
    })
  }

  private _listenForallDaysSameGuestsChanges(): void {
    this.allDaysSameGuests.valueChanges.subscribe((allDaysSameGuests) => {
      if (allDaysSameGuests) this.defaultGuests.forEach((weekday) => weekday.setValue(this.allDaysGuests.value))
    })
  }
}
