import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { RestApiService } from '../../services/rest-api.service'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MenuSectionsService {
  constructor(@Inject(LOCALE_ID) public language: string, private restApiService: RestApiService) {}

  deleteMenuSection(menu_section_id: string, menu_id: string): Observable<any> {
    const params = {
      menu_section_id: menu_section_id,
      menu_id: menu_id
    }
    return this.restApiService.deleteMenuSection(params)
  }
}
