import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Component, ComponentRef, Input, OnInit, ViewContainerRef } from '@angular/core'
import { LocationComponent } from './location/location.component'
import { GlobalFunctionsService, Subsidiary } from 'foodop-lib'

@Component({
  selector: 'locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
  @Input() subsidiary: Subsidiary

  panelOpenState = []
  overlayRef: OverlayRef

  constructor(public func: GlobalFunctionsService, public overlay: Overlay, public viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {}

  addLocation() {
    let config = new OverlayConfig()
    config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
    config.hasBackdrop = true

    this.overlayRef = this.overlay.create(config)

    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose()
    })

    this.overlayRef.addPanelClass('l-width-70p')
    this.overlayRef.addPanelClass('l-max-height-80p')
    this.overlayRef.addPanelClass('l-min-height-365')
    this.overlayRef.addPanelClass('l-overflow')

    const compRef: ComponentRef<LocationComponent> = this.overlayRef.attach(
      new ComponentPortal(LocationComponent, this.viewContainerRef, this.func.createInjector(Object.assign(this.subsidiary.as_dict, { id: null, name: 'Ny lokation', subsidiary_id: this.subsidiary.id, index: this.subsidiary.locations.length })))
    )

    compRef.instance.location_saved.subscribe((location) => {
      this.subsidiary.locations.push(location)
      this.overlayRef.dispose()
    })
  }
}
