import { FilesService } from '../../../services/files.service'
import { FoodopLibModule } from '../../../foodop-lib.module'
import { GlobalFunctionsService } from '../../../services/global-functions.service'
import * as uuid from 'uuid'

export class BasicMenuPrintTemplateElement {
  public type: 'menu' | 'menuTitle' | 'menuDate' | 'weekLabel' | 'customString' | 'image' | 'sectionName' | 'dishName' | 'dishDescription' | 'dishIngredients' | 'dishAllergens' | 'dishCO2' | 'dishTags'
  public identifier: string

  public filesService: FilesService
  public func: GlobalFunctionsService

  constructor(type: 'menu' | 'menuTitle' | 'menuDate' | 'weekLabel' | 'customString' | 'image' | 'sectionName' | 'dishName' | 'dishDescription' | 'dishIngredients' | 'dishAllergens' | 'dishCO2' | 'dishTags') {
    this.filesService = FoodopLibModule.injector.get(FilesService)
    this.func = FoodopLibModule.injector.get(GlobalFunctionsService)

    this.type = type
    this.identifier = uuid.v1()
  }
}
