<div class="filter-container">
    <div class="l-display-flex l-space-between l-position-center-vertically l-topmargin-medium">
        <input autocomplete="off" matInput placeholder="Search for users" [formControl]="userSearchControl" class="l-width-300p">
    </div>

    <mat-form-field appearance="fill" class="customer-picker">
        <input autocomplete="off" #userInput #autocompleteTrigger="matAutocompleteTrigger" 
            lang="en" [formControl]="customerFilterControl" type="text" matInput matAutocompletePosition="below" 
            [matAutocomplete]="autoCompleteName" #userInputField="matAutocompleteTrigger"
            placeholder="Search for subsidiaries">
    
        <mat-autocomplete id="userInputTrigger" #autoCompleteName="matAutocomplete" multiple disableRipple (optionsScroll)="onFilterScroll()">
            <ng-container *ngFor="let organization of filterOrganizations$ | async | organizationFilterPipe: customerFilterControl.value; let organizationIndex = index">
                <mat-option [value]="organization.id" class="option-level-0" [ngStyle]="{'z-index': filterSubsidiariesBeforeIndex(organizationIndex)+organizationIndex}">
                    <div class="option-container" (click)="$event.stopPropagation(); toggleSelection(organization)">
                        <mat-checkbox color="primary" [checked]="optionSelected(organization)" (click)="$event.stopPropagation();" (change)="toggleSelection(organization);">
                            <label class="check-box-label" (click)="$event.stopPropagation(); toggleSelection(organization)" [innerHTML]="organization.name.value | boldSearchQuery: userInput.value"></label>
                        </mat-checkbox>
                    </div>
                </mat-option>
                <ng-container *ngFor="let subsidiary of organization.subsidiaries | subsidiaryFilterPipe: customerFilterControl.value; let subidiaryIndex = index">
                    <mat-option [value]="subsidiary.id" class="option-level-1" [ngStyle]="{'z-index': filterSubsidiariesBeforeIndex(organizationIndex)+subidiaryIndex}">
                        <div class="sub-option-container" (click)="$event.stopPropagation(); toggleSelection(subsidiary)">
                            <mat-checkbox color="primary" [checked]="optionSelected(subsidiary)" (click)="$event.stopPropagation();" (change)="toggleSelection(subsidiary);">
                                <label class="check-box-label" (click)="$event.stopPropagation(); toggleSelection(subsidiary)" [innerHTML]="subsidiary.name.value | boldSearchQuery: userInput.value"></label>
                            </mat-checkbox>
                        </div>
                    </mat-option>
                </ng-container>
            </ng-container>
            <mat-option *ngIf="loadingOrganizations" class="o-cursor-default">
                <div class="l-display-flex l-position-center-vertically">
                    <mat-spinner class="o-inline-block l-float-left" color="primary" diameter="19"></mat-spinner> 
                    <label class="l-font-small l-line-height-19 l-padding-micro" i18n>Søger efter kunder ...</label>
                </div>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field> 
</div>

<label *ngIf="selections.length" class="picked-customers-label">Valgte kunder: {{selections}}</label>

<div class="add-button-container">
    <button type="button" mat-button class="primary-button" (click)="openUser()">
        Tilføj bruger
    </button>
</div>

<div *ngIf="users$ | async as users">
    <ng-container *ngIf="users.length; else noUsers">
        <ng-container *ngIf="organizations$ | async as organizations">
            <ng-container *ngIf="accessRoles$ | async as accessRoles">
                <mat-card *ngFor="let user of users; let user_index=index" (click)="openUser(user)" class="l-margin-medium">
                    <div class="l-display-flex l-space-between l-position-center-vertically">

                        <label class="l-font-bold l-font-small">{{user.first_name.value}} {{user.last_name.value}}</label>
                
                        <div class="selection-container">
                            <mat-form-field class="l-font-small l-width-30p" (click)="$event.stopPropagation()">
                                <mat-label>Subsidiary</mat-label>
                                <mat-select #subsidiarySelector [formControl]="user.subsidiary_id" (selectionChange)="onUserSubsidiaryChanges(user)" (optionsScroll)="onScroll()">
                                    <mat-select-trigger>{{userSubsidiaryName(user)}}</mat-select-trigger>
                                    <mat-option class="default-selection-option" [value]="user.subsidiary_id.value"></mat-option>
                                    
                                    <mat-option (click)="$event.stopPropagation();" class="option-level-0 mat-select-search-field">
                                        <input (click)="$event.stopPropagation();" autocomplete="off" #customerSearchInput lang="en" [formControl]="customerSearchControl" type="text" matInput placeholder="Search for subsidiaries">
                                    </mat-option>
                    
                                    <mat-option [value]="null">-- Ingen --</mat-option>
                    
                                    <ng-container *ngFor="let organization of organizations | organizationFilterPipe: customerSearchControl.value; let organizationIndex = index">
                                        <mat-option [value]="organization.id" class="option-level-1" [ngStyle]="{'z-index': subsidiariesBeforeIndex(organizationIndex)+organizationIndex}" disabled>
                                            <div class="option-container">
                                                <label class="check-box-label non-selectable-label" [innerHTML]="organization.name.value | boldSearchQuery: customerSearchInput.value"></label>
                                            </div>
                                        </mat-option>
                                        <ng-container *ngFor="let subsidiary of organization.subsidiaries | subsidiaryFilterPipe: customerSearchControl.value; let subidiaryIndex = index">
                                            <mat-option [value]="subsidiary.id">
                                                <div class="sub-option-container">
                                                    <label class="check-box-label" [innerHTML]="subsidiary.name.value | boldSearchQuery: customerSearchInput.value"></label>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                    </ng-container>
                                    <mat-option *ngIf="loadingOrganizations" class="o-cursor-default">
                                        <div class="l-display-flex l-position-center-vertically">
                                            <mat-spinner class="o-inline-block l-float-left" color="primary" diameter="19"></mat-spinner> 
                                            <label class="l-font-small l-line-height-19 l-padding-micro" i18n>Søger efter kunder ...</label>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                    


                            <mat-form-field class="l-font-small user-option-picker l-leftmargin-small" (click)="$event.stopImmediatePropagation();">
                                <mat-label>Access role</mat-label>
                                <mat-select [formControl]="user.access_role_id" [value]="user.access_role_id.value" (selectionChange)="user.save().subscribe()">
                                    <mat-option *ngFor="let access_role of accessRoles" [value]="access_role.id">
                                        {{access_role.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                </mat-card>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #noUsers>
        <div *ngIf="(userSearchControl.value || selectedSubsidiaryIds.length) && !loadingUsers" class="loading-container">
            <label>No users found!</label>
        </div>
    </ng-template>
</div>

<div *ngIf="loadingUsers" class="loading-container">
    <mat-spinner [diameter]="25" color="primary"></mat-spinner>
    <label>Loading users ...</label>
</div>

<div *ngIf="moreUsersAvailable" class="load-more-button-container">
    <button (click)="loadMoreUsers()" type="button" mat-button class="secondary-button">
        Load more users
    </button>
</div>