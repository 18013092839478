import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { ENotificationType, Gateway, IDataGatewayLogs, IPushNotification, WebsocketsService } from 'foodop-lib'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-gateway-logs',
  templateUrl: './gateway-logs.component.html',
  styleUrls: ['./gateway-logs.component.css']
})
export class GatewayLogsComponent implements OnChanges {
  @Input() gateway: Gateway
  logs = new Array<string>()
  notificationSubscription: Subscription
  interval: any

  constructor(private websocketsService: WebsocketsService) {}

  ngOnChanges(): void {
    if (this.gateway) {
      this.listenForGatewayLogs(this.gateway)
      this.queryGatewayLogs(this.gateway)
    } else {
      console.log('Ingen gateway')
    }
  }

  queryGatewayLogs(gateway: Gateway) {
    this.websocketsService.getGatewayLogs(gateway)
  }

  listenForGatewayLogs(gateway: Gateway) {
    this.notificationSubscription = this.websocketsService.notification.subscribe((notification: IPushNotification) => {
      if (gateway.id === notification.source) {
        console.log('WebSocket: notification originates from this gateway: ' + notification.source)
        switch (notification.type) {
          case ENotificationType.GetGatewayLogs: {
            const data: IDataGatewayLogs = <IDataGatewayLogs>notification.data
            this.logs = data.log.split(/\r?\n/)
            break
          }
        }
      }
    })
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe()
    }
  }
}
