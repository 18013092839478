<div class="card">
    <div class="card-body">
        <h5 class="card-title">Gateway log</h5>
    </div>
    <ul *ngIf="logs?.length" class="l-overflow l-block">
        <li *ngFor="let log of logs" >
            {{ log }}
        </li>
    </ul>
</div>
