import { Component } from '@angular/core'

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
  host: {
    class: 'l-margin-horizontal-3rem l-position-relative l-block l-topmargin-large'
  }
})
export class CustomersComponent {
  constructor() {}
}
