import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Observable, Subject, merge, of } from 'rxjs'
import { catchError, map, scan, tap } from 'rxjs/operators'
import { RestApiService } from '../../services/rest-api.service'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { TrashTemplate } from './trash-template.model'
import { ITrashTemplate } from '../../global.models'

@Injectable({
  providedIn: 'root'
})
export class TrashTemplatesService {
  trash_templates: TrashTemplate[] = []
  private _newTrashTemplate$: Subject<any> = new Subject<any>()

  constructor(public fb: FormBuilder, private restApiService: RestApiService, private subsidiaryService: SubsidiaryService, @Inject(LOCALE_ID) public language: string) {}

  public getTrashTemplates(): Observable<TrashTemplate[]> {
    const params = {
      fields: 'all',
      subsidiary_id: this.subsidiaryService.subsidiary.id
    }

    const loadPipe$ = this.restApiService.loadTrashTemplates(params).pipe(
      catchError(() => {
        return of([])
      })
    )

    return merge(loadPipe$, this._newTrashTemplate$).pipe(
      scan((trashTemplates: any[], newTrackingGroupTemplates: any[]) => {
        const map = new Map()
        trashTemplates.forEach((trashTemplate) => map.set(trashTemplate.id, trashTemplate))
        newTrackingGroupTemplates.forEach((trashTemplate) => map.set(trashTemplate.id, { ...map.get(trashTemplate.id), ...trashTemplate }))
        return Array.from(map.values())
      }),
      tap((trashTemplates) => {
        trashTemplates.forEach((trashTemplate) => this.addToTrashTemplates(trashTemplate))
      }),
      map((trashTemplates) => {
        return trashTemplates.map((trashTemplate) => this.trash_template_with_id(trashTemplate.id))
      })
    )
  }

  addToTrashTemplates(trash_template: ITrashTemplate): void {
    const existing_trash_template: TrashTemplate = this.trash_template_with_id(trash_template.id)
    if (!existing_trash_template) this.trash_templates.push(new TrashTemplate(trash_template))
    else existing_trash_template.patchValues(trash_template)
  }

  trash_template_with_id(trash_template_id: string): TrashTemplate {
    return this.trash_templates.find((trash_template) => trash_template.id == trash_template_id)
  }

  saveTrashTemplate(trash_template: TrashTemplate): Observable<ITrashTemplate> {
    return this.restApiService.createTrashTemplate(trash_template.as_dict, { subsidiary_id: this.subsidiaryService.subsidiary.id }).pipe(tap((response) => this._newTrashTemplate$.next([response])))
  }

  saveTemplateNames(trash_template: TrashTemplate): Observable<ITrashTemplate> {
    return this.restApiService.updateTrashTemplate(trash_template.names_dict, { subsidiary_id: this.subsidiaryService.subsidiary.id })
  }
}
