import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { Gateway, INJECTION_DATA } from 'foodop-lib'

@Component({
  selector: 'app-scale-dish-picker',
  templateUrl: './scale-dish-picker.component.html',
  styleUrls: ['./scale-dish-picker.component.css', './scale-dish-picker.component.scss'],
  host: {
    class: 'l-position-relative l-width-full l-height-full l-theme-white l-overflow l-box-shadow l-border-radius l-padding-large'
  }
})
export class ScaleAndDatePickerComponent implements OnInit {
  @Output() getDataForScaleMaccAndDate = new EventEmitter<any>()
  @Output() cancel = new EventEmitter<boolean>()

  gateway: Gateway
  scale_macc: string
  date: Date

  constructor(@Inject(INJECTION_DATA) public initData: any) {
    this.gateway = initData.gateway
  }

  ngOnInit(): void {}

  closeView() {
    this.cancel.emit(true)
  }

  getData() {
    this.getDataForScaleMaccAndDate.emit({ scale_macc: this.scale_macc, date: this.date })
  }
}
