import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { Component, ComponentRef, Input, ViewContainerRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Gateway, Location, Subsidiary, Organization, OrganizationsService, GatewaysService, GlobalFunctionsService, LocationsService } from 'foodop-lib'
import { Observable, Subject, catchError, debounceTime, exhaustMap, filter, merge, of, scan, startWith, switchMap, takeWhile, tap } from 'rxjs'
import { GatewayComponent } from '../../../popups/gateways/gateway/gateway.component'
import { ComponentPortal } from '@angular/cdk/portal'

@Component({
  selector: 'gateways-overview',
  templateUrl: './gateways-overview.component.html',
  styleUrls: ['./gateways-overview.component.css']
})
export class GatewaysOverviewComponent {
  @Input() customerSelection: FormControl

  public organizations$: Observable<Organization[]>
  public gateways$: Observable<Gateway[]>
  public customerSearchControl: FormControl = new FormControl('')
  public gatewaySearchControl: FormControl = new FormControl('')
  public loadingOrganizations: boolean = false
  public loadingGateways: boolean = false
  public moreGatewaysAvailable: boolean = false

  private _organizationsOffset: number = 0
  private _gatewaysOffset: number = 0
  private _loadNextOrganizations$ = new Subject()
  private _loadNextGateways$ = new Subject()
  private _organizations: Organization[] = []
  private _overlayRef: OverlayRef

  constructor(public organizationsService: OrganizationsService, private gatewaysService: GatewaysService, public func: GlobalFunctionsService, public overlay: Overlay, public viewContainerRef: ViewContainerRef, private locationsService: LocationsService) {}

  ngOnInit(): void {
    this.organizations$ = this._loadOrganizations()
    this.gateways$ = this._loadGateways()
  }

  private get _selectedLocations(): Location[] {
    return this.customerSelection.value.filter((selectionOption: Location | Subsidiary | Organization) => selectionOption.constructor.name == 'Location') as Location[]
  }
  public get selectedLocationIds(): string[] {
    return this._selectedLocations.map((location) => location.id)
  }

  public gatewayLocationName(gateway: Gateway): string {
    return this.locationsService.location_with_id(gateway.location_id.value)?.name?.value || 'Unallocated'
  }

  public subsidiariesBeforeIndex(organizationIndex: number): number {
    return this._organizations
      .slice(0, organizationIndex + 1)
      .map((organization) => organization.subsidiaries.length)
      .reduce((a, b) => a + b, 0)
  }

  public onScroll(): void {
    this._loadNextOrganizations$.next(true)
  }

  public loadMoreGateways(): void {
    this._loadNextGateways$.next(true)
  }

  viewGateway(gateway: Gateway) {
    let config = new OverlayConfig()
    config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
    config.hasBackdrop = true

    this._overlayRef = this.overlay.create(config)

    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.dispose()
    })

    this._overlayRef.addPanelClass('l-width-70p')
    this._overlayRef.addPanelClass('l-height-80p')
    this._overlayRef.addPanelClass('l-overflow')

    const compRef: ComponentRef<GatewayComponent> = this._overlayRef.attach(
      new ComponentPortal(
        GatewayComponent,
        this.viewContainerRef,
        this.func.createInjector({
          gateway: gateway
        })
      )
    )

    compRef.instance.closeGatewayView.subscribe(() => {
      this._overlayRef.dispose()
    })
  }

  private _loadOrganizations(): Observable<Organization[]> {
    const searchChanges$ = this.customerSearchControl.valueChanges.pipe(
      startWith(''),
      tap((value) => {
        if (typeof value === 'string') {
          this._organizations = []
          this._organizationsOffset = 0
        }
      }),
      filter((value) => typeof value === 'string' && (value.length > 2 || value == '')),

      debounceTime(200)
    )

    return searchChanges$.pipe(
      switchMap(() => {
        return this._loadNextOrganizations$.pipe(
          startWith(0),
          tap(() => (this.loadingOrganizations = true)),
          exhaustMap(() => this.organizationsService.loadOrganizations('all', this.customerSearchControl.value.toLowerCase(), this._organizationsOffset)),
          takeWhile((organizations) => organizations.length == 10, true),
          scan((allOrganizations: Organization[], newOrganizations: Organization[]) => allOrganizations.concat(newOrganizations)),
          tap((organizations) => {
            this._organizations = organizations
            this._organizationsOffset += 10
            this.loadingOrganizations = false
          })
        )
      })
    )
  }

  private _loadGateways(): Observable<Gateway[]> {
    const filterChanges$ = merge(this.gatewaySearchControl.valueChanges, this.customerSelection.valueChanges).pipe(
      startWith(''),
      tap(() => (this._gatewaysOffset = 0))
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextGateways$.pipe(
          startWith(0),
          tap(() => (this.loadingGateways = true)),
          exhaustMap(() => {
            if (!this._selectedLocations.length && this.gatewaySearchControl.value.length <= 1) return of([])
            return this.gatewaysService.getGateways(['scales', 'locations'], this.gatewaySearchControl.value, this.selectedLocationIds, this._gatewaysOffset)
          }),
          takeWhile((gateways) => {
            this.moreGatewaysAvailable = gateways.length == 10
            return gateways.length == 10, true
          }),
          scan((allGateways: Gateway[], newGateways: Gateway[]) => allGateways.concat(newGateways)),
          tap(() => {
            this._gatewaysOffset += 10
            this.loadingGateways = false
          })
        )
      }),
      catchError(() => of([]))
    )
  }
}
