import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Component, ComponentRef, Input, OnInit, ViewContainerRef } from '@angular/core'
import { SubsidiaryComponent } from './subsidiary/subsidiary.component'
import { GlobalFunctionsService, Organization } from 'foodop-lib'

@Component({
  selector: 'subsidiaries',
  templateUrl: './subsidiaries.component.html',
  styleUrls: ['./subsidiaries.component.css']
})
export class SubsidiariesComponent implements OnInit {
  @Input() organization: Organization

  panelOpenState = []
  overlayRef: OverlayRef

  constructor(public func: GlobalFunctionsService, public overlay: Overlay, public viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {}

  addSubsidiary() {
    let config = new OverlayConfig()
    config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
    config.hasBackdrop = true

    this.overlayRef = this.overlay.create(config)

    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose()
    })

    this.overlayRef.addPanelClass('l-width-70p')
    this.overlayRef.addPanelClass('l-max-height-80p')
    this.overlayRef.addPanelClass('l-min-height-365')
    this.overlayRef.addPanelClass('l-overflow')

    const compRef: ComponentRef<SubsidiaryComponent> = this.overlayRef.attach(
      new ComponentPortal(SubsidiaryComponent, this.viewContainerRef, this.func.createInjector(Object.assign(this.organization.as_dict, { id: null, name: 'Ny subsidiary', organization_id: this.organization.id })))
    )

    compRef.instance.subsidiary_saved.subscribe((subsidiary) => {
      this.organization.subsidiaries.push(subsidiary)
      this.overlayRef.dispose()
    })
  }
}
