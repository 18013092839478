import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { DialogPopUp, INJECTION_DATA, Location } from 'foodop-lib'

@Component({
  selector: 'location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css'],
  host: {
    class: 'l-position-relative l-width-full l-height-full l-theme-white l-overflow l-border-radius l-padding-large'
  }
})
export class LocationComponent implements OnInit {
  @Input() location: Location
  @Output() location_saved = new EventEmitter<Location>()

  constructor(@Inject(INJECTION_DATA) public initData: any, public dialog: MatDialog) {
    if (!this.location) this.location = new Location(initData)
  }

  ngOnInit(): void {}

  saveLocation(): void {
    const added_buffet_scales_to_trash_location: boolean = this.location.wasteTrackings.length && this.location.dish_scale_groups.length + this.location.display_scale_groups.length > 0
    if (added_buffet_scales_to_trash_location) {
      const dialogRef = this.dialog.open(DialogPopUp, {
        data: {
          title: 'Du er ved at ændre denne lokations type',
          content: 'Ved at ændre denne lokation til at indeholde buffetvægte/diplayvægte vil alle affaldstrackingregler for lokationen fjernes',
          option1_message: 'Fortryd',
          option2_message: 'Gem ændringer og slet affaldstrackingregler',
          option1_value: 0,
          option2_value: 1
        }
      })

      dialogRef.afterClosed().subscribe((result) => {
        if (result == 1) {
          this.location.save().subscribe(() => this.location_saved.emit(this.location))
        }
      })
    } else {
      this.location.save().subscribe(() => this.location_saved.emit(this.location))
    }
  }
}
