import { IMenuPrintTemplateElement } from '../../../global.models'
import { BasicMenuPrintTemplateElement } from './basic-element.model'
import { Names } from '../../names/names.model'
import { MenuPrintTemplateStringElementFormat } from '../formats/string-format.model'
import { Recipe } from '../../recipe/recipe.model'
import { FormControl } from '@angular/forms'

export class MenuPrintTemplateDishAllergensElement extends BasicMenuPrintTemplateElement {
  public format: MenuPrintTemplateStringElementFormat
  public prefix: Names
  public separator: FormControl
  public lastSeparator: Names
  public translatable = false
  public name: string = $localize`Retallergener`

  constructor(element: IMenuPrintTemplateElement) {
    super('dishAllergens')

    this.format = new MenuPrintTemplateStringElementFormat(element?.format)
    this.prefix = new Names(element?.prefix)
    this.separator = new FormControl(element?.separator || ', ')
    this.lastSeparator = new Names(element?.lastSeparator || { en: ' and ', da: ' og ' })
  }

  public get asDict(): IMenuPrintTemplateElement {
    return {
      type: this.type,
      format: this.format.asDict,
      prefix: this.prefix.as_dict,
      separator: this.separator.value,
      lastSeparator: this.lastSeparator.as_dict
    }
  }
  public elementString(recipe: Recipe, language: string): string {
    if (recipe.freeFromAllergens) return language == 'da' ? 'Fri for allergener' : 'Free from allergens'
    if (recipe.allergens_contained.length == 0) return ''
    return (
      (this.prefix[language].value || '') +
      this.func.arrayToString(
        recipe.allergens_contained.map((allergen) => allergen.names[language].value),
        this.separator.value,
        this.lastSeparator[language].value,
        this.format.capitalization.value,
        this.format.capitalization.value
      )
    )
  }
}
