import { FormControl, Validators } from '@angular/forms'
import { Bowl } from '../bowl/bowl.model'
import { Observable } from 'rxjs'
import { BowlsService } from '../bowls.service'
import { ScaleDish } from '../../scale-dish/scale-dish.model'
import { map } from 'rxjs/operators'
import { FoodopLibModule } from '../../../foodop-lib.module'
import { IBowlSetup } from '../../../global.models'
import { SubsidiaryService } from '../../subsidiary/subsidiary.service'
import { TrackingSectionTemplate } from '../../tracking-templates/tracking-section-template.model'
import { EBowlType } from '../../../global.types'

export class BowlSetup {
  public id: string

  public bowlId: FormControl
  public portionBowlId: FormControl
  public trashBowlId: FormControl

  public default: FormControl
  public name: FormControl

  public portionBowlUsed: FormControl
  public saving: FormControl = new FormControl(false)
  public savedBowlSetup: any

  private _bowlsService: BowlsService
  private _subsidiaryService: SubsidiaryService

  constructor(bowlSetup: IBowlSetup) {
    this._bowlsService = FoodopLibModule.injector.get(BowlsService)
    this._subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)

    this.id = bowlSetup?.id

    this.bowlId = new FormControl(bowlSetup?.bowl_id)
    this.portionBowlId = new FormControl(bowlSetup?.portion_bowl_id)
    this.trashBowlId = new FormControl(bowlSetup?.trash_bowl_id)

    this.default = new FormControl(bowlSetup?.default)
    this.name = new FormControl({ value: bowlSetup?.name, disabled: false }, [Validators.required])

    this.portionBowlUsed = new FormControl(!!bowlSetup?.portion_bowl_id)

    this.savedBowlSetup = JSON.stringify(this.as_dict)
  }

  get default_name(): string {
    return this.portionBowlUsed && this.portionBowl ? this.bowl?.name?.value + ' i ' + this.portionBowl.name?.value?.toLowerCase() : this.bowl?.name?.value
  }

  get as_dict(): IBowlSetup {
    return {
      id: this.id,
      default: this.default.value,
      name: this.name.value || this.default_name,
      bowl_id: this.bowlId.value,
      portion_bowl_id: this.portionBowlId.value,
      trash_bowl_id: this.trashBowlId.value
    }
  }

  private get _isNew(): boolean {
    return this.id == undefined
  }

  public get isChanged(): boolean {
    return JSON.stringify(this.as_dict) != this.savedBowlSetup
  }

  public get valid(): boolean {
    return (Object.keys(this).find((key) => this[key]?.invalid == true) == undefined && this.bowl?.weight?.value > 0) || this.trashBowl?.weight?.value > 0
  }

  public get type(): EBowlType {
    if (this.portionBowlId.value) return EBowlType.portion
    else if (this.trashBowlId.value) return EBowlType.trash
    else return EBowlType.dish
  }

  public get bowl(): Bowl {
    return this._bowlsService.dishBowlWithId(this.bowlId.value)
  }
  public get portionBowl(): Bowl {
    return this._bowlsService.portionBowlWithId(this.portionBowlId.value)
  }
  public get trashBowl(): Bowl {
    return this._bowlsService.trashBowlWithId(this.trashBowlId.value)
  }

  public isSelectedForSectionTemplate(trackingSectionTemplate: TrackingSectionTemplate): boolean {
    return !!trackingSectionTemplate.bowlSetups.find((bowlSetup) => bowlSetup.id == this.id)
  }
  public isSelectedForScaleDish(scaleDish: ScaleDish): boolean {
    return !!scaleDish.bowlSetups.find((bowlSetup) => bowlSetup.id == this.id)
  }

  public selectorForScaleDish(scaleDish: ScaleDish): FormControl {
    return new FormControl(!!scaleDish.bowlSetups.find((bowlSetup) => bowlSetup.id == this.id))
  }
  public selectorForTrackingSectionTemplate(trackingSectionTemplate: TrackingSectionTemplate): FormControl {
    return new FormControl(!!trackingSectionTemplate.bowlSetups.find((bowlSetup) => bowlSetup.id == this.id))
  }

  public isDefaultSelectionForScaleDish(scaleDish: ScaleDish): boolean {
    return !!scaleDish.bowlSetups.find((bowlSetup) => bowlSetup.id == this.id && bowlSetup.default.value)
  }

  public isDefaultSelectionForTrackingSectionTemplate(trackingSectionTemplate: TrackingSectionTemplate): boolean {
    return !!trackingSectionTemplate.bowlSetups.find((bowlSetup) => bowlSetup.id == this.id && bowlSetup.default.value)
  }

  public patchValue(bowlSetup: IBowlSetup): void {
    if (bowlSetup?.bowl_id) this.bowlId.setValue(bowlSetup?.bowl_id)
    if (bowlSetup?.portion_bowl_id) this.portionBowlId.setValue(bowlSetup?.portion_bowl_id)
    if (bowlSetup?.trash_bowl_id) this.trashBowlId.setValue(bowlSetup?.trash_bowl_id)

    if (bowlSetup?.default != undefined) this.default.setValue(bowlSetup?.default)

    if (bowlSetup?.portion_bowl_id) this.portionBowlUsed.setValue(true)
    else this.portionBowlUsed.setValue(false)

    this.savedBowlSetup = JSON.stringify(this.as_dict)
  }

  save(): Observable<BowlSetup> {
    if (!this.name.value) this.name.setValue(this.default_name)
    const params = {
      fields: '',
      subsidiary_id: this._subsidiaryService.subsidiary.id
    }
    if (this._isNew) {
      return this._bowlsService.createBowlSetup(this, params).pipe(map(() => this))
    } else {
      return this._bowlsService.updateBowlSetup(this, params).pipe(map(() => this))
    }
  }
}
