import { FormControl, Validators } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { ITrackingGroup } from '../../global.models'
import moment from 'moment'
import { TrackingsService } from './trackings.service'

export class TrackingGroup {
  public id: string
  public trackingGroupTemplateId: string
  public date: moment.Moment

  public guests: FormControl
  public kgPrice: FormControl
  public portionSize: FormControl

  public saving = false

  private _savedModel: any
  private _trackingsService: TrackingsService

  constructor(public trackingGroup?: ITrackingGroup) {
    this._trackingsService = FoodopLibModule.injector.get(TrackingsService)

    this.id = trackingGroup?.id
    this.trackingGroupTemplateId = trackingGroup?.tracking_group_template_id
    this.date = moment(trackingGroup?.date)

    this.guests = new FormControl(trackingGroup?.guests, [Validators.required, Validators.min(1)])
    this.kgPrice = new FormControl(trackingGroup?.kilo_price, [Validators.required, Validators.min(0)])
    this.portionSize = new FormControl(trackingGroup?.portion_size, [Validators.required, Validators.min(0)])

    this._savedModel = JSON.stringify(this.asDict)
  }

  public get changed(): boolean {
    return this._savedModel != JSON.stringify(this.asDict)
  }

  public get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined
  }

  public get asDict(): ITrackingGroup {
    return {
      id: this.id,
      tracking_group_template_id: this.trackingGroupTemplateId,
      guests: Number(this.guests.value),
      kilo_price: this.kgPrice.value,
      portion_size: this.portionSize.value,
      date: this.date.format('YYYY-MM-DD')
    }
  }

  public save(): Observable<TrackingGroup> {
    this.saving = true
    return this._trackingsService.saveTrackingGroup(this).pipe(
      tap((trackingGroup) => {
        this.patchValues(trackingGroup)
        this._savedModel = JSON.stringify(this.asDict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  public patchValues(trackingGroup?: ITrackingGroup): void {
    if (trackingGroup?.id) this.id = trackingGroup?.id
    if (trackingGroup?.tracking_group_template_id) this.trackingGroupTemplateId = trackingGroup.tracking_group_template_id
    if (trackingGroup?.date) this.date = moment(trackingGroup.date)
    if (trackingGroup?.guests) this.guests.setValue(trackingGroup.guests)
    if (trackingGroup?.kilo_price) this.kgPrice.setValue(trackingGroup.kilo_price)
    if (trackingGroup?.portion_size) this.portionSize.setValue(trackingGroup.portion_size)
    this._savedModel = JSON.stringify(this.asDict)
  }
}
