import { AbstractControl, FormBuilder, FormControl } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { BowlSetup } from './bowl-setup/bowl-setup.model'
import { Bowl } from './bowl/bowl.model'
import { BowlsService } from './bowls.service'
import { IBowl, IBowlAssociation, IBowlSetup } from '../../global.models'

export class BowlAssociation {
  bowl_setup_id: FormControl
  trash_bowl_id: FormControl
  default: FormControl

  saved_bowl_association: any
  saving: FormControl = new FormControl(false)

  bowlsService: BowlsService
  fb: FormBuilder

  constructor(bowl_association: IBowlAssociation) {
    this.bowlsService = FoodopLibModule.injector.get(BowlsService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.bowl_setup_id = this.fb.control(bowl_association?.bowl_setup_id)
    this.trash_bowl_id = this.fb.control(bowl_association?.trash_bowl_id)
    this.default = this.fb.control(bowl_association?.default)
  }

  get is_changed(): boolean {
    return JSON.stringify(this.as_dict) != this.saved_bowl_association
  }

  get trash_bowl(): Bowl {
    return this.bowlsService.trashBowlWithId(this.trash_bowl_id.value)
  }
  get bowl_setup(): BowlSetup {
    return this.bowlsService.bowlSetupWithId(this.bowl_setup_id.value)
  }
  patchValue(bowl: IBowl): void {
    if (bowl?.bowl_setup_id != undefined) this.bowl_setup_id.setValue(bowl?.bowl_setup_id)
    if (bowl?.trash_bowl_id != undefined) this.trash_bowl_id.setValue(bowl?.trash_bowl_id)
    if (bowl?.default != undefined) this.default.setValue(bowl?.default)

    this.saved_bowl_association = JSON.stringify(this.as_dict)
  }

  get as_dict(): IBowl | IBowlSetup {
    if (this.trash_bowl_id.value) return Object.assign(this.trash_bowl?.as_dict || {}, { default: this.default.value })
    if (this.bowl_setup_id.value) return Object.assign(this.bowl_setup?.as_dict || {}, { default: this.default.value })
  }

  get as_trash_bowl_dict(): IBowl {
    return Object.assign(this.trash_bowl?.as_dict || {}, { default: this.default.value })
  }
}
