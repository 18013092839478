import { Pipe, PipeTransform } from '@angular/core';

  @Pipe({name: 'timeToMealType'})
  export class TimeToMealTypePipe implements PipeTransform {
    transform(servingTime: string): string {
        let value = "Frokost";

        let hour = parseInt(servingTime.split(':')[0]); 
        
        if (hour < 10) {
            value = "Morgenmad";
        }
        if (hour >= 10 && hour <16) {
            value = "Frokost";
        } 
        else {
            value = "Aftensmad";
        }

        return value;
    }
  }