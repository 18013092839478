

<div class="l-clear l-bottommargin-large">
    
    <!-- Scale -->
    <mat-form-field class="l-font-small l-width-full">
        <mat-label>Vægt</mat-label>
        <mat-select [(ngModel)]="scale_macc" required>
            <mat-option *ngFor="let scale of gateway.scales" [value]="scale.macc" [disabled]="gateway.scales.length==0">
                {{scale.macc}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Date -->
    <mat-form-field class="l-font-small l-width-full">
        <mat-label>Angiv dato</mat-label>
        <input autocomplete="off"  [(ngModel)]="date" lang="da" matInput [matDatepicker]="picker" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>

<div class="l-clear">
    <button [disabled]="!scale_macc || !date" (click)="getData()" class="l-float-right l-margin-vertical-large l-leftmargin-small l-padding-horizontal-semi-large l-padding-vertical-large l-font-medium l-button-dark"><i class="fas fa-pen button-icon-large"></i><label>Hent data</label></button>
    <button (click)="closeView()" class="l-float-right l-margin-vertical-large l-leftmargin-small l-padding-horizontal-semi-large l-padding-vertical-large l-font-medium l-button-dark"><i class="fas fa-pen button-icon-large"></i><label>Annuller</label></button>
</div>