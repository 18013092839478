import { Injectable } from '@angular/core'
import moment from 'moment'
import { RestApiService } from '../../services/rest-api.service'
import { Tracking } from './tracking.model'
import { map, tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'
import { ITracking, ITrackingGroup } from '../../global.models'
import { TrackingGroup } from './tracking-group.model'
import { ScalesService } from '../scale/scales.service'

@Injectable({
  providedIn: 'root'
})
export class TrackingsService {
  public trackings: Tracking[] = []
  public trackingGroups: TrackingGroup[] = []

  constructor(private restApiService: RestApiService, private _scalesService: ScalesService) {}

  public getAdminTrackings(params: object): Observable<ITracking[] | number> {
    return this.restApiService.getAdminTrackings(params)
  }

  public loadTracking(trackingId?: string, menuId?: string, trackingTemplateId?: string): Observable<Tracking> {
    let params = {
      fields: 'all'
    }
    if (trackingId) params['id'] = trackingId
    if (menuId) params['menu_id'] = menuId
    if (trackingTemplateId) params['tracking_template_id'] = trackingTemplateId

    return this.restApiService.loadTrackings(params).pipe(
      map((trackings) => trackings[0]),
      tap((tracking) => {
        if (tracking) {
          this.addToTrackings(new Tracking(tracking))
          tracking['scale_dishes']?.forEach((scale_dish_dict) => {
            if (scale_dish_dict['scale']) this._scalesService.addToScales(Object.assign(scale_dish_dict['scale'], { macc: scale_dish_dict['scale_macc'] }))
          })
        }
      }),
      map((tracking) => this.trackingWithId(tracking ? tracking['id'] : null))
    )
  }

  public getTrackingsForMenuTemplateAndDates(menuTemplateId: string, startDate: moment.Moment, endDate: moment.Moment): Observable<ITracking[]> {
    const params = {
      menu_template_id: menuTemplateId,
      start_time: moment(startDate).format('YYYY-MM-DD') + ' 00:00:00',
      end_time: moment(endDate).format('YYYY-MM-DD') + ' 23:59:59',
      fields: 'scale_dishes,bowl_setups'
    }
    return this.restApiService.loadTrackings(params).pipe(tap((trackings) => trackings?.forEach((tracking) => this.addToTrackings(new Tracking(tracking)))))
  }

  public getTrackingGroups(startDate: moment.Moment, endDate: moment.Moment, trackingGroupTemplateId?: string): Observable<TrackingGroup[]> {
    let params = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD')
    }
    if (trackingGroupTemplateId) params['tracking_group_template_id'] = trackingGroupTemplateId
    return this.restApiService.loadTrackingGroups(params).pipe(
      tap((trackingGroups) => trackingGroups?.forEach((trackingGroup) => this.addToTrackingGroups(new TrackingGroup(trackingGroup)))),
      map((trackingGroups) => trackingGroups?.map((trackingGroup) => this.trackingGroupWithId(trackingGroup.id)))
    )
  }

  public saveTrackingGroup(trackingGroup: TrackingGroup): Observable<ITrackingGroup> {
    return this.restApiService.updateTrackingGroups([trackingGroup.asDict]).pipe(
      map((trackingGroupDicts) => trackingGroupDicts[0]),
      tap((trackingGroupDict) => {
        if (trackingGroupDict) this.addToTrackingGroups(trackingGroup)
      })
    )
  }

  public saveTrackingGroups(trackingGroups: TrackingGroup[]): Observable<ITrackingGroup[]> {
    return this.restApiService.updateTrackingGroups(trackingGroups.map((trackingGroup) => trackingGroup.asDict)).pipe(
      tap((trackingGroupDicts) => {
        if (trackingGroupDicts) trackingGroupDicts.forEach((trackingGroupDict) => this.addToTrackingGroups(new TrackingGroup(trackingGroupDict)))
      })
    )
  }

  public createTracking(tracking: Tracking, params): Observable<Tracking> {
    return this.restApiService.createTracking(tracking.asDict, params).pipe(
      tap((tracking_dict) => {
        if (tracking_dict) {
          tracking.patchValue(tracking_dict)
          this.addToTrackings(tracking)
        } else {
          tracking.error = $localize`Kunne ikke gemme servering`
        }
        tracking.saving.setValue(false)
      }),
      map(() => tracking)
    )
  }

  public updateTracking(tracking: Tracking, params): Observable<Tracking> {
    return this.restApiService.updateTracking(tracking.asDict, params).pipe(
      tap((tracking_dict) => {
        if (tracking_dict) {
          tracking.patchValue(tracking_dict)
          this.addToTrackings(tracking)
        } else {
          tracking.error = $localize`Kunne ikke gemme servering`
        }
        tracking.saving.setValue(false)
      }),
      map(() => tracking)
    )
  }

  public deleteTracking(tracking: Tracking, params): Observable<any> {
    params['tracking_id'] = tracking.id
    return this.restApiService.deleteTracking(params).pipe(
      tap((response) => {
        if (!(response instanceof HttpErrorResponse)) {
          this.trackings = this.trackings.filter((s) => s.id !== tracking.id)
        }
      })
    )
  }

  public addToTrackings(tracking: Tracking): void {
    const existing_tracking: Tracking = this.trackingWithId(tracking.id)
    if (!existing_tracking) this.trackings.push(tracking)
    else existing_tracking.patchValue(tracking.asDict)
  }

  public addToTrackingGroups(trackingGroup: TrackingGroup): void {
    const existingTrackingGroup: TrackingGroup = this.trackingGroupWithId(trackingGroup.id)
    if (!existingTrackingGroup) this.trackingGroups.push(trackingGroup)
    else existingTrackingGroup.patchValues(trackingGroup.asDict)
  }

  public trackingGroupWithId(trackingGroupId: string): TrackingGroup {
    return this.trackingGroups.find((trackingGroup) => trackingGroup.id == trackingGroupId)
  }
  public trackingGroupForDateAndTrackingGroupTemplateId(date: moment.Moment, trackingGroupTemplateId: string): TrackingGroup {
    return this.trackingGroups.find((trackingGroup) => trackingGroup.date.isSame(date) && trackingGroup.trackingGroupTemplateId == trackingGroupTemplateId)
  }

  public trackingWithId(trackingId: string): Tracking {
    return this.trackings.find((tracking) => tracking.id == trackingId)
  }

  public trackingsWithMenuDish(menuDishId: string): Tracking[] {
    return this.trackings.filter((tracking) => tracking.scaleDishes.find((scaleDish) => scaleDish.menu_dish_id == menuDishId))
  }
}
