<div class="l-display-flex l-space-between l-position-center-vertically l-topmargin-medium">
    
    <input autocomplete="off"  matInput placeholder="Søg efter kunde" [formControl]="customerSearchControl" class="l-width-300p">

    <div class="add-button-container">
        <button type="button" mat-button class="primary-button" (click)="openOrganization()">
            Tilføj organisation
        </button>
    </div>
</div>

<mat-accordion multi="false" class="l-overflow padding-large l-topmargin-medium l-block">
    <cdk-accordion-item *ngFor="let organization of organizations$ | async | organizationFilterPipe: customerSearchControl.value; let organization_index=index"
            (click)="openOrganization(organization)"
            class="organization-container"
            [id]="'org_'+organization_index">
        
        <div class="l-display-flex l-space-between l-position-center-vertically l-width-full l-padding-large ">
            <div class="organization-name" [ngClass]="{'disabled': !organization.active.value}">
                {{organization.name.value}}
            </div>
            
            <mat-slide-toggle color="primary" [formControl]="organization.active" (click)="$event.stopPropagation()" (change)="organization.save().subscribe();" class="organization-active-toggle">Active</mat-slide-toggle>
        </div>
    </cdk-accordion-item>
</mat-accordion>

<div *ngIf="loadingOrganizations" class="loading-container">
    <mat-spinner [diameter]="15" color="primary"></mat-spinner>
    <label>Loading customers ...</label>
</div>

<div *ngIf="moreOrganizationsAvailable" class="load-more-button-container">
    <button (click)="loadMore()" type="button" mat-button class="secondary-button">
        Load more customers
    </button>
</div>
