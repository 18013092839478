import { FormControl, Validators } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { Names } from '../names/names.model'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { IMenuSectionTemplate } from '../../global.models'
import { MenuTemplatesService } from './menu-templates.service'

export class MenuSectionTemplate {
  public id: string
  public names: Names
  public index: FormControl
  public active: FormControl
  public type: FormControl

  public saving = false

  private _menuTemplatesService: MenuTemplatesService

  constructor(public menuSectionTemplate?: IMenuSectionTemplate) {
    this._menuTemplatesService = FoodopLibModule.injector.get(MenuTemplatesService)

    this.id = menuSectionTemplate?.id
    this.names = new Names(menuSectionTemplate?.names)
    this.index = new FormControl({ value: menuSectionTemplate?.index, disabled: false }, [Validators.required])
    this.active = new FormControl(menuSectionTemplate?.active != undefined ? menuSectionTemplate?.active : true)
    this.type = new FormControl({ value: menuSectionTemplate?.type, disabled: false }, [Validators.required])
  }

  public get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined
  }

  public get asDict(): IMenuSectionTemplate {
    return {
      id: this.id,
      names: this.names.as_dict,
      index: this.index.value,
      active: this.active.value,
      type: this.type.value
    }
  }

  public get namesDict(): IMenuSectionTemplate {
    return {
      id: this.id,
      names: this.names.as_dict
    }
  }

  public isTranslated(language: string) {
    return this.names[language].value ? true : false
  }

  public saveNames(): Observable<MenuSectionTemplate> {
    this.saving = true
    return this._menuTemplatesService.saveMenuSectionTemplateNames(this.namesDict).pipe(
      tap(() => {
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }
}
