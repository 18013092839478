import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { Component, ComponentRef, Input, OnInit, ViewContainerRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Scale, Location, Subsidiary, Organization, OrganizationsService, ScalesService, GlobalFunctionsService, LocationsService } from 'foodop-lib'
import { Observable, Subject, catchError, debounceTime, exhaustMap, filter, merge, of, scan, startWith, switchMap, takeWhile, tap } from 'rxjs'
import { ScaleSwapPickerComponent } from './scale-swap-picker/scale-swap-picker.component'
import { ComponentPortal } from '@angular/cdk/portal'

@Component({
  selector: 'scales-overview',
  templateUrl: './scales-overview.component.html',
  styleUrls: ['./scales-overview.component.css']
})
export class ScalesOverviewComponent implements OnInit {
  @Input() customerSelection: FormControl

  public organizations$: Observable<Organization[]>
  public scales$: Observable<Scale[]>
  public customerSearchControl: FormControl = new FormControl('')
  public scaleSearchControl: FormControl = new FormControl('')
  public loadingOrganizations: boolean = false
  public loadingScales: boolean = false
  public moreScalesAvailable: boolean = false

  private _organizationsOffset: number = 0
  private _scalesOffset: number = 0
  private _loadNextOrganizations$ = new Subject()
  private _loadNextScales$ = new Subject()
  private _organizations: Organization[] = []
  private _overlayRef: OverlayRef

  constructor(public organizationsService: OrganizationsService, public locationsService: LocationsService, private scalesService: ScalesService, public func: GlobalFunctionsService, public overlay: Overlay, public viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.organizations$ = this._loadOrganizations()
    this.scales$ = this._loadScales()
  }

  private get _selectedLocations(): Location[] {
    return this.customerSelection.value.filter((selectionOption: Location | Subsidiary | Organization) => selectionOption.constructor.name == 'Location') as Location[]
  }
  public get selectedLocationIds(): string[] {
    return this._selectedLocations.map((location) => location.id)
  }

  public scaleLocationName(scale: Scale): string {
    return this.locationsService.location_with_id(scale.location_id.value)?.name?.value || 'Unallocated'
  }

  public scaleGatewayID(scale: Scale): string {
    const gateway = this.locationsService.location_with_id(scale.location_id.value).gateways.find((gateway) => gateway.id == scale.gateway_id.value)
    if (!gateway) return 'Unallocated'
    return gateway.id + ' (' + gateway.scales.length + ' vægte allokeret)'
  }

  public subsidiariesBeforeIndex(organizationIndex: number): number {
    return this._organizations
      .slice(0, organizationIndex + 1)
      .map((organization) => organization.subsidiaries.length)
      .reduce((a, b) => a + b, 0)
  }

  public onScroll(): void {
    this._loadNextOrganizations$.next(true)
  }

  public loadMoreScales(): void {
    this._loadNextScales$.next(true)
  }

  public selectLocationForScale(scale: Scale, location?: Location): void {
    scale.location_id.setValue(location?.id)

    if (!location) {
      scale.location_id.setValue(null)
      scale.gateway_id.setValue(null)
      scale.scale_group_id.setValue(null)
      scale.save().subscribe()
    } else {
      // Reset gateway_id and scale_group_id if a new location has been picked:
      if (scale.gateway_id.value) {
        if (!location.gateways.find((gateway) => gateway.id == scale.gateway_id.value)) {
          scale.gateway_id.setValue(null)
        }
      }
      if (scale.scale_group_id.value) {
        if (!location.scale_groups.find((scale_group) => scale_group.id == scale.scale_group_id.value)) {
          scale.scale_group_id.setValue(null)
        }
      }
      // Set gateway_id if only one gateway exists
      if (location.gateways.length == 1) scale.gateway_id.setValue(location.gateways[0].id)
      // Set scale_group_id if only one scale_group exists
      if (location.scale_groups.length == 1) scale.scale_group_id.setValue(location.scale_groups[0].id)
      scale.save().subscribe()
    }
  }

  public openSwapScalesView() {
    let config = new OverlayConfig()
    config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
    config.hasBackdrop = true

    this._overlayRef = this.overlay.create(config)

    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.dispose()
    })

    this._overlayRef.addPanelClass('l-width-70p')
    this._overlayRef.addPanelClass('l-max-height-80p')
    this._overlayRef.addPanelClass('l-min-height-365')
    this._overlayRef.addPanelClass('l-overflow')

    const compRef: ComponentRef<ScaleSwapPickerComponent> = this._overlayRef.attach(new ComponentPortal(ScaleSwapPickerComponent, this.viewContainerRef, this.func.createInjector({})))

    compRef.instance.closeScaleSwapView.subscribe(() => {
      this._overlayRef.dispose()
    })
    compRef.instance.cancel.subscribe(() => {
      this._overlayRef.dispose()
    })
  }

  private _loadOrganizations(): Observable<Organization[]> {
    const searchChanges$ = this.customerSearchControl.valueChanges.pipe(
      startWith(''),
      tap((value) => {
        if (typeof value === 'string') {
          this._organizations = []
          this._organizationsOffset = 0
        }
      }),
      filter((value) => typeof value === 'string' && (value.length >= 2 || value == '')),
      debounceTime(200)
    )

    return searchChanges$.pipe(
      switchMap(() => {
        return this._loadNextOrganizations$.pipe(
          startWith(0),
          tap(() => (this.loadingOrganizations = true)),
          exhaustMap(() => this.organizationsService.loadOrganizations('all', this.customerSearchControl.value.toLowerCase(), this._organizationsOffset)),
          takeWhile((organizations) => organizations.length == 10, true),
          scan((allOrganizations: Organization[], newOrganizations: Organization[]) => allOrganizations.concat(newOrganizations)),
          tap((organizations) => {
            this._organizations = organizations
            this._organizationsOffset += 10
            this.loadingOrganizations = false
          })
        )
      })
    )
  }

  private _loadScales(): Observable<Scale[]> {
    const filterChanges$ = merge(this.scaleSearchControl.valueChanges, this.customerSelection.valueChanges).pipe(
      startWith(''),
      tap(() => (this._scalesOffset = 0))
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextScales$.pipe(
          startWith(0),
          tap(() => (this.loadingScales = true)),
          exhaustMap(() => {
            if (!this._selectedLocations.length && this.scaleSearchControl.value.length <= 1) return of([])
            return this.scalesService.getScales(['locations'], null, null, this.scaleSearchControl.value, this.selectedLocationIds, this._scalesOffset)
          }),
          takeWhile((scales) => {
            this.moreScalesAvailable = scales.length == 10
            return scales.length == 10, true
          }),
          scan((allScales: Scale[], newScales: Scale[]) => allScales.concat(newScales)),
          tap(() => {
            this._scalesOffset += 10
            this.loadingScales = false
          })
        )
      }),
      catchError(() => of([]))
    )
  }
}
