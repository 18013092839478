import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { Names } from '../names/names.model'
import { IRecipeProductionStep } from '../../global.models'

export class RecipeProductionStep {
  index: FormControl
  descriptions: Names

  fb: FormBuilder

  constructor(public production_step?: IRecipeProductionStep) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.index = this.fb.control(production_step?.index)
    this.descriptions = new Names(production_step?.descriptions, 500)
  }

  isDescriptionTranslated(language: string) {
    return this.descriptions[language].value ? true : false
  }

  get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.valid == false) == undefined
  }

  get descriptions_dict(): IRecipeProductionStep {
    return {
      index: this.index.value,
      descriptions: this.descriptions.as_dict
    }
  }
}
