import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { ILanguageSettings } from '../../global.models'

export class LanguageSettings extends FormBuilder {
  preferred: FormControl
  //allowed: FormControl[];

  constructor(public language_settings?: ILanguageSettings) {
    super()
    this.preferred = super.control(language_settings?.preferred)
    //this.allowed = language_settings?.allowed.map(language => super.control(language));
  }

  get as_dict(): ILanguageSettings {
    return {
      preferred: this.preferred.value
      //'allowed': this.allowed.map(language => language.value),
    }
  }
}
