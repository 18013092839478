import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import moment from 'moment'
import { merge, Observable, of as observableOf } from 'rxjs'
import { startWith, switchMap, map, catchError, distinctUntilChanged, filter } from 'rxjs/operators'
import { GatewayComponent } from '../../popups/gateways/gateway/gateway.component'
import { GatewaysService, GlobalFunctionsService, ScaleDishService, ScalesService, TrackingsService, UserService, WebsocketsService } from 'foodop-lib'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ChartWidgetComponentV2 } from '../../popups/chart-widget/chart-widget.component'

@Component({
  selector: 'app-operation-overview',
  templateUrl: './operation-overview.component.html',
  styleUrls: ['./operation-overview.component.css'],
  animations: [trigger('detailExpand', [state('collapsed', style({ height: '0px', minHeight: '0' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])],
  host: {
    class: 'l-margin-horizontal-3rem l-position-relative l-block l-topmargin-large'
  }
})
export class OperationOverviewComponent implements AfterViewInit {
  @ViewChild('sort', { static: true }) sort: MatSort
  @ViewChild(MatPaginator) paginator: MatPaginator

  @ViewChild('scale_dish_sort', { static: false }) scale_dish_sort: MatSort

  search_query = new FormControl()
  date_range: FormGroup = this.fb.group({
    start: [moment(), Validators.required],
    end: [moment(), Validators.required]
  })

  isLoading: Boolean = true
  loadingScaleDishes: Boolean = false

  scale_dish_status: object = {}
  loadingScaleDishesStatus: Boolean = false

  columnsToDisplay: string[] = ['organization_name', 'subsidiary_name', 'location_name', 'tracking_name', 'serving_date', 'serving_time', 'download_time']
  calculatedColumnsToDisplay: string[] = ['status', 'valid']
  otherColumnsToDisplay: string[] = ['link']
  allColumnsToDisplay: string[]
  scaleDishTableColumnsToDisplay: string[] = ['scale_index', 'scale_macc', 'battery', 'seen', 'name', 'status', 'connected', 'started', 'stopped', 'data_uploaded', 'insights_up_to_date', 'valid_insight', 'data', 'gateway', 'gateway_seen']
  loadingColumnsToDisplay = ['spinner']

  expandedServing: any | null

  operationsStatus: Observable<any>

  overlayRef: OverlayRef
  /*********************************/
  /*         INITIALIZATION        */
  /*********************************/
  constructor(
    public websocketsService: WebsocketsService,
    private cd: ChangeDetectorRef,
    public func: GlobalFunctionsService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public userService: UserService,
    private _trackingsService: TrackingsService,
    private scaledishService: ScaleDishService,
    public scalesService: ScalesService,
    private gatewaysService: GatewaysService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {
    this.allColumnsToDisplay = this.columnsToDisplay.concat(this.calculatedColumnsToDisplay).concat(this.otherColumnsToDisplay)
  }

  ngAfterViewInit(): void {
    this.loadOverview()
    this.loadOverviewCount()
    this.loadScaleDishesKPI()
  }

  /*********************************/
  /*         EVENT HANDLING        */
  /*********************************/

  loadScaleDishesKPI(): void {
    merge(this.search_query.valueChanges, this.date_range.get('end').valueChanges)
      .pipe(
        startWith({}),
        filter((change) => this.date_range.get('end').value)
      )
      .subscribe(() => {
        this.loadingScaleDishesStatus = true
        const params = {
          search_query: this.search_query.value || '',
          start_time: this.date_range.get('start').value.format('YYYY-MM-DD') + ' 00:00:00',
          end_time: this.date_range.get('end').value.format('YYYY-MM-DD') + ' 23:59:59',
          fields: 'scale_dish_status',
          aggregation: 'scale_dish_status'
        }
        this.scaledishService.getScaleDishes(params).subscribe((scale_dishes) => {
          this.scale_dish_status = scale_dishes[0]
          this.loadingScaleDishesStatus = false
        })
      })
  }

  loadOverview(): void {
    this.operationsStatus = merge(this.sort.sortChange, this.paginator.page, this.search_query.valueChanges, this.date_range.get('end').valueChanges)
      .pipe(
        startWith({}),
        filter((change) => this.date_range.get('end').value),
        map(() => {
          return {
            sort_property: (this.sort.active == 'serving_date' ? 'serving_time' : this.sort.active) || 'status',
            sort_direction: this.sort.direction || 'asc',
            offset: this.paginator.pageSize * this.paginator.pageIndex,
            limit: this.paginator.pageSize,
            search_query: this.search_query.value || '',
            start_time: this.date_range.get('start').value.format('YYYY-MM-DD') + ' 00:00:00',
            end_time: this.date_range.get('end').value.format('YYYY-MM-DD') + ' 23:59:59',
            format: 'list',
            fields: 'tracking_name,location_name,subsidiary_name,organization_name,status'
          }
        }),
        distinctUntilChanged((prev, curr) => {
          return JSON.stringify(prev) == JSON.stringify(curr)
        })
      )
      .pipe(
        switchMap((params) => {
          this.isLoading = true
          return this._trackingsService.getAdminTrackings(params)
        }),
        map((serving_dicts: object[]) => {
          this.isLoading = false
          return this._configureMatTableDataSources(serving_dicts)
        }),
        catchError(() => {
          this.isLoading = false
          return observableOf([])
        })
      )
  }
  _configureMatTableDataSources(serving_dicts: object[]): MatTableDataSource<object> {
    serving_dicts.forEach((serving_dict) => {
      serving_dict['serving_time'] = moment(moment.utc(serving_dict['serving_time']).format())
      serving_dict['download_time'] = moment(moment.utc(serving_dict['download_time']).format())
    })
    return new MatTableDataSource(serving_dicts)
  }

  toggleServing(selected_tracking: MatTableDataSource<any>) {
    if (this.expandedServing === selected_tracking) this.expandedServing = null
    else {
      this.loadingScaleDishes = true
      selected_tracking['scale_dishes'] = new MatTableDataSource([null])
      this.expandedServing = selected_tracking
      this.cd.detectChanges()
      this.gatewaysService.getGateways([], null, [selected_tracking['location_id']]).subscribe((gateways) => {
        this.func.unique(gateways).forEach((gateway) => gateway.requestMenderInformation().subscribe())
      })
      this._trackingsService.loadTracking(selected_tracking['id']).subscribe((tracking) => {
        selected_tracking['scale_dishes'] = new MatTableDataSource(tracking.scaleDishes.sort((a, b) => (this.scalesService.scale_with_macc(a.scale_macc.value).index.value < this.scalesService.scale_with_macc(b.scale_macc.value).index.value ? -1 : 1)))
        selected_tracking['scale_dishes'].sort = this.scale_dish_sort
        this._initializeScaleDishSorting(selected_tracking['scale_dishes'])
        this.loadingScaleDishes = false
      })
    }
  }

  loadOverviewCount(): void {
    merge(this.search_query.valueChanges, this.date_range.get('end').valueChanges)
      .pipe(
        startWith({}),
        filter((change) => this.date_range.get('end').value)
      )
      .subscribe(() => {
        const params = {
          search_query: this.search_query.value || '',
          start_time: this.date_range.get('start').value.format('YYYY-MM-DD') + ' 00:00:00',
          end_time: this.date_range.get('end').value.format('YYYY-MM-DD') + ' 23:59:59',
          format: 'count'
        }
        this._trackingsService.getAdminTrackings(params).subscribe((count: number) => {
          this.paginator.length = count
        })
      })
  }

  resetPaging(): void {
    this.paginator.pageIndex = 0
  }

  openChefsWorkspace(row: any) {
    console.log('Changing subsidiary')
    this.userService.changeSubsidiary(row['subsidiary_id']).subscribe((response) => {
      if (response) {
        console.log('Successfully changed subsidiary')
        window.open('//portal.foodoptimize.com/da/', '_blank')
      }
    })
  }

  openGatewayView(gateway: object) {
    if (gateway['id']) {
      let config = new OverlayConfig()
      config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
      config.hasBackdrop = true

      this.overlayRef = this.overlay.create(config)

      this.overlayRef.backdropClick().subscribe(() => {
        this.overlayRef.dispose()
      })

      this.overlayRef.addPanelClass('l-width-70p')
      this.overlayRef.addPanelClass('l-height-80p')
      this.overlayRef.addPanelClass('l-overflow')

      const compRef: ComponentRef<GatewayComponent> = this.overlayRef.attach(
        new ComponentPortal(
          GatewayComponent,
          this.viewContainerRef,
          this.func.createInjector({
            gateway: this.gatewaysService.gateway_with_id(gateway['id'])
          })
        )
      )

      compRef.instance.closeGatewayView.subscribe(() => {
        this.overlayRef.dispose()
      })
    }
  }

  showCopiedSnackBar(scale_macc: string) {
    this.snackBar.open('Macc copied (' + scale_macc + ')', null, { duration: 2000 })
  }

  /*********************************/
  /*            GETTERS            */
  /*********************************/

  startedToolTip(): string {
    return (
      this.scale_dish_status['scales_should_be_started'] +
      ' scales should be started \n' +
      (this.scale_dish_status['scales_should_be_started'] - this.scale_dish_status['scales_started']) +
      ' scales not started (' +
      Math.round(((this.scale_dish_status['scales_should_be_started'] - this.scale_dish_status['scales_started']) / this.scale_dish_status['scales_should_be_started']) * 100) +
      '%) \n' +
      (this.scale_dish_status['scales_should_be_started'] - this.scale_dish_status['scales_starting']) +
      ' scales not attempted started (' +
      Math.round(((this.scale_dish_status['scales_should_be_started'] - this.scale_dish_status['scales_starting']) / this.scale_dish_status['scales_should_be_started']) * 100) +
      '%) \n' +
      this.scale_dish_status['scales_failed_start'] +
      ' scales failed start (' +
      Math.round((this.scale_dish_status['scales_failed_start'] / this.scale_dish_status['scales_should_be_started']) * 100) +
      '%)'
    )
  }

  stoppedToolTip(): string {
    return (
      this.scale_dish_status['scales_should_be_stopped'] +
      ' scales should be stopped \n' +
      (this.scale_dish_status['scales_should_be_stopped'] - this.scale_dish_status['scales_stopped']) +
      ' scales not stopped (' +
      Math.round(((this.scale_dish_status['scales_should_be_stopped'] - this.scale_dish_status['scales_stopped']) / this.scale_dish_status['scales_should_be_stopped']) * 100) +
      '%) \n' +
      (this.scale_dish_status['scales_should_be_stopped'] - this.scale_dish_status['scales_stopping']) +
      ' scales not attempted stopped (' +
      Math.round(((this.scale_dish_status['scales_should_be_stopped'] - this.scale_dish_status['scales_stopping']) / this.scale_dish_status['scales_should_be_stopped']) * 100) +
      '%) \n' +
      this.scale_dish_status['scales_failed_stop'] +
      ' scales failed stop (' +
      Math.round((this.scale_dish_status['scales_failed_stop'] / this.scale_dish_status['scales_should_be_stopped']) * 100) +
      '%)'
    )
  }

  statusForServing(_serving: MatTableDataSource<any>): string {
    if (_serving['status'] == '01_starting_not_ok') return '<span class="material-icons icon-false">warning</span> ' + (_serving['total_scale_dishes'] - _serving['scales_starting']) + '/' + _serving['total_scale_dishes'] + ' scales not starting'
    if (_serving['status'] == '02_started_not_ok') return '<span class="material-icons icon-false">warning</span> ' + _serving['scales_started'] + '/' + _serving['total_scale_dishes'] + ' scales started'
    if (_serving['status'] == '03_starting_ok') return '<span class="material-icons">update</span> ' + _serving['scales_started'] + '/' + _serving['total_scale_dishes'] + ' scales starting'
    if (_serving['status'] == '04_planned_near_future_not_ok') return '<span class="material-icons icon-false">warning</span> ' + _serving['scales_sampling'] + '/' + _serving['total_scale_dishes'] + ' scales not ready'
    if (_serving['status'] == '05_stopping_not_ok') return '<span class="material-icons icon-false">warning</span> ' + (_serving['total_scale_dishes'] - _serving['scales_stopping']) + '/' + _serving['total_scale_dishes'] + ' scales not stopping'
    if (_serving['status'] == '06_stopped_not_ok') return '<span class="material-icons icon-false">warning</span> ' + _serving['scales_stopped'] + '/' + _serving['total_scale_dishes'] + ' scales stopped'
    if (_serving['status'] == '07_stopping_ok') return '<span class="material-icons">update</span> ' + _serving['scales_stopped'] + '/' + _serving['total_scale_dishes'] + ' scales stopping'
    if (_serving['status'] == '08_planned_far_future_not_ok') return '<span class="material-icons icon-warn">warning</span> ' + _serving['scales_sampling'] + '/' + _serving['total_scale_dishes'] + ' scales not ready'
    if (_serving['status'] == '09_uploaded_not_ok') return '<span class="material-icons icon-false">warning</span> ' + _serving['scales_data_uploaded'] + '/' + _serving['total_scale_dishes'] + ' scales has uploaded data'
    if (_serving['status'] == '10_processed_not_ok') return '<span class="material-icons icon-warn">warning</span> ' + _serving['scales_insights_up_to_date'] + '/' + _serving['total_scale_dishes'] + ' scales has generated insights'
    if (_serving['status'] == '11_never_started') return '<span class="material-icons icon-false">warning</span> No scales started'
    if (_serving['status'] == '12_started_ok') return '<span class="material-icons icon-true">update</span> ' + _serving['scales_started'] + '/' + _serving['total_scale_dishes'] + ' scales started'
    if (_serving['status'] == '13_planned_ok') return 'Not started yet'
    if (_serving['status'] == '14_ended_ok_not_valid') return '<span class="material-icons icon-warn">warning</span> ' + (_serving['total_scale_dishes'] - _serving['scales_valid_insights']) + '/' + _serving['total_scale_dishes'] + ' scales not valid'
    if (_serving['status'] == '15_ended_ok') return '<span class="material-icons icon-true">warning</span> ' + _serving['scales_valid_insights'] + '/' + _serving['total_scale_dishes'] + ' scales valid'
  }

  statusTooltip(_serving: MatTableDataSource<any>): string {
    let tooltip = ''
    if (_serving['time_status'] == 'far_future' || _serving['time_status'] == 'near_future') {
      tooltip += 'Serving not started yet\n'
      tooltip += 'Planned for ' + _serving['serving_time'].format('HH:mm') + '\n'
    }
    if (_serving['time_status'] == 'just_started') {
      tooltip += 'Starting serving: \n'
    }
    if (_serving['time_status'] == 'started') {
      tooltip += 'Started serving: \n'
    }
    if (_serving['time_status'] == 'just_stopped') {
      tooltip += 'Stopping serving: \n'
    }
    if (_serving['time_status'] == 'stopped') {
      tooltip += 'Stopped serving: \n'
    }
    if ((_serving['time_status'] == 'far_future' || _serving['time_status'] == 'near_future') && _serving['scales_sampling'] > 0) {
      tooltip += _serving['scales_sampling'] + '/' + _serving['total_scale_dishes'] + ' scales currently sampling. Need to stop before ' + _serving['serving_time'].format('HH:mm') + ' to run this serving'
    }
    if (_serving['time_status'] == 'just_started' || _serving['time_status'] == 'started') {
      tooltip += this._tooltip_for_metric(_serving, 'scales_starting', ' scales starting', true)
      tooltip += this._tooltip_for_metric(_serving, 'scales_started', ' scales started', true)
      tooltip += this._tooltip_for_metric(_serving, 'scales_failed_start', ' scales failed to start\n', true)
    }

    if (_serving['time_status'] == 'just_stopped' || _serving['time_status'] == 'stopped') {
      tooltip += this._tooltip_for_metric(_serving, 'scales_started', ' scales started', true)
      tooltip += this._tooltip_for_metric(_serving, 'scales_failed_start', ' scales failed to start\n', true)
      tooltip += this._tooltip_for_metric(_serving, 'scales_stopping', ' scales stopping', true)
      tooltip += this._tooltip_for_metric(_serving, 'scales_stopped', ' scales stopped', true)
      tooltip += this._tooltip_for_metric(_serving, 'scales_failed_stop', ' scales failed to stop', true)
      tooltip += this._tooltip_for_metric(_serving, 'scales_data_uploaded', ' scales has uploaded data', true)
      tooltip += this._tooltip_for_metric(_serving, 'scales_insights_up_to_date', ' scales generated insights', true)
      tooltip += this._tooltip_for_metric(_serving, 'scales_valid_insights', ' scales has valid insights', true)
    }
    return tooltip
  }

  _tooltip_for_metric(_serving, metric: string, explanation: string, new_line: boolean): string {
    return _serving[metric] + '/' + _serving['total_scale_dishes'] + explanation + (new_line ? '\n' : '')
  }

  flagToolTip(scale_dish): string {
    let tooltip = ''
    if (scale_dish['flag_drift']) {
      tooltip += 'Scale drifting\n'
    }
    if (scale_dish['flag_level_shift']) {
      tooltip += 'Scale jumping\n'
    }
    if (scale_dish['flag_no_insights']) {
      tooltip += 'No insights recognized\n'
    }
    if (scale_dish['flag_offset']) {
      tooltip += 'Scale has unusual offset\n'
    }
    return tooltip
  }
  getBatteryIcon(battery) {
    if (battery == null) return 'battery_unknown'
    else if (battery > 90) return 'battery_full'
    else if (battery > 65) return 'battery_full'
    else if (battery > 35) return 'battery_alert'
    else if (battery >= 20) return 'battery_alert'
    else if (battery < 20) return 'battery_alert'
  }

  _initializeScaleDishSorting(dataSource: MatTableDataSource<any>): void {
    dataSource.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'scale_index':
          return this.scalesService.scale_with_macc(item['scale_macc']).index.value
        case 'scale_macc':
          return item['scale_macc']
        case 'battery':
          return this.scalesService.scale_with_macc(item['scale_macc']).battery
        case 'seen':
          return this.scalesService.scale_with_macc(item.scale_macc).time_since_last_interaction
        case 'name':
          return item['names']['da']
        case 'status':
          return this.scalesService.scale_with_macc(item['scale_macc']).sampling
        case 'connected':
          return this.scalesService.scale_with_macc(item.scale_macc).connected
        case 'started':
          return item.stopped
        case 'stopped':
          return item.stopped
        case 'data_uploaded':
          return item.data_uploaded
        case 'insights_up_to_date':
          return item.insights_up_to_date
        case 'valid_insight':
          return item.valid_insight
        case 'gateway':
          return this.scalesService.scale_with_macc(item.scale_macc).gateway_id.value
        case 'gateway_seen':
          return this.scalesService.scale_with_macc(item.scale_macc)?.gateway?.time_since_last_seen_label
      }
    }
  }

  showScaleDishData(scale_dish_row) {
    console.debug('Open data chart for ')

    let config = new OverlayConfig()
    config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
    config.hasBackdrop = true

    this.overlayRef = this.overlay.create(config)

    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose()
    })

    this.overlayRef.addPanelClass('pop-up-1')

    const compRef: ComponentRef<ChartWidgetComponentV2> = this.overlayRef.attach(
      new ComponentPortal(
        ChartWidgetComponentV2,
        this.viewContainerRef,
        this.func.createInjector({
          scale_dish: scale_dish_row
        })
      )
    )

    compRef.instance.closeView.subscribe(() => {
      this.overlayRef.dispose()
    })
  }
}
