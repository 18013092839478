import { Inject, Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import io from 'socket.io-client'
import { Gateway } from '../models/gateways/gateway.model'
import { RestApiService } from './rest-api.service'
import { ENotificationType, INotificationPOSTRequest, IPushNotification } from '../global.types'

@Injectable()
export class WebsocketsService {
  public ws: any
  public connected = new Subject()
  public notification = new Subject()
  public status: boolean

  constructor(private restApiService: RestApiService, @Inject('environment') private environment) {}

  /*********************************************/
  /*                CONNECTION                 */
  /*********************************************/

  connect(namespace) {
    if (this.ws === undefined) {
      this.ws = io(this.environment.wssURL + '/' + namespace, {
        transports: ['websocket'],
        path: '/socket.io'
      })
      console.log('Trying to connect to ' + this.environment.wssURL + '/' + namespace)
      this.ws.connect()
      this.ws.on('connect', () => {
        this.connected.next(true)
        this.status = true
      })
      this.ws.on('disconnect', () => {
        this.connected.next(false)
        this.status = false
      })
      this.ws.on('push-notification', (notification: IPushNotification) => {
        this.notification.next(notification)
      })
    } else {
      console.log('websocket object already exists')
    }
  }

  disconnect() {
    if (this.ws) {
      this.ws.close()
    }
    delete this.ws
    this.connected.next(false)
    this.status = false
    console.log('Real-time service disconnect.')
  }

  /*********************************************/
  /*              COMMUNICATION                */
  /*********************************************/

  getGatewayLogs(gateway) {
    const data: INotificationPOSTRequest = {
      destination: gateway.id,
      notification: {
        type: ENotificationType.GetGatewayLogs,
        source: 'cloud',
        data: {
          date: 'now'
        }
      }
    }
    this.restApiService.postNotification(data).subscribe(
      () => {},
      () => {}
    )
  }

  pingGateway(gateway: Gateway) {
    const data: INotificationPOSTRequest = {
      destination: gateway.id,
      notification: {
        type: ENotificationType.Ping,
        source: 'cloud'
      }
    }
    this.restApiService.postNotification(data).subscribe(
      () => {},
      () => {}
    )
  }
}
