import { IPublicClientApplication, PublicClientApplication, Configuration, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser'
import { MsalInterceptorConfiguration, MsalGuardConfiguration, ProtectedResourceScopes } from '@azure/msal-angular' // Redirect component imported from msal-angular

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message)
}

const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_sign_in'
  },
  authorities: {
    signUpSignIn: {
      authority: 'https://FoodOptimizeB2C.b2clogin.com/FoodOptimizeB2C.onmicrosoft.com/B2C_1_sign_in' // This is the tenant authority
    }
  },
  authorityDomain: 'https://FoodOptimizeB2C.b2clogin.com/FoodOptimizeB2C.onmicrosoft.com'
}

export function MSALInstanceFactory(environment: any) {
  let msalConfig = {
    auth: {
      clientId: '5549f9e7-1242-40a5-9185-1136831eff4e', // This is the ONLY mandatory field that you need to supply.
      authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
      knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
      redirectUri: environment.redirectUri, // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
    },
    system: {
      /**
       * Below you can configure MSAL.js logs. For more information, visit:
       * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
       */
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
          console.log(message)
        },
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false
      }
    }
  }
  return function msalGuardConfig(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig)
  }
}

export function MSALInterceptorConfigFactory(environment) {
  let protectedResources = {
    foodop_api: {
      endpoint: environment.apiEndpoint,
      scopes: {
        read: ['https://FoodOptimizeB2C.onmicrosoft.com/admin-api/api.read'],
        write: ['https://FoodOptimizeB2C.onmicrosoft.com/admin-api/API.write']
      }
    }
  }

  return function msalInterceptorConfiguration(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>()

    protectedResourceMap.set(protectedResources.foodop_api.endpoint, [
      {
        httpMethod: 'GET',
        scopes: [...protectedResources.foodop_api.scopes.read]
      },
      {
        httpMethod: 'POST',
        scopes: [...protectedResources.foodop_api.scopes.write]
      },
      {
        httpMethod: 'PUT',
        scopes: [...protectedResources.foodop_api.scopes.write]
      },
      {
        httpMethod: 'DELETE',
        scopes: [...protectedResources.foodop_api.scopes.write]
      }
    ])

    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    }
  }
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: []
    }
  }
}
