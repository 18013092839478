import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AccessRolesService, AuthService, UserService } from 'foodop-lib'
import moment from 'moment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  loading: boolean = false
  isIframe = false

  constructor(public authService: AuthService, public userService: UserService, private accessRolesService: AccessRolesService, private activatedRoute: ActivatedRoute, private router: Router) {
    moment.locale('da', {
      week: {
        dow: 1
      }
    })
  }

  ngOnInit() {
    this.authService.checkAccount()
    this.authService.subscribeToAuthService()
    this.isIframe = window !== window.parent && !window.opener
    this.loading = true
    this._loadUser()
  }

  _loadUser() {
    this.accessRolesService.loadAccessRoles().subscribe(() => {
      this.userService.loadUser().subscribe((user) => {
        if (user) {
          this.loading = false
        }
      })
    })
  }
}
