import { AbstractControl, FormBuilder, FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { FoodopLibModule } from '../../foodop-lib.module'
import { RestApiService } from '../../services/rest-api.service'
import { Bowl } from '..//bowls/bowl/bowl.model'
import { Names } from '../names/names.model'
import { TrashTemplatesService } from './trash-templates.service'
import { BowlsService } from '../bowls/bowls.service'
import { ITrashTemplate } from '../../global.models'

export class TrashTemplate extends FormBuilder {
  id: string
  names: Names
  descriptions: Names
  index: FormControl

  trash_bowls: Bowl[]

  saved_template: any
  saving = false

  restApiService: RestApiService
  trashTemplatesService: TrashTemplatesService
  bowlsService: BowlsService
  constructor(public trash_template?: ITrashTemplate) {
    super()

    this.restApiService = FoodopLibModule.injector.get(RestApiService)
    this.trashTemplatesService = FoodopLibModule.injector.get(TrashTemplatesService)
    this.bowlsService = FoodopLibModule.injector.get(BowlsService)

    this.id = trash_template?.id
    this.names = new Names(trash_template?.names)
    this.descriptions = new Names(trash_template?.descriptions, 150, false)
    this.index = super.control(trash_template?.index)

    this.trash_bowls = (trash_template?.trash_bowls || []).map((trash_bowl) => {
      const new_bowl = new Bowl(trash_bowl)
      new_bowl.trash_bowl.setValue(true)
      this.bowlsService.addToBowls(new_bowl)
      return new_bowl
    })

    this.saved_template = JSON.stringify(this.as_dict)
  }

  patchValues(trash_template: ITrashTemplate): void {
    if (trash_template?.id) this.id = trash_template.id
    if (trash_template?.names) this.names = new Names(trash_template?.names)
    if (trash_template?.descriptions) this.descriptions = new Names(trash_template?.descriptions)

    if (trash_template?.index) this.index.setValue(trash_template.index)

    if (trash_template?.trash_bowls) this.trash_bowls = (trash_template?.trash_bowls || []).map((trash_bowl) => new Bowl(trash_bowl))

    this.saved_template = JSON.stringify(this.as_dict)
  }

  isNameTranslated(language: string) {
    return this.names[language].value ? true : false
  }
  isDescriptionTranslated(language: string) {
    return this.descriptions[language].value ? true : false
  }

  toggle_trash_bowl_selection(trash_bowl) {
    const selected_trash_bowl_index: number = this.trash_bowls.findIndex((selected_trash_bowl) => selected_trash_bowl.id == trash_bowl.id)
    if (selected_trash_bowl_index >= 0 && !trash_bowl.is_default_selection_for_trash_template(this)) {
      this.trash_bowls.forEach((selected_trash_bowl) => selected_trash_bowl.default.setValue(false))
      this.trash_bowls[selected_trash_bowl_index].default.setValue(true)
    } else if (selected_trash_bowl_index >= 0) {
      this.trash_bowls.splice(selected_trash_bowl_index, 1)
      if (!this.is_selected_trash_bowls_valid && this.trash_bowls.length > 0) this.trash_bowls[0].default.setValue(true)
    } else {
      const new_trash_bowl = new Bowl(trash_bowl.as_dict)
      this.trash_bowls.push(new_trash_bowl)
      if (!this.is_selected_trash_bowls_valid) new_trash_bowl.default.setValue(true)
    }
  }

  get is_changed(): boolean {
    return this.saved_template != JSON.stringify(this.as_dict)
  }
  get is_new(): boolean {
    return this.id == undefined
  }
  get is_valid(): boolean {
    return this.names.da.value || this.names.en.value
  }

  get is_selected_trash_bowls_valid(): boolean {
    return this.trash_bowls.length == 0 || this.trash_bowls.find((trash_bowl) => trash_bowl.default.value) != undefined
  }

  get as_dict(): ITrashTemplate {
    return {
      id: this.id,
      names: this.names.as_dict,
      descriptions: this.descriptions.as_dict,
      trash_bowls: this.trash_bowls.map((trash_bowl) => trash_bowl.as_dict),
      index: this.index.value
    }
  }

  get names_dict(): ITrashTemplate {
    return {
      id: this.id,
      names: this.names.as_dict,
      descriptions: this.descriptions.as_dict
    }
  }

  save(): Observable<TrashTemplate> {
    this.saving = true
    return this.trashTemplatesService.saveTrashTemplate(this).pipe(
      tap((trash_template) => {
        this.id = trash_template.id
        this.saved_template = JSON.stringify(this.as_dict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  saveNames(): Observable<TrashTemplate> {
    this.saving = true
    return this.trashTemplatesService.saveTemplateNames(this).pipe(
      tap((trashg_template) => {
        this.saved_template = JSON.stringify(this.as_dict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }
}
