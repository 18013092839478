import { IMenuPrintTemplateElement } from '../../../global.models'
import { BasicMenuPrintTemplateElement } from './basic-element.model'
import { Names } from '../../names/names.model'
import { MenuPrintTemplateStringElementFormat } from '../formats/string-format.model'
import { Recipe } from '../../recipe/recipe.model'
import { FormControl } from '@angular/forms'
import { tag_categories } from '../../../global.types'

export class MenuPrintTemplateDishTagsElement extends BasicMenuPrintTemplateElement {
  public format: MenuPrintTemplateStringElementFormat
  public prefix: Names
  public separator: FormControl
  public lastSeparator: Names
  public tagCategories: FormControl
  public translatable = false
  public name: string = $localize`Rettags`

  constructor(element: IMenuPrintTemplateElement) {
    super('dishTags')

    this.format = new MenuPrintTemplateStringElementFormat(element?.format)
    this.prefix = new Names(element?.prefix)
    this.separator = new FormControl(element?.separator || ', ')
    this.lastSeparator = new Names(element?.lastSeparator || { en: ' and ', da: ' og ' })
    this.tagCategories = new FormControl(element?.tagCategories || tag_categories.filter((tag_category) => ['dietary', 'meat_type', 'irritant'].includes(tag_category.code)))
  }

  public get asDict(): IMenuPrintTemplateElement {
    return {
      type: this.type,
      format: this.format.asDict,
      prefix: this.prefix.as_dict,
      separator: this.separator.value,
      lastSeparator: this.lastSeparator.as_dict,
      tagCategories: this.tagCategories.value
    }
  }

  public elementString(recipe: Recipe, language: string): string {
    if (recipe.tagsForCategories(this.tagCategories.value).length == 0) return ''
    return (
      (this.prefix[language].value || '') +
      this.func.arrayToString(
        recipe.tagsForCategories(this.tagCategories.value).map((tag) => tag.names[language].value),
        this.separator.value,
        this.lastSeparator[language].value,
        this.format.capitalization.value,
        this.format.capitalization.value
      )
    )
  }
}
