import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ScalesService } from 'foodop-lib'

@Component({
  selector: 'app-scale-swap-picker',
  templateUrl: './scale-swap-picker.component.html',
  styleUrls: ['./scale-swap-picker.component.css'],
  host: {
    class: 'l-position-relative l-width-full l-height-full l-theme-white l-overflow l-box-shadow l-border-radius l-padding-large'
  }
})
export class ScaleSwapPickerComponent implements OnInit {
  @Output() closeScaleSwapView = new EventEmitter()
  @Output() cancel = new EventEmitter<boolean>()

  scale_out: string
  scale_in: string

  swapping: Boolean = false

  constructor(public scalesService: ScalesService, private snackBar: MatSnackBar) {}

  ngOnInit(): void {}

  closeView() {
    this.cancel.emit(true)
  }

  onScaleOutSelected(scale_macc: string) {
    console.log('Selected scale out')
    this.scale_out = scale_macc
  }
  onScaleInSelected(scale_macc: string) {
    console.log('Selected scale in')
    this.scale_in = scale_macc
  }
  swapScales() {
    this.swapping = true
    this.scalesService.swapScales(this.scale_in, this.scale_out).subscribe((response) => {
      if (response) {
        console.log('Swapped scales successfully!')
        this.snackBar.open('Vægte byttet!')
        this.swapping = false
        this.scale_out = null
        this.scale_in = null
        this.cancel.emit(true)
      } else {
        console.log('Failed to swap scales!')
        this.snackBar.open('Noget gik galt - prøv igen!')
        this.swapping = false
      }
    })
  }
}
