import { NgModule } from '@angular/core'

import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment'
import { MatDatetimepickerModule } from '@mat-datetimepicker/core'
import { MatNativeDateModule } from '@angular/material/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatChipsModule } from '@angular/material/chips'
import { MatIconModule } from '@angular/material/icon'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatExpansionModule } from '@angular/material/expansion'
import { OverlayModule } from '@angular/cdk/overlay'
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar, MatSnackBarContainer } from '@angular/material/snack-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatDialogModule } from '@angular/material/dialog'
import { MatTabsModule } from '@angular/material/tabs'
import { MatSelectModule } from '@angular/material/select'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatMenuModule } from '@angular/material/menu'
import { MatStepperModule } from '@angular/material/stepper'
import { MatCardModule } from '@angular/material/card'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { MatButtonModule } from '@angular/material/button'
import { CdkAccordionModule } from '@angular/cdk/accordion'
import { MatSortModule } from '@angular/material/sort'
import { MatSliderModule } from '@angular/material/slider'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatBadgeModule } from '@angular/material/badge'
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { DecimalPipe } from '@angular/common'
import { MatDividerModule } from '@angular/material/divider'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatRadioModule } from '@angular/material/radio'
import { ClipboardModule } from '@angular/cdk/clipboard'
import { NgxColorsModule } from 'ngx-colors'

@NgModule({
  imports: [],
  declarations: [],
  exports: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatDatetimepickerModule,
    MatMomentDatetimeModule,
    MatFormFieldModule,
    MatMomentDateModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    DragDropModule,
    MatExpansionModule,
    OverlayModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatStepperModule,
    MatCardModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatStepperModule,
    MatCardModule,
    ScrollingModule,
    MatButtonModule,
    CdkAccordionModule,
    MatSortModule,
    MatSliderModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatDividerModule,
    MatSidenavModule,
    MatRadioModule,
    ClipboardModule,
    NgxColorsModule
  ],
  providers: [DecimalPipe]
})
export class MatAngularModule {}
