import { Component, EventEmitter, Inject, LOCALE_ID, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, switchMap, filter, map, startWith, tap } from 'rxjs/operators'
import moment from 'moment'
import { IDataInsights, INJECTION_DATA } from 'foodop-lib'
import { DashboardServiceV2 } from './dashboard.service'

@Component({
  selector: 'chart-widget-v2',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.css'],
  host: {
    class: 'l-width-full l-theme-white l-box-shadow l-border-radius l-padding-extra-large l-overflow'
  }
})
export class ChartWidgetComponentV2 implements OnInit {
  @Output() closeView = new EventEmitter()

  show_raw_data: FormControl = new FormControl(false)
  signalReloadUpdateChart = new Date()
  bowlPickerHidden = true
  bowlPickerX = 0
  bowlPickerY = 0
  bowlPicked = null

  scale_dish: any
  scale_dish_data: IDataInsights
  scaleDishDataIsLoading: Boolean

  /*********************************/
  /*         INITIALIZATION        */
  /*********************************/
  constructor(public dashboardService: DashboardServiceV2, @Inject(LOCALE_ID) public language: string, @Inject(INJECTION_DATA) public initData: any) {
    this.scale_dish = initData.scale_dish
  }

  ngOnInit(): void {
    this.dashboardService.loadBowlSetups()
    this.loadGraphData()
    this.listenForRawDataToggle()
    console.log('Scale dish id:', this.scale_dish.id)
  }

  onCloseView(): void {
    this.closeView.emit()
  }

  loadGraphData(): void {
    this.bowlPickerHidden = true
    this.scaleDishDataIsLoading = true
    let data_types = 'presentation_data,bowl_data'
    if (this.show_raw_data) data_types = 'presentation_data,bowl_data,raw_data'
    this.dashboardService.loadGraphDataForScaleDish(this.scale_dish.id, data_types).subscribe((data: any) => {
      if (data) {
        this.scale_dish_data = this.formatDataInsights(data)

        this.scale_dish_data.trash = data.bowls.find((bowl) => bowl.bowl_setup_id) == undefined

        this.scale_dish_data.bowls = this.dashboardService.formatBowlData(data.bowls)
        this.dashboardService.constructChartAreas(this.scale_dish_data)

        this.scaleDishDataIsLoading = false
      }
    })
  }

  formatDataInsights(data: IDataInsights): IDataInsights {
    data.presentation_data.forEach((data_point) => {
      data_point['timestamp'] = this.dashboardService.localizeTimestamp(data_point['timestamp'])
    })
    data.raw_data.forEach((data_point) => {
      data_point['timestamp'] = this.dashboardService.localizeTimestamp(data_point['timestamp'])
    })
    data['download_time'] = this.dashboardService.localizeTimestamp(data['download_time'])
    data['serving_time'] = this.dashboardService.localizeTimestamp(data['serving_time'])
    return data
  }

  onBowlChanged() {
    this.signalReloadUpdateChart = new Date()
  }

  /*********************************/
  /*         EVENT HANDLING        */
  /*********************************/
  listenForRawDataToggle() {
    this.show_raw_data.valueChanges.pipe(filter((selection) => selection)).subscribe(() => {
      if (!this.scale_dish_data.raw_data) {
        let data_types = 'presentation_data,bowl_data'
        this.dashboardService.loadGraphDataForScaleDish(this.scale_dish.id, data_types).subscribe((data: any) => {
          if (data) {
            this.scale_dish_data = this.formatDataInsights(data)

            this.scale_dish_data.trash = data.bowls.find((bowl) => bowl.bowl_setup_id) == undefined

            this.scale_dish_data.bowls = this.dashboardService.formatBowlData(data.bowls)
            this.dashboardService.constructChartAreas(this.scale_dish_data)

            this.scaleDishDataIsLoading = false
          }
        })
      }
    })
  }

  onBowlClicked(event) {
    if (event['data']) {
      this.bowlPickerX = event['posX'] + 30
      this.bowlPickerY = event['posY'] + 30
      this.bowlPicked = event['data']
    }
    this.bowlPickerHidden = event['hidden']
  }
}
