import { FormControl, Validators } from '@angular/forms'
import { Observable, merge } from 'rxjs'
import { tap, map } from 'rxjs/operators'
import { DisplayElementTemplate } from '../display-element-template/display-element-template.model'
import { DisplayFormat } from '../display-format/display-format.model'
import { FoodopLibModule } from '../../foodop-lib.module'
import { IDisplayTemplate } from '../../global.models'
import { allDishDisplayElements } from '../../global.types'
import { DisplayTemplatesService } from './display-templates.service'

export class DisplayTemplate {
  id: string
  name: FormControl

  organization_id: string
  subsidiary_id: string
  location_id: string

  display_format: DisplayFormat

  all_display_element_templates: DisplayElementTemplate[]
  selected_display_element_templates: FormControl

  saved_display_template: any

  is_loading = false
  saving = false

  private _displayTemplatesService: DisplayTemplatesService

  get displayElementContentChanged(): Observable<any> {
    return merge(
      merge(...this.all_display_element_templates.map((display_element_template) => display_element_template.nutrition_types.valueChanges)),
      merge(...this.all_display_element_templates.map((display_element_template) => display_element_template.nutrition_types.valueChanges))
    )
  }

  get displayElementFormatChanged(): Observable<any> {
    return merge(
      merge(...this.selected_display_element_templates.value.map((display_element_template) => display_element_template.min_font_size.valueChanges)),
      merge(...this.selected_display_element_templates.value.map((display_element_template) => display_element_template.max_font_size_original.valueChanges)),
      merge(...this.selected_display_element_templates.value.map((display_element_template) => display_element_template.min_lines.valueChanges)),
      merge(...this.selected_display_element_templates.value.map((display_element_template) => display_element_template.max_lines_original.valueChanges)),
      merge(...this.selected_display_element_templates.value.map((display_element_template) => display_element_template.font_family.valueChanges))
    )
  }

  get changed(): boolean {
    return this.saved_display_template != JSON.stringify(this.as_dict)
  }

  get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined
  }

  get is_new(): boolean {
    return this.id == 'new_scale_display' || this.id == null
  }

  get as_dict(): IDisplayTemplate {
    return {
      id: this.id,
      name: this.name.value,
      display_format: this.display_format.as_dict,
      display_element_templates: this.selected_display_element_templates_sorted.map((display_element_template) => display_element_template.as_dict),
      subsidiary_id: this.subsidiary_id
    }
  }

  get all_display_element_templates_sorted(): DisplayElementTemplate[] {
    return this.all_display_element_templates.sort((a, b) => (a.index > b.index ? 1 : -1))
  }

  get selected_display_element_templates_sorted(): DisplayElementTemplate[] {
    return this.selected_display_element_templates.value.sort((a, b) => (a.row_index.value > b.row_index.value ? 1 : -1))
  }

  constructor(display_template?: IDisplayTemplate, language?: string) {
    this._displayTemplatesService = FoodopLibModule.injector.get(DisplayTemplatesService)

    this.id = display_template?.id
    this.name = new FormControl(display_template && display_template?.name ? display_template.name : language == 'da' ? 'Ny display skabelon' : 'New display template', [Validators.required])

    this.display_format = new DisplayFormat(display_template?.display_format)

    this.selected_display_element_templates = new FormControl(display_template?.display_element_templates.map((display_element_template) => new DisplayElementTemplate(display_element_template)))
    this.all_display_element_templates = allDishDisplayElements.map(
      (default_display_element_template) => this.selected_display_element_templates.value.find((display_element_template) => display_element_template.code == default_display_element_template.code) || new DisplayElementTemplate(default_display_element_template)
    )
    this.selected_display_element_templates.value.forEach((display_element_template, index) => {
      display_element_template.row_index.setValue(index)
    })
    this.setDisplayElementBoundaries()

    this.subsidiary_id = display_template?.subsidiary_id

    this.saved_display_template = JSON.stringify(this.as_dict)
  }

  public restore(): void {
    this.patchValues(JSON.parse(this.saved_display_template))
  }

  patchValues(display_template: IDisplayTemplate): void {
    if (display_template?.id) this.id = display_template?.id
    if (display_template?.display_format) this.display_format = new DisplayFormat(display_template?.display_format)
    if (display_template?.display_element_templates) {
      this.selected_display_element_templates = new FormControl(display_template?.display_element_templates.map((display_element_template) => new DisplayElementTemplate(display_element_template)))
      this.all_display_element_templates = allDishDisplayElements.map(
        (default_display_element_template) => this.selected_display_element_templates.value.find((display_element_template) => display_element_template.code == default_display_element_template.code) || new DisplayElementTemplate(default_display_element_template)
      )
      this.selected_display_element_templates.value.forEach((display_element_template, index) => {
        display_element_template.row_index.setValue(index)
      })
      this.setDisplayElementBoundaries()
    }

    if (display_template?.subsidiary_id) this.subsidiary_id = display_template?.subsidiary_id

    this.saved_display_template = JSON.stringify(this.as_dict)
  }

  save(): Observable<DisplayTemplate> {
    if (this.id == 'new_scale_display') this.id = null
    return this._displayTemplatesService.saveDisplayTemplate(this).pipe(
      tap((saved_display_template) => {
        this.patchValues(saved_display_template)
      }),
      map(() => this)
    )
  }

  reset_display_element_templates_shown_formats(): void {
    this.selected_display_element_templates.value.forEach((display_element_template) => {
      display_element_template.reset_max_font_size()
      display_element_template.reset_max_lines()
    })
  }

  reset_display_element_templates_shown_font_size(): void {
    this.selected_display_element_templates.value.forEach((display_element_template) => {
      display_element_template.reset_font_size()
    })
  }

  setDisplayElementBoundaries(): void {
    this.selected_display_element_templates.value.forEach((display_element_template) => {
      display_element_template.setDisplayElementBoundaries(this)
    })
  }

  reset_display_element_templates_index(): void {
    this.selected_display_element_templates.value.forEach((display_element_template, index) => {
      display_element_template.row_index.setValue(index, { emitEvent: false })
    })
  }
}
