import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler } from '@angular/common/http'
import { of } from 'rxjs'
import { tap } from 'rxjs/operators'
import { CacheMapService } from './cache-map.service'
import { apiURLs } from '../rest-api.service'

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: CacheMapService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRequestCachable(req)) {
      return next.handle(req)
    }
    const cachedResponse = this.cache.get(req)
    if (cachedResponse !== null) {
      return of(cachedResponse)
    }
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.cache.put(req, event)
        }
      })
    )
  }
  private isRequestCachable(req: HttpRequest<any>) {
    let cachable = req.method === 'GET' && Object.values(apiURLs).find((url: string) => req.url.indexOf(url) > -1) != undefined

    if (req.method === 'POST' && req.url.includes('insights')) cachable = true

    return cachable
  }
}
