<mat-accordion multi="true" class="l-overflow" *ngIf="subsidiary">
    <ng-container>
        <mat-expansion-panel *ngFor="let location of subsidiary.locations; let location_index=index"
                [expanded]="panelOpenState[location_index]" 
                (opened)="panelOpenState[location_index] = true;" 
                (closed)="panelOpenState[location_index] = false;">
            
            <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*" class="o-padding-vertical-large o-padding-right-extra-large o-padding-left-large l-position-relative">
                <mat-panel-title>
                    {{location.name.value}} {{location.active.value ? '' : ' (inaktiv)'}}
                </mat-panel-title>
            </mat-expansion-panel-header>
        
            <location [location]="location" class="l-display-flex l-flex-column l-padding-medium"></location>

        </mat-expansion-panel>
    </ng-container>

</mat-accordion>

<div *ngIf="subsidiary.locations.length == 0">
    Ingen lokationer tilføjet
</div>

<button [disabled]="!subsidiary.valid || subsidiary.is_new" type="button" mat-button class="primary-button" (click)="addLocation()">
    Tilføj lokation
</button>