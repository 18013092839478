import { FormControl, Validators } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { DisplayTemplate } from '../display-template/display-template.model'
import { DisplayTemplatesService } from '../display-template/display-templates.service'
import { Observable, merge } from 'rxjs'
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators'
import { ITrackingSectionTemplate, ITrackingTemplate } from '../../global.models'
import { TrackingTemplatesService } from './tracking-templates.service'
import { ScaleAllocation } from './scale-allocation.model'
import { LocationsService } from '../location/locations.service'
import { Location } from '../location/location.model'
import { TrackingSectionTemplate } from './tracking-section-template.model'
import { MenuTemplate } from '../menu-templates/menu-template.model'
import { MenuTemplatesService } from '../menu-templates/menu-templates.service'
import { MenuSectionTemplate } from '../menu-templates/menu-section-template.model'
import moment from 'moment'
import { TrackingGroupTemplate } from './tracking-group-template.model'

export class TrackingTemplate {
  public id: string
  public name: FormControl
  public index: FormControl
  public type: FormControl
  public language: FormControl
  public active: FormControl

  public servingTime: FormControl
  public downloadTime: FormControl
  public timezone: FormControl
  public weekdays: FormControl

  public trackingGroupTemplateId: FormControl
  public changedTrackingGroupTemplateId: FormControl
  public locationId: FormControl
  public menuTemplateId: FormControl
  public scaleAllocations: ScaleAllocation[]
  public displayTemplateId: FormControl
  public trackingSectionTemplates: TrackingSectionTemplate[]
  public saving = false

  private _savedTemplate: any
  private _displayTemplatesService: DisplayTemplatesService
  private _trackingTemplatesService: TrackingTemplatesService
  private _menuTemplatesService: MenuTemplatesService
  private _locationsService: LocationsService

  constructor(public trackingTemplate?: ITrackingTemplate) {
    this._displayTemplatesService = FoodopLibModule.injector.get(DisplayTemplatesService)
    this._trackingTemplatesService = FoodopLibModule.injector.get(TrackingTemplatesService)
    this._menuTemplatesService = FoodopLibModule.injector.get(MenuTemplatesService)
    this._locationsService = FoodopLibModule.injector.get(LocationsService)

    this.id = trackingTemplate?.id
    this.name = new FormControl(trackingTemplate?.name)
    this.index = new FormControl({ value: trackingTemplate?.index || 0, disabled: false }, [Validators.required])
    this.type = new FormControl({ value: trackingTemplate?.type, disabled: false }, [Validators.required])
    this.language = new FormControl(trackingTemplate?.language || 'da')
    this.active = new FormControl(trackingTemplate?.active != undefined ? trackingTemplate?.active : true)

    this.servingTime = new FormControl({ value: moment(trackingTemplate?.serving_time || '06:00', 'HH:mm'), disabled: false }, [Validators.required])
    this.downloadTime = new FormControl({ value: moment(trackingTemplate?.download_time || '14:00', 'HH:mm'), disabled: false }, [Validators.required])
    this.timezone = new FormControl({ value: trackingTemplate?.timezone || 0, disabled: false }, [Validators.required])
    this.weekdays = new FormControl({ value: this._parseWeekdays(trackingTemplate?.weekdays), disabled: false }, [Validators.required])

    this.trackingGroupTemplateId = new FormControl({ value: trackingTemplate?.tracking_group_template_id, disabled: false })
    this.changedTrackingGroupTemplateId = new FormControl({ value: trackingTemplate?.tracking_group_template_id, disabled: false })
    this.locationId = new FormControl({ value: trackingTemplate?.location_id, disabled: false }, [Validators.required])
    this.menuTemplateId = new FormControl({ value: trackingTemplate?.menu_template_id, disabled: false }, [Validators.required])
    this.displayTemplateId = new FormControl({ value: trackingTemplate?.display_template_id, disabled: false }, [Validators.required])

    this._generateScaleAllocations(trackingTemplate)
    this.generateTrackingSectionTemplates(trackingTemplate)

    this._savedTemplate = JSON.stringify(this.asDict)

    this._listenForTrackingGroupTemplateChanges()
    this._listenForLocationChanges()
    this._listenForMenuTemplateChanges()
    this._listenForLanguageChanges()
  }

  public get isNew(): boolean {
    return this.id == undefined
  }

  public get changed(): boolean {
    return this._savedTemplate != JSON.stringify(this.asDict)
  }

  public get scaleAllocationsValid(): boolean {
    return this.type.value == 'menu' || (this.scaleAllocations.find((scaleAllocation) => !scaleAllocation.valid) == undefined && this.scaleAllocations.find((scaleAllocation) => scaleAllocation.configured) != undefined)
  }

  public get trackingSectionTemplatesValid(): boolean {
    return this.type.value == 'waste' || this.trackingSectionTemplates.find((trackingSectionTemplate) => !trackingSectionTemplate.valid) == undefined
  }

  public get valid(): boolean {
    return (
      Object.keys(this)
        .filter((key) => (this.type.value == 'menu' ? null : !['menuTemplateId'].includes(key)))
        .find((key) => this[key]?.invalid == true) == undefined &&
      this.scaleAllocationsValid &&
      this.trackingSectionTemplatesValid
    )
  }

  public get trackingGroupTemplate(): TrackingGroupTemplate {
    return this._trackingTemplatesService.trackingGroupTemplateWithId(this.trackingGroupTemplateId.value)
  }

  public get changedTrackingGroupTemplate(): TrackingGroupTemplate {
    return this._trackingTemplatesService.trackingGroupTemplateWithId(this.changedTrackingGroupTemplateId.value)
  }

  public get displayTemplate(): DisplayTemplate {
    return this._displayTemplatesService.display_template_with_id(this.displayTemplateId.value)
  }

  public get location(): Location {
    return this._locationsService.location_with_id(this.locationId.value)
  }

  public get menuTemplate(): MenuTemplate {
    return this._menuTemplatesService.menuTemplateWithId(this.menuTemplateId.value)
  }

  public get trackingSectionTemplatesSorted(): TrackingSectionTemplate[] {
    return this.trackingSectionTemplates.sort((a, b) => (a.menuSectionTemplate?.index.value > b.menuSectionTemplate?.index.value ? 1 : -1))
  }

  public get selectedWeekdays(): number[] {
    return this.weekdays.value.filter((weekday) => weekday != 'all')
  }

  public get asDict(): ITrackingTemplate {
    return {
      id: this.id,
      name: this.name.value,
      index: this.index.value,
      type: this.type.value,
      language: this.language.value,
      active: this.active.value,
      serving_time: this.servingTime.value.format('HH:mm'),
      download_time: this.downloadTime.value.format('HH:mm'),
      timezone: this.timezone.value,
      weekdays: this.selectedWeekdays,
      tracking_group_template_id: this.changedTrackingGroupTemplateId.value || this.trackingGroupTemplateId.value,
      location_id: this.locationId.value,
      menu_template_id: this.menuTemplateId.value,
      waste_scale_allocations: this.scaleAllocations.filter((scaleAllocation) => scaleAllocation.configured).map((scaleAllocation) => scaleAllocation.asDict),
      display_template_id: this.displayTemplateId.value,
      tracking_section_templates: this.trackingSectionTemplates.map((trackingSectionTemplate) => trackingSectionTemplate.asDict)
    }
  }

  private get _indexDict(): ITrackingTemplate {
    return {
      id: this.id,
      active: this.active.value,
      index: this.index.value
    }
  }

  public restore(): void {
    this.patchValues(JSON.parse(this._savedTemplate))
  }

  public save(): Observable<TrackingTemplate> {
    this.saving = true
    return this._trackingTemplatesService.saveTrackingTemplate(this).pipe(
      tap((trackingTemplate) => {
        this.patchValues(trackingTemplate)
        this._savedTemplate = JSON.stringify(this.asDict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  public patchValues(trackingTemplate?: ITrackingTemplate): void {
    if (trackingTemplate?.id) this.id = trackingTemplate?.id
    if (trackingTemplate?.name) this.name.setValue(trackingTemplate.name)
    if (trackingTemplate?.index != undefined) this.index.setValue(trackingTemplate.index)
    if (trackingTemplate?.type) this.type.setValue(trackingTemplate.type)
    if (trackingTemplate?.language) this.language.setValue(trackingTemplate.language)
    if (trackingTemplate?.active != undefined) this.active.setValue(trackingTemplate.active)

    if (trackingTemplate?.serving_time) this.servingTime.setValue(moment(trackingTemplate?.serving_time, 'HH:mm'))
    if (trackingTemplate?.download_time) this.downloadTime.setValue(moment(trackingTemplate?.download_time, 'HH:mm'))
    if (trackingTemplate?.timezone) this.timezone.setValue(trackingTemplate.timezone)
    if (trackingTemplate?.weekdays) this.weekdays.setValue(this._parseWeekdays(trackingTemplate?.weekdays))

    if (trackingTemplate?.tracking_group_template_id) this.trackingGroupTemplateId.setValue(trackingTemplate.tracking_group_template_id, { emitEvent: false })
    if (trackingTemplate?.location_id) this.locationId.setValue(trackingTemplate.location_id, { emitEvent: false })
    if (trackingTemplate?.menu_template_id) this.menuTemplateId.setValue(trackingTemplate.menu_template_id, { emitEvent: false })

    this._generateScaleAllocations(trackingTemplate)
    this.generateTrackingSectionTemplates(trackingTemplate)

    if (trackingTemplate?.display_template_id) this.displayTemplateId.setValue(trackingTemplate.display_template_id, { emitEvent: false })

    this._savedTemplate = JSON.stringify(this.asDict)
  }

  public trackingSectionTemplateForMenuSectionTemplate(menuSectionTemplate: MenuSectionTemplate): TrackingSectionTemplate {
    return this.trackingSectionTemplates.find((trackingSectionTemplate) => trackingSectionTemplate.menuSectionTemplateId == menuSectionTemplate.id)
  }

  public scaleAllocationForMacc(scaleMacc: string): ScaleAllocation {
    return this.scaleAllocations.find((scaleAllocation: ScaleAllocation) => scaleAllocation.scaleMacc.value == scaleMacc)
  }

  public generateTrackingSectionTemplates(trackingTemplate?: ITrackingTemplate): void {
    this.trackingSectionTemplates = (this.menuTemplate?.menuSectionTemplates || []).map((menuSectionTemplate) => {
      const trackingSectionTemplate: ITrackingSectionTemplate = trackingTemplate?.tracking_section_templates?.find((trackingSectionTemplate) => trackingSectionTemplate.menu_section_template_id == menuSectionTemplate.id)
      return new TrackingSectionTemplate(trackingSectionTemplate || { menu_section_template_id: menuSectionTemplate.id }, this)
    })
  }

  private _generateScaleAllocations(trackingTemplate?: ITrackingTemplate): void {
    this.scaleAllocations = (trackingTemplate?.waste_scale_allocations || []).map((scaleAllocation) => new ScaleAllocation(scaleAllocation, this))
    this.location?.waste_scales?.filter((wasteScale) => !this.scaleAllocationForMacc(wasteScale.macc)).map((waste_scale) => this.scaleAllocations.push(new ScaleAllocation({ scale_macc: waste_scale.macc }, this)))
  }

  private _listenForTrackingGroupTemplateChanges(): void {
    this.trackingGroupTemplateId.valueChanges.subscribe(() => {
      this.patchValues(this._trackingTemplatesService.trackingGroupTemplateWithId(this.trackingGroupTemplateId.value)?.asTrackingTemplateDict)
    })
  }

  private _listenForLocationChanges(): void {
    this.locationId.valueChanges.subscribe(() => {
      this._generateScaleAllocations()
    })
  }

  private _listenForMenuTemplateChanges(): void {
    this.menuTemplateId.valueChanges.subscribe(() => {
      this.generateTrackingSectionTemplates()
    })
  }

  private _listenForLanguageChanges(): void {
    this.language.valueChanges.subscribe(() => {
      this.displayTemplate?.reset_display_element_templates_shown_formats()
    })
  }

  private _parseWeekdays(weekdays?: number[]): any[] {
    if (!weekdays) return [0, 1, 2, 3, 4]
    if (weekdays.length == 7) return ['all'].concat(weekdays as any[])
    return weekdays
  }
}
