import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Observable } from 'rxjs'
import { FormBuilder } from '@angular/forms'
import moment from 'moment'
import { ActivatedRoute, Router } from '@angular/router'
import { BowlsService, EBowlType, GlobalFunctionsService, IBowl, IBowlSetup, LocationsService, RestApiService } from 'foodop-lib'

@Injectable({
  providedIn: 'root'
})
export class DashboardServiceV2 {
  bowl_setups: IBowlSetup[]
  trash_bowls: IBowl[]
  portion_bowl_setups: IBowlSetup[]

  constructor(
    @Inject(LOCALE_ID) public language: string,
    private fb: FormBuilder,
    private func: GlobalFunctionsService,
    private restApiService: RestApiService,
    private route: ActivatedRoute,
    private router: Router,
    private bowlsService: BowlsService,
    private locationsService: LocationsService
  ) {}

  loadGraphDataForScaleDish(scale_dish_id: string, data_types: string): Observable<any> {
    let params = {}
    params['scale_dish_id'] = scale_dish_id
    params['data'] = 'graph_data'
    params['fields'] = data_types
    params['language'] = this.language
    return this.restApiService.loadPresentationDataForScaleDish(params)
  }

  loadBowlSetups() {
    this.bowlsService.getBowlSetupsForSubsidiary(EBowlType.dish, 'bowl_weight').subscribe((bowl_setups) => {
      if (bowl_setups) {
        this.bowl_setups = bowl_setups
      }
    })
    this.bowlsService.getBowlSetupsForSubsidiary(EBowlType.portion, 'bowl_weight').subscribe((bowl_setups) => {
      if (bowl_setups) {
        this.portion_bowl_setups = bowl_setups
      }
    })
    this.bowlsService.getBowlSetupsForSubsidiary(EBowlType.trash, 'bowl_weight').subscribe((bowl_setups) => {
      if (bowl_setups) {
        this.trash_bowls = bowl_setups
      }
    })
  }

  updateBowlSetup(scale_dish_id: string, bowl_index: number, bowl_setup_id: string): Observable<any> {
    const params = {
      scale_dish_id: scale_dish_id,
      bowl_index: bowl_index,
      bowl_setup_id: bowl_setup_id
    }
    return this.restApiService.updateSelectedBowlSetups(params)
  }

  updateTrashBowl(scale_dish_id: string, bowl_index: number, trash_bowl_id: string): Observable<any> {
    const params = {
      scale_dish_id: scale_dish_id,
      bowl_index: bowl_index,
      trash_bowl_id: trash_bowl_id
    }
    return this.restApiService.updateSelectedTrashBowls(params)
  }

  formatBowlData(bowls) {
    bowls.forEach((bowl, bowl_index) => {
      bowl['bowl_start'] = this.localizeTimestamp(bowl['bowl_start'])
      bowl['bowl_end'] = this.localizeTimestamp(bowl['bowl_end'])
      bowl.refills.forEach((refill) => {
        refill['start_timestamp'] = this.localizeTimestamp(refill['start_timestamp'])
        refill['end_timestamp'] = this.localizeTimestamp(refill['end_timestamp'])
      })
    })
    return bowls
  }

  constructChartAreas(scale_dish_data) {
    scale_dish_data.bowls.forEach((bowl, bowl_index) => {
      bowl['bowl_area'] = []
      bowl['eaten_area'] = []
      bowl['refilled_area'] = []
      bowl['waste_area'] = []

      if (bowl['bowl_setup_id']) {
        // Create bowl_area data:
        bowl['bowl_area'] = [
          { timestamp: bowl['bowl_start'], bowl_weight: bowl['bowl_weight'] },
          { timestamp: bowl['bowl_end'], bowl_weight: bowl['bowl_weight'] }
        ]

        bowl.refills.forEach((refill, refill_index) => {
          if (bowl_index == scale_dish_data.bowls.length - 1 && refill_index == bowl.refills.length - 1) {
            // Create waste_area data for last refill in last bowl:
            bowl['waste_area'] = scale_dish_data['presentation_data']
              .filter((d) => new Date(refill['start_timestamp']) <= new Date(d['timestamp']) && new Date(d['timestamp']) <= new Date(refill['end_timestamp']))
              .map((d) => {
                d['final_weight'] = Math.min(bowl['final_weight'], d['weight'])
                d['bowl_weight'] = bowl['bowl_weight']
                return d
              })
          } else {
            // Create refilled_area data:
            let refilled_area = scale_dish_data['presentation_data'].filter((d) => new Date(refill['start_timestamp']) <= new Date(d['timestamp']) && new Date(d['timestamp']) <= new Date(refill['end_timestamp']))
            refilled_area.map((d) => {
              d['eaten_lower_weight'] = refilled_area[refilled_area.length - 2]['weight']
              d['bowl_weight'] = bowl['bowl_weight']
              return d
            })
            bowl['refilled_area'] = bowl['refilled_area'].concat(refilled_area)
          }

          // Create eaten_area data:
          let refill_eaten_area = scale_dish_data['presentation_data'].filter((d) => new Date(refill['start_timestamp']) <= new Date(d['timestamp']) && new Date(d['timestamp']) <= new Date(refill['end_timestamp']))
          refill_eaten_area.map((d) => {
            d['eaten_lower_weight'] = refill_eaten_area[refill_eaten_area.length - 2]['weight']
            return d
          })

          bowl['eaten_area'] = bowl['eaten_area'].concat(refill_eaten_area)
        })
      } else {
        if (bowl['trash_bowl_id']) {
          // Create bowl_area data:
          bowl['bowl_area'] = [
            { timestamp: bowl['bowl_start'], bowl_weight: bowl['bowl_weight'] },
            { timestamp: bowl['bowl_end'], bowl_weight: bowl['bowl_weight'] }
          ]
        }

        // Create waste_area data:
        bowl['waste_area'] = scale_dish_data['presentation_data']
          .filter((d) => new Date(bowl['bowl_start']) <= new Date(d['timestamp']) && new Date(d['timestamp']) <= new Date(bowl['bowl_end']))
          .map((d) => {
            d['final_weight'] = d['weight']
            d['bowl_weight'] = bowl['bowl_weight'] || 0
            return d
          })
      }
    })
  }
  localizeTimestamp(timestamp: string): string {
    var date = moment.utc(timestamp).format('YYYY-MM-DD HH:mm:ss')
    var stillUtc = moment.utc(date).toDate()
    var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss')
    return local
  }
}
