import { IMenuPrintTemplateElement, IName } from '../../../global.models'
import { BasicMenuPrintTemplateElement } from './basic-element.model'
import { Names } from '../../names/names.model'
import { MenuPrintTemplateStringElementFormat } from '../formats/string-format.model'

export class MenuPrintTemplateCustomTextElement extends BasicMenuPrintTemplateElement {
  public format: MenuPrintTemplateStringElementFormat
  public customContent: Names
  public removable = true
  public translatable = true
  public name: IName

  constructor(element: IMenuPrintTemplateElement) {
    super('customString')

    if (element?.removable != undefined) this.removable = element.removable
    if (element?.translatable != undefined) this.translatable = element.translatable
    this.format = new MenuPrintTemplateStringElementFormat(element?.format)
    this.customContent = new Names(element?.customContent)
    this.name = element?.name || this.customContent?.as_dict
  }

  public get asDict(): IMenuPrintTemplateElement {
    return {
      type: this.type,
      format: this.format.asDict,
      customContent: this.customContent.as_dict
    }
  }

  isTranslated(language: string) {
    return this.customContent[language].value ? true : false
  }

  public elementString(language: string): string {
    return this.func.caseString(this.customContent[language].value, this.format.capitalization.value)
  }
}
