import { FormControl, Validators } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { Display } from '../display/display.model'
import { TrashTemplate } from '../trash-template/trash-template.model'
import { TrashTemplatesService } from '../trash-template/trash-templates.service'
import { BowlAssociation } from '../bowls/bowl-association.model'
import { Bowl } from '../bowls/bowl/bowl.model'
import { IDisplay, IScaleAllocation } from '../../global.models'
import { TrackingTemplate } from './tracking-template.model'
import { merge } from 'rxjs'

export class ScaleAllocation {
  public scaleMacc: FormControl
  public trashTemplateId: FormControl

  public display: IDisplay
  public trashBowls: BowlAssociation[]

  private _trashTemplatesService: TrashTemplatesService

  constructor(public scaleAllocation?: IScaleAllocation, public trackingTemplate?: TrackingTemplate) {
    this._trashTemplatesService = FoodopLibModule.injector.get(TrashTemplatesService)

    this.scaleMacc = new FormControl({ value: scaleAllocation?.scale_macc, disabled: false }, [Validators.required])
    this.trashTemplateId = new FormControl({ value: scaleAllocation?.trash_template_id, disabled: false })

    this.display = scaleAllocation?.display
    this.trashBowls = (scaleAllocation?.waste_scale_allocation_trash_bowls || []).map((trash_bowl) => new BowlAssociation({ trash_bowl_id: trash_bowl.id, default: trash_bowl.default }))

    this._listenForTrashTemplateChanges()
    this._listenForTrackingTemplateDisplayChanges()
  }

  public get trashTemplate(): TrashTemplate {
    return this._trashTemplatesService.trash_template_with_id(this.trashTemplateId.value)
  }
  public get valid(): boolean {
    return (Object.keys(this).find((key) => this[key]?.invalid == true) == undefined && this.areSelectedTrashBowlsValid && this.display?.display_components?.length > 0 && this.configured) || !this.configured
  }
  public get configured(): boolean {
    return this.trashTemplate != undefined
  }
  public get asDict(): IScaleAllocation {
    if (this.display && this.scaleMacc) this.display['scale_macc'] = this.scaleMacc?.value
    return {
      scale_macc: this.scaleMacc.value,
      trash_template_id: this.trashTemplateId.value,
      tracking_template_id: this.trackingTemplate.id,
      display: this.display,
      waste_scale_allocation_trash_bowls: this.trashBowls.map((trash_bowl) => trash_bowl.as_trash_bowl_dict)
    }
  }
  public get asDictExclDisplay(): IScaleAllocation {
    let dict = this.asDict
    delete dict.display
    return dict
  }

  public get areSelectedTrashBowlsValid(): boolean {
    return this.trashBowls.length == 0 || this.trashBowls.find((trashBowl) => trashBowl.default.value) != undefined
  }

  public toggleTrashBowlSelection(trash_bowl: Bowl) {
    const selected_trash_bowl_association_index: number = this.trashBowls.findIndex((selected_trash_bowl_association) => selected_trash_bowl_association.trash_bowl_id.value == trash_bowl.id)
    if (selected_trash_bowl_association_index >= 0 && !this.isDefaultTrashBowl(trash_bowl)) {
      this.trashBowls.forEach((selected_trash_bowl) => selected_trash_bowl.default.setValue(false))
      this.trashBowls[selected_trash_bowl_association_index].default.setValue(true)
    } else if (selected_trash_bowl_association_index >= 0) {
      this.trashBowls.splice(selected_trash_bowl_association_index, 1)
      if (!this.areSelectedTrashBowlsValid && this.trashBowls.length > 0) this.trashBowls[0].default.setValue(true)
    } else {
      const new_trash_bowl = new BowlAssociation({ trash_bowl_id: trash_bowl.id, default: !this.areSelectedTrashBowlsValid })
      this.trashBowls.push(new_trash_bowl)
      if (!this.areSelectedTrashBowlsValid && this.trashBowls.length > 0) this.trashBowls[0].default.setValue(true)
    }
  }

  public isDefaultTrashBowl(trashBowl: Bowl): boolean {
    return this.trashBowls.find((selected_trash_bowl_association) => selected_trash_bowl_association.trash_bowl_id.value == trashBowl.id && selected_trash_bowl_association.default.value) == undefined ? false : true
  }

  private _listenForTrashTemplateChanges(): void {
    this.trashTemplateId.valueChanges.subscribe(() => {
      this.trashBowls = this.trashTemplate.trash_bowls.map((trash_bowl) => new BowlAssociation({ trash_bowl_id: trash_bowl.id, default: trash_bowl.default.value }))
      this._updateDisplay()
    })
  }

  private _listenForTrackingTemplateDisplayChanges(): void {
    merge(this.trackingTemplate?.displayTemplateId.valueChanges, this.trackingTemplate?.language.valueChanges).subscribe(() => {
      this._updateDisplay()
    })
  }

  private _updateDisplay(): void {
    if (this.trashTemplateId.value) {
      const display = new Display(this.trackingTemplate?.displayTemplateId, this.trackingTemplate?.language)
      display.reDraw(null, this.trashTemplate, null)
      this.display = display.as_dict
    } else this.display = null
  }
}
