import { Component, EventEmitter, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { Scale, ScalesService } from 'foodop-lib'
import { Observable, Subject, of } from 'rxjs'
import { catchError, exhaustMap, scan, startWith, switchMap, takeWhile, tap } from 'rxjs/operators'

@Component({
  selector: 'scale-picker',
  templateUrl: './scale-picker.component.html',
  styleUrls: ['./scale-picker.component.css']
})
export class ScalePickerComponent {
  @Output() scaleSelected = new EventEmitter<string>()

  public scaleSearchControl: FormControl = new FormControl('')
  public loadingScales: boolean = false
  public moreScalesAvailable: boolean = false

  private _scalesOffset: number = 0
  private _loadNextScales$ = new Subject()

  public scales$: Observable<Scale[]> = this._loadScales()

  constructor(public scalesService: ScalesService) {}

  public scaleSelectedEvent(selection: MatAutocompleteSelectedEvent) {
    this.scaleSelected.emit(selection.option.value.macc)
  }

  public displayFormatter(item: any): string {
    if (item) {
      if (typeof item === 'string') {
        return item
      } else if (item['macc']) {
        return item['macc']
      }
    } else {
      return ''
    }
  }

  public onScroll(): void {
    this._loadNextScales$.next(true)
  }

  private _loadScales(): Observable<Scale[]> {
    const filterChanges$ = this.scaleSearchControl.valueChanges.pipe(
      tap(() => {
        this._scalesOffset = 0
        this.loadingScales = true
      })
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextScales$.pipe(
          startWith(0),
          exhaustMap(() => this.scalesService.getScales([], null, null, this.scaleSearchControl.value, null, this._scalesOffset)),
          takeWhile((scales) => {
            this.moreScalesAvailable = scales.length == 10
            return scales.length == 10, true
          }),
          scan((allScales: Scale[], newScales: Scale[]) => allScales.concat(newScales)),
          tap(() => {
            this._scalesOffset += 10
            this.loadingScales = false
          })
        )
      }),
      catchError(() => of([]))
    )
  }
}
