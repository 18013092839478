import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogData } from '../global.models'

@Component({
  selector: 'dialog-content-example-dialog',
  template:
    '<h1 mat-dialog-title>{{title}}</h1> ' +
    '<div mat-dialog-content>{{content}}</div>' +
    '<div class="l-clear l-topmargin-large l-inline-block-centered">' +
    '<div class="l-overflow l-inline-block">' +
    '<button mat-button [hidden]="option1_value == undefined" (click)="option1()" class="l-margin-medium l-padding-medium l-font-medium l-button-light">{{option1_message}}</button>' +
    '<button mat-button [hidden]="option2_value == undefined" (click)="option2()" class="l-margin-medium l-padding-medium l-font-medium l-button-dark">{{option2_message}}</button>' +
    '<button mat-button [hidden]="option3_value == undefined" (click)="option3()" class="l-margin-medium l-padding-medium l-font-medium l-button-dark">{{option3_message}}</button>' +
    '</div>' +
    '</div>'
})
export class DialogPopUp {
  title: string
  content: string
  option1_message: string
  option2_message: string
  option3_message: string
  option1_value: string
  option2_value: string
  option3_value: string

  constructor(public dialogRef: MatDialogRef<DialogPopUp>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.title = data.title
    this.content = data.content
    this.option1_message = data.option1_message
    this.option2_message = data.option2_message
    this.option3_message = data.option3_message
    this.option1_value = data.option1_value
    this.option2_value = data.option2_value
    this.option3_value = data.option3_value
  }

  option1(): void {
    this.dialogRef.close(this.option1_value)
  }
  option2(): void {
    this.dialogRef.close(this.option2_value)
  }
  option3(): void {
    this.dialogRef.close(this.option3_value)
  }
}
