<mat-card class="l-display-flex">
    <div class="l-display-flex l-space-between l-width-full l-height-50">

        <label class="card_title l-width-full">Driftoverblik</label>

        <!-- Sync -->
        <button type="button" class="l-display-flex l-position-center-vertically l-padding-none l-cursor-pointer">
            <mat-icon *ngIf="!isLoading" matTooltip="Opdater overblik" (click)="loadOverview()"  matTooltipClass="l-text-center" class="l-font-large o-height-large l-line-height-large">sync</mat-icon>
            <mat-spinner *ngIf="isLoading" [diameter]="25" class="o-inline-block l-center-top"></mat-spinner>
        </button>  

    </div>

    <div class="kpi-containers">
      <div class="kpi-container">
        <h1 *ngIf="!isLoading && paginator.length">{{paginator.length | number:'1.0-0':'en'}}</h1>
        <label *ngIf="!isLoading && paginator.length"> servings</label>
        <div *ngIf="isLoading" class="loading-container">
          <label>Loader ...</label>
          <mat-spinner [diameter]="25"></mat-spinner>
        </div>
      </div>

      <div class="kpi-container">
        <h1 *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']">{{scale_dish_status['total_scale_dishes'] | number:'1.0-0':'en'}}</h1>
        <label *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']"> scales configured</label>
        <div *ngIf="loadingScaleDishesStatus" class="loading-container">
          <label>Loader ...</label>
          <mat-spinner [diameter]="25"></mat-spinner>
        </div>
      </div>

      <div class="kpi-container">
        <h1 *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']" [matTooltip]="startedToolTip()" matTooltipClass="l-text-center">{{(scale_dish_status['scales_should_be_started'] ? scale_dish_status['scales_started']/scale_dish_status['scales_should_be_started']*100 : 0) | number:'1.0-1':'en'}}%</h1>
        <label *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']"> scales started</label>
        <div *ngIf="loadingScaleDishesStatus" class="loading-container">
          <label>Loader ...</label>
          <mat-spinner [diameter]="25"></mat-spinner>
        </div>
      </div>

      <div class="kpi-container">
        <h1 *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']" [matTooltip]="stoppedToolTip()" matTooltipClass="l-text-center">{{(scale_dish_status['scales_should_be_stopped'] ? scale_dish_status['scales_stopped']/scale_dish_status['scales_should_be_stopped']*100 : 0)  | number:'1.0-1':'en'}}%</h1>
        <label *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']"> scales stopped</label>
        <div *ngIf="loadingScaleDishesStatus" class="loading-container">
          <label>Loader ...</label>
          <mat-spinner [diameter]="25"></mat-spinner>
        </div>
      </div>

      <div class="kpi-container">
        <h1 *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']" [matTooltip]="scale_dish_status['scales_data_uploaded'] + '/' + scale_dish_status['scales_should_be_stopped']" matTooltipClass="l-text-center">{{(scale_dish_status['scales_should_be_stopped'] ? scale_dish_status['scales_data_uploaded']/scale_dish_status['scales_should_be_stopped']*100 : 0) | number:'1.0-1':'en'}}%</h1>
        <label *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']"> scales uploaded data</label>
        <div *ngIf="loadingScaleDishesStatus" class="loading-container">
          <label>Loader ...</label>
          <mat-spinner [diameter]="25"></mat-spinner>
        </div>
      </div>

      <div class="kpi-container">
        <h1 *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']" [matTooltip]="scale_dish_status['scales_insights_up_to_date'] + '/' + scale_dish_status['scales_should_be_stopped']" matTooltipClass="l-text-center">{{(scale_dish_status['scales_should_be_stopped'] ? scale_dish_status['scales_insights_up_to_date']/scale_dish_status['scales_should_be_stopped']*100 : 0) | number:'1.0-1':'en'}}%</h1>
        <label *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']"> insights generated</label>
        <div *ngIf="loadingScaleDishesStatus" class="loading-container">
          <label>Loader ...</label>
          <mat-spinner [diameter]="25"></mat-spinner>
        </div>
      </div>

      <div class="kpi-container">
        <h1 *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']" [matTooltip]="scale_dish_status['scales_valid_insights'] + '/' + scale_dish_status['scales_should_have_valid_insights']" matTooltipClass="l-text-center">{{(scale_dish_status['scales_should_have_valid_insights'] ? scale_dish_status['scales_valid_insights']/scale_dish_status['scales_should_have_valid_insights']*100 : 0) | number:'1.0-1':'en'}}%</h1>
        <label *ngIf="!loadingScaleDishesStatus && scale_dish_status['total_scale_dishes']"> scales with valid data</label>
        <div *ngIf="loadingScaleDishesStatus" class="loading-container">
          <label>Loader ...</label>
          <mat-spinner [diameter]="25"></mat-spinner>
        </div>
      </div>
    </div>
   

    <mat-form-field appearance="standard" class="l-width-half">
        <mat-label>Filter</mat-label>
        <input autocomplete="off"  matInput [formControl]="search_query" placeholder="Søg efter kunde/lokation" #input>
    </mat-form-field>

    <date-picker [date_range]="date_range"></date-picker>

    <table mat-table #sort="matSort" [dataSource]="operationsStatus | async" class="outer-table l-topmargin-large" multiTemplateDataRows matSort matSortActive="status" matSortDirection="asc">

        <ng-container matColumnDef="organization_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization</th>
          <td mat-cell *matCellDef="let serving"> {{serving['organization_name']}} </td>
        </ng-container>

        <ng-container matColumnDef="subsidiary_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Subsidiary</th>
          <td mat-cell *matCellDef="let serving"> {{serving['subsidiary_name']}} </td>
        </ng-container>

        <ng-container matColumnDef="location_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
          <td mat-cell *matCellDef="let serving"> {{serving['location_name']}} </td>
        </ng-container>

        <ng-container matColumnDef="tracking_name">
          <th mat-header-cell *matHeaderCellDef>Tracking</th>
          <td mat-cell *matCellDef="let serving"> {{serving['tracking_name']}} </td>
        </ng-container>
        
        <ng-container matColumnDef="serving_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="date-column">Date</th>
          <td mat-cell *matCellDef="let serving"> {{serving['serving_time'].format('DD-MM-YYYY')}} </td>
        </ng-container>
        
        <ng-container matColumnDef="serving_time">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="time-column">Start</th>
          <td mat-cell *matCellDef="let serving"> {{serving['serving_time'].format('HH:mm')}} </td>
        </ng-container>
        
        <ng-container matColumnDef="download_time">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="time-column">End</th>
          <td mat-cell *matCellDef="let serving"> {{serving['download_time'].format('HH:mm')}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="serving-status-column">Status</th>
            <td mat-cell *matCellDef="let serving" [matTooltip]="statusTooltip(serving)" >
              <div class="l-display-flex l-position-center-vertically" [innerHTML]="statusForServing(serving)"></div>
            </td>
        </ng-container>

        <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef class="link-column"></th>
            <td mat-cell *matCellDef="let serving_row" matTooltip="Åben servering" class="l-cursor-pointer" (click)="openChefsWorkspace(serving_row)">Åben servering</td>
        </ng-container>
        

        <ng-container matColumnDef="valid">
          <th mat-header-cell *matHeaderCellDef class="state_column">Valid</th>
          <td mat-cell *matCellDef="let serving_row">
            <span *ngIf="serving_row.valid"class="material-icons icon-true">check_circle</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedServing">
            <td mat-cell *matCellDef="let serving_row" [attr.colspan]="allColumnsToDisplay.length">
              <div class="element-detail" *ngIf="serving_row == expandedServing" [@detailExpand]="serving_row == expandedServing ? 'expanded' : 'collapsed'">
                <table #scaleDishTables mat-table #scale_dish_sort="matSort" matSort matSortActive="scale_index" matSortDirection="asc" [dataSource]="expandedServing.scale_dishes" class="scale-dish-table" *ngIf="expandedServing">

                  <ng-container matColumnDef="spinner">
                    <td mat-cell *matCellDef="let scale_dish_row" [attr.colspan]="allColumnsToDisplay.length">
                      <div class="loading-overlay">
                        <label>Loader ...</label>
                        <mat-spinner [diameter]="25"></mat-spinner>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="scale_index">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="scale_column">Scale</th>
                    <td mat-cell *matCellDef="let scale_dish_row">Scale {{ scale_dish_row.scale.index.value }}</td>
                  </ng-container>

                  <ng-container matColumnDef="scale_macc">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="macc_column">Macc</th>
                    <td mat-cell *matCellDef="let scale_dish_row" class="l-cursor-pointer" [cdkCopyToClipboard]="scale_dish_row.scale_macc.value" (click)="showCopiedSnackBar(scale_dish_row.scale_macc.value)">{{ scale_dish_row.scale_macc.value }}</td>
                  </ng-container>

                  <ng-container matColumnDef="name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef >
                      <div class="dish_name_column">
                        Ret/Affaldstype
                      </div>
                    </th>
                    <td mat-cell *matCellDef="let scale_dish_row">
                      <div class="dish_name_column">
                        {{ scale_dish_row.scaleDish?.names ? scale_dish_row.scaleDish?.names['da'] : ''}} / {{scale_dish_row.scaleDish?.names ? scale_dish_row.scaleDish?.names['en'] : ''}}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="status_column">Status</th>
                    <td mat-cell *matCellDef="let scale_dish_row">{{ scale_dish_row.scale.sampling ? 'Sampling' : scale_dish_row.scale.sleeping ? 'Sleeping' : 'Sampling' }}</td>
                  </ng-container>

                  <ng-container matColumnDef="battery">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="battery_column">Battery</th>
                    <td mat-cell *matCellDef="let scale_dish_row">
                      <div class="icon-cell-container">
                        <mat-icon class="scale-battery-icon" [ngClass]="{'l-color-dimgrey': scale_dish_row.scale.battery == null,
                        'l-color-green': scale_dish_row.scale.battery>90,
                        'l-color-lightgreen': (scale_dish_row.scale.battery>65 && scale_dish_row.scale.battery<=90),
                        'l-color-yellow': (scale_dish_row.scale.battery>35 && scale_dish_row.scale.battery<=65),
                        'l-color-orange': (scale_dish_row.scale.battery>20 && scale_dish_row.scale.battery<=35),
                        'l-color-red': (scale_dish_row.scale.battery<=20)}"> {{getBatteryIcon(scale_dish_row.scale.battery)}}</mat-icon> 
                        <span>{{ scale_dish_row.scale.battery }} %</span>
                      </div>
                    </td> 
                  </ng-container>

                  <ng-container matColumnDef="seen">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="last_seen_column">Last scale update</th>
                    <td mat-cell *matCellDef="let scale_dish_row" class="last_seen_column">
                      <div class="icon-cell-container">
                        <span *ngIf="scale_dish_row.scale.minutes_since_last_interaction>24*60" class="material-icons icon-false">warning</span>
                        <span>{{ scale_dish_row.scale.time_since_last_interaction }}</span>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="connected">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="state_column">Connected</th>
                    <td mat-cell *matCellDef="let scale_dish_row">
                      <span *ngIf="scale_dish_row.scale.connected" class="material-icons icon-true">check_circle</span>
                    </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="started">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="state_column">Started</th>
                    <td mat-cell *matCellDef="let scale_dish_row">
                      <div class="icon-cell-container">
                        <span *ngIf="scale_dish_row.starting && !scale_dish_row.started && !scale_dish_row.failed_start" [matTooltip]="scale_dish_row.starting.format('HH:mm')" matTooltipClass="l-text-center" class="material-icons">schedule</span>
                        <span *ngIf="scale_dish_row.started" [matTooltip]="scale_dish_row.started.format('HH:mm')" matTooltipClass="l-text-center" class="material-icons icon-true">check_circle</span>
                        <span *ngIf="!scale_dish_row.started && scale_dish_row.failed_start" [matTooltip]="'Scale failed to start\n' + scale_dish_row.failed_start.format('HH:mm')" matTooltipClass="l-text-center" class="material-icons icon-false">warning</span>
                        <span *ngIf="!scale_dish_row.started && scale_dish_row.failed_start_out_of_reach" [matTooltip]="'Scale out of reach\n' + scale_dish_row.failed_start_out_of_reach.format('HH:mm')" matTooltipClass="l-text-center" class="material-icons icon-false">bluetooth_disabled</span>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="stopped">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="state_column">Stopped</th>
                    <td mat-cell *matCellDef="let scale_dish_row">
                      <div class="icon-cell-container">
                        <span *ngIf="scale_dish_row.stopping && !scale_dish_row.stopped && !scale_dish_row.failed_stop" [matTooltip]="scale_dish_row.stopping.format('HH:mm')" matTooltipClass="l-text-center" class="material-icons">schedule</span>
                        <span *ngIf="scale_dish_row.stopped" [matTooltip]="scale_dish_row.stopped.format('HH:mm')" matTooltipClass="l-text-center" class="material-icons icon-true">check_circle</span>
                        <span *ngIf="!scale_dish_row.stopped && scale_dish_row.failed_stop" [matTooltip]="'Scale failed to stop\n' + scale_dish_row.failed_stop.format('HH:mm')" matTooltipClass="l-text-center" class="material-icons icon-false">warning</span>
                        <span *ngIf="!scale_dish_row.stopped && scale_dish_row.failed_stop_out_of_reach" [matTooltip]="'Scale out of reach\n' + scale_dish_row.failed_stop_out_of_reach.format('HH:mm')" matTooltipClass="l-text-center" class="material-icons icon-false">bluetooth_disabled</span>
                      </div>
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="data_uploaded">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="state_column">Data uploaded</th>
                    <td mat-cell *matCellDef="let scale_dish_row">
                      <span *ngIf="scale_dish_row.data_uploaded"class="material-icons icon-true">check_circle</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="insights_up_to_date">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="state_column">Insights</th>
                    <td mat-cell *matCellDef="let scale_dish_row" >
                      <span *ngIf="scale_dish_row.insights_up_to_date" class="material-icons icon-true">check_circle</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="valid_insight">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="state_column">Data</th>
                    <td mat-cell *matCellDef="let scale_dish_row">
                      <span *ngIf="scale_dish_row.type == 'display'" matTooltip="Display scale" matTooltipClass="l-text-center" class="material-icons">dvr</span>
                      <span *ngIf="scale_dish_row.type != 'display' && scale_dish_row.valid_insight" matTooltip="Valid" matTooltipClass="l-text-center" class="material-icons icon-true">check_circle</span>
                      <span *ngIf="scale_dish_row.type != 'display' && (scale_dish_row.flag_drift || scale_dish_row.flag_level_shift || scale_dish_row.flag_no_insights || scale_dish_row.flag_offset)" [matTooltip]="flagToolTip(scale_dish_row)" matTooltipClass="l-text-center" class="material-icons" [ngClass]="{'icon-warn': (scale_dish_row.flag_no_insights || scale_dish_row.flag_offset) && !scale_dish_row.flag_drift && !scale_dish_row.flag_level_shift, 'icon-false':scale_dish_row.flag_drift || scale_dish_row.flag_level_shift}">flag</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="data">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="state_column">Data</th>
                    <td mat-cell *matCellDef="let scale_dish_row">
                      <mat-icon *ngIf="scale_dish_row.data_uploaded && scale_dish_row.type != 'display'" (click)="showScaleDishData(scale_dish_row)" i18n-matTooltip matTooltip="Vis data" matTooltipClass="l-text-center" class="chart-icon">show_chart</mat-icon>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="gateway">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="gateway_column">Gateway</th>
                    <td mat-cell *matCellDef="let scale_dish_row" [ngClass]="{'l-cursor-pointer':scale_dish_row.scale.gateway_id.value}" (click)="openGatewayView(scale_dish_row.scale.gateway)">
                      <div class="icon-cell-container">
                        <span *ngIf="!scale_dish_row.scale.gateway_id.value" class="material-icons icon-false">warning</span>
                        <span *ngIf="!scale_dish_row.scale.gateway_id.value">Unallocated</span>
                        <span *ngIf="scale_dish_row.scale.gateway_id.value">{{ scale_dish_row.scale.gateway_id.value}}</span>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="gateway_seen">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="last_seen_column">Last gateway interaction</th>
                    <td mat-cell *matCellDef="let scale_dish_row" class="last_seen_column">
                      <div class="icon-cell-container" *ngIf="scale_dish_row.scale.gateway_id.value">
                        <span *ngIf="!scale_dish_row.scale?.gateway?.online" class="material-icons icon-false">warning</span>
                        <span *ngIf="scale_dish_row.scale.gateway_id.value">{{ scale_dish_row.scale?.gateway?.time_since_last_seen_label }}</span>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="scaleDishTableColumnsToDisplay; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: loadingScaleDishes ? loadingColumnsToDisplay: scaleDishTableColumnsToDisplay"></tr>
                </table>
              </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="allColumnsToDisplay"></tr>
        <tr mat-row *matRowDef="let serving_row; columns: allColumnsToDisplay;" class="element-row" [class.expanded-row]="expandedServing === serving_row" (click)="toggleServing(serving_row)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedServing']" class="detail-row"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell">No servings "{{input.value ? 'for ' + input.value : ''}}"</td>
        </tr>
    </table>

    <div *ngIf="isLoading" class="loading-overlay">
      <label>Loader ...</label>
      <mat-spinner [diameter]="25"></mat-spinner>
    </div>

    <!---->
    <div [hidden]="(operationsStatus | async)?.length<=10">
        <mat-paginator #paginator [pageSize]="30"></mat-paginator>
    </div>

</mat-card>