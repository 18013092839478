import { IMenuPrintTemplateElement } from '../../../global.models'
import { BasicMenuPrintTemplateElement } from './basic-element.model'
import { MenuPrintTemplateStringElementFormat } from '../formats/string-format.model'
import { FormControl } from '@angular/forms'
import moment from 'moment'
import { Names } from '../../names/names.model'

export class MenuPrintTemplateWeekLabelElement extends BasicMenuPrintTemplateElement {
  public format: MenuPrintTemplateStringElementFormat
  public dateFormat: FormControl
  public prefix: Names
  public suffix: Names
  public removable = true
  public translatable = false
  public name: string = $localize`Week label`

  constructor(element: IMenuPrintTemplateElement) {
    super('weekLabel')

    this.format = new MenuPrintTemplateStringElementFormat(element?.format)
    this.dateFormat = new FormControl(element?.dateFormat || 'W')
    this.prefix = new Names(element?.prefix || { en: 'Week ', da: 'Uge ' }, null, false)
    this.suffix = new Names(element?.suffix || { en: '', da: '' }, null, false)
    this._listenForDateFormatChanges()
  }

  public get asDict(): IMenuPrintTemplateElement {
    return {
      type: this.type,
      format: this.format.asDict,
      dateFormat: this.dateFormat.value
    }
  }
  private _listenForDateFormatChanges() {
    this.dateFormat?.valueChanges.subscribe(() => {
      this.dateFormat?.setValue(this.dateFormat?.value)
    })
  }
  public elementString(startDate: FormControl, endDate: FormControl, language: string): string {
    moment.locale(language, {
      week: {
        dow: 1
      }
    })
    let dateString = moment(startDate.value).format(this.dateFormat.value)
    if (this.dateFormat.value.toLowerCase().includes('d')) dateString += ' - ' + moment(endDate.value).format(this.dateFormat.value)
    return (this.prefix[language].value || '') + this.func.caseString(dateString, this.format.capitalization.value) + (this.suffix[language].value || '')
  }
}
