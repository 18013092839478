import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { User } from './user.model'
import { IUser } from '../../global.models'
import { RestApiService } from '../../services/rest-api.service'
import { OrganizationsService } from '../organization/organizations.service'
import { LocationsService } from '../location/locations.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User
  users: User[] = []

  constructor(private restApiService: RestApiService, private subsidiaryService: SubsidiaryService, private organizationService: OrganizationsService, private locationsService: LocationsService) {}

  loadUser(): Observable<IUser> {
    const params = {
      fields: 'all'
    }
    return this.restApiService.getProfileV3(params).pipe(
      tap((user: IUser) => {
        if (user) {
          this.user = new User(user)
          user.subsidiary_accesses.forEach((subsidiary) => this.subsidiaryService.addToSubsidiaries(subsidiary))
        }
      })
    )
  }

  saveProfile(profile: IUser): Observable<IUser> {
    return this.restApiService.saveProfile(profile)
  }

  loadUsers(fields?: string[], search_string?: string, subsidiaryIds?: string[], offset?: number): Observable<User[]> {
    let params = {}
    if (fields) params['fields'] = fields.join(',')
    if (search_string) params['search_string'] = search_string
    if (subsidiaryIds?.length) params['subsidiary_ids'] = subsidiaryIds.join(',')
    if (offset != undefined) {
      params['limit'] = 10
      params['offset'] = offset
    }
    return this.restApiService.getAllUsers(params).pipe(
      tap((response: object) => {
        response['organizations'].forEach((organization) => {
          this.organizationService.addToOrganizations(organization)
          organization.subsidiaries?.forEach((subsidiary) => {
            this.subsidiaryService.addToSubsidiaries(Object.assign(subsidiary, { organization_id: organization.id }))
            subsidiary.locations?.forEach((location) => this.locationsService.addToLocations(Object.assign(location, { subsidiary_id: subsidiary.id })))
          })
        })
        response['users'].forEach((user) => this.addToUsers(user))
        this.users = this.users.sort((a, b) => (a.first_name.value > b.first_name.value ? 1 : -1))
      }),
      map((response) => {
        return response['users'].map((user) => this.user_with_id(user.id))
      })
    )
  }

  saveUser(user: User): Observable<IUser> {
    if (user.is_new) this.restApiService.createUser(user.as_dict)
    else return this.restApiService.updateUser(user.as_dict)
  }

  addToUsers(user: IUser): void {
    let existing_user: User = this.user_with_id(user.id)
    if (!existing_user) this.users.push(new User(user))
  }

  user_with_id(user_id: string): User {
    return this.users.find((user) => user.id.value == user_id)
  }

  changeSubsidiary(subsidary_id: string): Observable<any> {
    this.user.subsidiary_id.setValue(subsidary_id)
    return this.restApiService.updateUser(this.user.as_dict)
  }
}
