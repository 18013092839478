import { MenuPrintTemplatePageFormat } from '../menu-print-template/formats/page-format.model'
import { PrintDocument } from '../print-document/print-document.model'
import { PrintPageImageElement } from './print-page-elements/image-element.model'
import { PrintPageStringElement } from './print-page-elements/string-element.model'

export class PrintPage {
  headerElements: PrintPageStringElement[]
  bodyElements: (PrintPageStringElement | PrintPageStringElement[][])[]
  footerElements: PrintPageStringElement[]
  imageElements: PrintPageImageElement[]
  contentHeight: number

  constructor(public document: PrintDocument) {
    this.render()
  }

  public get bodyContentDOMHeight(): number {
    return this.document.DOMHeight - this._headerDOMHeight - this._footerDOMHeight - this._marginsDOMHeight(this.document.menuPrintTemplate.template.body.format)
  }

  public get bodyContentDOMWidth(): number {
    return this.document.DOMWidth - this._marginsDOMWidth(this.document.menuPrintTemplate.template.body.format)
  }

  public get columnDOMWidth(): number {
    return (this.bodyContentDOMWidth - this.document.scaledDistance(this.document.menuPrintTemplate.template.pageFormat.columnGap.value) * (this.document.menuTemplate.selectedWeekdays.length - 1)) / this.document.menuTemplate.selectedWeekdays.length
  }

  public get bodyColumnStyle(): any {
    return {
      gap: this.document.percentageDistance(this.document.menuPrintTemplate.template.body.format.columnGap.value) + '%'
    }
  }
  public get headerStyle(): any {
    return this._pageAreaStyle(this.document.menuPrintTemplate.template.header.format)
  }
  public get bodyStyle(): any {
    return this._pageAreaStyle(this.document.menuPrintTemplate.template.body.format)
  }
  public get footerStyle(): any {
    return this._pageAreaStyle(this.document.menuPrintTemplate.template.footer.format)
  }

  public render() {
    this.headerElements = this.document.generatePrintPageElementsFromTemplate(this.document.menuPrintTemplate.template.header.elements) as PrintPageStringElement[]
    this.bodyElements = []
    this.footerElements = this.document.generatePrintPageElementsFromTemplate(this.document.menuPrintTemplate.template.footer.elements) as PrintPageStringElement[]
    this.imageElements = (this.document.menuPrintTemplate.template.imageElements || []).map((element) => new PrintPageImageElement(element, this.document))
    this.contentHeight = 0
  }

  private get _headerDOMHeight(): number {
    return this.headerElements.reduce((acc, element) => acc + element.componentDOMSize, 0) + this._marginsDOMHeight(this.document.menuPrintTemplate.template.header.format)
  }
  private get _footerDOMHeight(): number {
    return this.footerElements.reduce((acc, element) => acc + element.componentDOMSize, 0) + this._marginsDOMHeight(this.document.menuPrintTemplate.template.footer.format)
  }

  private _marginsDOMHeight(format: MenuPrintTemplatePageFormat): number {
    return this.document.scaledDistance(format.marginTop.value) + this.document.scaledDistance(format.marginBottom.value)
  }
  private _marginsDOMWidth(format: MenuPrintTemplatePageFormat): number {
    return this.document.scaledDistance(format.marginLeft.value) + this.document.scaledDistance(format.marginRight.value)
  }

  private _pageAreaStyle(format: MenuPrintTemplatePageFormat): any {
    return {
      'background-color': format.backgroundColor.value,
      'padding-top': this.document.percentageDistance(format.marginTop.value) + '%',
      'padding-right': this.document.percentageDistance(format.marginRight.value) + '%',
      'padding-bottom': this.document.percentageDistance(format.marginBottom.value) + '%',
      'padding-left': this.document.percentageDistance(format.marginLeft.value) + '%'
    }
  }
}
