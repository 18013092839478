import { FormControl, Validators } from '@angular/forms'
import { Scale } from '..//scale/scale.model'
import { Tracking } from '../tracking/tracking.model'
import { IScaleGroup } from '../../global.models'

export const scale_group_types = [
  { code: 'dish', label: $localize`Buffetvægte` },
  { code: 'display', label: $localize`Displayvægte` },
  { code: 'trash', label: $localize`Affaldsvægte` }
]

export class ScaleGroup {
  public id: string

  public name: FormControl

  public location_id: FormControl
  public index: FormControl
  public type: FormControl

  public scales: Scale[]

  private _savedScaleGroup: any

  constructor(public scaleGroup?: IScaleGroup) {
    this.id = scaleGroup?.id
    this.name = new FormControl({ value: scaleGroup?.name, disabled: false }, [Validators.required])
    this.index = new FormControl({ value: scaleGroup?.index, disabled: false }, [Validators.required])
    this.type = new FormControl({ value: scaleGroup?.type, disabled: false }, [Validators.required])

    this.scales = (scaleGroup?.scales || []).map((scale) => new Scale(scale, this))

    this._savedScaleGroup = JSON.stringify(this.as_dict)
  }

  public get changed(): boolean {
    return this._savedScaleGroup != JSON.stringify(this.as_dict)
  }

  public get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined
  }

  public get sorted_scales(): Scale[] {
    return this.scales.sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }

  public available_scales_for_serving(tracking: Tracking): Scale[] {
    return this.scales.filter((scale) => !scale.scale_dish_for_serving(tracking))
  }

  public get as_dict(): IScaleGroup {
    return {
      id: this.id,
      name: this.name.value,
      index: this.index.value,
      type: this.type.value,
      scales: this.scales.map((scale) => scale.as_dict)
    }
  }
}
