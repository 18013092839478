import { FormBuilder, FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { FoodopLibModule } from '../../../foodop-lib.module'
import { TrashTemplate } from '../../trash-template/trash-template.model'
import { BowlsService } from '../bowls.service'
import { IBowl } from '../../../global.models'
import { SubsidiaryService } from '../../subsidiary/subsidiary.service'
import { ScaleAllocation } from '../../tracking-templates/scale-allocation.model'
import { EBowlType } from '../../../global.types'

export class Bowl {
  id: string

  name: FormControl
  index: FormControl
  weight: FormControl

  portion_bowl: FormControl
  trash_bowl: FormControl
  default: FormControl

  saved_bowl: any
  saving: FormControl = new FormControl(false)

  bowlsService: BowlsService
  subsidiaryService: SubsidiaryService
  fb: FormBuilder

  constructor(bowl: IBowl) {
    this.bowlsService = FoodopLibModule.injector.get(BowlsService)
    this.subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.id = bowl?.id

    this.name = this.fb.control(bowl?.name)
    this.index = this.fb.control(bowl?.index)
    this.weight = this.fb.control(bowl?.weight)
    this.portion_bowl = this.fb.control(bowl?.portion_bowl != undefined ? bowl?.portion_bowl : false)
    this.trash_bowl = this.fb.control(bowl?.trash_bowl != undefined ? bowl?.trash_bowl : false)
    this.default = this.fb.control(bowl?.default)
  }

  get is_new(): boolean {
    return this.id == undefined
  }

  get is_changed(): boolean {
    return JSON.stringify(this.as_dict) != this.saved_bowl
  }

  get valid(): boolean {
    return this.weight?.value > 0 && this.name.value
  }

  get type(): EBowlType {
    if (this.portion_bowl.value) return EBowlType.portion
    else if (this.trash_bowl.value) return EBowlType.trash
    else return EBowlType.dish
  }

  patchValue(bowl: IBowl): void {
    if (bowl?.name) this.name.setValue(bowl?.name)
    if (bowl?.index != undefined) this.index.setValue(bowl?.index)
    if (bowl?.weight != undefined) this.weight.setValue(bowl?.weight)
    if (bowl?.portion_bowl != undefined) this.portion_bowl.setValue(bowl?.portion_bowl)
    if (bowl?.trash_bowl != undefined) this.trash_bowl.setValue(bowl?.trash_bowl)
    if (bowl?.default != undefined) this.default.setValue(bowl?.default)

    this.saved_bowl = JSON.stringify(this.as_dict)
  }

  is_selected_for_trash_template(trash_template: TrashTemplate): boolean {
    return !!trash_template.trash_bowls.find((trash_bowl) => trash_bowl.id == this.id)
  }
  selector_for_trash_template(trash_template: TrashTemplate): FormControl {
    return this.fb.control(!!trash_template.trash_bowls.find((trash_bowl) => trash_bowl.id == this.id))
  }
  selector_for_waste_scale_allocation(scaleAllocation: ScaleAllocation): FormControl {
    return this.fb.control(!!scaleAllocation.trashBowls.find((trashBowl) => trashBowl.trash_bowl.id == this.id))
  }
  is_selected_for_waste_scale_allocation(scaleAllocation: ScaleAllocation): boolean {
    return !!scaleAllocation.trashBowls.find((trashBowl) => trashBowl.trash_bowl.id == this.id)
  }
  is_default_selection_for_waste_scale_allocation(scaleAllocation: ScaleAllocation): boolean {
    return !!scaleAllocation.trashBowls.find((trashBowl) => trashBowl.trash_bowl.id == this.id && trashBowl.default.value)
  }
  is_default_selection_for_trash_template(trash_template: TrashTemplate): boolean {
    return !!trash_template.trash_bowls.find((trash_bowl) => trash_bowl.id == this.id && trash_bowl.default.value)
  }
  save(): Observable<Bowl> {
    this.saving.setValue(true)
    const params = {
      fields: '',
      subsidiary_id: this.subsidiaryService.subsidiary.id
    }
    if (this.is_new) {
      return this.bowlsService.createBowl(this, params)
    } else {
      return this.bowlsService.updateBowl(this, params)
    }
  }

  get as_dict(): IBowl {
    return {
      id: this.id,
      name: this.name.value,
      index: this.index.value,
      weight: this.weight.value,
      default: this.default.value,
      trash_bowl: this.trash_bowl.value,
      portion_bowl: this.portion_bowl.value
    }
  }
}
