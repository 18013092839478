<app-navigation-bar class="l-overflow l-clear" *ngIf="userService.user?.is_user_super_admin"></app-navigation-bar>
<router-outlet class="l-overflow" *ngIf="userService.user?.is_user_super_admin"></router-outlet>

<div *ngIf="!loading && authService.is_logged_in && !userService.user?.is_user_super_admin" class="l-topmargin-large">
    <h1 class="l-width-full l-text-center">FEJL</h1>
    <label class="l-width-full l-text-center">Du har ikke rettighed til at bruge www.admin.foodoptimize.com</label>
    <label class="l-width-full l-text-center">Log venligst ind som administrator</label>
</div>

<div *ngIf="!authService.is_logged_in && loading && !userService.user?.is_user_super_admin" class="l-topmargin-large">
    <h1 class="l-width-full l-text-center">Validerer log ind oplysninger</h1>
</div>