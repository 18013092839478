import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Observable } from 'rxjs'
import { FormBuilder } from '@angular/forms'
import { DishCatalogue } from './dish-catalogue.model'
import { tap, map } from 'rxjs/operators'
import { UserService } from '../user/user.service'
import { Recipe } from '../recipe/recipe.model'
import { IDishCatalogue, IRecipe } from '../../global.models'
import { RestApiService } from '../../services/rest-api.service'
import { CacheMapService } from '../../services/caching/cache-map.service'

@Injectable({
  providedIn: 'root'
})
export class DishCatalogueService {
  dish_catalogues: DishCatalogue[] = []

  constructor(public fb: FormBuilder, private restApiService: RestApiService, private cacheMapService: CacheMapService, @Inject(LOCALE_ID) public language: string, private userService: UserService) {}

  get standard_dish_catalogues(): DishCatalogue[] {
    return this.dish_catalogues.filter((dish_catalogue) => dish_catalogue.standard_catalogue)
  }

  get standard_dish_catalogues_sorted(): DishCatalogue[] {
    return ['all', 'subsidiary', 'organization', 'mine', 'user_favorites', 'used'].map((catalogue_id) => this.standard_dish_catalogues.find((dish_catalogue) => dish_catalogue.id == catalogue_id)).filter((catalogue) => catalogue)
  }

  get visible_standard_dish_catalogues(): DishCatalogue[] {
    return this.standard_dish_catalogues.filter((dish_catalogue) => dish_catalogue.visibility.value)
  }

  get visible_standard_dish_catalogues_sorted(): DishCatalogue[] {
    return this.standard_dish_catalogues_sorted.filter((dish_catalogue) => dish_catalogue.visibility.value)
  }

  get custom_dish_catalogues(): DishCatalogue[] {
    return this.dish_catalogues.filter((dish_catalogue) => !dish_catalogue.standard_catalogue)
  }

  get my_custom_dish_catalogues_sorted(): DishCatalogue[] {
    return this.dish_catalogues.filter((dish_catalogue) => !dish_catalogue.standard_catalogue && dish_catalogue.user_id == this.userService.user.id.value).sort((a, b) => (a.names[this.language].value > b.names[this.language].value ? 1 : -1))
  }

  get other_custom_dish_catalogues_sorted(): DishCatalogue[] {
    return this.dish_catalogues.filter((dish_catalogue) => !dish_catalogue.standard_catalogue && dish_catalogue.user_id != this.userService.user.id.value).sort((a, b) => (a.names[this.language].value > b.names[this.language].value ? 1 : -1))
  }

  get visible_custom_dish_catalogues_sorted(): DishCatalogue[] {
    return [...this.my_custom_dish_catalogues_sorted, ...this.other_custom_dish_catalogues_sorted].filter((dish_catalogue) => dish_catalogue.visibility.value)
  }

  get hidden_custom_dish_catalogues_sorted(): DishCatalogue[] {
    return [...this.my_custom_dish_catalogues_sorted, ...this.other_custom_dish_catalogues_sorted].filter((dish_catalogue) => !dish_catalogue.visibility.value)
  }

  get custom_dish_catalogues_sorted(): DishCatalogue[] {
    return [...this.visible_custom_dish_catalogues_sorted, ...this.hidden_custom_dish_catalogues_sorted]
  }

  get write_accessible_custom_dish_catalogues_sorted(): DishCatalogue[] {
    return this.custom_dish_catalogues_sorted.filter((dish_catalogue) => dish_catalogue.user_has_write_access)
  }

  addToDishCatalogues(dish_catalogue: IDishCatalogue): void {
    if (!this.dish_catalogue_with_id(dish_catalogue.id)) this.dish_catalogues.push(new DishCatalogue(dish_catalogue))
    else this.dish_catalogue_with_id(dish_catalogue.id).patchValues(dish_catalogue)
  }

  dish_catalogue_with_id(dish_catalogue_id: string): DishCatalogue {
    return this.dish_catalogues.find((dish_catalogue) => dish_catalogue.id == dish_catalogue_id)
  }

  getStandardDishCatalogues(scope: string): Observable<IDishCatalogue[]> {
    const params = {
      language: this.language,
      scope: scope,
      fields: 'all'
    }
    return this.restApiService.getStandardDishCatalogues(params).pipe(
      tap((catalogues) => {
        catalogues.forEach((catalogue) => this.addToDishCatalogues(catalogue))
      })
    )
  }

  getCustomDishCatalogues(scope: string, query?: string, offset?: number): Observable<IDishCatalogue[]> {
    const params = {
      language: this.language,
      scope: scope,
      fields: 'all'
    }
    if (query) params['query'] = encodeURIComponent(query)
    if (offset) {
      params['offset'] = offset
      params['limit'] = 18
    }
    return this.restApiService.getCustomDishCatalogues(params).pipe(
      tap((catalogues) => {
        catalogues.forEach((catalogue) => this.addToDishCatalogues(catalogue))
      })
    )
  }

  createDishCatalogue(catalogue: IDishCatalogue): Observable<DishCatalogue> {
    const params = {
      language: this.language
    }
    return this.restApiService.createDishCatalogue(catalogue, params).pipe(
      tap((response) => {
        catalogue.id = response['catalogue_id']
        this.addToDishCatalogues(catalogue)
      }),
      map((response) => this.dish_catalogue_with_id(response['catalogue_id']))
    )
  }

  updateDishCatalogue(catalogue: IDishCatalogue): Observable<DishCatalogue> {
    const params = {
      language: this.language
    }
    return this.restApiService.updateDishCatalogue(catalogue, params).pipe(map((response) => this.dish_catalogue_with_id(response['catalogue_id'])))
  }

  saveDishCatalogueNames(ingredient: DishCatalogue): Observable<DishCatalogue> {
    return this.updateDishCatalogue(ingredient.names_dict)
  }

  updateStandardDishCatalogueVisibility(catalogue_id: string, visibility: boolean): Observable<IDishCatalogue> {
    const params = {
      catalogue_id: catalogue_id,
      visibility: visibility
    }
    return this.restApiService.updateStandardDishCatalogueVisibility(params)
  }

  updateCustomDishCatalogueVisibility(catalogue_id: string, visibility: boolean): Observable<IDishCatalogue> {
    const params = {
      catalogue_id: catalogue_id,
      visibility: visibility
    }
    return this.restApiService.updateCustomDishCatalogueVisibility(params)
  }

  updateDishCatalogueAllocations(recipe: Recipe): Observable<any> {
    this.cacheMapService.deleteCacheEntryForURL('recipes')
    const params = {
      language: this.language
    }
    return this.restApiService.updateDishCatalogueAllocations(
      recipe.id,
      recipe.catalogue_ids.filter((catalogue_id) => catalogue_id),
      params
    )
  }
  addDishToMyDishes(recipe: IRecipe): Observable<IRecipe> {
    return this.restApiService.addDishToMyDishes(recipe.id)
  }

  removeDishFromMyDishes(recipe: IRecipe): Observable<any> {
    return this.restApiService.removeDishFromMyDishes(recipe.id)
  }
}
