import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms'
import { Names } from '../names/names.model'
import { FoodopLibModule } from '../../foodop-lib.module'
import { CustomValidators } from '../../validators/number_validator'
import { IPortionTemplate } from '../../global.models'

export class PortionTemplate {
  id: string
  names: Names
  index: FormControl
  active: FormControl
  servings: FormControl
  default: FormControl

  saved_template: any

  fb: FormBuilder

  constructor(public portion_template?: IPortionTemplate) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.id = portion_template?.id
    this.names = new Names(portion_template?.names)
    this.index = this.fb.control({ value: portion_template?.index, disabled: false }, [Validators.required])
    this.active = this.fb.control(portion_template?.active)
    this.servings = this.fb.control({ value: portion_template?.servings || 0, disabled: false }, [Validators.required, Validators.min(0), CustomValidators.isNumbers])
    this.default = this.fb.control(portion_template?.default)

    this.saved_template = JSON.stringify(this.as_dict)
  }

  get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined
  }

  isNameTranslated(language: string) {
    return this.names[language].value ? true : false
  }

  get as_dict(): IPortionTemplate {
    return {
      id: this.id,
      names: this.names.as_dict,
      index: this.index.value,
      active: this.active.value,
      servings: this.servings.value,
      default: this.default.value
    }
  }

  get names_dict(): IPortionTemplate {
    return {
      id: this.id,
      names: this.names.as_dict
    }
  }
}
