import { AbstractControl, FormBuilder, FormControl } from '@angular/forms'
import { DisplayElementTemplate } from '../display-element-template/display-element-template.model'
import { EDisplayElementContentType, EFunctionStatusCode } from '../../global.types'
import { IDisplayElement, IDisplayElementTemplate } from '../../global.models'

export class DisplayElement extends FormBuilder {
  content: FormControl
  content_formatted: FormControl
  content_type: EDisplayElementContentType
  display_element_template: DisplayElementTemplate
  // format: DisplayElementFormat;

  invalid = false

  context = document.createElement('canvas').getContext('2d')

  constructor(content: string, content_type: EDisplayElementContentType, display_element_template: DisplayElementTemplate) {
    super()
    this.content = super.control(content)
    this.content_formatted = super.control(content)
    this.content_type = content_type
    this.display_element_template = display_element_template
  }

  get as_dict(): IDisplayElement {
    return {
      //'content': this.content.value,
      content: this.content_formatted.value || '',
      content_type: this.content_type,
      display_element_template: this.display_element_template.as_dict,
      format: this.display_element_template.as_dict
    }
  }

  adjustFontSizeToBoundaries(): EFunctionStatusCode {
    let string_w = this.measureTextWidthWithFont(this.content.value, this.display_element_template.font_size.value, this.display_element_template.font_family.value)
    let string_h = this.measureTextHeightWithFont(this.content.value, this.display_element_template.font_size.value, this.display_element_template.font_family.value)

    let width_can_fit_on_max_lines: boolean = string_w <= this.display_element_template.available_multiline_width
    while (!width_can_fit_on_max_lines && !this.display_element_template.has_reached_min_font_size) {
      if (!this.display_element_template.has_reached_min_font_size) {
        this.display_element_template.decrease_font_size()
      }
      // else {
      //     return EFunctionStatusCode.reachedMinFontSize;
      // }
      string_w = this.measureTextWidthWithFont(this.content.value, this.display_element_template.font_size.value, this.display_element_template.font_family.value)
      width_can_fit_on_max_lines = string_w <= this.display_element_template.available_multiline_width
    }

    let height_can_fit_on_min_lines: boolean = string_h * this.display_element_template.min_lines.value <= this.display_element_template.boundary_height.value
    while (!height_can_fit_on_min_lines && !this.display_element_template.has_reached_min_font_size) {
      if (!this.display_element_template.has_reached_min_font_size) this.display_element_template.decrease_font_size()
      // else {
      //     return EFunctionStatusCode.reachedMinFontSize;
      // }
      string_h = this.measureTextHeightWithFont(this.content.value, this.display_element_template.font_size.value, this.display_element_template.font_family.value)
      height_can_fit_on_min_lines = string_h * this.display_element_template.min_lines.value <= this.display_element_template.boundary_height.value
      //console.log("Adjusted font size for string '" + this.content.value + "'. New height is " + string_h);
    }

    const can_fit_on_one_line: boolean = string_w <= this.display_element_template.boundary_width.value - 5
    if (!can_fit_on_one_line) {
      //console.log("Wrapping text to multiple lines for string '" + this.content.value + "'");
      return this.wrapText()
    }

    return EFunctionStatusCode.success
  }

  measureTextWidthWithFont(string: string, font_size: number, font_family: string): number {
    this.context.font = font_size + 'px ' + font_family
    return this.context.measureText(string).width
  }
  measureTextHeightWithFont(string: string, font_size: number, font_family: string): number {
    this.context.font = font_size + 'px ' + font_family
    return this.context.measureText(string).fontBoundingBoxAscent + this.context.measureText(string).fontBoundingBoxDescent
  }

  // isDisplayElementBoundariesValid(): boolean {
  //     if ((this.content_type == EDisplayElementContentType.string || this.content_type == EDisplayElementContentType.tags) && this.content.value) {
  //         if (this.adjustFontSizeToBoundaries() == EFunctionStatusCode.reachedMinFontSize) return false;
  //         if (this.wrapText() == EFunctionStatusCode.reachedMinFontSize) return false;
  //         return true
  //     }
  //     else {
  //         return true
  //     }
  // }

  wrapText(): EFunctionStatusCode {
    // Split string into words and lines:
    const words = this.content.value.split(' ')
    let line_index = 0
    let word_index = 1
    let lines = []
    let first_line_w = this.measureTextWidthWithFont(words[0] + ' ', this.display_element_template.font_size.value, this.display_element_template.font_family.value)
    const first_line_h = this.measureTextHeightWithFont(words[0] + ' ', this.display_element_template.font_size.value, this.display_element_template.font_family.value)
    lines.push({
      string: words[0],
      width: first_line_w,
      height: first_line_h
    })

    while (word_index < words.length) {
      //Check next word:
      const next_word_w = this.measureTextWidthWithFont(words[word_index] + ' ', this.display_element_template.font_size.value, this.display_element_template.font_family.value)
      const next_word_h = this.measureTextHeightWithFont(words[word_index] + ' ', this.display_element_template.font_size.value, this.display_element_template.font_family.value)
      //console.log("Next word '" + words[word_index] + " ' has width " + next_word_w + " and height: " + next_word_h);

      const can_fit_on_this_line: boolean = lines[line_index]['width'] + next_word_w <= this.display_element_template.boundary_width.value - 5
      const has_reached_max_lines: boolean = line_index == this.display_element_template.max_lines.value
      const current_text_height: number = lines.map((line) => line['height']).reduce((a, b) => a + b)
      const can_fit_new_line_within_boundaries: boolean = (current_text_height / lines.length) * (lines.length + 1) <= this.display_element_template.boundary_height.value

      // If it can be appended without breaking maxWidth, add it:
      if (can_fit_on_this_line) {
        lines[line_index]['string'] = lines[line_index]['string'] + ' ' + words[word_index]
        // Calculate current line size:
        lines[line_index]['width'] = this.measureTextWidthWithFont(lines[line_index]['string'] + ' ', this.display_element_template.font_size.value, this.display_element_template.font_family.value)
        lines[line_index]['height'] = this.measureTextHeightWithFont(lines[line_index]['string'] + ' ', this.display_element_template.font_size.value, this.display_element_template.font_family.value)
        //console.log("Line " + line_index + " is '" + lines[line_index]['string'] + "' with width: " + lines[line_index]['width'] + " and height: " + lines[line_index]['height']);

        word_index += 1
      }

      // else generate new line:
      else if (!has_reached_max_lines && can_fit_new_line_within_boundaries) {
        lines.push({
          string: words[word_index],
          width: next_word_w
        })
        line_index += 1
        //console.log("Line " + line_index + " is '" + lines[line_index]['string'] + "' with width: " + lines[line_index]['width']);

        word_index += 1
      } else {
        console.warn("Next word '" + words[word_index] + "' for component '" + this.display_element_template.code + "' can't fit on last line '" + lines[line_index]['string'] + "' and max lines " + this.display_element_template.max_lines.value + ' is reached. Retrying')
        if (this.display_element_template.has_reached_min_font_size) {
          return EFunctionStatusCode.reachedMinFontSize
        }
        if (!this.display_element_template.has_reached_min_font_size) this.display_element_template.decrease_font_size()

        // Reset loop:
        line_index = 0
        word_index = 1
        lines = []
        first_line_w = this.measureTextWidthWithFont(words[0] + ' ', this.display_element_template.font_size.value, this.display_element_template.font_family.value)
        lines.push({
          string: words[0],
          width: first_line_w
        })
      }
    }

    let final_string = ''
    lines.forEach((line) => {
      final_string = final_string + line['string'] + '        \n'
    })
    this.content_formatted.setValue(final_string)

    return EFunctionStatusCode.success
  }
}
