import { Pipe, PipeTransform } from '@angular/core'
import { Subsidiary } from '../models/subsidiary/subsidiary.model'

@Pipe({ name: 'subsidiaryFilterPipe' })
export class SubsidiaryFilterPipe implements PipeTransform {
  public transform(subsidiaries: Subsidiary[], filter: string): any[] {
    const name_filter = filter?.toLowerCase()

    if (!subsidiaries || !subsidiaries.length) return []
    if (!name_filter) return subsidiaries

    let filtered_subsidiaries = subsidiaries.filter((subsidiary: Subsidiary) => {
      if (subsidiary.name.value?.toLowerCase()?.indexOf(name_filter) >= 0) return true
      if (subsidiary.nick_name.value?.toLowerCase()?.indexOf(name_filter) >= 0) return true
      if (subsidiary.organization.name.value?.toLowerCase()?.indexOf(name_filter) >= 0) return true
      if (subsidiary.locations.find((location) => location.name.value?.toLowerCase()?.indexOf(name_filter) >= 0) != undefined) return true
    })

    return filtered_subsidiaries
  }
}
