import { FormControl } from '@angular/forms'
import { FilesService } from '../../../services/files.service'
import { IMenuPrintElementImageFormatTemplate } from '../../../global.models'
import { FoodopLibModule } from '../../../foodop-lib.module'

export class MenuPrintTemplateImageElementFormat {
  public hidden: FormControl

  public position: FormControl
  public top: FormControl
  public bottom: FormControl
  public left: FormControl
  public right: FormControl
  public width: FormControl
  public height: FormControl

  public opacity: FormControl
  public objectFit: FormControl

  _filesService: FilesService

  constructor(public elementFormat?: IMenuPrintElementImageFormatTemplate, private defaultHidden?: boolean) {
    this._filesService = FoodopLibModule.injector.get(FilesService)

    this.hidden = new FormControl(elementFormat?.hidden != undefined ? elementFormat?.hidden : defaultHidden || false)

    this.position = new FormControl(elementFormat?.position || 'top-right')
    this.top = new FormControl(elementFormat?.top || 0)
    this.bottom = new FormControl(elementFormat?.bottom || 0)
    this.left = new FormControl(elementFormat?.left || 0)
    this.right = new FormControl(elementFormat?.right || 0)
    this.width = new FormControl(elementFormat?.width || 0)
    this.height = new FormControl(elementFormat?.height || 0)

    this.opacity = new FormControl(elementFormat?.opacity || 100)
    this.objectFit = new FormControl(elementFormat?.objectFit || 'contain')
  }

  public get asDict(): IMenuPrintElementImageFormatTemplate {
    return {
      hidden: this.hidden.value,
      position: this.position.value,
      top: this.top.value,
      bottom: this.bottom.value,
      left: this.left.value,
      right: this.right.value,
      width: this.width.value,
      height: this.height.value,
      opacity: this.opacity.value,
      objectFit: this.objectFit.value
    }
  }

  public patchValues(elementFormat): void {
    this.hidden.setValue(elementFormat?.hidden != undefined ? elementFormat?.hidden : false, { emitEvent: false })

    this.position.setValue(elementFormat?.position || 'top-right', { emitEvent: false })
    this.top.setValue(elementFormat?.top || 0, { emitEvent: false })
    this.bottom.setValue(elementFormat?.bottom || 0, { emitEvent: false })
    this.left.setValue(elementFormat?.left || 0, { emitEvent: false })
    this.right.setValue(elementFormat?.right || 0, { emitEvent: false })
    this.width.setValue(elementFormat?.width || 0, { emitEvent: false })
    this.height.setValue(elementFormat?.height || 0, { emitEvent: false })
    this.opacity.setValue(elementFormat?.opacity || 1, { emitEvent: false })
    this.objectFit.setValue(elementFormat?.objectFit || 'contain', { emitEvent: false })
  }
}
