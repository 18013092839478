import { FormBuilder, FormControl } from '@angular/forms'
import { Observable, Subscription, tap } from 'rxjs'
import { FoodopLibModule } from '../../foodop-lib.module'
import { GlobalFunctionsService } from '../../services/global-functions.service'
import { Recipe } from './recipe.model'
import { IRecipePortionSubRecipe } from '../../global.models'

export class RecipePortionSubRecipe {
  calculated_share: FormControl
  amount_rounded: FormControl = new FormControl()
  amount: number
  index: FormControl

  shrinkage_subscription: Subscription
  preparation_subscription: Subscription
  netto_subscription: Subscription

  public amountChange$: Observable<number> = this._listenForAmountChanges()

  fb: FormBuilder
  func: GlobalFunctionsService

  constructor(public portion_sub_recipe: IRecipePortionSubRecipe, private sub_recipe?: Recipe, private sub_recipe_index?: number) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)
    this.func = FoodopLibModule.injector.get(GlobalFunctionsService)

    this.calculated_share = this.fb.control(portion_sub_recipe?.calculated_share)
    this.amount_rounded.setValue(portion_sub_recipe?.amount?.toString(), { emitEvent: false })
    this.amount = portion_sub_recipe?.amount
    this.index = this.fb.control(portion_sub_recipe?.index || sub_recipe_index)
  }

  get object(): Recipe {
    return this.sub_recipe
  }

  calculated_netto_share(portion_netto_amount: number): number {
    return this.amount != undefined && portion_netto_amount > 0 ? ((this.amount_in_kg * this.sub_recipe.netto_factor.value) / portion_netto_amount) * 100 : null
  }

  setBruttoAmountBasedOnCalculatedShares(amount_in_g: number, total_calculated_shares: number): void {
    if (this.calculated_share.value != undefined && total_calculated_shares > 0) {
      this.amount = (this.object.unit.value == 'g' ? amount_in_g : amount_in_g / 1000) * (this.calculated_share.value / total_calculated_shares)
      this.amount_rounded.setValue(this.func.round(this.amount, 2).toString(), { emitEvent: false })
    }
  }

  updateBruttoAmountBasedOnNettoDiff(diff_netto_amount_in_kg: number): void {
    if (this.amount_rounded.value == '' && !this.amount) return
    this.amount = this.amount + (this.object.unit.value == 'g' ? diff_netto_amount_in_kg * 1000 : diff_netto_amount_in_kg) / this.sub_recipe.netto_factor.value
    this.amount_rounded.setValue(this.func.round(this.amount, 2)?.toString(), { emitEvent: false })
  }

  updateIngredientCalculatedShares(portion_brutto_amount: number): void {
    this.calculated_share.setValue(this.amount != undefined && portion_brutto_amount > 0 ? (this.amount_in_kg / portion_brutto_amount) * 100 : null)
  }

  get amount_in_kg(): number {
    return this.object.unit.value == 'g' ? this.amount / 1000 : this.amount
  }

  get as_dict(): IRecipePortionSubRecipe {
    return {
      amount: this.amount,
      calculated_share: this.calculated_share.value,
      index: this.index.value,
      recipe_id: this.sub_recipe.id
    }
  }

  private _listenForAmountChanges(): Observable<number> {
    return this.amount_rounded.valueChanges.pipe(
      tap(() => {
        this.amount = this.amount_rounded.value ? parseFloat(this.amount_rounded.value) : null
      })
    )
  }
}
