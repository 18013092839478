import { Pipe, PipeTransform } from '@angular/core'
@Pipe({
  name: 'childrenLengthFilterPipe'
})
export class childrenLengthFilterPipe implements PipeTransform {
  public transform(list: any[], [childKeys, minLength]: [string[], number]): any[] {
    return list?.filter((item) => this._getNestedChildren(item, childKeys).length >= minLength)
  }

  private _getNestedChildren = (item: any, childKeys: string[]) => {
    return childKeys?.reduce((acc, key) => {
      if (Array.isArray(acc)) return acc.map((subOption) => subOption[key])
      else if (typeof acc == 'object') return acc[key]
    }, item)
  }
}
