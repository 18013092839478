import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { RestApiService } from '../../services/rest-api.service'
import { Observable } from 'rxjs'
import { IMenuDish } from '../../global.models'

@Injectable({
  providedIn: 'root'
})
export class MenuDishesService {
  constructor(@Inject(LOCALE_ID) public language: string, private restApiService: RestApiService) {}

  removeDishFromMenuSection(menu_dish_id: string, menu_section_id: string, menu_id: string): Observable<any> {
    const params = {
      menu_dish_id: menu_dish_id,
      menu_section_id: menu_section_id,
      menu_id: menu_id
    }
    return this.restApiService.removeDishFromMenuSection(params)
  }

  updateMenuDish(menu_dish: IMenuDish): Observable<IMenuDish> {
    const params = {
      fields: 'all',
      language: this.language
    }
    return this.restApiService.updateMenuDish(menu_dish, params)
  }
}
