import { IMenuPrintTemplateElement } from '../../../global.models'
import { BasicMenuPrintTemplateElement } from './basic-element.model'
import { MenuPrintTemplateStringElementFormat } from '../formats/string-format.model'
import { Recipe } from '../../recipe/recipe.model'

export class MenuPrintTemplateDishNameElement extends BasicMenuPrintTemplateElement {
  public format: MenuPrintTemplateStringElementFormat
  public translatable = true
  public name: string = $localize`Retnavne`

  constructor(element: IMenuPrintTemplateElement) {
    super('dishName')

    this.format = new MenuPrintTemplateStringElementFormat(element?.format)
  }

  public get asDict(): IMenuPrintTemplateElement {
    return {
      type: this.type,
      format: this.format.asDict
    }
  }

  public elementString(recipe: Recipe, language: string): string {
    return this.func.caseString(recipe.names[language].value, this.format.capitalization.value)
  }
}
