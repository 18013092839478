import { FormBuilder, FormControl } from '@angular/forms'
import { Names } from '../names/names.model'
import { IAllergen } from '../../global.models'
import { FoodopLibModule } from '../../foodop-lib.module'
import { RestApiService } from '../../services/rest-api.service'

export class Allergen {
  code: string
  required: boolean
  default_index: number
  names: Names
  containment: FormControl

  restApiService: RestApiService
  fb: FormBuilder

  constructor(public allergen?: IAllergen | any) {
    this.restApiService = FoodopLibModule.injector.get(RestApiService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.code = allergen?.code
    this.required = allergen?.required
    this.default_index = allergen?.default_index
    this.names = new Names(allergen?.names)
    try {
      this.containment = this.fb.control(allergen?.containment?.toLowerCase())
    } catch (e) {
      this.containment = this.fb.control(null)
      this.restApiService
        .logError({
          error: e,
          message: 'Error while creating Allergen',
          data: { allergen }
        })
        .subscribe()
    }
  }

  get as_dict(): IAllergen {
    return {
      code: this.code,
      names: this.names.as_dict,
      containment: this.containment.value
    }
  }
}
