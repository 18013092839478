import { IMenuPrintTemplateElement } from '../../../global.models'
import { BasicMenuPrintTemplateElement } from './basic-element.model'
import { FormControl } from '@angular/forms'
import { Observable, filter, startWith, switchMap } from 'rxjs'
import { MenuPrintTemplateImageElementFormat } from '../formats/image-format.model'

export class MenuPrintTemplateImageElement extends BasicMenuPrintTemplateElement {
  public format: MenuPrintTemplateImageElementFormat
  public image: FormControl
  public imageUrl$: Observable<string>
  public removable = true
  public translatable = false
  public name: string = $localize`Billede`

  constructor(element: IMenuPrintTemplateElement) {
    super('image')

    this.format = new MenuPrintTemplateImageElementFormat(element?.format)

    this.image = new FormControl(element?.image)
    this.imageUrl$ = this._getImageUrl()
  }

  public get asDict(): IMenuPrintTemplateElement {
    return {
      type: this.type,
      format: this.format.asDict,
      image: this.image.value
    }
  }

  private _getImageUrl(): Observable<string> {
    return this.image.valueChanges.pipe(
      startWith(this.image.value),
      filter(() => this.type == 'image' && this.image.value),
      switchMap(() => this.filesService.getFileUrl([this.image.value], 'images'))
    )
  }
}
