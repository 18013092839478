import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dateToWeekNumber'})
export class dateToWeekNumberPipe implements PipeTransform {
    transform(selectedDate: Date): number {
        let value = 0;

        if (selectedDate != undefined) {

            let WeekOneDate = new Date(selectedDate.getFullYear(), 0, 4); 
            WeekOneDate.setDate(WeekOneDate.getDate()-WeekOneDate.getDay() + 1);
        
            value = Math.ceil((selectedDate.getTime() - WeekOneDate.getTime()) / (1000 * 3600 * 24 * 7));

        }
    
        return value;
    }
  }
