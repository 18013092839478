import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Observable, Subject, catchError, map, merge, scan, tap } from 'rxjs'
import { RestApiService } from '../../services/rest-api.service'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { IMenuSectionTemplate, IMenuTemplate } from '../../global.models'
import { MenuTemplate } from './menu-template.model'
import { menuTemplateTypes, sampleMenuTemplate1 } from '../../global.types'

@Injectable({
  providedIn: 'root'
})
export class MenuTemplatesService {
  availableMenuTemplateTypes = []
  allMenuTemplates: MenuTemplate[] = []
  activeMenuTemplatesByTypeSorted: any[] = []

  private _newMenuTemplates$: Subject<any> = new Subject<any>()

  constructor(@Inject(LOCALE_ID) public language: string, private restApiService: RestApiService, private subsidiaryService: SubsidiaryService) {}

  public menuTemplatesForSubsidiary(subsidiaryId: string, active?: boolean): MenuTemplate[] {
    return this.allMenuTemplates.filter((menuTemplate) => menuTemplate.subsidiaryId == subsidiaryId && (active == undefined || menuTemplate.active.value == active))
  }
  public menuTemplatesForSubsidiarySorted(subsidiaryId: string, active?: boolean): MenuTemplate[] {
    return this.menuTemplatesForSubsidiary(subsidiaryId, active).sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }
  public menuTemplatesForSubsidiaryAndTypeSorted(subsidiaryId: string, type: string, active?: boolean): MenuTemplate[] {
    return this.menuTemplatesForSubsidiarySorted(subsidiaryId, active).filter((menuTemplate) => menuTemplate.type.value == type)
  }
  public menuTemplateWithId(menuTemplateId: string): MenuTemplate {
    return this.allMenuTemplates.find((menuTemplate) => menuTemplate.id == menuTemplateId)
  }

  public getMenuTemplateByID(menuTemplateId: string): Observable<IMenuTemplate> {
    const params = {
      fields: 'all',
      active: true,
      menu_template_id: menuTemplateId
    }
    return this.restApiService.getMenuTemplate(params).pipe(
      tap((menuTemplate) => {
        this.addToMenuTemplates(menuTemplate)
      })
    )
  }

  public getMenuTemplates(subsidiary_id?: string, type?: string, active?: boolean, output?: string): Observable<MenuTemplate[]> {
    const params = {
      fields: 'all',
      language: this.language,
      subsidiary_id: subsidiary_id || this.subsidiaryService.subsidiary.id
    }
    if (active != undefined) params['active'] = active
    if (type) params['type'] = type

    const loadPipe$ = this.restApiService.getMenuTemplates(params).pipe(catchError(() => []))

    return merge(loadPipe$, this._newMenuTemplates$).pipe(
      scan((menuTemplates: any[], newMenuTemplates: any[]) => {
        const map = new Map()
        menuTemplates.forEach((menuTemplate) => map.set(menuTemplate.id, menuTemplate))
        newMenuTemplates.forEach((menuTemplate) => map.set(menuTemplate.id, { ...map.get(menuTemplate.id), ...menuTemplate }))
        return Array.from(map.values())
      }),
      tap((menuTemplates) => {
        menuTemplates.forEach((menuTemplate) => this.addToMenuTemplates(menuTemplate))
        this._generateAvailableMenuTemplateTypes(menuTemplates)
        this._generateMenuTemplatesByTypeArray()
      }),
      map((menuTemplates) => {
        if (output == 'nested') return this.activeMenuTemplatesByTypeSorted
        else return menuTemplates.map((menuTemplate) => this.menuTemplateWithId(menuTemplate.id)).sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
      })
    )
  }

  public addToMenuTemplates(menuTemplate: IMenuTemplate): void {
    if (!this.menuTemplateWithId(menuTemplate.id)) this.allMenuTemplates.push(new MenuTemplate(menuTemplate, this.language))
    else this.menuTemplateWithId(menuTemplate.id).patchValues(menuTemplate)
  }

  public saveMenuTemplate(menuTemplate: MenuTemplate): Observable<IMenuTemplate> {
    if (menuTemplate.isNew) {
      this.allMenuTemplates.push(menuTemplate)
      return this.restApiService.createMenuTemplate({ subsidiary_id: this.subsidiaryService.subsidiary.id }, menuTemplate.asDict).pipe(tap((response) => this._newMenuTemplates$.next([response])))
    } else return this.restApiService.updateMenuTemplate({ subsidiary_id: this.subsidiaryService.subsidiary.id }, menuTemplate.asDict)
  }

  public saveMenuTemplateNames(menuTemplate: IMenuTemplate): Observable<IMenuTemplate> {
    return this.restApiService.updateMenuTemplate({ subsidiary_id: this.subsidiaryService.subsidiary.id }, menuTemplate)
  }

  public saveMenuTemplateIndex(menuTemplate: IMenuTemplate): Observable<IMenuTemplate> {
    return this.restApiService.updateMenuTemplate({ subsidiary_id: this.subsidiaryService.subsidiary.id }, menuTemplate)
  }

  public saveMenuSectionTemplateNames(menuSectionTemplate: IMenuSectionTemplate): Observable<IMenuSectionTemplate> {
    return this.restApiService.updateMenuSectionTemplate({ subsidiary_id: this.subsidiaryService.subsidiary.id }, menuSectionTemplate)
  }

  private _generateAvailableMenuTemplateTypes(menuTemplates: IMenuTemplate[]): void {
    this.availableMenuTemplateTypes = menuTemplateTypes.filter((menuTemplateType) =>
      menuTemplates
        .filter((menuTemplate) => menuTemplate.active && menuTemplate.subsidiary_id == this.subsidiaryService.subsidiary.id)
        .map((menuTemplate) => menuTemplate.type)
        .includes(menuTemplateType.id)
    )
  }

  private _generateMenuTemplatesByTypeArray(): void {
    this.activeMenuTemplatesByTypeSorted = this.availableMenuTemplateTypes.map((type) => {
      return {
        names: {
          da: { value: type.da },
          en: { value: type.en }
        },
        menuTemplates: this.menuTemplatesForSubsidiarySorted(this.subsidiaryService.subsidiary.id, true).filter((menuTemplate) => menuTemplate.type.value == type.id)
      }
    })
  }
}
