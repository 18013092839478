<div cdkDropList (cdkDropListDropped)="moveScaleGroup($event)" class="l-width-full">
    <div *ngFor="let scale_group of location.scale_groups; let scale_group_index = index" cdkDrag cdkDragLockAxis="y" [cdkDragData]="scale_group" class="l-overflow l-position-relative l-padding-medium l-theme-white l-display-flex l-space-between">
        <div class="scale-group-info-container">
            <mat-form-field>
                <mat-label>Navngiv gruppe</mat-label>
                <input autocomplete="off" matInput [formControl]="scale_group.name">
            </mat-form-field>
    
           
            <mat-form-field class="l-width-full">
                <mat-label>Type</mat-label>
                <mat-select [formControl]="scale_group.type" [value]="scale_group.type.value">
                    <mat-option value="dish">Buffetvægte</mat-option>
                    <mat-option value="trash">Affaldsvægte</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
       

        <button type="button" (click)="deleteScaleGroup(scale_group_index)" class="l-float-right">
            <mat-icon matTooltip="Slet gruppe" matTooltipClass="l-text-center" class="material-icons">delete</mat-icon>
        </button>
        
    </div>
</div>
<div (click)="addScaleGroup()" class="l-overflow l-position-relative l-padding-medium l-theme-white l-border l-border-radius l-cursor-pointer">
    + Tilføj gruppe
</div>