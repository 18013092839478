import { Injectable } from '@angular/core'
import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import { Subscription, filter } from 'rxjs'
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  is_logged_in = false
  subscriptions: Subscription[] = []

  constructor(private authService: MsalService, private msalBroadcastService: MsalBroadcastService) {}

  subscribeToAuthService() {
    let loginSuccessSubscription: Subscription
    let loginFailureSubscription: Subscription
    let acquireTokenSuccess: Subscription
    let acquireTokenFailure: Subscription

    this.checkAccount()

    loginSuccessSubscription = this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)).subscribe((result: EventMessage) => {
      this.checkAccount()
      this.authService.instance.setActiveAccount(result.payload['account'])
    })

    loginFailureSubscription = this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE)).subscribe((result: EventMessage) => {
      console.log('ERROR: ', result)
    })

    acquireTokenSuccess = this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS)).subscribe((result: EventMessage) => {
      if (!this.authService.instance.getActiveAccount()) this.authService.instance.setActiveAccount(result.payload['account'])
    })

    acquireTokenFailure = this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE)).subscribe((result: EventMessage) => {
      console.log('ERROR: ', result)
      this._logoutActiveAccount()
    })

    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe((result) => {
      if (this.authService.instance.getActiveAccount()) {
        this.authService.instance
          .acquireTokenSilent({
            scopes: ['openid'],
            account: this.authService.instance.getActiveAccount()
          })
          .then((result) => {
            this.checkAccount()
          })
      }
      this.checkAccount()
    })

    this.subscriptions.push(loginSuccessSubscription)
    this.subscriptions.push(loginFailureSubscription)
    this.subscriptions.push(acquireTokenSuccess)
    this.subscriptions.push(acquireTokenFailure)
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  checkAccount() {
    this.is_logged_in = this.authService.instance.getActiveAccount() != undefined
  }

  login() {
    try {
      this.authService.loginRedirect({ account: this.authService.instance.getActiveAccount(), scopes: ['openid'] })
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }

  private _logoutActiveAccount() {
    if (this.authService.instance.getAllAccounts().length > 1) {
      this.authService.instance.logoutRedirect({
        account: this.authService.instance.getActiveAccount(),
        onRedirectNavigate: () => {
          this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0])
          return false
        }
      })
    } else this.authService.logout()
  }

  logout() {
    this.authService.logout()
  }
}
