import { FormControl } from '@angular/forms'
import { BasicMenuPrintTemplateElement } from './basic-element.model'
import { IMenuPrintTemplateElement } from '../../../global.models'
import { MenuPrintTemplateStringElementFormat } from '../formats/string-format.model'
import { Names } from '../../names/names.model'
import { Recipe } from '../../recipe/recipe.model'

export class MenuPrintTemplateDishCO2Element extends BasicMenuPrintTemplateElement {
  public format: MenuPrintTemplateStringElementFormat
  public prefix: Names
  public suffix: Names
  public display: FormControl
  public translatable = false
  public name: string = $localize`Ret CO2`
  public fontSizeToDOMHeightFactor = 2.25

  constructor(element: IMenuPrintTemplateElement) {
    super('dishCO2')

    this.format = new MenuPrintTemplateStringElementFormat(element?.format)
    this.prefix = new Names(element?.prefix || { en: 'CO₂-footprint (est.): ', da: 'CO₂-aftryk (est.): ' })
    this.suffix = new Names(element?.suffix || { en: '', da: '' })
    this.display = new FormControl(element?.display || 'icon')
  }

  public get asDict(): IMenuPrintTemplateElement {
    return {
      type: this.type,
      format: this.format.asDict,
      prefix: this.prefix.as_dict,
      suffix: this.suffix.as_dict,
      display: this.display.value
    }
  }
  public elementString(recipe: Recipe, language: string): string {
    return (this.prefix[language].value || '') + this._displayValue(recipe, language) + (this.suffix[language].value || '')
  }

  private _displayValue(recipe: Recipe, language: string): string {
    if (this.display.value == 'value') return recipe.co2.value.toPrecision(2) + ' kg CO₂/kg'
    else if (this.display.value == 'level') return this._co2LevelString(recipe, language)
    else if (this.display.value == 'value-level') return recipe.co2.value.toPrecision(2) + ' kg CO₂/kg (' + this._co2LevelString(recipe, language) + ')'
    else if (this.display.value == 'level-value') return this._co2LevelString(recipe, language) + ' (' + recipe.co2.value.toPrecision(2) + ' kg CO₂/kg)'
  }
  private _co2LevelString(recipe: Recipe, language: string): string {
    if (recipe.co2.value <= 2.3) return language == 'da' ? 'Lav' : 'Low'
    else if (recipe.co2.value > 2.3 && recipe.co2.value <= 10) return language == 'da' ? 'Middel' : 'Medium'
    else return language == 'da' ? 'Høj' : 'High'
  }
}
