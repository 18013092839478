import { FormBuilder, FormControl } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { MenuSection } from '../menu-section/menu-section.model'
import { Recipe } from '../recipe/recipe.model'
import { MenuDishesService } from './menu-dishes.service'
import { Observable, forkJoin, merge, of } from 'rxjs'
import { UserService } from '../user/user.service'
import { tap } from 'rxjs/operators'
import { MenusService } from '../menu/menus.service'
import { IMenuDish, IRecipe } from '../../global.models'
import { Menu } from '../menu/menu.model'
import moment from 'moment'
import { GlobalFunctionsService } from '../../services/global-functions.service'
import { TrackingsService } from '../tracking/trackings.service'
import { RecipePortion } from '../recipe/recipe-portion.model'
import { PortionTemplate } from '../portion-template/portion_template.model'

export class MenuDish {
  id: string
  index: FormControl

  recipe: Recipe

  menu_section_id: string
  menu_id: string

  subsidiary_id: string

  changed = new FormControl(false)
  saved_menu_dish: string
  saving = false

  menuDishesService: MenuDishesService
  menusService: MenusService
  userService: UserService
  trackingsService: TrackingsService
  fb: FormBuilder
  func: GlobalFunctionsService

  constructor(public menu_dish?: IMenuDish, public menu_section?: MenuSection, private dish_recipe?: IRecipe, private dish_index?: number) {
    this.menuDishesService = FoodopLibModule.injector.get(MenuDishesService)
    this.menusService = FoodopLibModule.injector.get(MenusService)
    this.userService = FoodopLibModule.injector.get(UserService)
    this.trackingsService = FoodopLibModule.injector.get(TrackingsService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)
    this.func = FoodopLibModule.injector.get(GlobalFunctionsService)

    this.id = menu_dish?.id
    let index = menu_dish?.index
    if (index == undefined) index = dish_index
    if (index == undefined) index = this.menu_section?.menu_dishes?.findIndex((dish) => dish.id && dish.id == menu_dish?.id)
    if (index == undefined || index < 0) index = this.menu_section?.menu_dishes?.length || 0
    this.index = this.fb.control(index)

    this.recipe = new Recipe(menu_dish?.recipe || dish_recipe, this)

    this.menu_section_id = menu_dish?.menu_section_id || menu_section?.id
    this.menu_id = menu_dish?.menu_id || menu_section?.menu_id || menu_section?.menu?.id

    this.subsidiary_id = menu_dish?.subsidiary_id

    this.saved_menu_dish = JSON.stringify(this.as_dict)
  }

  patchValues(menu_dish: IMenuDish): void {
    if (menu_dish?.id) this.id = menu_dish.id
    if (menu_dish?.index != undefined) this.index.setValue(menu_dish.index)

    if (menu_dish?.menu_section_id) this.menu_section_id = menu_dish.menu_section_id
    if (menu_dish?.menu_id) this.menu_id = menu_dish.menu_id
    if (menu_dish?.recipe) {
      this.recipe.patchValue(menu_dish?.recipe)
      this.recipe.listenForPortionChanges()
    }
    if (menu_dish?.subsidiary_id) this.subsidiary_id = menu_dish.subsidiary_id

    this.recipe.addingRecipeToMenu = false
    this.changed.setValue(false)
    this.saved_menu_dish = JSON.stringify(this.as_dict)
  }

  get saved(): boolean {
    return this.id != undefined
  }

  get menu(): Menu {
    return this.menu_section?.menu
  }

  get selectedPortion(): RecipePortion {
    return this['portionSelection']?.value
  }

  portion_for_template(portion_template: PortionTemplate | string, language: string): RecipePortion {
    if (!portion_template) return
    if (portion_template == 'all') return this.recipe.default_portion
    return this.recipe.portion_with_template_name((portion_template as PortionTemplate).names[language].value, language) || this.recipe.portion_with_template_id((portion_template as PortionTemplate).id)
  }

  restore_recipe(): void {
    this.recipe.patchValue(JSON.parse(this.recipe.saved_recipe))
    this.recipe.listenForPortionChanges()
  }

  saveMenuDishObject() {
    this.saved_menu_dish = JSON.stringify(this.as_dict)
  }

  moveDish(subsidiary_id: string, date: moment.Moment, menu_template_id: string, section_template_id: string, to_index?: number): Observable<any> {
    let to_menu: Menu = this._create_destination_menu(date, menu_template_id)
    let to_menu_section: MenuSection = this._create_destination_menu_section(to_menu, section_template_id)

    this.menu_section._removeDishAndReindexSection(this)
    if (this.menu.id != to_menu.id) this.clearScaleDishesForDish()

    if (to_menu_section.is_new) {
      this.index.setValue(null)
    } else {
      this.menu_section_id = to_menu_section.id
      if (to_index != undefined) {
        this.index.setValue(to_index)
        this._reindex_other_dishes(to_menu_section, to_index)
      } else this.index.setValue(to_menu_section.menu_dishes.length)
    }

    this.menu_section = to_menu_section

    return to_menu_section.addDishes(
      [this],
      subsidiary_id,
      this.menu_id,
      this.menu.menu_sections.map((section) => section.id)
    )
  }

  _reindex_other_dishes(to_menu_section: MenuSection, to_index: number): void {
    to_menu_section.menu_dishes_sorted.slice(to_index, to_menu_section.menu_dishes.length).forEach((menu_dish) => {
      menu_dish.index.setValue(menu_dish.index.value + 1)
    })
  }

  clearScaleDishesForDish(): void {
    this.menu.trackings.forEach((tracking) => {
      tracking.scaleDishesForMenuDish(this).forEach((scale_dish) => tracking.removeScaleDish(scale_dish))
      if (tracking.scaleDishes.length) tracking.save().subscribe()
      else
        this.trackingsService.trackings.splice(
          this.trackingsService.trackings.findIndex((s) => s.id == tracking.id),
          1
        )
    })
  }

  copyDish(subsidiary_id: string, dates: any, menu_template_id: string, section_template_id: string): Observable<any> {
    const daterange: moment.Moment[] = this.func.createDaterange(dates, this.userService.user.subsidiary.show_weekends.value)
    let completed_copies = []
    daterange.forEach((date) => {
      let to_menu: Menu = this._create_destination_menu(date, menu_template_id)
      let to_menu_section: MenuSection = this._create_destination_menu_section(to_menu, section_template_id)
      let menu_dish_copy: MenuDish = this.copy(true, to_menu_section)
      menu_dish_copy.index.setValue(null)
      completed_copies.push(to_menu_section.addDishes([menu_dish_copy], subsidiary_id))
    })
    return forkJoin(completed_copies)
  }

  _create_destination_menu(date: moment.Moment, menu_template_id: string): Menu {
    return this.menusService.menuForDateAndMenuTemplateId(date, menu_template_id) || new Menu({ menu_template_id: menu_template_id, date: date })
  }
  _create_destination_menu_section(to_menu: Menu, section_template_id: string): MenuSection {
    return to_menu.section_for_section_template(section_template_id)
  }

  copy(reset_id?: boolean, menu_section?: MenuSection): MenuDish {
    let copy_of_menu_dish: MenuDish = new MenuDish(this.as_dict)
    if (menu_section) {
      copy_of_menu_dish.menu_section_id = menu_section.id
      copy_of_menu_dish.menu_section = menu_section
    }
    if (reset_id) copy_of_menu_dish.id = null
    return copy_of_menu_dish
  }

  save() {
    this.saving = true
    return this.menuDishesService.updateMenuDish(this.as_dict).pipe(
      tap((response) => {
        if (response) {
          this.changed.setValue(false)
          this.saved_menu_dish = JSON.stringify(this.as_dict)
        }
      })
    )
  }

  get as_dict(): IMenuDish {
    return {
      id: this.id,
      index: this.index?.value,
      menu_section_id: this.menu_section_id,
      menu_id: this.menu_id,
      recipe: this.recipe.as_dict,
      subsidiary_id: this.subsidiary_id
    }
  }
}
