import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'foodop-lib'

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css'],
  host: {
    class: 'l-z-index-100'
  }
})
export class NavigationBarComponent implements OnInit {
  showNavList = true
  @ViewChild('navbar') navbar: any
  @ViewChild('navlist') navlist: any
  @ViewChild('logo') logo: any

  activeRoute = '/welcome'

  constructor(private authenticationService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) {
    router.events.subscribe((event) => {
      if (event['url']) {
        console.log(event['url'])
        this.activeRoute = event['url']
      }
    })
  }

  ngOnInit() {
    this.checkWidth()

    window.addEventListener('resize', this.checkWidth.bind(this))

    console.log(this.activatedRoute.snapshot)
  }

  checkWidth() {
    console.log('checking')
    if (window.innerWidth < 700) {
      this.showNavList = false
    } else {
      this.showNavList = true
    }
  }

  onClickLogout() {
    this.authenticationService.logout()
  }
}
