import { IMenuPrintTemplateElement } from '../../../global.models'
import { BasicMenuPrintTemplateElement } from './basic-element.model'
import { MenuPrintTemplateMenuSectionElement } from './menu-section-element.model'
import { MenuPrintTemplateDishNameElement } from './dish-name-element.model'
import { MenuPrintTemplateDishDescriptionElement } from './dish-description-element.model'
import { MenuPrintTemplateDishIngredientsElement } from './dish-ingredients-element.model'
import { MenuPrintTemplateDishAllergensElement } from './dish-allergens-element.model'
import { MenuPrintTemplateDishTagsElement } from './dish-tags-element.model'
import { MenuPrintTemplateDishCO2Element } from './dish-co2-element.model'
import { MenuPrintTemplateMenuDateElement } from './menu-date-element.model'
import { MenuPrintTemplateCustomTextElement } from './custom-text-element.model'

export class MenuPrintTemplateMenuElement extends BasicMenuPrintTemplateElement {
  public menuElements: {
    emptySectionLabel: MenuPrintTemplateCustomTextElement
    menuDate: MenuPrintTemplateMenuDateElement
    sectionName: MenuPrintTemplateMenuSectionElement
    dishName: MenuPrintTemplateDishNameElement
    dishDescription: MenuPrintTemplateDishDescriptionElement
    dishIngredients: MenuPrintTemplateDishIngredientsElement
    dishAllergens: MenuPrintTemplateDishAllergensElement
    dishCO2: MenuPrintTemplateDishCO2Element
    dishTags: MenuPrintTemplateDishTagsElement
  }
  public translatable = false
  public removable = false
  public name: string = $localize`Menu`

  constructor(element: IMenuPrintTemplateElement) {
    super('menu')

    this.menuElements = {
      emptySectionLabel: new MenuPrintTemplateCustomTextElement(element.menuElements?.emptySectionLabel),
      menuDate: new MenuPrintTemplateMenuDateElement(element.menuElements?.menuDate),
      sectionName: new MenuPrintTemplateMenuSectionElement(element.menuElements?.sectionName),
      dishName: new MenuPrintTemplateDishNameElement(element.menuElements?.dishName),
      dishDescription: new MenuPrintTemplateDishDescriptionElement(element.menuElements?.dishDescription),
      dishIngredients: new MenuPrintTemplateDishIngredientsElement(element.menuElements?.dishIngredients),
      dishAllergens: new MenuPrintTemplateDishAllergensElement(element.menuElements?.dishAllergens),
      dishCO2: new MenuPrintTemplateDishCO2Element(element.menuElements?.dishCO2),
      dishTags: new MenuPrintTemplateDishTagsElement(element.menuElements?.dishTags)
    }
  }

  public get asDict(): IMenuPrintTemplateElement {
    return {
      type: this.type,
      menuElements: {
        emptySectionLabel: this.menuElements.emptySectionLabel.asDict,
        menuDate: this.menuElements.menuDate.asDict,
        sectionName: this.menuElements.sectionName.asDict,
        dishName: this.menuElements.dishName.asDict,
        dishDescription: this.menuElements.dishDescription.asDict,
        dishIngredients: this.menuElements.dishIngredients.asDict,
        dishAllergens: this.menuElements.dishAllergens.asDict,
        dishCO2: this.menuElements.dishCO2.asDict,
        dishTags: this.menuElements.dishTags.asDict
      }
    }
  }
}
