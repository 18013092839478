<div class="l-display-flex l-space-between l-position-center-vertically l-topmargin-medium">
    <input autocomplete="off"  matInput placeholder="Search for serial" [formControl]="gatewaySearchControl" class="l-width-300p">
</div>

<div *ngIf="gateways$ | async as gateways">
    <ng-container *ngIf="gateways.length; else noGateways">
        <ng-container *ngIf="organizations$ | async as organizations">
            <mat-card *ngFor="let gateway of gateways; let gateway_index=index" class="l-margin-medium">
                <div class="l-display-flex l-space-between l-position-center-vertically">

                    <label class="l-font-bold l-font-small"><mat-icon class="l-font-bold l-font-small o-size-mini l-margin-horizontal-3">tablet_android</mat-icon>{{gateway.id}} | {{gateway.serial}}</label>
            
                    <button (click)="viewGateway(gateway)">Se gateway</button>

                    <mat-form-field class="l-font-small l-width-30p">
                        <mat-label>Lokation</mat-label>
                        <mat-select #locationSelector [formControl]="gateway.location_id" (selectionChange)="gateway.save().subscribe()" (optionsScroll)="onScroll()">
                            <mat-select-trigger>{{gatewayLocationName(gateway)}}</mat-select-trigger>
                            <mat-option class="default-selection-option" [value]="gateway.location_id.value"></mat-option>
                            
                            <mat-option (click)="$event.stopPropagation();" class="option-level-0 mat-select-search-field">
                                <input (click)="$event.stopPropagation();" autocomplete="off" #customerSearchInput lang="en" [formControl]="customerSearchControl" type="text" matInput placeholder="Search for locations">
                            </mat-option>
            
                            <mat-option [value]="null">-- Ingen --</mat-option>
            
                            <ng-container *ngFor="let organization of organizations | organizationFilterPipe: customerSearchControl.value; let organizationIndex = index">
                                <mat-option [value]="organization.id" class="option-level-1" [ngStyle]="{'z-index': subsidiariesBeforeIndex(organizationIndex)+organizationIndex}" disabled>
                                    <div class="option-container">
                                        <label class="check-box-label non-selectable-label" [innerHTML]="organization.name.value | boldSearchQuery: customerSearchInput.value"></label>
                                    </div>
                                </mat-option>
                                <ng-container *ngFor="let subsidiary of organization.subsidiaries | subsidiaryFilterPipe: customerSearchControl.value; let subidiaryIndex = index">
                                    <mat-option [value]="subsidiary.id" class="option-level-2" [ngStyle]="{'z-index': subsidiariesBeforeIndex(organizationIndex)+subidiaryIndex}" disabled>
                                        <div class="sub-option-container">
                                            <label class="check-box-label non-selectable-label" [innerHTML]="subsidiary.name.value | boldSearchQuery: customerSearchInput.value"></label>
                                        </div>
                                    </mat-option>
                                    <mat-option *ngFor="let location of subsidiary.locations | locationFilterPipe: customerSearchControl.value" [value]="location.id">
                                        <div class="sub-sub-option-container">
                                            <label class="check-box-label" [innerHTML]="(location.name.value | boldSearchQuery: customerSearchInput.value)"></label>
                                        </div>
                                    </mat-option>
                                </ng-container>
                            </ng-container>
                            <mat-option *ngIf="loadingOrganizations" class="o-cursor-default">
                                <div class="l-display-flex l-position-center-vertically">
                                    <mat-spinner class="o-inline-block l-float-left" color="primary" diameter="19"></mat-spinner> 
                                    <label class="l-font-small l-line-height-19 l-padding-micro" i18n>Søger efter kunder ...</label>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
            
                </div>
            </mat-card>
        </ng-container>
    </ng-container>
    <ng-template #noGateways>
        <div *ngIf="(gatewaySearchControl.value || selectedLocationIds.length) && !loadingGateways" class="loading-container">
            <label>No gateways found!</label>
        </div>
    </ng-template>
</div>

<div *ngIf="loadingGateways" class="loading-container">
    <mat-spinner [diameter]="25" color="primary"></mat-spinner>
    <label>Loading gateways ...</label>
</div>

<div *ngIf="moreGatewaysAvailable" class="load-more-button-container">
    <button (click)="loadMoreGateways()" type="button" mat-button class="secondary-button">
        Load more gateways
    </button>
</div>
