import { FormBuilder, FormControl } from '@angular/forms'
import { EDisplayElementInformationType, EFunctionStatusCode, allDishDisplayElements, macro_nutrition_facts, tag_categories } from '../../global.types'
import { DisplayTemplate } from '../display-template/display-template.model'
import { IDisplayElementTemplate } from '../../global.models'
import { FoodopLibModule } from '../../foodop-lib.module'

export class DisplayElementTemplate {
  code: EDisplayElementInformationType

  shown: FormControl
  index: number

  min_font_size: FormControl
  max_font_size: FormControl
  max_font_size_original: FormControl
  font_size: FormControl

  min_lines: FormControl
  max_lines: FormControl
  max_lines_original: FormControl

  height_weight: FormControl

  row_index: FormControl
  col_index: FormControl

  font_family: FormControl
  text_alignment: FormControl

  boundary_x: FormControl
  boundary_y: FormControl
  boundary_width: FormControl
  boundary_height: FormControl

  show_may_contained_allergens: FormControl
  allergen_format: FormControl
  tag_categories: FormControl
  nutrition_types: FormControl

  context = document.createElement('canvas').getContext('2d')

  fb: FormBuilder

  constructor(display_element_template?: IDisplayElementTemplate) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.code = display_element_template?.code || EDisplayElementInformationType.title

    this.index = allDishDisplayElements.findIndex((el) => el.code == this.code)

    this.min_font_size = this.fb.control(display_element_template?.min_font_size || (this.code == EDisplayElementInformationType.title ? 20 : 14))
    this.max_font_size = this.fb.control(display_element_template?.max_font_size || 30)
    this.max_font_size_original = this.fb.control(display_element_template?.max_font_size || 30)

    this.font_size = this.fb.control(display_element_template?.font_size || display_element_template?.max_font_size || 30)

    this.min_lines = this.fb.control(display_element_template?.min_lines || 1)
    this.max_lines = this.fb.control(display_element_template?.max_lines || 3)
    this.max_lines_original = this.fb.control(display_element_template?.max_lines || 3)

    this.height_weight = this.fb.control(display_element_template?.height_weight || 5)

    this.row_index = this.fb.control(display_element_template?.row_index || 1)
    this.col_index = this.fb.control(display_element_template?.col_index || 1)

    this.font_family = this.fb.control(display_element_template?.font_family || 'Helvetica')
    this.text_alignment = this.fb.control(display_element_template?.text_alignment || 'left')

    this.show_may_contained_allergens = this.fb.control(display_element_template?.show_may_contained_allergens != null ? display_element_template.show_may_contained_allergens : false)
    this.allergen_format = this.fb.control(display_element_template?.allergen_format || 'definition')

    this.tag_categories = this.fb.control(display_element_template?.tag_categories || tag_categories.filter((tag_category) => ['dietary', 'meat_type', 'irritant'].includes(tag_category.code)))
    this.nutrition_types = this.fb.control(display_element_template?.nutrition_types || macro_nutrition_facts.filter((macro_nutrition_fact) => ['ENER-', 'FAT', 'CHOAVL', 'PRO-'].includes(macro_nutrition_fact.code)))

    this.boundary_x = this.fb.control(null)
    this.boundary_y = this.fb.control(null)
    this.boundary_width = this.fb.control(null)
    this.boundary_height = this.fb.control(null)
  }

  get has_reached_min_font_size(): boolean {
    if (this.font_size.value == this.min_font_size.value) console.warn("Minimum font size for element '" + this.code + "' has been reached at " + this.font_size.value)
    return this.font_size.value == this.min_font_size.value
  }

  get available_multiline_width(): number {
    return this.max_lines.value * (this.boundary_width.value - 5)
  }

  get font_size_slack(): number {
    return this.max_font_size.value - this.font_size.value
  }

  get name(): any {
    switch (this.code) {
      case EDisplayElementInformationType.title:
        return { value: $localize`Titel` }
      case EDisplayElementInformationType.description:
        return { value: $localize`Beskrivelse` }
      case EDisplayElementInformationType.ingredients:
        return { value: $localize`Ingredienser` }
      case EDisplayElementInformationType.allergens:
        return { value: $localize`Allergener` }
      case EDisplayElementInformationType.co2:
        return { value: $localize`CO2` }
      case EDisplayElementInformationType.tags:
        return { value: $localize`Tags` }
      case EDisplayElementInformationType.nutrition:
        return { value: $localize`Næringsindhold` }
      case EDisplayElementInformationType.scale_group:
        return { value: $localize`Vægtgruppe` }
      case EDisplayElementInformationType.scale_name:
        return { value: $localize`Vægt` }
      default:
        return { value: this.code }
    }
  }

  get font_size_options(): ReadonlyArray<number> {
    switch (this.code) {
      case EDisplayElementInformationType.title:
        return this._range(15, 50)
      case EDisplayElementInformationType.description:
        return this._range(15, 30)
      case EDisplayElementInformationType.ingredients:
        return this._range(15, 30)
      case EDisplayElementInformationType.allergens:
        return this._range(15, 30)
      case EDisplayElementInformationType.co2:
        return this._range(15, 30)
      case EDisplayElementInformationType.tags:
        return this._range(15, 30)
      case EDisplayElementInformationType.nutrition:
        return this._range(15, 30)
      case EDisplayElementInformationType.scale_group:
        return this._range(15, 30)
      case EDisplayElementInformationType.scale_name:
        return this._range(15, 30)
      default:
        return this._range(15, 30)
    }
  }

  get line_options(): ReadonlyArray<number> {
    switch (this.code) {
      case EDisplayElementInformationType.title:
        return this._range(1, 3)
      case EDisplayElementInformationType.description:
        return this._range(1, 3)
      case EDisplayElementInformationType.ingredients:
        return this._range(1, 3)
      case EDisplayElementInformationType.allergens:
        return this._range(1, 3)
      case EDisplayElementInformationType.co2:
        return this._range(1, 1)
      case EDisplayElementInformationType.tags:
        return this._range(1, 3)
      case EDisplayElementInformationType.nutrition:
        return this._range(1, 3)
      case EDisplayElementInformationType.scale_group:
        return this._range(1, 1)
      case EDisplayElementInformationType.scale_name:
        return this._range(1, 1)
      default:
        return this._range(1, 3)
    }
  }

  _range(startAt: number, endAt: number): ReadonlyArray<number> {
    return [...Array(endAt - startAt + 1).keys()].map((i) => i + startAt)
  }

  decrease_font_size(): void {
    this.font_size.setValue(this.font_size.value - 1)
  }
  decrease_max_font_size(): void {
    this.max_font_size.setValue(this.max_font_size.value - 1)
  }
  decrease_max_lines(): void {
    this.max_lines.setValue(this.max_lines.value - 1)
  }
  reset_max_lines(): void {
    this.max_lines.setValue(this.max_lines_original.value)
  }
  reset_max_font_size(): void {
    this.max_font_size.setValue(this.max_font_size_original.value)
  }
  reset_font_size(): void {
    this.font_size.setValue(this.max_font_size.value)
  }

  get as_dict(): IDisplayElementTemplate {
    return {
      code: this.code,
      min_font_size: this.min_font_size.value,
      max_font_size: this.max_font_size.value,
      font_size: this.font_size.value,
      min_lines: this.min_lines.value,
      max_lines: this.max_lines.value,
      height_weight: this.height_weight.value,
      row_index: this.row_index.value,
      col_index: this.col_index.value,
      font_family: this.font_family.value,
      font_type: this.font_family.value.toLowerCase(), // to accomodate old gateway logic
      text_alignment: this.text_alignment.value.toLowerCase(),
      boundary_x: this.boundary_x.value,
      boundary_y: this.boundary_y.value,
      boundary_width: this.boundary_width.value,
      boundary_height: this.boundary_height.value,
      x: this.boundary_x.value, // to accomodate old gateway logic
      y: this.boundary_y.value, // to accomodate old gateway logic
      width: this.boundary_width.value, // to accomodate old gateway logic
      height: this.boundary_height.value, // to accomodate old gateway logic
      show_may_contained_allergens: this.show_may_contained_allergens.value,
      allergen_format: this.allergen_format.value,
      tag_categories: this.tag_categories.value,
      nutrition_types: this.nutrition_types.value
    }
  }

  as_css(display_size_scale) {
    return {
      top: this.boundary_y.value * display_size_scale + 'px',
      left: this.boundary_x.value * display_size_scale + 'px',
      height: this.boundary_height.value * display_size_scale + 'px',
      width: this.boundary_width.value * display_size_scale + 'px',
      'font-size': this.font_size.value * display_size_scale + 'px',
      'line-height': this.font_size.value * display_size_scale + 'px',
      'font-family': this.font_family.value,
      'text-alignment': this.text_alignment.value
      //'border': 'red 1px solid'
    }
  }

  setDisplayElementBoundaries(display_template: DisplayTemplate): void {
    const sum_of_height_weights_in_display = display_template.selected_display_element_templates.value.map((display_element_template) => display_element_template.max_lines.value * display_element_template.min_font_size.value).reduce((a, b) => a + b, 0)
    const sum_of_row_gaps_in_display = (display_template.selected_display_element_templates.value.length - 1) * display_template.display_format.row_gap.value
    const elements_shown_before_this = display_template.selected_display_element_templates.value.filter((display_element_template) => display_element_template.row_index.value < this.row_index.value)
    const sum_of_row_gaps_in_display_before_this_element = elements_shown_before_this.length * display_template.display_format.row_gap.value
    const sum_of_height_weights_in_display_before_this_element = elements_shown_before_this.map((element_before_this) => element_before_this.max_lines.value * element_before_this.min_font_size.value).reduce((a, b) => a + b, 0)

    const element_height_share = (this.max_lines.value * this.min_font_size.value) / sum_of_height_weights_in_display
    const elements_shown_before_this_height_share = sum_of_height_weights_in_display_before_this_element / sum_of_height_weights_in_display
    const available_content_height = display_template.display_format.available_height - sum_of_row_gaps_in_display

    this.boundary_height.setValue(available_content_height * element_height_share)
    this.boundary_width.setValue(display_template.display_format.available_width)

    this.boundary_x.setValue(display_template.display_format.padding_left.value)
    this.boundary_y.setValue(display_template.display_format.padding_top.value + sum_of_row_gaps_in_display_before_this_element + elements_shown_before_this_height_share * available_content_height)

    this.adjustElementFormatToHeightBoundary()
  }

  adjustElementFormatToHeightBoundary(): EFunctionStatusCode {
    const max_lines_adjustment = this.adjustMaxLinesToHeightBoundary()
    if (max_lines_adjustment == EFunctionStatusCode.error) return max_lines_adjustment

    const max_font_size_adjustment = this.adjustMaxFontSizeToHeightBoundary()
    return max_font_size_adjustment
  }

  adjustMaxLinesToHeightBoundary(): EFunctionStatusCode {
    let doesMinFontSizeAndMaxLinesExceedBoundaryHeight = this.measureTextHeightWithFont('Gg', this.min_font_size.value, this.font_family.value) * this.max_lines.value > this.boundary_height.value
    while (doesMinFontSizeAndMaxLinesExceedBoundaryHeight) {
      if (this.max_lines.value > this.min_lines.value) this.decrease_max_lines()
      //else if(this.max_font_size.value>this.min_font_size.value) this.max_font_size.setValue(this.max_font_size.value-1);
      else {
        console.error('Display element template for ' + this.code + " is invalid. Minimum font size on 1 line can't fit inside height boundaries")
        break
      }
      doesMinFontSizeAndMaxLinesExceedBoundaryHeight = this.measureTextHeightWithFont('Gg', this.min_font_size.value, this.font_family.value) * this.max_lines.value > this.boundary_height.value
    }
    return doesMinFontSizeAndMaxLinesExceedBoundaryHeight ? EFunctionStatusCode.error : EFunctionStatusCode.success
  }

  adjustMaxFontSizeToHeightBoundary(): EFunctionStatusCode {
    let doesMaxFontSizeAndMinLinesExceedBoundaryHeight = this.measureTextHeightWithFont('Gg', this.max_font_size.value, this.font_family.value) * this.min_lines.value > this.boundary_height.value
    while (doesMaxFontSizeAndMinLinesExceedBoundaryHeight) {
      if (this.max_font_size.value > this.min_font_size.value) this.decrease_max_font_size()
      //else if(this.max_lines.value>this.min_lines.value) this.max_lines.setValue(this.max_lines.value-1);
      else {
        console.error('Display element template for ' + this.code + " is invalid. Minimum font size on 1 line can't fit inside height boundaries")
        break
      }
      doesMaxFontSizeAndMinLinesExceedBoundaryHeight = this.measureTextHeightWithFont('Gg', this.max_font_size.value, this.font_family.value) * this.min_lines.value > this.boundary_height.value
    }
    return doesMaxFontSizeAndMinLinesExceedBoundaryHeight ? EFunctionStatusCode.error : EFunctionStatusCode.success
  }

  measureTextHeightWithFont(string: string, font_size: number, font_family: string): number {
    this.context.font = font_size + 'px ' + font_family
    return this.context.measureText(string).fontBoundingBoxAscent + this.context.measureText(string).fontBoundingBoxDescent
  }
}
