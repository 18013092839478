
<mat-form-field class="l-width-full">
    <mat-label>ID</mat-label>
    <input autocomplete="off" matInput [formControl]="user.id">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Fornavn</mat-label>
    <input autocomplete="off" matInput [formControl]="user.first_name">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Efternavn</mat-label>
    <input autocomplete="off" matInput [formControl]="user.last_name">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Email</mat-label>
    <input autocomplete="off" matInput [formControl]="user.email">
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Access role</mat-label>
    <mat-select [formControl]="user.access_role_id" [value]="user.access_role_id.value">
        <mat-option *ngFor="let access_role of accessRolesService.access_roles" [value]="access_role.id">
            {{access_role.name}}
        </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field class="l-font-small l-width-full" (click)="$event.stopPropagation()">
    <mat-label>Subsidiary</mat-label>
    <mat-select #subsidiarySelector [formControl]="user.subsidiary_id" (selectionChange)="onUserSubsidiaryChanges(user)" (optionsScroll)="onScroll()">
        <mat-select-trigger>{{userSubsidiaryName(user)}}</mat-select-trigger>
        <mat-option class="default-selection-option" [value]="user.subsidiary_id.value"></mat-option>
        
        <mat-option (click)="$event.stopPropagation();" class="option-level-0 mat-select-search-field">
            <input (click)="$event.stopPropagation();" autocomplete="off" #customerSearchInput lang="en" [formControl]="customerSearchControl" type="text" matInput placeholder="Search for subsidiaries">
        </mat-option>

        <mat-option [value]="null">-- Ingen --</mat-option>

        <ng-container *ngFor="let organization of organizations$ | async | organizationFilterPipe: customerSearchControl.value; let organizationIndex = index">
            <mat-option [value]="organization.id" class="option-level-1" [ngStyle]="{'z-index': subsidiariesBeforeIndex(organizationIndex)+organizationIndex}" disabled>
                <div class="option-container">
                    <label class="check-box-label non-selectable-label" [innerHTML]="organization.name.value | boldSearchQuery: customerSearchInput.value"></label>
                </div>
            </mat-option>
            <ng-container *ngFor="let subsidiary of organization.subsidiaries | subsidiaryFilterPipe: customerSearchControl.value; let subidiaryIndex = index">
                <mat-option [value]="subsidiary.id">
                    <div class="sub-option-container">
                        <label class="check-box-label" [innerHTML]="subsidiary.name.value | boldSearchQuery: customerSearchInput.value"></label>
                    </div>
                </mat-option>
            </ng-container>
        </ng-container>
        <mat-option *ngIf="loadingOrganizations" class="o-cursor-default">
            <div class="l-display-flex l-position-center-vertically">
                <mat-spinner class="o-inline-block l-float-left" color="primary" diameter="19"></mat-spinner> 
                <label class="l-font-small l-line-height-19 l-padding-micro" i18n>Søger efter kunder ...</label>
            </div>
        </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Lokationsbegrænsning</mat-label>
    <mat-select [formControl]="user.location_id" [value]="user.location_id.value">
        <mat-option [value]="null">-- Ingen --</mat-option>
        <mat-option *ngFor="let location of user.subsidiary?.locations" [value]="location.id">{{location.name.value}}</mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Titel</mat-label>
    <mat-select [formControl]="user.title" [value]="user.title.value">
        <mat-option value="canteen_owner">Kantineejer</mat-option>
        <mat-option value="kitchen_manager">Køkkenchef</mat-option>
        <mat-option value="chef">Kok</mat-option>
        <mat-option value="other">Anden</mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field class="l-width-full">
    <mat-label>Foretrukken sprog</mat-label>
    <mat-select [formControl]="user.preferred_language" [value]="user.preferred_language.value">
        <mat-option value="da">Dansk</mat-option>
        <mat-option value="en">Engelsk</mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field *ngIf="user.organization?.subsidiaries?.length>1" class="l-font-small l-width-full" (click)="$event.stopImmediatePropagation();">
    <mat-label>Subsidiary adgange</mat-label>
    <mat-select multiple [formControl]="user.subsidiary_accesses" [value]="user.subsidiary_accesses.value" [compareWith]="compareSubsidiaryAccessesID">
        <mat-option *ngFor="let subsidiary of user.organization?.subsidiaries" [value]="subsidiary.as_dict" [disabled]="subsidiary.id == user.subsidiary_id.value">
            {{subsidiary.name.value}}
        </mat-option>
    </mat-select>
</mat-form-field>

<button type="button" mat-button [disabled]="!user.valid || !user.changed" (click)="saveUser()" class="l-float-right l-margin-horizontal-medium l-bottommargin-large primary-button" ><span ><mat-spinner *ngIf="user.saving" [diameter]="19" class="o-inline-block"></mat-spinner></span><span i18n>Gem rettelser</span></button>

