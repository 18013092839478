import { IMenuPrintTemplateElement, IName } from '../../../global.models'
import { BasicMenuPrintTemplateElement } from './basic-element.model'
import { MenuPrintTemplateStringElementFormat } from '../formats/string-format.model'
import { FormControl } from '@angular/forms'
import moment from 'moment'

export class MenuPrintTemplateMenuDateElement extends BasicMenuPrintTemplateElement {
  public format: MenuPrintTemplateStringElementFormat
  public dateFormat: FormControl
  public removable = true
  public translatable = false
  public name: IName

  constructor(element: IMenuPrintTemplateElement) {
    super('menuDate')

    this.name = element?.name || { da: 'Menu dato', en: 'Menu date' }
    this.format = new MenuPrintTemplateStringElementFormat(element?.format)
    this.dateFormat = new FormControl(element?.dateFormat || 'dddd, Do MMMM YYYY')
    this._listenForDateFormatChanges()
  }

  public get asDict(): IMenuPrintTemplateElement {
    return {
      type: this.type,
      format: this.format.asDict,
      dateFormat: this.dateFormat.value
    }
  }

  private _listenForDateFormatChanges() {
    this.dateFormat?.valueChanges.subscribe(() => {
      this.dateFormat?.setValue(this.dateFormat?.value)
    })
  }
  public elementString(date: FormControl, language: string): string {
    moment.locale(language, {
      week: {
        dow: 1
      }
    })
    return this.func.caseString(moment(date.value).format(this.dateFormat.value), this.format.capitalization.value)
  }
}
