import { FormBuilder, FormControl } from '@angular/forms'
import { FoodopLibModule } from '../../../foodop-lib.module'
import { IMenuPrintTemplatePageFormat } from '../../../global.models'
import { CustomValidators } from '../../../validators/number_validator'

export class MenuPrintTemplatePageFormat {
  width: FormControl
  height: FormControl
  orientation: FormControl
  menusOrientation: FormControl
  emptySectionDisplay: FormControl

  backgroundColor: FormControl

  marginTop: FormControl
  marginRight: FormControl
  marginBottom: FormControl
  marginLeft: FormControl

  columnGap: FormControl

  content_width: FormControl
  pageSplit: FormControl

  saved_page_formats: any
  fb: FormBuilder

  constructor(public menuPrintTemplatePageFormat?: IMenuPrintTemplatePageFormat) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this.width = this.fb.control(menuPrintTemplatePageFormat?.width)
    this.content_width = this.fb.control(menuPrintTemplatePageFormat?.content_width)
    this.height = this.fb.control(menuPrintTemplatePageFormat?.height)

    this.orientation = this.fb.control(menuPrintTemplatePageFormat?.orientation || 'portrait')
    this.pageSplit = this.fb.control(menuPrintTemplatePageFormat?.pageSplit || 'section')
    this.menusOrientation = this.fb.control(menuPrintTemplatePageFormat?.menusOrientation || 'horizontal')
    this.emptySectionDisplay = this.fb.control(menuPrintTemplatePageFormat?.emptySectionDisplay || 'hide')

    this.backgroundColor = this.fb.control({ value: menuPrintTemplatePageFormat?.backgroundColor || '#ffffff', disabled: false }, [CustomValidators.isValidColor])

    this.marginTop = this.fb.control(menuPrintTemplatePageFormat?.marginTop != undefined ? menuPrintTemplatePageFormat?.marginTop : 0)
    this.marginRight = this.fb.control(menuPrintTemplatePageFormat?.marginRight != undefined ? menuPrintTemplatePageFormat?.marginRight : 0)
    this.marginBottom = this.fb.control(menuPrintTemplatePageFormat?.marginBottom != undefined ? menuPrintTemplatePageFormat?.marginBottom : 0)
    this.marginLeft = this.fb.control(menuPrintTemplatePageFormat?.marginLeft != undefined ? menuPrintTemplatePageFormat?.marginLeft : 0)

    this.columnGap = this.fb.control(menuPrintTemplatePageFormat?.columnGap != undefined ? menuPrintTemplatePageFormat?.columnGap : 10)

    this.saved_page_formats = JSON.stringify(this.asDict)
  }

  get asDict(): IMenuPrintTemplatePageFormat {
    return {
      width: this.width.value,
      content_width: this.content_width.value,
      height: this.height.value,

      orientation: this.orientation.value,
      pageSplit: this.pageSplit.value,
      menusOrientation: this.menusOrientation.value,
      emptySectionDisplay: this.emptySectionDisplay.value,

      backgroundColor: this.backgroundColor.value,

      marginTop: this.marginTop.value,
      marginRight: this.marginRight.value,
      marginBottom: this.marginBottom.value,
      marginLeft: this.marginLeft.value,

      columnGap: this.columnGap.value
    }
  }
}
