import { moveItemInArray } from '@angular/cdk/drag-drop'
import { Component, Input, OnInit } from '@angular/core'
import { ScaleGroup, Location } from 'foodop-lib'

@Component({
  selector: 'scale-groups',
  templateUrl: './scale-groups.component.html',
  styleUrls: ['./scale-groups.component.css']
})
export class ScaleGroupsComponent implements OnInit {
  @Input() location: Location

  constructor() {}

  ngOnInit(): void {}

  addScaleGroup() {
    this.location.scale_groups.push(new ScaleGroup({ index: this.location.scale_groups.length, type: this.location.scale_groups.length ? this.location.scale_groups[0].type.value : 'dish' }))
  }

  moveScaleGroup(event) {
    moveItemInArray(this.location.scale_groups, event.previousIndex, event.currentIndex)
    this.location.scale_groups.forEach((scale_group, index) => {
      scale_group.index.setValue(index, { emitEvent: false })
    })
  }

  deleteScaleGroup(scale_group_index: number) {
    this.location.scale_groups.splice(scale_group_index, 1)
    this.location.scale_groups.forEach((scale_group, index) => {
      scale_group.index.setValue(index)
    })
  }
}
