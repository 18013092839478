import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Component, ComponentRef, EventEmitter, Inject, OnInit, Output, ViewContainerRef } from '@angular/core'
import { Subscription } from 'rxjs'
import { ScaleAndDatePickerComponent } from '../scale-dish-picker/scale-dish-picker.component'
import { ENotificationType, Gateway, GlobalFunctionsService, INJECTION_DATA, INotificationPOSTRequest, IPushNotification, LocationsService, RestApiService, WebsocketsService } from 'foodop-lib'
import moment from 'moment'

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.css'],
  host: {
    class: 'l-position-relative l-width-full l-height-full l-theme-white l-overflow l-box-shadow l-border-radius'
  }
})
export class GatewayComponent implements OnInit {
  @Output() closeGatewayView = new EventEmitter()

  gateway: Gateway
  status: string
  notificationSubscription: Subscription
  notificationStatus = {}

  overlayRef: OverlayRef

  constructor(
    public websocketsService: WebsocketsService,
    private restApiService: RestApiService,
    private locationsService: LocationsService,
    @Inject(INJECTION_DATA) public initData: any,
    public func: GlobalFunctionsService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef
  ) {
    this.gateway = initData.gateway
  }

  ngOnInit(): void {
    this.websocketsService.disconnect()
    this.status = 'Forbinder til gateway ...'
    this.websocketsService.connect('adminapp')
    this.websocketsService.connected.subscribe((status: Boolean) => {
      if (status) {
        this.listenForGatewayPing(this.gateway)
        this.websocketsService.pingGateway(this.gateway)
        this.gateway.requestMenderInformation().subscribe()
      }
    })

    this.locationsService.getLocation(this.gateway.location_id.value).subscribe()
  }

  ngOnDestroy() {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe()
    }
  }

  /*********************************************/
  /*        GATEWAY COMMUNICATION          */
  /*********************************************/
  listenForGatewayPing(gateway: Gateway) {
    this.notificationSubscription = this.websocketsService.notification.subscribe((notification: IPushNotification) => {
      if (gateway.id === notification.source) {
        console.log('WebSocket: notification of type ' + notification.type + ' originates from this gateway: ' + notification.source)
        switch (notification.type) {
          case ENotificationType.Ping: {
            this.status = 'Forbundet til gateway!'
            gateway.last_interaction = moment()
            break
          }
        }
      }
    })
  }

  restartGateway() {
    this.notificationStatus['restartGateway'] = 'sending'

    const data: INotificationPOSTRequest = {
      destination: this.gateway.id,
      notification: {
        type: ENotificationType.RestartGateway,
        source: 'cloud'
      }
    }
    this.restApiService.postNotification(data).subscribe(
      () => {
        this.notificationStatus['restartGateway'] = 'sent'
        setTimeout(() => {
          this.notificationStatus['restartGateway'] = null
        }, 3000)
      },
      () => {
        this.notificationStatus['restartGateway'] = 'failed'
        setTimeout(() => {
          this.notificationStatus['restartGateway'] = null
        }, 3000)
      }
    )
  }

  pickScaleAndDate() {
    let config = new OverlayConfig()
    config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
    config.hasBackdrop = true

    this.overlayRef = this.overlay.create(config)

    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose()
    })

    this.overlayRef.addPanelClass('l-max-width-70p')
    this.overlayRef.addPanelClass('l-max-height-70p')
    this.overlayRef.addPanelClass('l-overflow')

    const compRef: ComponentRef<ScaleAndDatePickerComponent> = this.overlayRef.attach(
      new ComponentPortal(
        ScaleAndDatePickerComponent,
        this.viewContainerRef,
        this.func.createInjector({
          gateway: this.gateway
        })
      )
    )

    compRef.instance.cancel.subscribe(() => {
      this.overlayRef.dispose()
    })
    compRef.instance.getDataForScaleMaccAndDate.subscribe((selection) => {
      this.overlayRef.dispose()
      this.getScaleData(selection.scale_macc, selection.date)
    })
  }

  getScaleData(scale_macc, date) {
    this.notificationStatus['getScaleData'] = 'sending'

    const data: INotificationPOSTRequest = {
      destination: this.gateway.id,
      notification: {
        type: ENotificationType.GetScaleData,
        source: 'cloud',
        data: {
          scale: scale_macc,
          date: this.func._localDateFormatter(date)
        }
      }
    }
    this.restApiService.postNotification(data).subscribe(
      () => {
        this.notificationStatus['getScaleData'] = 'sent'
        setTimeout(() => {
          this.notificationStatus['getScaleData'] = null
        }, 3000)
      },
      () => {
        this.notificationStatus['getScaleData'] = 'failed'
        setTimeout(() => {
          this.notificationStatus['getScaleData'] = null
        }, 3000)
      }
    )
  }

  getScaleStatus() {
    this.notificationStatus['getScaleStatus'] = 'sending'

    const data: INotificationPOSTRequest = {
      destination: this.gateway.id,
      notification: {
        type: ENotificationType.ReadScaleStatus,
        source: 'cloud',
        scales: this.gateway?.scales?.map((scale) => {
          return { macc: scale.macc }
        })
      }
    }
    this.restApiService.postNotification(data).subscribe(
      () => {
        this.notificationStatus['getScaleStatus'] = 'sent'
        setTimeout(() => {
          this.notificationStatus['getScaleStatus'] = null
        }, 3000)
      },
      () => {
        this.notificationStatus['getScaleStatus'] = 'failed'
        setTimeout(() => {
          this.notificationStatus['getScaleStatus'] = null
        }, 3000)
      }
    )
  }

  clearGatewaySchedule() {
    this.notificationStatus['clearGatewaySchedule'] = 'sending'

    const data: INotificationPOSTRequest = {
      destination: this.gateway.id,
      notification: {
        type: ENotificationType.ClearGatewaySchedule,
        source: 'cloud'
      }
    }
    this.restApiService.postNotification(data).subscribe(
      () => {
        this.notificationStatus['clearGatewaySchedule'] = 'sent'
        setTimeout(() => {
          this.notificationStatus['clearGatewaySchedule'] = null
        }, 3000)
      },
      () => {
        this.notificationStatus['clearGatewaySchedule'] = 'failed'
        setTimeout(() => {
          this.notificationStatus['clearGatewaySchedule'] = null
        }, 3000)
      }
    )
  }

  updateGatewaySchedule() {
    this.notificationStatus['updateGatewaySchedule'] = 'sending'

    const data: INotificationPOSTRequest = {
      destination: this.gateway.id,
      notification: {
        type: ENotificationType.UpdateGatewaySchedule,
        source: 'cloud'
      }
    }
    this.restApiService.postNotification(data).subscribe(
      () => {
        this.notificationStatus['updateGatewaySchedule'] = 'sent'
        setTimeout(() => {
          this.notificationStatus['updateGatewaySchedule'] = null
        }, 3000)
      },
      () => {
        this.notificationStatus['updateGatewaySchedule'] = 'failed'
        setTimeout(() => {
          this.notificationStatus['updateGatewaySchedule'] = null
        }, 3000)
      }
    )
  }

  recreateGatewayDB() {
    this.notificationStatus['recreateGatewayDB'] = 'sending'

    const data: INotificationPOSTRequest = {
      destination: this.gateway.id,
      notification: {
        type: ENotificationType.RecreateGatewayDB,
        source: 'cloud'
      }
    }
    this.restApiService.postNotification(data).subscribe(
      () => {
        this.notificationStatus['recreateGatewayDB'] = 'sent'
        setTimeout(() => {
          this.notificationStatus['recreateGatewayDB'] = null
        }, 3000)
      },
      () => {
        this.notificationStatus['recreateGatewayDB'] = 'failed'
        setTimeout(() => {
          this.notificationStatus['recreateGatewayDB'] = null
        }, 3000)
      }
    )
  }
}
