import { IDomain } from '../../global.models'
import { EDomains } from '../../global.types'

export class Domain {
  id: string
  name: EDomains

  constructor(public domain?: IDomain) {
    this.id = domain?.id
    this.name = domain.name
  }

  get as_dict(): IDomain {
    return {
      id: this.id,
      name: this.name
    }
  }
}
