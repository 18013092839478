import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { Location } from './location.model'
import { ILocation } from '../../global.models'
import { RestApiService } from '../../services/rest-api.service'

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  locations: Location[] = []

  constructor(public fb: FormBuilder, private restApiService: RestApiService, @Inject(LOCALE_ID) public language: string) {}

  getLocation(location_id: string): Observable<ILocation> {
    const params = {
      fields: 'all',
      active: true,
      location_id
    }
    return this.restApiService.loadLocation(params).pipe(
      tap((location) => {
        this.addToLocations(location)
      })
    )
  }

  getLocations(active?: boolean): Observable<ILocation[]> {
    let params = {
      fields: 'all'
    }
    if (active != undefined) params['active'] = active
    return this.restApiService.loadLocations(params).pipe(
      tap((locations) => {
        locations.forEach((location) => {
          this.addToLocations(location)
        })
      })
    )
  }

  addToLocations(location: ILocation): void {
    const existing_location: Location = this.location_with_id(location.id)
    if (!existing_location) this.locations.push(new Location(location))
    else existing_location.patchValues(location)
  }

  location_with_id(location_id: string): Location {
    return this.locations.find((location) => location.id == location_id)
  }

  get active_locations(): Location[] {
    return this.locations.filter((location) => location.active.value)
  }
  get active_locations_sorted(): Location[] {
    return this.active_locations.sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }
  get inactive_locations(): Location[] {
    return this.locations.filter((location) => !location.active.value)
  }
  get inactive_locations_sorted(): Location[] {
    return this.inactive_locations.sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }
  get active_dish_locations(): Location[] {
    return this.active_locations.filter((location) => location.dish_scale_groups.length)
  }
  get active_dish_locations_sorted(): Location[] {
    return this.active_locations.filter((location) => location.dish_scale_groups.length).sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }
  get active_dish_and_display_locations_sorted(): Location[] {
    return this.active_locations.filter((location) => location.dish_and_display_scale_groups.length).sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }
  get active_waste_locations(): Location[] {
    return this.active_locations.filter((location) => location.waste_scale_groups.length)
  }
  get active_waste_locations_sorted(): Location[] {
    return this.active_locations.filter((location) => location.waste_scale_groups.length).sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }
  get active_dish_waste_locations(): Location[] {
    return this.active_locations.filter((location) => location.waste_scale_groups.length && location.dish_scale_groups.length)
  }
  get active_dish_waste_locations_sorted(): Location[] {
    return this.active_locations.filter((location) => location.waste_scale_groups.length && location.dish_scale_groups.length).sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }
  get active_dish_or_dish_waste_locations(): Location[] {
    return [...this.active_dish_locations, ...this.active_dish_waste_locations]
  }
  get all_dish_locations(): Location[] {
    return this.locations.filter((location) => location.dish_scale_groups.length)
  }
  get all_waste_locations(): Location[] {
    return this.locations.filter((location) => location.waste_scale_groups.length)
  }
  get all_dish_waste_locations(): Location[] {
    return this.locations.filter((location) => location.waste_scale_groups.length && location.dish_scale_groups.length)
  }

  saveLocation(location: Location): Observable<ILocation> {
    if (location.is_new) return this.restApiService.createLocation(location.as_dict).pipe(tap(() => this.locations.push(location)))
    else return this.restApiService.updateLocation(location.as_dict)
  }

  get locations_sorted(): Location[] {
    return [...this.active_locations_sorted, ...this.inactive_locations_sorted]
  }
}
