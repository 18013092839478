import { FormBuilder, FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { Scale } from '../scale/scale.model'
import { GatewaysService } from './gateways.service'
import { FoodopLibModule } from '../../foodop-lib.module'
import { IGateway } from '../../global.models'
import { Location } from '../location/location.model'
import { LocationsService } from '../location/locations.service'
import moment from 'moment'
import { ScalesService } from '../scale/scales.service'

export class Gateway {
  id: string
  serial: string
  name: FormControl
  environment: FormControl
  location_id: FormControl
  last_seen_by_mender: moment.Moment
  last_interaction: moment.Moment
  mender_status: string
  software_version: string

  saved_gateway: {}
  saving: boolean = false

  gatewaysService: GatewaysService
  locationsService: LocationsService
  scalesService: ScalesService
  fb: FormBuilder

  constructor(gateway: IGateway) {
    this.gatewaysService = FoodopLibModule.injector.get(GatewaysService)
    this.locationsService = FoodopLibModule.injector.get(LocationsService)
    this.scalesService = FoodopLibModule.injector.get(ScalesService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)

    this._storeRelatedObjects(gateway)

    this.id = gateway?.id
    this.serial = gateway?.serial
    this.name = this.fb.control(gateway?.name)
    this.environment = this.fb.control(gateway?.environment)
    this.location_id = this.fb.control(gateway?.location_id)
    if (gateway?.last_seen_by_mender) this.last_seen_by_mender = moment(moment.utc(gateway?.last_seen_by_mender).format())
    if (gateway?.last_interaction) this.last_interaction = moment(gateway?.last_interaction)
    this.mender_status = gateway?.mender_status
    this.software_version = gateway?.software_version

    this.saved_gateway = JSON.stringify(this.as_dict)
  }

  public get scales(): Scale[] {
    return this.scalesService.scales.filter((scale) => scale.gateway_id.value == this.id)
  }

  patchValues(gateway: IGateway): void {
    this._storeRelatedObjects(gateway)

    if (gateway?.id) this.id = gateway.id
    if (gateway?.serial) this.serial = gateway.serial
    if (gateway?.name) this.name.setValue(gateway.name)
    if (gateway?.environment) this.environment.setValue(gateway.environment)
    if (gateway?.location_id) this.location_id.setValue(gateway.location_id)
    if (gateway?.last_seen_by_mender) this.last_seen_by_mender = moment(gateway.last_seen_by_mender)
    if (gateway?.last_interaction) this.last_interaction = moment(gateway.last_interaction)
    if (gateway?.mender_status) this.mender_status = gateway.mender_status
    if (gateway?.software_version) this.software_version = gateway.software_version

    this.saved_gateway = JSON.stringify(this.as_dict)
  }

  get minutes_since_last_seen(): number {
    return moment().diff(this.last_seen, 'minutes')
  }
  get last_seen(): moment.Moment {
    if (this.last_seen_by_mender && this.last_interaction) return moment.max(this.last_seen_by_mender, this.last_interaction)
    if (this.last_seen_by_mender) return this.last_seen_by_mender
    if (this.last_interaction) return this.last_interaction
    return null
  }

  get time_since_last_seen_label(): string {
    if (!this.last_seen) return $localize`Never seen`
    if (this.minutes_since_last_seen < 60) return this.minutes_since_last_seen + ' minutes ago'
    if (this.minutes_since_last_seen < 60 * 24) return moment().diff(this.last_seen, 'hours') + ' hours ago'
    return moment().diff(this.last_seen, 'days') + ' days ago'
  }

  get online(): boolean {
    return this.minutes_since_last_seen != undefined ? (this.minutes_since_last_seen < 60 * 6 ? true : false) : false
  }

  get location(): Location {
    return this.locationsService.location_with_id(this.location_id.value)
  }

  requestMenderInformation(): Observable<Gateway> {
    return this.gatewaysService.requestMenderInformation(this).pipe(
      tap((gateway: IGateway) => this.patchValues(gateway)),
      map(() => {
        return this
      })
    )
  }

  save(): Observable<Gateway> {
    this.saving = true
    return this.gatewaysService.saveGateway(this).pipe(
      tap((gateway) => {
        this.patchValues(gateway)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  get as_dict(): IGateway {
    return {
      id: this.id,
      serial: this.serial,
      name: this.name.value,
      environment: this.environment.value,
      location_id: this.location_id.value,
      scales: this.scales.map((scale) => scale.as_dict)
    }
  }

  private _storeRelatedObjects(gateway: IGateway): void {
    gateway['scales']?.forEach((scale) => this.scalesService.addToScales(Object.assign(scale, { gateway_id: gateway.id })))
  }
}
