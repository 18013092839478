import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Cache } from './cache';
import { CacheEntry, MAX_CACHE_AGE } from './cache-entry';

@Injectable()
export class CacheMapService implements Cache  {

    cacheMap = new Map<string, CacheEntry>();

    get(req: HttpRequest<any>): HttpResponse<any> | null {
        let entry;
        if (req.method == 'POST') {
            entry = this.cacheMap.get(req.urlWithParams + JSON.stringify(req.body));
        }
        else{
            entry = this.cacheMap.get(req.urlWithParams);
        }
        if (!entry) {
            return null;
        }
        if ((Date.now() - entry.entryTime) > MAX_CACHE_AGE) {
            return null;
        }
        else {
            return entry.response;
        }
    }

    put(req: HttpRequest<any>, res: HttpResponse<any>): void {
        const entry: CacheEntry = { url: req.urlWithParams, request: req, response: res, entryTime: Date.now() };
        if (req.method == 'POST') {
            this.cacheMap.set(req.urlWithParams + JSON.stringify(req.body), entry);
        }
        else{
            this.cacheMap.set(req.urlWithParams, entry);
        }
        this.deleteExpiredCache();
    }
    
    private deleteExpiredCache() {
        this.cacheMap.forEach(entry => {
            if ((Date.now() - entry.entryTime) > MAX_CACHE_AGE) {
                this.cacheMap.delete(entry.url);
            }
        })
    }
    public deleteCacheEntryForURL(url) {
        this.cacheMap.forEach(entry => {
            if (entry.url.includes(url)) {
                this.cacheMap.delete(entry.url);
                if (entry.request.method == 'POST') {
                    this.cacheMap.delete(entry.request.urlWithParams + JSON.stringify(entry.request.body));
                }
            }
        })
    }
} 