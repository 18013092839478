import { Pipe, PipeTransform } from '@angular/core'
import { Organization } from '../models/organization/organization.model'

@Pipe({ name: 'organizationFilterPipe' })
export class OrganizationFilterPipe implements PipeTransform {
  public transform(organizations: Organization[], filter: string): any[] {
    const name_filter = filter?.toLowerCase()

    if (!organizations || !organizations.length) return []
    if (!name_filter) return organizations

    let filtered_organizations = organizations.filter((organization: Organization) => {
      if (organization.name.value?.toLowerCase()?.indexOf(name_filter) >= 0) return true
      if (organization.nick_name.value?.toLowerCase()?.indexOf(name_filter) >= 0) return true
      if (organization.subsidiaries.find((subsidiary) => subsidiary.name.value?.toLowerCase()?.indexOf(name_filter) >= 0 || subsidiary.nick_name.value?.toLowerCase()?.indexOf(name_filter) >= 0) != undefined) return true
      if (organization.subsidiaries.find((subsidiary) => subsidiary.locations.find((location) => location.name.value?.toLowerCase()?.indexOf(name_filter) >= 0)) != undefined) return true
    })
    return filtered_organizations
  }
}
