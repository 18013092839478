<mat-tab-group mat-align-tabs="center" animationDuration="800ms">
    <mat-tab label="Kunder">
        <ng-template matTabContent>
            <organizations></organizations>
        </ng-template>
    </mat-tab>

    <mat-tab label="Brugere">
        <ng-template matTabContent>
            <users></users>
        </ng-template>
    </mat-tab>
</mat-tab-group>