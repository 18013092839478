import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'boldSearchQuery' })
export class BoldSearchQueryPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (!args) {
      return value
    }
    if (!value) {
      return value
    }

    const regex = new RegExp(args, 'gi')
    const match = value?.match(regex)

    if (!match) {
      return value
    }

    return value.replace(regex, `<span class='l-font-bold'>${match[0]}</span>`)
  }
}
